// eslint-disable-next-line
import React, { useState } from "react";
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    input, textarea {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 15px;
        box-sizing: border-box;
        font-size: 14px;
    }

    button {
        background: #0563bb;
        border: 0;
        padding: 10px 35px;
        color: #fff;
        transition: background 0.4s;
        border-radius: 50px;
        display: flex;
        justify-content: flex-start;
    }

    button:hover {
        background: #0678e3;
    }
`;

export function FeedBackPage() {
    return (
        <Container>
            <form>
                <h2 style={{ display: 'flex', justifyContent: 'flex-start' }}>Virhe ilmoitus</h2>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '48%' }}>
                        <input type="text" name="name" id="name" placeholder="Nimesi *" required />
                    </div>
                    <div style={{ width: '48%' }}>
                        <input type="email" name="email" id="email" placeholder="Sähköposti osoitteesi *" required />
                    </div>
                </div>

                <input type="text" name="subject" id="subject" placeholder="Osion nimi *" required />

                <p style={{ margin: '10px 0 5px 0', opacity: 0.8, fontStyle: 'italic' }}>Kuvaile virheesi mahdollisimman tarkasti, jotta voimme itse testata tätä ja mahdollisesti korjata.</p>
                <textarea name="message" rows={5} placeholder="Viestisi *" required></textarea>

                <button type="submit">Lähetä</button>
            </form>
        </Container>
    );
}
