import React from 'react';
import { toggleLabel } from '../../utils';
import Icon from '../Icon';
import Button, { ButtonProps } from './Button';

interface Props extends ButtonProps {
  toggled: boolean;
}

const ToggleButton: React.FC<Props> = ({
  toggled,
  text,
  color = 'primary',
  variant = 'link',
  ...rest
}) => (
  <Button
    text={toggleLabel(toggled, text ?? '')}
    color={color}
    variant={variant}
    icon={<Icon type={!toggled ? 'Visible' : 'NotVisible'} color={color} />}
    {...rest}
  />
);

export default ToggleButton;
