import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmDelete, formatDateTime, useDelayedAction } from '../../utils';
import { emptyRecognisedControl } from '../../views/auditingSections/planning/itAndProcessesUtils';
import { ContextInfoProps } from '../ContextInfo';
import { TextArea, TextInput } from '../inputs';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'itAndProcesses';

const tBase = `auditing:form.${sectionKey}.businessProcesses.processDescription`;

interface Props extends FormFieldProps<ItAndProcessesForm> {
  businessProcess: BusinessProcess;
  manageRecognisedControls?: boolean;
}

export const ProcessDescription = ({
  businessProcess,
  manageRecognisedControls,
  formState,
  patchFormState,
  store,
}: Props) => {
  const { t } = useTranslation();
  const { auditingLocked, isAuditingSectionFinished } = store.auditingStore;

  const communicatedDateData = formState.communicatedDate ?? '';
  const communicatedDetailsData = formState.communicatedDetails ?? '';
  //communicatedDate: ISODate | null; --> 2024-09-09T00:00:00.000+03:00 (example)
  //communicatedDetails: string; --> 'example'

  const disabled = auditingLocked || isAuditingSectionFinished(sectionKey);

  const processDescription = businessProcess.processDescription;

  const originalCommunicatedDetails =
      //processDescription?.communicatedDetails ?? ('' || communicatedDetailsData);
      communicatedDetailsData ?? processDescription?.communicatedDetails ?? '';

  const [communicatedDetails, setCommunicatedDetails] = useState(
    originalCommunicatedDetails
  );

  const originalCommunicatedDate = communicatedDateData ?? processDescription?.communicatedDate ?? '';
  const [communicatedDate, setCommunicatedDate] = useState(
    originalCommunicatedDate
  );

  const originalDescription = processDescription?.description ?? '';
  const [description, setDescription] = useState(originalDescription);

  const originalRecognisedControls =
    processDescription?.recognisedControls ?? [];

  const collectFormData = (): ProcessDescription => ({
    communicatedDetails,
    communicatedDate,
    description,
    recognisedControls: originalRecognisedControls,
  });

  // Send form patch after a short delay to detect when user stopped typing
  useDelayedAction(() => {
    const detailsChanged = communicatedDetails !== originalCommunicatedDetails;
    const dateChanged = communicatedDate !== originalCommunicatedDate;
    const descriptionChanged = description !== originalDescription;

    const changedMade = detailsChanged || dateChanged || descriptionChanged;
    if (changedMade) {
      const patch: BusinessProcess[] = formState.businessProcesses.map(item => {
        if (item.id !== businessProcess.id) return item;
        return { ...item, processDescription: collectFormData() };
      }) ?? [];
      patchFormState({ ...formState, businessProcesses: patch });
    }
  }, 500);

  if (!formState) return null;

  const updateBusinessProcess = (businessProcess: BusinessProcess) => {
    const patch: BusinessProcess[] = formState.businessProcesses.map(item => {
      if (item.id !== businessProcess.id) return item;
      return businessProcess;
    });
    patchFormState({ ...formState, businessProcesses: patch });
  };

  const updateProcessDescription = (processDescription: ProcessDescription) => {
    updateBusinessProcess({ ...businessProcess, processDescription });
  };

  const updateRecognisedControls = (
    recognisedControls: RecognisedControl[]
  ) => {
    updateProcessDescription({
      ...businessProcess.processDescription,
      recognisedControls,
    });
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    updateRecognisedControls(
      businessProcess.processDescription.recognisedControls.map(
        (row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
          return row;
        }
      )
    );
  }

  const handleAddNewRecognisedControl = () => {
    updateRecognisedControls([
      ...businessProcess.processDescription.recognisedControls,
      emptyRecognisedControl(),
    ]);
  };

  const handleDeleteRecognisedControl = ({ id, name }: RecognisedControl) => {
    if (confirmDelete(name ?? '')) {
      updateRecognisedControls(
        businessProcess.processDescription.recognisedControls.filter(
          row => row.id !== id
        )
      );
    }
  };

  const recognisedControlsTableColumns: TableColumnDefs<RecognisedControl> = [
    {
      accessorKey: 'name',
      header: () => t(`${tBase}.recognisedControls.name`),
      className: 'width-half',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'isTrusted',
      className: 'text-center width-min',
      header: () => t(`${tBase}.recognisedControls.isTrusted`),
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
    },
  ];

  const getContextInfo = (): ContextInfoProps | undefined => {
    if (businessProcess.kind && !businessProcess.kind.includes('custom')) {
      const text = t(`${tBase}.${businessProcess.kind}Info`);
      if (text.length) return { position: 'bottom-right', text };
    }
  };

  const formatDate = (isoDate: ISODate) => {
    return isoDate?.length ? formatDateTime(isoDate, { format: 'DDD' }) : '';
  };

  const contextInfo = getContextInfo();

  const communicatedDateLabel = t(`${tBase}.communicatedDate`, {
    value: formatDate(communicatedDate),
  });

  // Detect link in description
  const regex = /https?:\/\/[www]?\S+\.\w{1,3}\S+/g
  const detectedLinks = String(description).match(regex)

  return (
    <>
      <h6>{businessProcess.label}</h6>

      <div className="flex-row flex-row-gap">
        <div className="flex-1">
          <TextInput
            label={t(`${tBase}.communicatedDetails`)}
            value={communicatedDetails}
            setValue={setCommunicatedDetails}
            placeholder={t(`${tBase}.communicatedDetailsInfo`)}
            contextInfo={{
              text: t(`${tBase}.communicatedDetailsInfo`),
            }}
            disabled={disabled}
            fullWidth
          />
        </div>

        <div className="flex-1">
          <TextInput
            type="date"
            label={communicatedDateLabel}
            value={communicatedDate ?? ''}
            setValue={setCommunicatedDate}
            disabled={disabled}
            fullWidth
          />
        </div>
      </div>

      <TextArea
        label={t('common:label.description')}
        value={description}
        placeholder={contextInfo?.text}
        contextInfo={contextInfo}
        setValue={setDescription}
        disabled={disabled}
        fullWidth
      />

      {/* Render links here */}
      {detectedLinks && detectedLinks.map((link, index) => {
        return (
          <a key={index} href={link} target="__blank">
            {link}
            <br />
          </a>
        );
      }
      )}

      {manageRecognisedControls && (
        <AuditingTable<RecognisedControl>
          sectionKey={sectionKey}
          onAddNewRow={handleAddNewRecognisedControl}
          data={originalRecognisedControls}
          columns={recognisedControlsTableColumns}
          onRowDelete={handleDeleteRecognisedControl}
          showGlobalFilter={false}
          disableSort
        />
      )}
    </>
  );
};
