import { t } from '../../i18n';
import { useQuery } from './useQuery';

/**
 * Returns query parameter values for given keys
 *
 * For example:
 * const { year, week } = useQueryParams('year', 'week');
 * when url is .../something?year=2021&week=1
 */
export const useQueryParams = (...params: string[]) => {
  const query = useQuery();

  // Try locale translation first and plain english after
  const getQueryParam = (param: string) =>
    query.get(t(param).toLowerCase()) ?? query.get(param);

  const values: { [key: string]: string | null } = {};

  params.forEach(param => (values[param] = getQueryParam(param)));

  return values;
};
