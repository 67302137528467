import { FormFieldBuilder } from '../FormFieldBuilder';
import { CustomerVerificationTable, CustomerVerificationTable1 } from '../../../components/essentials/customerVerification.Customer';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import Scanner, { FreezingScanner } from '../../../components/essentials/communityInformation.Scanner';

type SectionFormType = CustomerVerificationForm;
const sectionKey: AuditingSectionKey = 'CustomerVerification';
export const tBase = `auditing:form.${sectionKey}`;

const vocabulary = auditingJson.form.CustomerVerification;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  customerVerificationTable: [
    {
      id: '1',
      label: vocabulary.verification.label.representative,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.verification.label.whereSecured,
      inputValue: 'Kaupparekisteriote 29.4.2024'
    },
    {
      id: '3',
      label: vocabulary.verification.label.nameVerification,
      inputValue: 'Johdon vahvistuskirje, joka allekirjoitettu sähköisesti...'
    }
  ],
  customerVerificationTable1: [
    {
      id: '1',
      label: vocabulary.verification.label.beneficialIdentity,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.verification.label.nameVerification,
      inputValue: ''
    }
  ],

    textArea: '',
    carriedTextArea: '',
    otherRisk: '',
    //Boolean -fields
    countriesButton: null,
    politalButton: null,
    monitoringButton: null,

    totalRiskAssessment: null, // Needed elsewhere

    customerCarriedBasis: null,
    transactionsButton: null,
    documentOther: null,
    customerCarried: '',

    // From permanentData
    properIdentificationOfRepresentatives: null,
    properIdentificationOfRepresentativesDetails: '',

    // Performance improvement
    chooseIndustry: 'choose',
    riskAssessment: 'medium',
    commentBetter: 'Dokumentoi toimiala ja perustelut.',

    // Maat ja maantieteelliset alueet
    CountriesGeoAreas: 'choose',
    CountriesRiskAssessment: 'medium',
    CountriesCommentBetter: 'Dokumentoi valtiot ja tietolähde.',
    // Lisätietoa
    gatherMoreInfo: 'choose',
    gatherMoreInfoBusiness: 'choose',
    gatherMoreInfoCustomer: 'choose',
    gatherMoreInfoTransaction: 'choose',
    gatherMoreInfoContinue: 'choose',
    organizeEnchanced: 'choose',
    documentBetterCountry: 'choose',
    documentBetterCountryInfo: 'Dokumentoi mitä lisätoimenpiteitä on arvion mukaan tarpeen toteuttaa ja miten tämä on toteutettu.',

    // Poliittisesti vaikuvaltaiset henkilöt
    pep: 'choose',
    pepInfo: 'Esim. Eero Edustaja, yhtiön edustaja, ks. Tarkemmin sharepoint index X, PEP-lomakkeen tiedot',
    pep2: 'choose',
    pep2Info: 'Esim. Ella Edunsaaja, yhtiön tosiasiallinen edunsaaja, ks. Tarkemmin sharepoint index X, PEP-lomakkeen tiedot',
    obtainSeniorManagement: 'choose',
    obtainSeniorManagementInfo: 'Kuka on hyväksynyt ja milloin? Tallenna hyväksyntä indexoituna sharepoint ja lisää tähän työpaperiin viittaus dokumenttiin.',
    takeAppropriateMeasures: 'choose',
    takeAppropriateMeasuresInfo: 'Dokumentoi varojen alkuperä ja viittaa sharepoint liittyvään asiakirjaan.',
    organizeEnchancedCustomer: 'choose',
    organizeEnchancedCustomerInfo: 'Dokumentoi miten tehostettu jatkuva asiakassuhteen seuranta on toteutettu.',
    impactOnRiskPEP: 'medium',

    // Pakote- ja jäädyttämispäätösten seuranta
    isCustomerFreezeList: 'choose',
    isCustomerFreezeListInfo: '',
    isEUsanctions: 'choose',
    isEUsanctionsInfo: '',
    obtainSeniorManagementSanction: 'choose',
    obtainSeniorManagementSanctionInfo: 'Esim. Marko Paasovaara xx.xx.xxxx sähköposti. Sähköposti tallennettu sharepointiin index..Asiakas hyväksytään / toimeksiantoa jatketaan / ei hyväksytä / jatketa.',
    impactOnRiskPEP2: 'medium',

    // Epäilyttävät liiketoimet
    transactionsThatDiffer: 'choose',
    transactionsThatObvious: 'choose',
    transactionThatInconsistent: 'choose',
    askACustomer: 'choose',
    askACustomerInfo: 'Onko liiketoimelle saatu luonnollinen peruste? Mikä? Dokumentoi. Jos ei, dokumentoi erikseen, mikäli johtuu siitä, ettei selvityksiä ole saatu.',
    evaluate: 'choose',
    evaluateInfo: 'Anna tarvittaessa lisätietoa.',
    assessWheather: 'choose',
    assessWheatherInfo: vocabulary.assesWheatherInfo,
    assessWheather2: 'choose',
    assessWheather2Info: vocabulary.assesWheatherInfo2,
    impactOnRiskPEP3: 'medium',

    // Vähäiseen riskiin viittavat tekijät
    publiclyTradedCompany: 'choose',
    publiclyTradedCompanyInfo: '',
    customerPublicEntity: 'choose',
    customerPublicEntityInfo: '',
    customersPlaceResidence: 'choose',
    customersPlaceResidenceInfo: '',
    impactOnRiskPEP4: 'medium',

    // Korkeaan riskiin viittaavat tekijät
    transactionIsCarried: 'choose',
    transactionIsCarriedInfo: '',
    legalEntity: 'choose',
    legalEntityInfo: '',
    companyHasATrustee: 'choose',
    companyHasATrusteeInfo: '',
    productsTransactions: 'choose',
    productsTransactionsInfo: '',
    lotOfCashPayments: 'choose',
    lotOfCashPaymentsInfo: '',
    transActionIsCarriedOut: 'choose',
    transActionIsCarriedOutInfo: '',
    companysOwnership: 'choose',
    companysOwnershipInfo: '',
    impactOnRiskPEP5: 'medium',
})

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  /* formBuilder.text({}), lisätty tiettyihin kohtiin, jotta saamme lisää etäisyyttä toisita. */
  /* formBuilder.text({}), added to give more space between tablets. */

  formBuilder.accordionGroup({
    titleKey: 'verification.title',
    customRemarkTriggers: ['verification.title'],
    open: true,
    items: [
        formBuilder.custom(props => (<CustomerVerificationTable {...props} />)),
        formBuilder.custom(props => (<CustomerVerificationTable1  {...props} />)),
        formBuilder.boolean({
          accessor: 'properIdentificationOfRepresentatives',
          options: ['no', 'yes'],
          detailsAccessor: 'properIdentificationOfRepresentativesDetails',
        }),
    ],
  }),
  formBuilder.accordionGroup({
    open: true,
    titleKey: 'CustomerSpecific.title',
    customRemarkTriggers: ['CustomerSpecific.title'],
    items: [

        // Make this more dynamic and performance friendly
        // Toimiala
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Toimiala'}),
                // Valitse
                formBuilder.select({
                  accessor: 'chooseIndustry', 
                  options: Object.keys(vocabulary.chooseIndustryOptions),
                }),
                // Riskiarvio
                formBuilder.select({
                  accessor: 'riskAssessment', 
                  options: Object.keys(vocabulary.riskAssessmentOptions)
            }),
            formBuilder.textArea({
              accessor: 'commentBetter',
              showContextInfo: 'bottom-right',
            }),
          ],
        }),

        // Maat ja maantieteelliset alueet
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Maat ja maantieteelliset alueet'}),
            // Valitse
            formBuilder.select({
              accessor: 'CountriesGeoAreas', 
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            // Riskiarvio
            formBuilder.select({
              accessor: 'CountriesRiskAssessment',
              plainLabel: 'Riskiarvio',
              options: Object.keys(vocabulary.riskAssessmentOptions),
              optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
            }),
            formBuilder.textArea({
              accessor: 'CountriesCommentBetter',
              showContextInfo: 'bottom-right',
            }),
          ],
        }),
        // Lisätietoa
        formBuilder.accordionGroup({
          title: 'Maat ja maantieteelliset alueet lisätietoa',
          open: true,
          hidden: !(formState.CountriesGeoAreas === 'yes'),
          items: [
            formBuilder.group({
              items: [
                formBuilder.subtitle({title: 'Toteuta seuraavat toimenpiteet'}),
                formBuilder.select({
                  accessor: 'gatherMoreInfo',
                  plainLabel: 'Hanki lisätietoa asiakkaasta ja tosiallisesta edunsaajasta.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'gatherMoreInfoBusiness',
                  plainLabel: 'Hanki lisätietoa perustettavan liikesuhteen tarkoituksesta.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'gatherMoreInfoCustomer',
                  plainLabel: 'Hanki lisätietoja asiakkaan ja tosiallisen edunsaajan varojen ja varallisuuden alkuperästä.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'gatherMoreInfoTransaction',
                  plainLabel: 'Hanki lisätietoja liiketoimien syistä.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'gatherMoreInfoContinue',
                  plainLabel: 'Hanki ylemmän johdon hyväksyntä asiakasuhteen aloittamiselle ja jatkamiselle.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'organizeEnchanced',
                  plainLabel: 'Järjestä tehostettu jatkuva asiakassuhteen seuranta lisäämällä tarkastusten määrää lukumääräisesti ja ajoituksellisesti sekä valitsemalla liiketoimia, joita on selvitettävä laajemmin.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.select({
                  accessor: 'documentBetterCountry',
                  plainLabel: 'Dokumentoi tarkemmin mitkä toimet arvioitu tarpeelliseksi, millä perusteella ja mitä toteutettu.',
                  options: Object.keys(vocabulary.chooseIndustryOptions),
                  optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
                }),
                formBuilder.textArea({
                  accessor: 'documentBetterCountryInfo',
                  //showContextInfo: 'bottom-right',
                  hidden: !(formState.documentBetterCountry === 'yes'),
                }),
              ]
            }),
          ]
        }),

        // Poliittisesti vaikuvaltaiset henkilöt
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Poliittisesti vaikuvaltaiset henkilöt'}),
            formBuilder.select({
              accessor: 'pep',
              plainLabel: 'Onko asiakas tai tämän perjeenjäsen tai yhtiökumppani tai onko hän ollut poliittisesti vaikutusvaltainen henkilö (PEP)?',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({
              accessor: 'pepInfo',
              plainLabel: 'Dokumentoi tarkemmin',
            }),
            formBuilder.select({
              accessor: 'pep2',
              plainLabel: 'Onko yksi tai useampi tosiasiallisen edunsaaja tai tämän perjeenjäsen tai yhtiökumppani poliittisesti vaikutusvaltainen henkilö (PEP)?',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({
              accessor: 'pep2Info',
              plainLabel: 'Dokumentoi tarkemmin',
            }),
          ],
        }),
        // Lisätietoa
        formBuilder.accordionGroup({
          title: 'Poliittisesti vaikutusvaltaiset henkilöt lisätietoa',
          open: true,
          hidden: !(formState.pep === 'yes' || formState.pep2 === 'yes'),
          items: [
            formBuilder.group({
              items: [
                formBuilder.subtitle({title: 'Jos vastasit vähintään yhteen kysymykseen kyllä, toteuta seuraavat toimenpiteet'}),
                formBuilder.select({
                  accessor: 'obtainSeniorManagement',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.textArea({accessor: 'obtainSeniorManagementInfo', plainLabel: 'Dokumentoi tarkemmin'}),
                formBuilder.select({
                  accessor: 'takeAppropriateMeasures',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.textArea({accessor: 'takeAppropriateMeasuresInfo', plainLabel: 'Dokumentoi tarkemmin'}),
                formBuilder.select({
                  accessor: 'organizeEnchancedCustomer',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.textArea({accessor: 'organizeEnchancedCustomerInfo', plainLabel: 'Dokumentoi tarkemmin'}),
                formBuilder.select({
                  accessor: 'impactOnRiskPEP',
                  options: Object.keys(vocabulary.riskAssessmentOptions),
                  optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
                })
              ]
            })
          ]
        }),

        // Pakote- ja jäädyttämispäätösten seuranta
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Pakote- ja jäädyttämispäätösten seuranta'}),
            formBuilder.select({
              accessor: 'isCustomerFreezeList',
              plainLabel: 'Onko asiakas (yhteisö, edustaja, tosiasiallinen edunsaaja) kansallisella jäädytyslistalla?',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({
              accessor: 'isCustomerFreezeListInfo',
              plainLabel: 'Dokumentoi tarkemmin',
              hidden: formState.isCustomerFreezeList !== 'yes',
            }),
            formBuilder.select({
              accessor: 'isEUsanctions',
              plainLabel: 'Onko asiakas (yhteisö, edustaja, tosiasiallinen edunsaaja) EU:n pakotelistoilla?',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({
              accessor: 'isEUsanctionsInfo',
              plainLabel: 'Dokumentoi tarkemmin',
              hidden: formState.isEUsanctions !== 'yes',
            }),
          ]
        }),
        // Lisätietoa
        formBuilder.accordionGroup({
          title: 'Pakote- ja jäädyttämispäätösten seuranta lisätietoa',
          open: true,
          hidden: !(formState.isCustomerFreezeList === 'yes' || formState.isEUsanctions === 'yes'),
          items: [
            formBuilder.group({
              items: [
                formBuilder.subtitle({title: 'Jos vastasit vähintään yhteen kysymykseen kyllä, toteuta seuraavat toimenpiteet'}),
                formBuilder.select({
                  accessor: 'obtainSeniorManagementSanction',
                  plainLabel: 'Hanki toimeksiannon hyväksymiselle tai jatkamiselle ylemmän johdon hyväksyntä.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.textArea({accessor: 'obtainSeniorManagementSanctionInfo', plainLabel: 'Dokumentoi tarkemmin'}),
                formBuilder.select({
                  accessor: 'impactOnRiskPEP2',
                  options: Object.keys(vocabulary.riskAssessmentOptions),
                  optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
                })
              ]
            })
          ],
        }),

        // Epäilyttävät liiketoimet
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Epäilyttävät liiketoimet'}),
            formBuilder.text({text: 'Onko tilintarkastuksen yhteydessä havaittu seuraavia:'}),
            formBuilder.select({
              accessor: 'transactionsThatDiffer',
              plainLabel: 'Rakenteeltaan tai suuruudeltaan poikkeavat tai ilmoitusvelvollisen koon tai toimipaikan osalta epätavalliset liiketoimet.',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.select({
              accessor: 'transactionsThatObvious',
              plainLabel: 'Liiketoimet, joilla ei ole ilmeistä taloudellista tarkoitusta.',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.select({
              accessor: 'transactionThatInconsistent',
              plainLabel: 'Liiketoimet, jotka eivät sovi yhteen  sen kokemuksen tai tietojen kanssa, jotka on saatu asiakkaasta.',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
          ]
        }),
        // Lisätietoa
        formBuilder.accordionGroup({
          title: 'Epäilyttävät liiketoimet lisätietoa',
          open: true,
          hidden: !(formState.transactionsThatDiffer === 'yes' || formState.transactionsThatObvious === 'yes' || formState.transactionThatInconsistent === 'yes'),
          items: [
            formBuilder.group({
              items: [
                formBuilder.subtitle({title: 'Jos vastasit vähintään yhteen kysymykseen kyllä, toteuta seuraavat toimenpiteet'}),
                formBuilder.select({
                  accessor: 'askACustomer',
                  plainLabel: 'Pyydä asiakkaalta selvityksiä epäilyttävään liiketoimeen liittyen ja keskeytä toimeksianto selvittelytyön ajaksi.',
                  options: Object.keys(vocabulary.implementedOptions),
                  optionDisplayValue: (key: string) => vocabulary.implementedOptions[key as keyof typeof vocabulary.implementedOptions],
                }),
                formBuilder.textArea({accessor: 'askACustomerInfo', plainLabel: 'Onko liiketoimelle saatu luonnollinen peruste?'}),
                formBuilder.select({
                  accessor: 'evaluate',
                  plainLabel: 'Arvioi, vaikuttaako epäilyttävä liiketoimi toimeksiannon jatkamiseen.',
                  options: Object.keys(vocabulary.chooseIndustryOptions),
                  optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
                }),
                formBuilder.textArea({accessor: 'evaluateInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.evaluate === 'yes')}),
                formBuilder.select({
                  accessor: 'assessWheather',
                  plainLabel: 'Arvioi onko epäilyttävien liiketoimeen liittyvien varojen alkuperä arvion mukaan tarpeen selvittää?',
                  options: Object.keys(vocabulary.chooseIndustryOptions),
                  optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
                }),
                formBuilder.textArea({accessor: 'assessWheatherInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.assessWheather === 'yes')}),
                formBuilder.select({
                  accessor: 'assessWheather2',
                  plainLabel: 'Arvioi onko epäilyttävästä liiketoimesta tehtävä ilmoitus Keskusrikospoliisin selvittelykeskukselle. Ilmoitusta koskee salassapitovelvollisuus. Joten ilmoituksen tekemistä ei dokumentoida erikseen?',
                  options: Object.keys(vocabulary.chooseIndustryOptions),
                  optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
                }),
                formBuilder.textArea({accessor: 'assessWheather2Info', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.assessWheather2 === 'yes')}),
                formBuilder.select({
                  accessor: 'impactOnRiskPEP3',
                  options: Object.keys(vocabulary.riskAssessmentOptions),
                  optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
                })
              ]
            }),
          ],
        }),

        // Vähäiseen riskiin viittavat tekijät
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Vähäiseen riskiin viittavat tekijät'}),
            formBuilder.select({
              accessor: 'publiclyTradedCompany',
              plainLabel: 'Asiakas on julkisen kaupankäynnin kohteena oleva yhtiö?',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'publiclyTradedCompanyInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.publiclyTradedCompany === 'yes')}),
            formBuilder.select({
              accessor: 'customerPublicEntity',
              plainLabel: 'Asiakas on julkisyhteisö tai julkinen yritys',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'customerPublicEntityInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.customerPublicEntity === 'yes')}),
            formBuilder.select({
              accessor: 'customersPlaceResidence',
              plainLabel: 'Asiakkaan asuin- tai kotipaikkana on maantieteellinen vähäisemmän riskin alue.',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'customersPlaceResidenceInfo', plainLabel: 'Dokumentoi tarkemmin', showContextInfo: 'bottom-right', hidden: !(formState.customersPlaceResidence === 'yes')}),
            formBuilder.select({
              accessor: 'impactOnRiskPEP4',
              options: Object.keys(vocabulary.riskAssessmentOptions),
              optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
            }),
          ]
        }),
        // Lisätietoa

        // Korkeaan riskiin viittaavat tekijät
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Korkeaan riskiin viittaavat tekijät'}),
            formBuilder.select({
              accessor: 'transactionIsCarried',
              plainLabel: 'Liiketoimi toteutetaan epätavallisissa olosuhteissa',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'transactionIsCarriedInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.transactionIsCarried === 'yes')}),

            formBuilder.select({
              accessor: 'legalEntity',
              plainLabel: 'Henkilökohtaisten varojen hallintaan käytetään oikeushenkilöä tai oikeudellisia järjestelyjä',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'legalEntityInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.legalEntity === 'yes')}),

            formBuilder.select({
              accessor: 'companyHasATrustee',
              plainLabel: 'Yrityksessä on hallintarekisteröinnin hoitaja tai sen osakkeet on laskettu liikkeeseen haltijaosakkeina',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'companyHasATrusteeInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.companyHasATrustee === 'yes')}),

            formBuilder.select({
              accessor: 'productsTransactions',
              plainLabel: 'Tuotteet ja liiketoimet saattavat vaikeuttaa asiakkaan tai tosiasiallisen edunsaajan tunnistamista',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'productsTransactionsInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.productsTransactions === 'yes')}),
            
            formBuilder.select({
              accessor: 'lotOfCashPayments',
              plainLabel: 'Yritystoiminnassa kätetään paljon käteissuorituksia',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'lotOfCashPaymentsInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.lotOfCashPayments === 'yes')}),

            formBuilder.select({
              accessor: 'transActionIsCarriedOut',
              plainLabel: 'Liiketoimi toteutetaan huomattavana käteissuorituksena',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'transActionIsCarriedOutInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.transActionIsCarriedOut === 'yes')}),

            formBuilder.select({
              accessor: 'companysOwnership',
              plainLabel: 'Yrityksen omistussuhteet vaikuttavat epätavallisilta tai liian monimutkaisilta verrattunayrityksen liiketoiminnan luonteeseen',
              options: Object.keys(vocabulary.chooseIndustryOptions),
              optionDisplayValue: (key: string) => vocabulary.chooseIndustryOptions[key as keyof typeof vocabulary.chooseIndustryOptions],
            }),
            formBuilder.textArea({accessor: 'companysOwnershipInfo', plainLabel: 'Dokumentoi tarkemmin', hidden: !(formState.companysOwnership === 'yes')}),

            formBuilder.select({
              accessor: 'impactOnRiskPEP5',
              options: Object.keys(vocabulary.riskAssessmentOptions),
              optionDisplayValue: (key: string) => vocabulary.riskAssessmentOptions[key as keyof typeof vocabulary.riskAssessmentOptions],
            }),
          ]
        }),

        // Muut mahdolliset riskiarvioon vaikuttavat tekijät
        formBuilder.text({}),
        formBuilder.textArea({
          accessor: 'otherRisk',
          showContextInfo: 'bottom-right',
        }),

        // Asiakaskohtainen kokonaisriskiarvio
        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'totalRiskAssessment',
              options: Object.keys(auditingJson.fullRiskValueOptions), 
              optionDisplayValue: (key: string) => auditingJson.fullRiskValueOptions[key as keyof typeof auditingJson.fullRiskValueOptions]
            }),
            formBuilder.textArea({
              accessor: 'textArea', 
              showContextInfo: 'bottom-right', 
              hidden: formState.totalRiskAssessment 
              ? !(formState.totalRiskAssessment.hasOwnProperty("lowRisk") && formState.totalRiskAssessment.lowRisk === true) 
              && !(formState.totalRiskAssessment.hasOwnProperty("highRisk") && formState.totalRiskAssessment.highRisk === true)
              : true,
            }),
          ]
        }),

        // Arvion perusteella asiakkaan tunteminen toteutetaan
        formBuilder.group({
          items: [
            formBuilder.checkboxGroup({
              accessor: 'customerCarriedBasis',
              options: Object.keys(auditingJson.simpleOptions), 
              optionDisplayValue: (key: string) => auditingJson.simpleOptions[key as keyof typeof auditingJson.simpleOptions]
            }),
            formBuilder.textArea({
              accessor: 'customerCarried', 
              showContextInfo: 'bottom-right', 
              hidden: formState.customerCarriedBasis 
              ? !(formState.customerCarriedBasis.hasOwnProperty("simple") && formState.customerCarriedBasis.simple === true) 
              && !(formState.customerCarriedBasis.hasOwnProperty("force") && formState.customerCarriedBasis.force === true)
              : true,
            }),
          ]
        }),
    ],
  }),
  // Scanners
  formBuilder.accordionGroup({
    title: 'Pakotelistan nimien tarkistus',
    open: false,
    items: [
      formBuilder.group({
        items: [
          //formBuilder.text({text: "Tarkistus tehdään hallituksen 'Jäsenet' ja 'Varajäsenet',  Myös lisätyistä 'Lisäjäsen' 'Varajäsen' kohdista."}),
          formBuilder.custom(props => (<Scanner fieldToScan={["Jäsenet", "Varajäsenet", "Lisäjäsen", "Varajäsen", "Toimitusjohtaja", "Tosiasialliset edunsaajat ja omistusosuus"]} {...props} />)),
        ]
      })
    ]
  }),
  formBuilder.accordionGroup({
    title: 'Jäädyttämispakotelistan nimien tarkistus',
    open: false,
    items: [
      formBuilder.custom(props => (<FreezingScanner fieldToScan={["Jäsenet", "Varajäsenet", "Lisäjäsen", "Varajäsen", "Toimitusjohtaja", "Tosiasialliset edunsaajat ja omistusosuus"]} {...props} />)),
    ]
  }),


];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;