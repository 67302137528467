import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../components/layout/Wrapper';
import { useStore } from '../store/RootContext';
import Table, { TableColumnDefs } from '../components/table/Table';
import { confirmDelete } from '../utils';
import Modal from '../components/Modal';
import { getAddress } from '../utils/company';
import CompanyForm from '../components/forms/CompanyForm';
import { useFetchAuditFirms, useFetchCustomerCompanies } from '../utils/hooks';
import Divider from '../components/Divider';
import { Select } from '../components/inputs';

export const CustomerCompanies: React.FC = observer(() => {
  const { t } = useTranslation(['customerCompanies', 'common']);

  const {
    authStore: {
      role: { isAdmin },
    },
    customerCompanyStore,
    auditFirmStore,
  } = useStore();

  const { allEntities, deleteEntity } = customerCompanyStore;

  const [stagedEntity, setStagedEntity] = useState<Partial<CustomerCompany>>();

  // Additional form data
  const [auditFirm, setAuditFirm] = useState<AuditFirm>();

  useFetchCustomerCompanies();

  useFetchAuditFirms();

  useEffect(() => {
    setAuditFirm(auditFirmStore.findEntity(stagedEntity?.auditFirmId));
  }, [auditFirmStore, stagedEntity?.auditFirmId]);

  const clearStagedEntity = () => setStagedEntity(undefined);

  const handleAddNew = () => {
    setStagedEntity({});
  };

  const handleEdit = (entity: CustomerCompany) => {
    setStagedEntity(entity);
  };

  const handleDelete = (entity: CustomerCompany) => {
    if (confirmDelete(entity.name)) {
      deleteEntity({ id: entity.id });
    }
  };

  const handleSubmit = async (company: Omit<Company, 'id'>) => {
    const data: Omit<CustomerCompany, 'id'> = {
      ...company,
      auditFirmId: auditFirm?.id,
    };

    let success: boolean | undefined;

    if (stagedEntity?.id) {
      const response = await customerCompanyStore.editEntity({
        id: stagedEntity.id,
        data,
      });
      success = response.success;
    } else {
      const response = await customerCompanyStore.addEntity({ data });
      success = response.success;
    }

    if (success) clearStagedEntity();
  };

  const columns: TableColumnDefs<CustomerCompany> = [
    {
      accessorKey: 'businessId',
      header: () => t('common:label.businessId'),
      className: 'nowrap',
    },
    {
      accessorKey: 'name',
      header: () => t('customerCompanies:label.name'),
      className: 'text-bold',
    },
    {
      id: 'address',
      accessorFn: row => getAddress(row),
      header: () => t('common:label.address'),
      cell: info => info.getValue(),
      sortingFn: (a, b) =>
        getAddress(a.original).localeCompare(getAddress(b.original)),
    },
    {
      accessorKey: 'contactName',
      header: () => t('common:label.contactName'),
    },
    {
      accessorKey: 'contactEmail',
      header: () => t('common:label.email'),
    },
    {
      accessorKey: 'contactPhone',
      header: () => t('common:label.phone'),
    },
  ];

  return (
    <Wrapper>
      <h1 data-testid="view-title">{t('title')}</h1>
      <Divider />

      <Table<CustomerCompany>
        data={allEntities ?? []}
        columns={columns}
        onAddNew={isAdmin && handleAddNew}
        onRowEdit={isAdmin && handleEdit}
        onRowDelete={isAdmin && handleDelete}
        showIdColumn
      />

      {stagedEntity && (
        <Modal
          title={t(`customerCompanies:${stagedEntity.id ? 'edit' : 'add'}`)}
          onClose={clearStagedEntity}
          isInitiallyOpen
        >
          <CompanyForm company={stagedEntity} onSubmit={handleSubmit}>
            <Select<AuditFirm>
              autocomplete
              label={t('common:label.currentAuditFirm')}
              options={auditFirmStore.allEntities ?? []}
              value={auditFirm}
              setValue={setAuditFirm}
              displayValue={(firm: AuditFirm) => firm?.name}
              disabled={!isAdmin}
              nullable
              fullWidth
            />
          </CompanyForm>
        </Modal>
      )}
    </Wrapper>
  );
});

export default CustomerCompanies;
