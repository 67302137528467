import i18n from '../../../i18n';
import { makeEmptyBalanceComparison } from './balanceComparisonUtils';

export const makeDefaultPayAccrualsComparisonRows = () => {
  const tBase = 'auditing:form.auditingProcedures.balanceMatching';
  return [
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.vacationPayWithAssociatedCostsAccount`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}:vacationPayWithoutAssociatedCostsAccount`),
    }),
  ];
};
