import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { OtherOwnCapitalForm, OwnCapitalForm, ThirdOwnCapitalForm } from './notReUsables/ownCapitalForms';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = OwnCapitalForm;

const sectionKey: AuditingSectionKey = 'ownCapital';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = "R1-ownCapital";
const jsonVocab = auditingJson.form.ownCapital;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  //const incomeStatement: IncomeStatementAndBalanceItem[] = auditing?.incomeStatementAndBalance?.form?.incomeStatement || [];
  //const balanceAssets: IncomeStatementAndBalanceItem[] = auditing?.incomeStatementAndBalance?.form?.balanceAssets || [];
  const balanceLiabilities: IncomeStatementAndBalanceItem[] = auditing?.incomeStatementAndBalance?.form?.balanceLiabilities || [];
  return {
  procedureAccountsManualData: [{
    currentYear: 0,
    priorYear: 0,
    accountName: "",
    comment: ""
  }],
  procedureAccountsData: {},
  tableColumnData: [
    {
      left: "Oma pääoma on olemassa",
      right: auditingJson.OLE
    },
    {
      left: "Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin, ja velat ovat yhteisön velvoitteita",
      right: auditingJson.OVE
    },
    {
      left: "Oma pääoma, joka olisi pitänyt kirjata, on kirjattu, ja kaikki niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksessä, on esitetty siinä.",
      right: auditingJson.TAY
    },
    {
      left: "Oma pääoma sisältyy tilinpäätökseen asianmukaisessa rahamäärässä ja arvostamisesta tai kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti, ja kyseisiä eriä koskevat tilinpäätöksessä esitettävättiedot on määritetty ja kuvattu asianmukaisesti",
      right: auditingJson.ARV
    },
    {
      left: "Oma pääoman erät on kirjattu oikeille tileille.",
      right: auditingJson.LUOK
    },
    {
      left: "Oma pääoma on yhdistelty tai jaoteltu asianmukaisesti ja kuvattu selvästi, ja siihen liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston vaatimukset huomioon ottaen",
      right: auditingJson.ESI
    },
  ],

    TwoFieldSubtractionFormData: {
     "1": [] 
    },
    SumFormData: {
      "1": [
        {
          left: 'Vaihto omaisuus',
          right: 0,
          id: "1"
        },
        {
          left: 'Lyhytaikaiset saamiset',
          right: 0,
          id: "2"
        },
        {
          left: 'Rahoitusomaisuus arvopaperit',
          right: 0,
          id: "3"
        },
        {
          left: 'Rahat ja pankkisaamiset',
          right: 0,
          id: "4"
        },
        {
          left: '- Lyhytaikaiset velat',
          right: 0,
          id: "5"
        },
        {
          left: '- Riskilliset erät',
          right: 0,
          id: "6"
        },
      ]
    },

  ownCapitalFormData2: [
    {
      label: 'Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa',
      number: 0,
      firstField: true
    },
    {
      label: 'Edellisen tilikauden tulos',
      number: 0,
      firstField: true
    },
    {
      label: 'Ed tilikaudelta jaettu osinko (-syötä negatiivisena)',
      number: 0,
      firstField: true,
      id: "minus"
    },
    {
      label: 'Muut voittovaroihin tehdyt oikaisut',
      number: 0,
      firstField: true
    },
    
    {
      label: 'Tilikauden tulos',
      number: 0,
      firstField: false
    },
    {
      label: 'Muut oman pääoman erät tilikauden alussa',
      number: 0,
      firstField: false
    },
    {
      label: 'Muut omaan pääomaan oikaisut / muutokset ',
      number: 0,
      firstField: false
    },
  ],

  ownCapitalFormData: {
      firstTable: {
        stockAndOtherFunds: {// Osake-, osuus- tai muu vastaava pääoma
          label: "Osake-, osuus- tai muu vastaava pääoma",
          cy: balanceLiabilities.find(item => item.key === 'subscribedEquity')?.currentYear ?? 0,
          py: balanceLiabilities.find(item => item.key === 'subscribedEquity')?.priorYear ??0,
          commentField: "",
          id: "1"
        },
        overCourseFunds: {// Ylikurssirahasto
          label: "Ylikurssirahasto",
          cy: balanceLiabilities.find(item => item.key === 'sharePremiumAccount')?.currentYear ?? 0,
          py: balanceLiabilities.find(item => item.key === 'sharePremiumAccount')?.priorYear ?? 0,
          commentField: "",
          id: "2"
        },
        valueRisingFunds: {// Arvonkorotusrahasto
          label: "Arvonkorotusrahasto",
          cy: balanceLiabilities.find(item => item.key === 'revaluationReserve')?.currentYear ?? 0,
          py: balanceLiabilities.find(item => item.key === 'revaluationReserve')?.priorYear ?? 0,
          commentField: "",
          id: "3"
        },
        otherFunds: {// Muut rahastot
          label: "Muut rahastot",
          cy: balanceLiabilities.find(item => item.key === 'otherReserves')?.currentYear ?? 0,
          py: balanceLiabilities.find(item => item.key === 'otherReserves')?.priorYear ?? 0,
          commentField: "",
          id: "4"
        },
        freeInvestedAssetsFund: {// Sijoitetun vapaan oman pääoman rahasto
          label: "Sijoitetun vapaan oman pääoman rahasto",
          cy: balanceLiabilities.find(item => item.key === 'svop')?.currentYear ?? 0,
          py: balanceLiabilities.find(item => item.key === 'svop')?.priorYear ?? 0,
          commentField: "",
          id: "5"
      },
      lastSeasonsWins: {// Edellisten tilikausien voitto (tappio
        label: "Edellisten tilikausien voitto (tappio)",
        cy: balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')?.currentYear ?? 0,
        py: balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')?.priorYear ?? 0,
        commentField: "",
        id: "6"
      },
      thisSeasonsWins: {// Tilikauden voitto (tappio)
        label: "Tilikauden voitto (tappio)",
        cy: balanceLiabilities.find(item => item.key === 'freeEquity_profitOrLoss')?.currentYear ?? 0,
        py: balanceLiabilities.find(item => item.key === 'freeEquity_profitOrLoss')?.priorYear ?? 0,
        commentField: "",
        id: "7"
      },



    },
    unEditableParts: {
      allTogether: {
        label: "Yhteensä",
        commentField: "",
        id: "8",
        dontEdit: true
      }
    },
    secondTable: {
      winningFundsContinuity: {// Voittovarojen jatkuvuus
          lastSeasonsWins: {// Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa
            label: "Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa",
            commentField: "",
            id: "1"
          },
          lastSeasonResult: {// Edellisen tilikauden tulos
            label: "Edellisen tilikauden tulos",
            commentField: "",
            id: "2"
          },
          distributedDividents: {// Ed tilikaudelta jaettu osinko (-syötä negatiivisena)
            label: "Ed tilikaudelta jaettu osinko",
            commentField: "",
            id: "3"
          },
          otherCorrections: {// Muut voittovaroihin tehdyt oikaisut
            label: "Muut voittovaroihin tehdyt oikaisut",
            commentField: "",
            id: "4",
            number: 0
          },
          allTogether: {// Yhteensä
            label: "Yhteensä",
            commentField: "",
            id: "5"
          },
          subtractionIntoWinnings: {// Erotus taseen voittovaroihin
            label: "Erotus taseen voittovaroihin",
            commentField: "",
            id: "6"
          }
      },
      ownFundsContinuity: {// Oman pääoman jatkuvuus
          resultOfThePeriod: {// Tilikauden tulos
            label: "Tilikauden tulos",
            commentField: "",
            id: "7"
          },
          otherOwnCapitalPartsAtBeginningOfSeason: {// Muut oman pääoman erät tilikauden alussa
            label: "Muut oman pääoman erät tilikauden alussa",
            commentField: "",
            id: "8"
          },
          otherOwnFundChanges: {// Muut omaan pääomaan oikaisut / muutokset 
            label: "Muut omaan pääomaan oikaisut / muutokset ",
            commentField: "",
            id: "9",
            number: 0
          },
          balance: {//Tase yht
            label: "Tase yht",
            commentField: "",
            id: "10"
          },
          subtractionToBalance: {// Erotus taseelle
            label: "Erotus taseelle",
            commentField: "",
            id: "11"
          }
      }
    },
},
otherOwnCapitalFormData: [
  {
      label: "Pääomalainat",
      number: 0,
  },
  {
      label: "Tilinpäätössiirrot (poistoero ja vapaaehtoiset varaukset)",
      number: 0,
  },
  {
      label: "omaisuuserän arvonnousu (käypä hinta taseen hankintameno)",
      number: 0,
  },
],

thirdOwnCapitalData: [
  {
    label: "Aktivoidut kehittämismenot",
    cy: 0,
    py: 0
  },
  {
    label: "Pääomalainojen kirjaamaton korko",
    cy: 0,
    py: 0
  },
  {
    label: "Muut jakokelvottomat erät",
    cy: 0,
    py: 0
  },
],

  // Oman pääoman tarkastus
  equityComment: '',

  equityContinuityCheck: '',

  useTables: true,

  // Oman pääoman tarkistus
  reasonableEvidence: jsonVocab.reasonableEvidenceValue,
  additionalParts: {},


  describePeriodTitle: jsonVocab.describePeriodValue,
  ownedStocksMatchRegister: '',
  lastAuditResultProcessedAccordingly: '',
  lastAuditResultComment: '',
  yesOrNo1: null,
  yesOrNo1Info: "",
  yesOrNo2: null,
  numberInput1: 0,
  numberInput2: 0,
  meetingDecisionOnTaxesMatchesGoverments: '',
  meetingDecisionComment: '',

  yesOrNo3: null,
  ideasGoalsAndCompletedWork: jsonVocab.ideasGoalsAndCompletedWorkValue,
  conclusionSelect: '',
  ideasGoalsAndCompletedWork2: jsonVocab.ideasGoalsAndCompletedWork4Value,
  useCalculation: true,

  // Oman pääoman riittävyys
  yesOrNo4: null,
  yesOrNo5: null,
  yesOrNo6: null,
  useCalculation2: true,
  ownCapitalIsPositiveSelect: '',
  ownCapitalIsPositiveComment: '',
  ideasGoalsAndCompletedWork4: jsonVocab.ideasGoalsAndCompletedWork4Value,
  reference: auditingJson.defaultReferenceValue1,

  // Laskelma jakokelpoisista varoista
  conclusion2: null,
  reasonableEvidence2: jsonVocab.reasonableEvidence2Value,
  useCalculation3: true,
  reference2: auditingJson.defaultReferenceValue1,
  newlyCalculatedFundsMatchReference: null,
  numberReportedInReference: 0,

  // Arvio voitonjakoehdotuksen asianmukaisuudesta
  conclusion3: null,
  numberInput3: 0,
  reference3: jsonVocab.referenceValue,
  reference4: jsonVocab.reference4Value,

  yesOrNo7: null,
  reasonableEvidence3: jsonVocab.reasonableEvidence3Value,
  useCalculation4: true,
  accountingPeriod: 0,
  useSheetTab: false,
  };
};

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement customAccountTitle='Kommentit' {...props} auditing={auditing} batchString={batchString} addCommentField={true}/>),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({accessor: 'reasonableEvidence', plainLabel: auditingJson.conclusion}),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Oman pääoman tarkastus
      accordionId: 'equityAudit',
      lowerElements: [
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Osakepääoman täsmäytys kaupparekisteriin'}),
            formBuilder.checkboxGroup({plainLabel: auditingJson.conclusionOfInspection, accessor: 'ownedStocksMatchRegister', options: Object.keys(jsonVocab.ownedStocksMatchRegisterOptions), 
            optionDisplayValue: (key: string) => jsonVocab.ownedStocksMatchRegisterOptions[key as keyof typeof jsonVocab.ownedStocksMatchRegisterOptions]}),
            formBuilder.textArea({accessor: 'equityComment', plainLabel: 'Kommentit',
              hidden: formState.ownedStocksMatchRegister?.three === true ? false : true
            }),
          ]
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Oman pääoman jatkuvuuden tarkastus'}),
            formBuilder.checkboxGroup({plainLabel: auditingJson.conclusionOfInspection, accessor: 'conclusionSelect', options: Object.keys(jsonVocab.conclusion1Options), 
            optionDisplayValue: (key: string) => jsonVocab.conclusion1Options[key as keyof typeof jsonVocab.conclusion1Options]}),
            formBuilder.textArea({accessor: 'equityContinuityCheck', plainLabel: 'Kommentit',
              hidden: formState.conclusionSelect?.three === true ? false : true
            }),
            formBuilder.boolean({accessor: 'useTables', plainLabel: 'Käytä laskentataulukoita',}),
            ...formState.useTables ? [
              formBuilder.custom(props => <OwnCapitalForm {...props} /> ),
              formBuilder.subtitle({title: 'Jakokelpoiset varat'}),
              formBuilder.custom(props => <ThirdOwnCapitalForm {...props} /> ),
              formBuilder.checkboxGroup({accessor: "newlyCalculatedFundsMatchReference", options: Object.keys(jsonVocab.newlyCalculatedFundsMatchReferenceOptions), 
              optionDisplayValue: (key: string) => jsonVocab.newlyCalculatedFundsMatchReferenceOptions[key as keyof typeof jsonVocab.newlyCalculatedFundsMatchReferenceOptions],
              hidden: formState.useCalculation3 === true ? false : true}),
              formBuilder.group({items: [
                formBuilder.numberInput({accessor: 'numberReportedInReference', plainLabel: "Liitetiedoissa ilmoitettu määrä"}),
                formBuilder.custom(() => {
                  const divisibleAssets = (
                    formState.ownCapitalFormData.firstTable.thisSeasonsWins.cy + 
                    formState.ownCapitalFormData.firstTable.lastSeasonsWins.cy + 
                    formState.ownCapitalFormData.firstTable.freeInvestedAssetsFund.cy
                    ) - Object.keys(formState.thirdOwnCapitalData).reduce((accumulator, key) => accumulator + formState.thirdOwnCapitalData[key].cy, 0);
          
                  const number = divisibleAssets - formState.numberReportedInReference
                  return <h3>Ero uudelleen laskentaan: {number}</h3>
                  }),
              ], hidden: formState.newlyCalculatedFundsMatchReference?.one|formState.newlyCalculatedFundsMatchReference?.two|formState.newlyCalculatedFundsMatchReference?.three ? false : true})
            ] : [],
          ]
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Edellisten tilikauden tuloksen käsittelyn asianmukaisuus'}),
            formBuilder.checkboxGroup({plainLabel: auditingJson.conclusionOfInspection, accessor: 'lastAuditResultProcessedAccordingly', options: Object.keys(jsonVocab.lastAuditResultProcessedAccordinglyOptions), 
            optionDisplayValue: (key: string) => jsonVocab.lastAuditResultProcessedAccordinglyOptions[key as keyof typeof jsonVocab.lastAuditResultProcessedAccordinglyOptions]}),
            formBuilder.textArea({accessor: 'lastAuditResultComment', plainLabel: 'Kommentit', hidden: formState.lastAuditResultProcessedAccordingly?.three ? false : true}),
            formBuilder.group({
              items: [
                formBuilder.checkboxGroup({plainLabel: jsonVocab.yesOrNo1, accessor: 'yesOrNo1', options: Object.keys(auditingJson.yesOrNoOptionsWithComment), 
                optionDisplayValue: (key: string) => auditingJson.yesOrNoOptionsWithComment[key as keyof typeof auditingJson.yesOrNoOptionsWithComment]}),
                formBuilder.textArea({
                  accessor: 'yesOrNo1Info',
                  plainLabel: 'Edellisen tilikauden tulos on käsitelty kirjanpidossa kommentit',
                  hidden: (formState.yesOrNo1 !== null && formState.yesOrNo1.hasOwnProperty("comment")) ? formState.yesOrNo1.comment !== true: true
                }),
              ],
            }),
            formBuilder.select({plainLabel: jsonVocab.yesOrNo2, accessor: 'yesOrNo2', options: Object.keys(auditingJson.yesOrNoOptions), 
            optionDisplayValue: (key: string) => auditingJson.yesOrNoOptions[key as keyof typeof auditingJson.yesOrNoOptions]}),
            formBuilder.groupRow({
              items: [
                formBuilder.numberInput({accessor: 'numberInput1', plainLabel: jsonVocab.numberInput1}),
                formBuilder.numberInput({accessor: 'numberInput2', plainLabel: jsonVocab.numberInput2}),
                formBuilder.subtitle({title: "Erotus: "+String(formState.numberInput1-formState.numberInput2)+"€"})
              ], hidden: formState.yesOrNo2 === "yes" ? false : true
            }),
            formBuilder.checkboxGroup({accessor: 'meetingDecisionOnTaxesMatchesGoverments', options: Object.keys(jsonVocab.meetingDecisionOnTaxesMatchesGovermentsOptions), 
            optionDisplayValue: (key: string) => jsonVocab.meetingDecisionOnTaxesMatchesGovermentsOptions[key as keyof typeof jsonVocab.meetingDecisionOnTaxesMatchesGovermentsOptions]}),
            formBuilder.textArea({accessor: 'meetingDecisionComment', plainLabel: 'Kommentit', hidden: formState.meetingDecisionOnTaxesMatchesGoverments?.three ? false : true}),
        
            formBuilder.select({plainLabel: jsonVocab.yesOrNo3, accessor: 'yesOrNo3', options: Object.keys(auditingJson.yesOrNoOptions), 
            optionDisplayValue: (key: string) => auditingJson.yesOrNoOptions[key as keyof typeof auditingJson.yesOrNoOptions]}),
          ]
        }),
        formBuilder.group({
          items: [
            formBuilder.subtitle({title: 'Oman pääoman riittävyys'}),
            formBuilder.checkboxGroup({accessor: 'ownCapitalIsPositiveSelect', plainLabel: auditingJson.inspectionConclusion, options: Object.keys(jsonVocab.ownCapitalIsPositiveSelectOptions)}),
            formBuilder.textArea({accessor: 'ownCapitalIsPositiveComment', plainLabel: 'Kommentit', hidden: formState.ownCapitalIsPositiveSelect?.five ? false : true}),
            formBuilder.boolean({accessor: 'useCalculation2', plainLabel: jsonVocab.useCalculation2}),
            formBuilder.custom(props => formState.useCalculation2 === true ? <OtherOwnCapitalForm {...props} /> : null),
          ],
        }),
      ],
    }
  ]),

];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;