import { AuditingTemplate, isInRange, uuid } from '../../../utils';
import { generateAccountMap } from './accountMapUtils';

interface Sums {
  currentYear?: number;
  priorYear?: number;
}

const sumAccountValues = (a?: number, b?: number) => {
  if (a === undefined && b === undefined) return;
  return (a ?? 0) + (b ?? 0);
};

const computeChangeValues = ({ currentYear, priorYear }: Sums) => {
  if (priorYear === undefined) return;

  const change = (currentYear ?? 0) - priorYear;

  return {
    change,
    changePercent: !!priorYear
      ? (change / priorYear) * 100
      : !!currentYear
      ? 100 // If no prior value but current value is present -> change percent is 100%.
      : undefined,
  };
};

/**
 * Map through account map and count total sums of matching accounts
 *
 * @param groupedGeneralLedger General ledger data grouped by accounts
 * @param accountMap Account map to be used as a base
 * @returns list of financial statement items with account values summed
 */
const sumMatchingAccounts =
  (groupedGeneralLedger: GroupedGeneralLedger, isExampleData: boolean = false) =>
  (accountMap: AccountMapRow[]) => {
    const generalLedgerIsUsed = Object.keys(groupedGeneralLedger).every((key: any) => {
      const account = groupedGeneralLedger[key];
      return (account.currentYear !== undefined || account.priorYear !== undefined);
    });

    return accountMap
      .map(({ start, end, ...accountItem }) => {
        const matchingAccounts = groupedGeneralLedger.filter(({ account }) =>
          isInRange(account, [start, end])
        );

        const accountsSums: Sums = matchingAccounts.reduce(
          (sum: Sums, account) => ({
            currentYear: sumAccountValues(sum.currentYear, account.currentYear),
            priorYear: sumAccountValues(sum.priorYear, account.priorYear),
          }),
          { currentYear: undefined, priorYear: undefined }
        );

        const hasSumValue = accountsSums.currentYear || accountsSums.priorYear;

        if (!isExampleData && generalLedgerIsUsed && start && end && hasSumValue === undefined) return undefined;

        const item: IncomeStatementItem | BalanceItem = {
          id: uuid(),
          ...accountItem,
          ...accountsSums,
          ...computeChangeValues(accountsSums),
        };

        return item;
      })
      .filter((item): item is IncomeStatementItem | BalanceItem => item !== undefined && (item.currentYear !== undefined || item.priorYear !== undefined));
      //.filter((item): item is IncomeStatementItem | BalanceItem => item !== undefined);
  };

/**
 * Generate income statement data
 */
export const generateFinancialStatementData = (
  groupedGeneralLedger: GroupedGeneralLedger,
  accountMap: AccountMap = generateAccountMap(AuditingTemplate.private)
) => {

  const sumAccounts = sumMatchingAccounts(groupedGeneralLedger);

  return {
    incomeStatement: sumAccounts(accountMap.incomeStatementAccountMap),
    balanceAssets: sumAccounts(accountMap.balanceSheetAssetsAccountMap),
    balanceLiabilities: sumAccounts(
      accountMap.balanceSheetLiabilitiesAccountMap
    ),
  };
};
