import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
// API
import { useStore } from "../../store/RootContext";
import { RootStore } from "../../store/RootStore";
import { getNamesFromDB, getUploadInfo, getFreezingNamesFromDB, getFreezingUploadInfo } from "../../api/modules";
import { debounce } from "../../router/AppRouter";
// Syles
import styled from "styled-components";

const GreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    background-color: green;
    color: white;
    border-radius: 10px;
    width: 100%;
`;
const RedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    background-color: red;
    color: white;
    border-radius: 10px;
    width: 100%;
`;

interface ScannerProps {
    fieldToScan: string[];
}
interface FreezingProps {
    fieldToScan: string[];
}

export const Scanner: React.FC<ScannerProps> = observer(({ fieldToScan }) => {

    const store = useStore();
    const rootStore = store as RootStore;
    const state = store.auditingStore.getAuditingSection('communityInformation');
    
    if (!state) {
        return null;
    }

    const [scanResult, setScanResult] = useState<string[]>([]);
    const [uploadDate, setUploadDate] = useState<Date | null>(null);

    // Fetch the names from the database
    const fetchNames = async () => {
        const names = await getNamesFromDB(rootStore.api);
        return names;
    };

    useEffect(() => {
        getUploadInfo(rootStore.api)
        .then((date) => {
            if(date){
                setUploadDate(new Date(date));
            }
        });
    }, [rootStore.api]);

    // Scan for sanctions in the membersInformationTable ('Jäsenet', 'Lisäjäsen', or 'Varajäsenet' on label)
    //const fieldToScan = ["Jäsenet", "Varajäsenet", "Lisäjäsenet"];
    const scanForSanctions = async () => {
        const names = await fetchNames();
        const foundNamesPromises = names.map(async name => {
            if (state?.form.membersInformationTable || state?.form.beneficialInformationTable) {
                const tables = [...state.form.membersInformationTable, ...state.form.beneficialInformationTable];
                for (const member of tables) {
                    if (fieldToScan.includes(member.label) && member.inputValue) {
                        const memberNames = member.inputValue.split(',').map((name: string) => name.trim().toLowerCase());
                        if (memberNames.some((memberName: string) => name.toLowerCase().includes(memberName))) {
                            return name;
                        }
                    }
                }
            }
            return null;
        });
        const foundNames = (await Promise.all(foundNamesPromises)).filter(name => name !== null) as string[];
        setScanResult(foundNames);
    };

    // Show a notification if names are found in the sanctions list
    const hasNotified = useRef(false);
    useEffect(() => {
        if (scanResult.length > 0 && !hasNotified.current) {
            store.notificationStore.notify('Nimi(ä) löydetty Pakotelistasta!', 'error');
            hasNotified.current = true;
        }
    }, [scanResult, store.notificationStore])
    
    const namesInTable = useMemo(() => {
        return state?.form.membersInformationTable?.map((member: { inputValue: any; }) => member.inputValue).join(',');
    }, [state?.form.membersInformationTable]);
    useEffect(() => {
        hasNotified.current = false;
    }, [namesInTable]);

    // Debounce scanForSanctions with a 500ms delay
    const debouncedScanForSanctions = debounce(scanForSanctions, 500);

    useEffect(() => {
        debouncedScanForSanctions();
        return () => {
            // Cancel any pending execution when the component unmounts
            debouncedScanForSanctions.cancel();
        };
    }, [state?.form.membersInformationTable, debouncedScanForSanctions]);

    let namesNotFoundText = "Nimiä ei löydetty Pakotelistasta."
    let namesFoundText = "Nimi(ä) löydetty Pakotelistasta!"

    return (
        <>
        <div style={{justifyContent: 'center', textAlign: 'center'}}>
            <p>Tarkistus tehdään hallituksen '<b>Jäsenet</b>' ja '<b>Varajäsenet</b>',  Myös lisätyistä '<b>Lisäjäsen</b>' '<b>Varajäsen</b>' kohdista.</p>
            <p>Tarkistus tehdään tosialliset edunsaajat '<b>Tosiasialliset edunsaajat ja omistusosuus</b>' kohdasta.</p>
            {uploadDate && <p>Pakotelista päivitetty viimeksi: <b>{uploadDate.toLocaleDateString()}</b></p>}
        </div>
        {scanResult.length > 0 ? (
        <RedContainer>
        {scanResult.length > 0 
            && 
            <div><b>{namesFoundText}</b> <br></br>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {scanResult.map((name, index) => (
                        <span key={index} style={{flexBasis: '25%', padding: '5px'}}>{index + 1}. {name}</span>
                    ))}
                </div>
            </div>
        }
        </RedContainer>
        ) : (
        <GreenContainer>
            <p>{namesNotFoundText}</p>
        </GreenContainer>
        )}
        </>
      );
});
export const FreezingScanner: React.FC<FreezingProps> = observer(({ fieldToScan }) => {

    const store = useStore();
    const rootStore = store as RootStore;
    const state = store.auditingStore.getAuditingSection('communityInformation');

    if (!state) {
        return null;
    }

    const [scanResult, setScanResult] = useState<string[]>([]);
    const [uploadDate, setUploadDate] = useState<Date | null>(null);

    // Fetch the names from the database
    const fetchNames = async () => {
        const names = await getFreezingNamesFromDB(rootStore.api);
        return names;
    };

    useEffect(() => {
        getFreezingUploadInfo(rootStore.api)
        .then((date) => {
            if(date){
                setUploadDate(new Date(date));
            }
        });
    }, [rootStore.api]);

    // Scan for sanctions in the membersInformationTable ('Jäsenet', 'Lisäjäsen', or 'Varajäsenet' on label)
    // fieldToScan={["Jäsenet", "Varajäsenet", "Lisäjäsen", "Varajäsen", 'Tosiasialliset edunsaajat ja omistusosuus']} in communityInformation.tsx file <Scanner />
    const scanForSanctions = async () => {
        const names = await fetchNames();
        const foundNamesPromises = names.map(async name => {
            if (state?.form.membersInformationTable || state?.form.beneficialInformationTable) {
                const tables = [...state.form.membersInformationTable, ...state.form.beneficialInformationTable];
                for (const member of tables) {
                    if (fieldToScan.includes(member.label) && member.inputValue) {
                        const memberNames = member.inputValue.split(',').map((name: string) => name.trim().toLowerCase());
                        if (memberNames.some((memberName: string) => name.toLowerCase().includes(memberName))) {
                            return name;
                        }
                    }
                }
            }
            return null;
        });
        const foundNames = (await Promise.all(foundNamesPromises)).filter(name => name !== null) as string[];
        setScanResult(foundNames);
    };

    // Show a notification if names are found in the sanctions list
    const hasNotified = useRef(false);
    useEffect(() => {
        if (scanResult.length > 0 && !hasNotified.current) {
            store.notificationStore.notify('Nimi(ä) löydetty Jäädyttämispakotelistasta!', 'error');
            hasNotified.current = true;
        }
    }, [scanResult, store.notificationStore])
    
    const namesInTable = useMemo(() => {
        return state?.form.membersInformationTable?.map((member: { inputValue: any; }) => member.inputValue).join(',');
    }, [state?.form.membersInformationTable]);
    useEffect(() => {
        hasNotified.current = false;
    }, [namesInTable]);

    // Debounce scanForSanctions with a 500ms delay
    const debouncedScanForSanctions = debounce(scanForSanctions, 500);

    useEffect(() => {
        debouncedScanForSanctions();
        return () => {
            // Cancel any pending execution when the component unmounts
            debouncedScanForSanctions.cancel();
        };
    }, [state?.form.membersInformationTable, debouncedScanForSanctions]);

    let namesNotFoundText = "Nimiä ei löydetty Jäädyttämispakotelistasta."
    let namesFoundText = "Nimi(ä) löydetty Jäädyttämispakotelistasta!"

    return (
        <>
        <div style={{justifyContent: 'center', textAlign: 'center'}}>
            <p>Tarkistus tehdään hallituksen '<b>Jäsenet</b>' ja '<b>Varajäsenet</b>',  Myös lisätyistä '<b>Lisäjäsen</b>' '<b>Varajäsen</b>' kohdista.</p>
            <p>Tarkistus tehdään tosialliset edunsaajat '<b>Tosiasialliset edunsaajat ja omistusosuus</b>' kohdasta.</p>
            {uploadDate && <p>Jäädyttämispakotelista päivitetty viimeksi: <b>{uploadDate.toLocaleDateString()}</b></p>}
        </div>
        {scanResult.length > 0 ? (
        <RedContainer>
        {scanResult.length > 0 
            && 
            <div><b>{namesFoundText}</b> <br></br>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {scanResult.map((name, index) => (
                        <span key={index} style={{flexBasis: '25%', padding: '5px'}}>{index + 1}. {name}</span>
                    ))}
                </div>
            </div>
        }
        </RedContainer>
        ) : (
        <GreenContainer>
            <p>{namesNotFoundText}</p>
        </GreenContainer>
        )}
        </>
      );
});

export default Scanner;