import { IncomeStatementAndBalanceTable } from '../../../components/analyzes/IncomeStatementAndBalance';
import { IncomeStatementAndBalanceComputeds } from '../../../components/analyzes/IncomeStatementAndBalance.ComputedsTable';
//import { IncomeStatementAndBalancePin } from '../../../components/analyzes/IncomeStatementPin';
import { MaterialitiesTable } from '../../../components/auditing/Materiality.MaterialitiesTable';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { IncomeStatementMateriality } from './incomeStatementMateriality';

type SectionFormType = IncomeStatementAndBalanceForm;

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalance';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

export enum Risk {
  notSignificant = 'notSignificant',
  limitedRisk = 'limitedRisk',
  significant = 'significant',
}

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    incomeStatement: [],
    balanceAssets: [],
    balanceLiabilities: [],

    dateIncome: 'Ajanjakso 1.1-31.12.202X.',
    dateBalance: 'Ajanjakso 1.1-31.12.202X.',
    dateLiabilities: 'Ajanjakso 1.1-31.12.202X.',

    selectMateriality: true,
    materialityNumber: '',
    incomeStatementHelp: false,
  }
};

const plainTitle = 'Tarkastuksen ajanjakso';

const formFields: FormFields<SectionFormType> = ({ auditing, formState }) => [
  /* formBuilder.custom((
    <ExcelIsabComponent />
  )), */

  formBuilder.boolean({
    plainLabel: 'Ohje',
    accessor: 'incomeStatementHelp',
    showContextInfo: 'bottom-right',
  }),

  formBuilder.group({
    items: [
      formBuilder.custom(props => (
        <IncomeStatementMateriality {...props} />
      )),
    ]
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'incomeStatement',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateIncome',
            plainLabel: plainTitle,
          }),
        ]
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable
          targetKey="incomeStatement"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceAssets',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateBalance',
            plainLabel: plainTitle,
          }),
        ]
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable targetKey="balanceAssets" {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceLiabilities',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateLiabilities',
            plainLabel: plainTitle,
          }),
        ]
      }),
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable targetKey="balanceLiabilities" {...props} />
      )),
    ],
  }),

  formBuilder.custom(props => (
    <IncomeStatementAndBalanceComputeds {...props} />
  )),

  /* formBuilder.custom(props => (
    <IncomeStatementAndBalancePin {...props} />
  )), */

/*   formBuilder.accordionGroup({
    open: false,
    titleKey: 'pin',
    items: [
      formBuilder.custom(props => (
        <IncomeStatementAndBalancePin {...props} />
      )),
    ],
  }), */

  formBuilder.custom(props => {
    const formState = auditing?.materiality?.form;
    if (!formState) return;
    return <MaterialitiesTable {...props} formState={formState} readonly />;
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMap', 'generalLedger'],
};

export default section;
