import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps } from '../table/Table';

const sectionKey: AuditingSectionKey = 'auditingPlanning';

export const MeetingTopics = ({
  formState,
  patchFormState,
}: FormFieldProps<AuditingPlanningForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.meetingTopic`;

  const data = formState.meetingTopics ?? [];

  const patchMeetingTopics = (patch: MeetingTopic[]) => {
    patchFormState({
      ...formState,
      meetingTopics: patch,
    });
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = (formState.meetingTopics ?? []).map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchMeetingTopics(patch);
  }

  const tableColumns = [
    {
      accessorKey: 'covered',
      className: 'text-center width-min',
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
    },
    {
      accessorKey: 'label',
      className: 'width-half',
      placeholder: t('common:label.topic') + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'details',
      className: 'width-half',
      placeholder: t('common:label.additionalInfo') + '...',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      meetingTopics: [
        ...(formState.meetingTopics ?? []),
        {
          id: uuid(),
          label: '',
          covered: null,
          details: '',
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: MeetingTopic) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.meetingTopics ?? []).filter(
        row => row.id !== id
      );
      patchMeetingTopics(patch);
    }
  };

  const getStatus = () => {
    const total = formState.meetingTopics?.length;
    const covered = formState.meetingTopics?.filter(
      ({ covered }) => covered
    ).length;
    return `${covered}/${total}`;
  };

  return (
    <AuditingTable<MeetingTopic>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      title={t(`${tBase}.title`) + ` (${getStatus()})`}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
      hideHeader
    />
  );
};
