import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, TextInput } from '../components/inputs';
import Logo from '../components/Logo';
import PasswordForgotModal from '../components/PasswordForgotModal';
import PasswordResetModal from '../components/PasswordResetModal';
import { useStore } from '../store/RootContext';
import { useQueryParams } from '../utils';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  text-align: center;

  form {
    width: 400px;

    > div {
      margin: ${p => p.theme.spacing.lg} 0;
    }

    > button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Login: React.FC = observer(() => {
  const { t } = useTranslation(['login', 'action']);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { passwordResetToken } = useQueryParams('passwordResetToken');

  const { authStore: { login } } = useStore();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    login({ email, password });
  };

  return (
    <LoginContainer>
      <form onSubmit={handleSubmit}>
        <Logo height={90} />

        <div>
          <TextInput
            type="text"
            autocomplete="username"
            label={t('login:username')}
            value={email}
            setValue={setEmail}
            autoFocus
            required
            fullWidth
          />
          <TextInput
            type="password"
            autocomplete="current-password"
            label={t('login:password')}
            value={password}
            setValue={setPassword}
            required
            fullWidth
          />
        </div>

        <div>
          <Button text={t('action:login')} type="submit" fullWidth />
        </div>

        <PasswordForgotModal email={email} setEmail={setEmail} />
      </form>

      {passwordResetToken && <PasswordResetModal token={passwordResetToken} />}
    </LoginContainer>
  );
});
