import { action, makeObservable } from 'mobx';
import { Api } from '../../api/RootApi';
import { sortAuditings } from '../../utils/auditing';
import { BaseEntityStore } from '../BaseEntityStore';
import { RootStore } from '../RootStore';

export class CustomerCompanyStore extends BaseEntityStore<CustomerCompany> {
  constructor(rootStore: RootStore, api: Api) {
    super(rootStore, api, api.customerCompany, 'CUSTOMER_COMPANY');

    makeObservable(this, {
      getAuditings: action,
    });
  }

  getAuditings = (entityId?: number) => {
    return this.findEntity(entityId)?.auditings?.sort(sortAuditings) ?? [];
  };
}
