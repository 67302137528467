import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../theme';
import {
  AuditingProcedureResult,
  auditingProcedureResultToNumber,
  isNotRemarkBatch,
  isRemarkBatch,
  transformRemarkToAuditingProcedure,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import Icon from '../Icon';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs, TableRowProps } from '../table/Table';
import Tooltip from '../Tooltip';

type TableItem = AuditingProcedure & TableRowProps<AuditingProcedure>;

const sectionKey: AuditingSectionKey = 'auditingProcedures';

const tBase = `auditing:form.${sectionKey}`;

interface Props extends FormFieldProps<AuditingProceduresForm> {
  auditing?: Auditing;
}

const AuditingRemarks: React.FC<Props> = ({
  formState,
  patchFormState,
  store,
}) => {
  const { t } = useTranslation();

  const auditingProcedures = formState.auditingProcedures;

  const [auditingRemarks, auditingProceduresWithoutRemarks] = [
    auditingProcedures?.filter(isRemarkBatch),
    auditingProcedures?.filter(isNotRemarkBatch),
  ];

  const storeRemarks = store.auditingStore.getAuditingRemarks();

  const storeRemarksRef = useRef<AuditingRemark[]>();

  const isStoreRemarksChanged = useCallback(() => {
    const refRemarks = storeRemarksRef.current;

    let isChanged = false;

    if (storeRemarks.length !== refRemarks?.length) {
      isChanged = true;
    } else {
      const newItemsDetected = !!storeRemarks.filter(
        remark => !refRemarks?.find(refRemark => remark.key === refRemark.key)
      ).length;
      const removedItemsDetected = !!refRemarks?.filter(
        refRemark => !storeRemarks.find(remark => remark.key === refRemark.key)
      ).length;
      if (newItemsDetected || removedItemsDetected) isChanged = true;
    }

    if (isChanged) storeRemarksRef.current = storeRemarks;
    return isChanged;
  }, [storeRemarks]);

  /**
   * Compare to "actual" computed remarks and update auditingRemarks list if any changes detected
   */
  useEffect(() => {
    if (!auditingRemarks || !isStoreRemarksChanged()) return;

    // Remove items that are no longer in store remarks
    let updatedRemarks = auditingRemarks.filter(remark =>
      storeRemarks.find(storeRemark => remark.id === storeRemark.key)
    );

    const deletedSome = updatedRemarks.length !== auditingRemarks.length;

    // Add items that are currently only in store remarks
    const newRemarks = storeRemarks
      .filter(
        storeRemark =>
          !updatedRemarks.find(remark => storeRemark.key === remark.id)
      )
      .map(transformRemarkToAuditingProcedure);

    const addedSome = newRemarks.length;

    updatedRemarks = [...updatedRemarks, ...newRemarks];

    if (deletedSome || addedSome) {
      const patch = [...updatedRemarks, ...auditingProceduresWithoutRemarks];
      patchFormState({ ...formState, auditingProcedures: patch });
    }
  }, [
    auditingProceduresWithoutRemarks,
    auditingRemarks,
    formState,
    isStoreRemarksChanged,
    patchFormState,
    storeRemarks,
  ]);

  if (!auditingRemarks) return null;

  const addRowProps = (row: TableItem) => ({
    ...row,
    bgColor: Colors.red,
  });

  const data: TableItem[] = auditingRemarks.map(addRowProps);

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const updatedRemarks = auditingRemarks.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    const patch = [...updatedRemarks, ...auditingProceduresWithoutRemarks];
    patchFormState({ ...formState, auditingProcedures: patch });
  }

  const tableColumns: TableColumnDefs<TableItem> = [
    {
      accessorKey: 'batch',
      cell: () => (
        <Tooltip text={t('auditing:remarks.title')}>
          <Icon type="Exclamation" color="error" />
        </Tooltip>
      ),
      header: () => '',
      className: 'width-min text-center',
    },
    {
      accessorKey: 'action',
      header: () => t(`auditing:remarks.title2`),
      accessorFn: row => t(`auditing:form.${row.id}`),
    },
    {
      accessorKey: 'auditingResult',
      className: 'width-min text-center',
      header: () => t(`${tBase}.auditingResult`),
      selectProps: {
        options: Object.values(AuditingProcedureResult),
        displayValue: (option: string) =>
          t(`${tBase}.auditingResultOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
      disabled: () => true,
      enableGlobalFilter: false,
      sortingFn: ({ original: a }, { original: b }) => {
        const aResult = auditingProcedureResultToNumber(a.auditingResult);
        const bResult = auditingProcedureResultToNumber(b.auditingResult);
        return aResult - bResult;
      },
    },
    {
      accessorKey: 'auditingReference',
      header: () => t(`${tBase}.auditingReference`),
      className: 'text-center',
    },
    {
      accessorKey: 'auditingComments',
      header: () => t(`${tBase}.auditingComments`),
      className: 'width-min text-center',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable<TableItem>
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
    />
  );
};

export default AuditingRemarks;
