import { Api } from '../api/RootApi';
import { Storage } from '../utils';
import {
  AppStore,
  AuditFirmStore,
  AuditingStore,
  AuthStore,
  CommonStore,
  CustomerCompanyStore,
  NotificationStore,
  UserStore,
} from './modules';

export class RootStore {
  api: Api;
  appStore: AppStore;
  authStore: AuthStore;
  notificationStore: NotificationStore;
  userStore: UserStore;
  auditFirmStore: AuditFirmStore;
  auditingStore: AuditingStore;
  customerCompanyStore: CustomerCompanyStore;
  commonStore: CommonStore;

  constructor(language?: LanguageCode) {
    const token = this.getStorageToken();
    const api = new Api(token);

    this.api = api;
    this.appStore = new AppStore(this, api, language);
    this.authStore = new AuthStore(this, api, token);
    this.notificationStore = new NotificationStore(this, api);
    this.userStore = new UserStore(this, api);
    this.auditFirmStore = new AuditFirmStore(this, api);
    this.auditingStore = new AuditingStore(this, api);
    this.customerCompanyStore = new CustomerCompanyStore(this, api);
    this.commonStore = new CommonStore(this, api);
  }

  private getStorageToken() {
    const storageToken = Storage.read({ key: 'AUTH_TOKEN' });
    return storageToken?.length ? storageToken : undefined;
  }

  resetAllStores(api = new Api(), language = this.appStore.language) {
    this.appStore = new AppStore(this, api, language);
    this.authStore = new AuthStore(this, api);
    this.notificationStore = new NotificationStore(this, api);
    this.userStore = new UserStore(this, api);
    this.auditFirmStore = new AuditFirmStore(this, api);
    this.auditingStore = new AuditingStore(this, api);
    this.customerCompanyStore = new CustomerCompanyStore(this, api);
    this.commonStore = new CommonStore(this, api);
  }
}
