import React, { useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../../../components/table/Table';
import { formatCurrency, uuid } from '../../../../utils';
import { TextContainer } from '../../../../components/auditing/CapitalLoans';


interface Props {
  addCommentField?: boolean;
  formState: any;
  patchFormState: any;
  showChange?: boolean;
  customAccountTitle?: string;
  showSum?: boolean;
}


export const OwnCapitalForm: React.FC<Props> = ({ formState, patchFormState }) => {
    

const [loading, setLoading] = useState(false)


function getChange(object: any){
    return object.cy - object.py
}
const shortFormState1 = formState.ownCapitalFormData.firstTable;
const shortFormState2 = formState.ownCapitalFormData.secondTable.winningFundsContinuity;
const shortFormState3 = formState.ownCapitalFormData.secondTable.ownFundsContinuity;

for(var i=0; i<Object.keys(shortFormState1).length; i++){
    const object = shortFormState1[Object.keys(shortFormState1)[i] as keyof typeof shortFormState1]
    object.change = getChange(object)
}



var items = {
    firstTable: {
        ...shortFormState1
    },
    unEditableParts: {},
    secondTable: {
        winningFundsContinuity: {// Voittovarojen jatkuvuus
            lastSeasonsWins: {// Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa
                ...shortFormState2.lastSeasonsWins,
                number: shortFormState1.lastSeasonsWins.py
            },
            lastSeasonResult: {// Edellisen tilikauden tulos
                ...shortFormState2.lastSeasonResult,
                number: shortFormState1.thisSeasonsWins.py
            },
            distributedDividents: {// Ed tilikaudelta jaettu osinko (-syötä negatiivisena)
                ...shortFormState2.distributedDividents,
            },
            otherCorrections: {// Muut voittovaroihin tehdyt oikaisut
                ...shortFormState2.otherCorrections,
            },
            allTogether: {// Yhteensä
                ...shortFormState2.allTogether,
                number: 
                    (shortFormState1.thisSeasonsWins.py + shortFormState1.lastSeasonsWins.py + shortFormState2.otherCorrections.number) - 
                    (formState.numberInput1 === null || formState.numberInput1 === undefined ? 0 : formState.numberInput1)
            },
            subtractionIntoWinnings: {// Erotus taseen voittovaroihin
                ...shortFormState2.subtractionIntoWinnings,
                number: ((shortFormState1.thisSeasonsWins.py + shortFormState1.lastSeasonsWins.py + shortFormState2.otherCorrections.number) - 
                (formState.numberInput1 === null || formState.numberInput1 === undefined ? 0 : formState.numberInput1)) - 
                shortFormState1.lastSeasonsWins.cy
            }
        },
        ownFundsContinuity: {// Oman pääoman jatkuvuus
            resultOfThePeriod: {// Tilikauden tulos
                ...shortFormState3.resultOfThePeriod,
                number: shortFormState1.thisSeasonsWins.cy
            },
            otherOwnCapitalPartsAtBeginningOfSeason: {// Muut oman pääoman erät tilikauden alussa
                ...shortFormState3.otherOwnCapitalPartsAtBeginningOfSeason,
                number: Object.keys(shortFormState1).map((item:any) => 
                        shortFormState1[item].id !== "6" && shortFormState1[item].id !== "7" ? 
                        shortFormState1[item].py : 0).reduce((accumulator, currentValue) => accumulator + currentValue)
            },
            otherOwnFundChanges: {// Muut omaan pääomaan oikaisut / muutokset 
                ...shortFormState3.otherOwnFundChanges,
            },
            balance: {//Tase yht
                ...shortFormState3.balance,
                number: 
                    (((shortFormState1.thisSeasonsWins.py + shortFormState1.lastSeasonsWins.py + shortFormState2.otherCorrections.number) - 
                    (formState.numberInput1 === null || formState.numberInput1 === undefined ? 0 : formState.numberInput1)) + // allTogether
                    shortFormState1.thisSeasonsWins.cy + // resultOfThePeriod
                    Object.keys(shortFormState1).map((item:any) => 
                        shortFormState1[item].id !== "6" && shortFormState1[item].id !== "7" ? 
                        shortFormState1[item].py : 0).reduce((accumulator, currentValue) => accumulator + currentValue) + 
                    shortFormState3.otherOwnFundChanges.number)
            },
            subtractionToBalance: {// Erotu taseelle
                ...shortFormState3.subtractionToBalance,
                // Tämän lasku vaatii että tämä asetetaan alempana.
            }
        }
    }
} as any;


// Calculating and setting first tables sum part(at the bottom)
var cyAllTogether = Object.keys(formState.ownCapitalFormData.firstTable).reduce((accumulator, key) => accumulator + formState.ownCapitalFormData.firstTable[key].cy, 0)
var pyAllTogether = Object.keys(formState.ownCapitalFormData.firstTable).reduce((accumulator, key) => accumulator + formState.ownCapitalFormData.firstTable[key].py, 0)

items.unEditableParts.allTogether = {
    ...formState.ownCapitalFormData.unEditableParts.allTogether,
    cy: cyAllTogether,
    py: pyAllTogether,
    change: getChange({cy: cyAllTogether, py: pyAllTogether}),
}
items.secondTable.ownFundsContinuity.subtractionToBalance.number = cyAllTogether - 
    (((shortFormState1.thisSeasonsWins.py + shortFormState1.lastSeasonsWins.py + shortFormState2.otherCorrections.number) - 
    (formState.numberInput1 === null || formState.numberInput1 === undefined ? 0 : formState.numberInput1)) + // allTogether
    shortFormState1.thisSeasonsWins.cy + // resultOfThePeriod
    Object.keys(shortFormState1).map((item:any) => 
        shortFormState1[item].id !== "6" && shortFormState1[item].id !== "7" ? 
        shortFormState1[item].py : 0).reduce((accumulator, currentValue) => accumulator + currentValue) + 
    shortFormState3.otherOwnFundChanges.number)




function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
}: OnEditProps<T>) {

    var oldData = {...formState.ownCapitalFormData.firstTable};
    const tableItemKeys = Object.keys(oldData);

    for(var i=0; i<tableItemKeys.length; i++){
        if(oldData[tableItemKeys[i]].id === itemId){
            if(oldData[tableItemKeys[i]][columnId] !== value){
                oldData[tableItemKeys[i]][columnId] = value
            }
        }
    }

    if(oldData !== formState.ownCapitalFormData.firstTable){
        patchFormState({
            ...formState,
            ownCapitalFormData: {
                ...formState.ownCapitalFormData,
                firstTable: oldData
            }
        })
    }
}

function handleEditFirstTableResults<T>({
    itemId,
    rowIndex,
    columnId,
    value,
}: OnEditProps<T>) {
    var oldData = {...formState.ownCapitalFormData.unEditableParts};
    const tableItemKeys = Object.keys(oldData);

    for(var i=0; i<tableItemKeys.length; i++){
        if(oldData[tableItemKeys[i]].id === itemId){
            if(oldData[tableItemKeys[i]][columnId] !== value){
                oldData[tableItemKeys[i]][columnId] = value
            }
        }
    }

    if(oldData !== formState.ownCapitalFormData.unEditableParts){
        patchFormState({
            ...formState,
            ownCapitalFormData: {
                ...formState.ownCapitalFormData,
                unEditableParts: oldData
            }
        })
    }
}



function handleSecondTableEdits<T>({
    itemId,
    value,
}: OnEditProps<T>) {
    const oldFormState = {...formState};
    const winningFundsContinuityIds = ["7","8","9","10","11"]
    if(typeof value === "string"){
        if(!winningFundsContinuityIds.includes(itemId as string)){
            Object.keys(oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity).forEach((key:any) => 
                {
                    const object = oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity[key];
                    if(object.id === itemId){
                        oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity[key].commentField = value
                        patchFormState(oldFormState)
                    }
                }
            )
        }else{
            Object.keys(oldFormState.ownCapitalFormData.secondTable.ownFundsContinuity).forEach((key:any) => 
                {
                    const object = oldFormState.ownCapitalFormData.secondTable.ownFundsContinuity[key];
                    if(object.id === itemId){
                        oldFormState.ownCapitalFormData.secondTable.ownFundsContinuity[key].commentField = value
                        patchFormState(oldFormState)
                    }
                }
            )
        }
    }else {
        if(itemId === "4" && oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity.otherCorrections.number !== value){
            oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity.otherCorrections.number = value
            patchFormState(oldFormState)
        }else if(itemId === "9" && oldFormState.ownCapitalFormData.secondTable.ownFundsContinuity.otherOwnFundChanges.number !== value){
            oldFormState.ownCapitalFormData.secondTable.ownFundsContinuity.otherOwnFundChanges.number = value
            patchFormState(oldFormState)
        }else if(itemId === "3" && oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity.distributedDividents.number !== value){
            oldFormState.ownCapitalFormData.secondTable.winningFundsContinuity.distributedDividents.number = value
            patchFormState(oldFormState)
        } 
    }

}


function handleAddNewRow(){
    const id = uuid()
    patchFormState({
        ...formState,
        ownCapitalFormData: {
            ...formState.ownCapitalFormData,
            firstTable: {
                ...formState.ownCapitalFormData.firstTable,
                [id]: {// Ylikurssirahasto
                    label: "Lisätty rivi",
                    cy: 0,
                    py: 0,
                    commentField: "",
                    id: id
                },
            }
        }
    })
}

function handleDeleteRow(props: any){
  setLoading(true)
  var newObject = {} as any
  for(var i=0; i<Object.keys(formState.ownCapitalFormData).length; i++){
    const objectKey = Object.keys(formState.ownCapitalFormData)[i]
    if(formState.ownCapitalFormData[objectKey].id !== props.id){
        newObject[objectKey] = formState.ownCapitalFormData[objectKey]
    }
  }
  patchFormState({
    ...formState,
    ownCapitalFormData: newObject
  })
  setTimeout(() => setLoading(false), 1)
}


var tableColumns: TableColumnDefs<any> = [
  {
    accessorKey: 'label',
    header: () => "Nimike",
    onEdit: handleEdit,
    className: 'width-middle text-center nowrap',

  },
  {
    accessorKey: 'cy',
    header: () => 'CY',
    className: 'width-min text-center nowrap',
    onNumberEdit: handleEdit
  },
  {
    accessorKey: 'py',
    header: () => 'PY',
    className: 'width-min text-center nowrap',
    onNumberEdit: handleEdit
  },
  {
    accessorKey: 'change',
    header: () => 'Muutos',
    className: 'width-middle text-center nowrap',
    accessorFn: row => formatCurrency(row.change),

  },  
  {
    accessorKey: 'commentField',
    header: () => 'Kommentoi',
    className: 'width-middle text-center nowrap',
    onEdit: handleEdit
  }
];

var unEditableTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
    header: () => 'Yhteensä',
    className: 'width-middle text-center nowrap',


    },
    {
      accessorKey: 'cy',
      className: 'width-min text-center nowrap',
    accessorFn: row => formatCurrency(row.cy),
    header: () => 'CY',

    },
    {
      accessorKey: 'py',
      className: 'width-min text-center nowrap',
    accessorFn: row => formatCurrency(row.py),
    header: () => 'PY',
    },
    {
    header: () => 'Muutos',

      accessorKey: 'change',
      className: 'width-middle text-center nowrap',
    accessorFn: row => formatCurrency(row.change),
    },  
    {
    header: () => 'Kommentoi',

      accessorKey: 'commentField',
      className: 'width-middle text-center nowrap',
      onEdit: handleEditFirstTableResults
    }
  
];

var twoRowTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: () => "Oman pääoman jatkuvuus",
      className: 'width-half text-left',
  
    },
    {
      accessorKey: 'number',
      header: () => null,
      className: 'width-min text-left nowrap',
      onNumberEdit: handleSecondTableEdits
    },
    {
        accessorKey: 'commentField',
        header: () => "Kommentit",
        className: 'width-middle text-left nowrap',
        onEdit: handleSecondTableEdits
    },
];


var unEditableTwoRowTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: () => "Voittovarojen jatkuvuus",
      className: 'width-half text-left',
        
    },
    {
      accessorKey: 'number',
      header: () => null,
      className: 'width-min text-left nowrap',
    accessorFn: row => formatCurrency(row.number),

    },
    {
        accessorKey: 'commentField',
        header: () => "Kommentit",
        className: 'width-middle text-left nowrap',
        onEdit: handleSecondTableEdits
    },
];



return !loading ? (
  <>
      <AuditingTable
          data={Object.keys(items.firstTable).map((item:any) => items.firstTable[item])}
          columns={tableColumns}
          showGlobalFilter={false}
          onAddNewRow={handleAddNewRow}
          onRowDelete={handleDeleteRow}
          animationDisabled={true}
      />
      <AuditingTable
          data={Object.keys(items.unEditableParts).map((item:any) => items.unEditableParts[item])}
          columns={unEditableTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
          
      />
        <TextContainer>
            <div className="additional-info">Kaava = CY - PY</div>
        </TextContainer>
      <h2>Voittovarojen jatkuvuus</h2>
      <AuditingTable
          data={Object.keys(items.secondTable.winningFundsContinuity).map((item:any) => items.secondTable.winningFundsContinuity[item]).filter((item:any) => ["1","2"].includes(item.id))}
          columns={unEditableTwoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
      />
      <AuditingTable
          data={Object.keys(items.secondTable.winningFundsContinuity).map((item:any) => items.secondTable.winningFundsContinuity[item]).filter((item:any) => item.id === "3" || item.id === "4")}
          columns={twoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
          hideHeader
      />
      <AuditingTable
          data={Object.keys(items.secondTable.winningFundsContinuity).map((item:any) => items.secondTable.winningFundsContinuity[item]).filter((item:any) => ["5","6","7"].includes(item.id))}
          columns={unEditableTwoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
          hideHeader
      />

<h2>Oman pääoman jatkuvuus</h2>
        <AuditingTable
          data={Object.keys(items.secondTable.ownFundsContinuity).map((item:any) => items.secondTable.ownFundsContinuity[item]).filter((item:any) => ["7","8"].includes(item.id))}
          columns={unEditableTwoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
      />
        <AuditingTable
          data={Object.keys(items.secondTable.ownFundsContinuity).map((item:any) => items.secondTable.ownFundsContinuity[item]).filter((item:any) => item.id === "9")}
          columns={twoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
          hideHeader
      />
        <AuditingTable
          data={Object.keys(items.secondTable.ownFundsContinuity).map((item:any) => items.secondTable.ownFundsContinuity[item]).filter((item:any) => ["10","11"].includes(item.id))}
          columns={unEditableTwoRowTableColumns}
          showGlobalFilter={false}
          animationDisabled={true}
          hideHeader
      />
    </>
) : null;
};


    
export const OtherOwnCapitalForm: React.FC<Props> = ({ formState, patchFormState }) => {

    function handleEdit(props: any){
        const newFormState = {...formState}
        if(formState.otherOwnCapitalFormData[props.rowIndex].number !== props.value){
            newFormState.otherOwnCapitalFormData[props.rowIndex].number = props.value
            patchFormState(newFormState)
        }
    }

    var cyAllTogether = Object.keys(formState.ownCapitalFormData.firstTable).reduce((accumulator, key) => accumulator + formState.ownCapitalFormData.firstTable[key].cy, 0)

    const shortFormState = formState.otherOwnCapitalFormData;
    
    const data = [
        {
            label: "Omapääoma",
            number: cyAllTogether,
        },
        ...shortFormState,
        {
            label: "Yhteensä",
            number: cyAllTogether +
                shortFormState[0].number +
                shortFormState[1].number +
                shortFormState[2].number
            
        },
    ]

    var editableTwoRowTableColumns: TableColumnDefs<any> = [
        {
          accessorKey: 'label',
          header: () => "Laskelma OPOn riittävyydestä ",
          className: 'width-middle text-left nowrap',
      
        },
        {
          accessorKey: 'number',
          header: () => "Eur",
          className: 'width-min text-left nowrap',
            onNumberEdit: handleEdit
        }
    ];

    var unEditableTwoRowTableColumns: TableColumnDefs<any> = [
        {
          accessorKey: 'label',
          header: () => "Laskelma OPOn riittävyydestä ",
          className: 'width-middle text-left nowrap',
      
        },
        {
          accessorKey: 'number',
          header: () => "Eur",
          className: 'width-min text-left nowrap',
            accessorFn: row => formatCurrency(row.number),
        }
    ];
    return (
        <>
            <AuditingTable
                data={[data[0]]}
                columns={unEditableTwoRowTableColumns}
                showGlobalFilter={false}
                animationDisabled={true}
            />
            <AuditingTable
                data={[data[1],data[2],data[3]]}
                columns={editableTwoRowTableColumns}
                showGlobalFilter={false}
                animationDisabled={true}
                hideHeader
            />
            <AuditingTable
                data={[data[4]]}
                columns={unEditableTwoRowTableColumns}
                showGlobalFilter={false}
                animationDisabled={true}
                hideHeader
            />
        </>
    )
}


    
export const ThirdOwnCapitalForm: React.FC<Props> = ({ formState, patchFormState }) => {

    function handleEdit(props: any){
        const newFormState = {...formState}
        if(formState.thirdOwnCapitalData[props.rowIndex][props.columnId] !== props.value){
            newFormState.thirdOwnCapitalData[props.rowIndex][props.columnId] = props.value
            patchFormState(newFormState)
        }
    }

    const freeAssetsAllTogether = {
        cy: (
            formState.ownCapitalFormData.firstTable.thisSeasonsWins.cy + 
            formState.ownCapitalFormData.firstTable.lastSeasonsWins.cy + 
            formState.ownCapitalFormData.firstTable.freeInvestedAssetsFund.cy
        ),
        py: (
            formState.ownCapitalFormData.firstTable.thisSeasonsWins.py + 
            formState.ownCapitalFormData.firstTable.lastSeasonsWins.py + 
            formState.ownCapitalFormData.firstTable.freeInvestedAssetsFund.py
        ),
    }

    const shortFormState = formState.thirdOwnCapitalData;

    const data = [
        {
            label: "Vapaa pääoma yhteensä",
            cy: freeAssetsAllTogether.cy,
            py: freeAssetsAllTogether.py,
        },
        ...shortFormState,
        {
            label: "Jakokelpoiset varat",
            cy: freeAssetsAllTogether.cy - Object.keys(shortFormState).reduce((accumulator, key) => accumulator + shortFormState[key].cy, 0),
            py: freeAssetsAllTogether.py - Object.keys(shortFormState).reduce((accumulator, key) => accumulator + shortFormState[key].py, 0),
        },
    ]

    var editableTwoRowTableColumns: TableColumnDefs<any> = [
        {
          accessorKey: 'label',
          header: () => null,
          className: 'width-middle text-left nowrap',
      
        },
        {
          accessorKey: 'cy',
          header: () => "CY",
          className: 'width-middle text-left nowrap',
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'py',
            header: () => "PY",
            className: 'width-middle text-left nowrap',
              onNumberEdit: handleEdit
          }
    ];

    var unEditableTwoRowTableColumns: TableColumnDefs<any> = [
        {
          accessorKey: 'label',
          header: () => null,
          className: 'width-middle text-left nowrap',
      
        },
        {
            accessorKey: 'cy',
            header: () => "CY",
            className: 'width-middle text-left nowrap',
            accessorFn: row => formatCurrency(row.cy),

          },
        {
            accessorKey: 'py',
            header: () => "PY",
            className: 'width-middle text-left nowrap',
            accessorFn: row => formatCurrency(row.py),

        }
    ];
    return (
        <>
            <AuditingTable
            data={[data[0]]}
            columns={unEditableTwoRowTableColumns}
            showGlobalFilter={false}
            animationDisabled={true}
        />
        <AuditingTable
            data={[data[1],data[2],data[3]]}
            columns={editableTwoRowTableColumns}
            showGlobalFilter={false}
            animationDisabled={true}
        />
        <AuditingTable
            data={[data[4]]}
            columns={unEditableTwoRowTableColumns}
            showGlobalFilter={false}
            animationDisabled={true}
        />
      </>
    )
}