// import { ApisauceInstance, create, ApiResponse } from 'apisauce';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiConfig, DEFAULT_API_CONFIG } from './apiConfig';

type AxiosConfig = AxiosRequestConfig<any> | undefined;

export abstract class ApiBase {
  private api: AxiosInstance;
  private token: string | undefined;
  private otpSecret: string | undefined;
  private isVerified: boolean | undefined;

  constructor(token?: string, config: ApiConfig = DEFAULT_API_CONFIG) {
    this.token = token;
    this.api = axios.create({
      ...config,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  /**
   * Adds authorization header to axios config.
   *
   * @param config Axios config. By default, sends authorization header. Override default with, e.g., empty object if (optional) to API.
   * @returns Axios config object with authorization header included.
   */
  authHeader(config?: AxiosConfig) {
    if (!this.token) return config;

    const headers = {
      ...config?.headers,
      Authorization: `Bearer ${this.token}`,
    };
    return {
      ...config,
      headers,
    };
  }

  /**
   * Set jwt-token.
   *
   * @param token jwt token
   */
  setToken(token?: string) {
    this.token = token;
  }

  /**
   * Set OTP.
   *
   * @param otpSecret OTP secret
   */
  setOTP(otpSecret?: string) {
    this.otpSecret = otpSecret;
  }

  /**
   * Set isVerified.
   * 
   * @param isVerified isVerified
   */
  setVerified(isVerified?: boolean) {
    this.isVerified = isVerified;
  }

  /**
   * Make GET reguest.
   *
   * @param url API endpoint
   * @param config Axios config. By default, sends authorization header to API.
   *               Override default config with, e.g., empty object if endpoint is public (e.g. /login)
   * @returns either success (requested data) or error response
   */
  async get<T>(url: string, config: AxiosConfig = this.authHeader()) {
    try {
      const response = await this.api.get(url, config);
      return this.handleSuccess<T>(response);
    } catch (error: any) {
      return this.handleError(error.response as AxiosResponse);
    }
  }

  /**
   * Make POST reguest.
   *
   * @param url API endpoint
   * @param data Payload
   * @param config Axios config. By default, sends authorization header to API.
   *               Override default config with, e.g., empty object if endpoint is public (e.g. /login)
   * @returns either success (requested data) or error response
   */
  async post<T>(
    url: string,
    data?: any,
    config: AxiosConfig = this.authHeader()
  ) {
    try {
      const response = await this.api.post(url, data, config);
      return this.handleSuccess<T>(response);
    } catch (error: any) {
      return this.handleError(error.response as AxiosResponse);
    }
  }

  /**
   * Make PUT reguest.
   *
   * @param url API endpoint
   * @param data Payload
   * @param config Axios config. By default, sends authorization header to API.
   *               Override default config with, e.g., empty object if endpoint is public (e.g. /login)
   * @returns either success (requested data) or error response
   */
  async put<T>(
    url: string,
    data?: any,
    config: AxiosConfig = this.authHeader()
  ) {
    try {
      const response = await this.api.put(url, data, config);
      return this.handleSuccess<T>(response);
    } catch (error: any) {
      return this.handleError(error.response as AxiosResponse);
    }
  }

  /**
   * Make PATCH reguest
   * .
   * @param url API endpoint
   * @param data Payload
   * @param config Axios config. By default, sends authorization header to API.
   *               Override default config with, e.g., empty object if endpoint is public (e.g. /login)
   * @returns either success (requested data) or error response
   */
  async patch<T>(
    url: string,
    data?: any,
    config: AxiosConfig = this.authHeader()
  ) {
    try {
      const response = await this.api.patch(url, data, config);
      return this.handleSuccess<T>(response);
    } catch (error: any) {
      return this.handleError(error.response as AxiosResponse);
    }
  }

  /**
   * Make DELETE reguest.
   *
   * @param url API endpoint
   * @param config Axios config. By default, sends authorization header to API.
   *               Override default config with, e.g., empty object if endpoint is public (e.g. /login)
   * @returns either success (requested data) or error response
   */
  async delete<T>(url: string, config: AxiosConfig = this.authHeader()) {
    try {
      const response = await this.api.delete(url, config);
      return this.handleSuccess<T>(response);
    } catch (error: any) {
      return this.handleError(error.response as AxiosResponse);
    }
  }

  /**
   * Handle successful response.
   *
   * @param response axios response
   * @returns requested data
   */
  private handleSuccess<T>(response: AxiosResponse): Api.Success<T> {
    return {
      kind: 'OK',
      data: response.data as T,
    };
  }

  /**
   * Handle error response.
   *
   * @param response axios response
   * @returns error information
   */
  private handleError(response: AxiosResponse): Api.Problem {
    return this.composeApiError(response);
  }

  /**
   * Inspects HTTP response status code and returns corresponding error information.
   *
   * @param response axios response
   * @returns error information
   */
  private composeApiError(response: AxiosResponse): Api.Problem {
    const { status, data } = response;

    if (status >= 500 && status < 600) {
      return { kind: 'SERVER_ERROR', data };
    }

    if (status >= 400 && status < 500) {
      switch (status) {
        case 401:
          return { kind: 'UNAUTHORIZED', data };
        case 403:
          return { kind: 'FORBIDDEN', data };
        case 404:
          return { kind: 'NOT_FOUND', data };
        default:
          return { kind: 'REJECTED', data };
      }
    }

    return { kind: 'UNKNOWN', data };
  }
}

export default ApiBase;
