import AuditingStatus from '../../components/auditing/AuditingStatus';
import AuditingTemplateSelect from '../../components/auditing/AuditingTemplateSelect';
import DegreeOfReadiness from '../../components/auditing/DegreeOfReadiness';
import RemarksList from '../../components/auditing/RemarksList';
import { FormFieldBuilder } from './FormFieldBuilder';

type SectionFormType = AuditingFrontPageForm;

const sectionKey: AuditingSectionKey = 'auditingFrontPage';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  auditingStartDate: null,
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.group({
    items: [
      formBuilder.dateField({ accessor: 'auditingStartDate' }),
      formBuilder.custom(<AuditingTemplateSelect />),
      formBuilder.custom(<AuditingStatus />),
    ],
  }),

  formBuilder.custom(<DegreeOfReadiness />),

  formBuilder.custom(<RemarksList />),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
