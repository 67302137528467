import { t } from '.';
import { slug } from '../utils/string';

// Get route translations as it is (these are meant to show for the users, e.g. "Front page")
export const rt = (routeKey: string) => t(`route:${routeKey}`);

// Sanitize route translations
// E.g. "Front Page" becomes "front-page" (these are used in url)
export const path = (...routeKeys: (string | number)[]) => {
  return routeKeys
    .map(key => {
      if (typeof key === 'string') return slug(t(`route:${key}`));
      return key;
    })
    .join('/');
};
