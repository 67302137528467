import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  AdministrativeIndependency,
  AdministrativeParticipation,
  CommitmentToCompetence,
  CommunityHasInternalAuditing,
  CompetitiveSituation,
  CustomerBase,
  CyclicalActivity,
  DifferentiationOfWorkTasks,
  EconomicSituation,
  HonestAndEthicalCulture,
  InternalAuditingEvidence,
  InternalAuditingInquiries,
  InternalAuditingInquiriesFindings,
  InternalControlFollowsLawsAndInternalInstructions,
  InternalControlProvideAppropriateBasis,
  ManagementAttitudeToFinancialReporting,
  ManagementInvolvement,
  ManagementsReportMeetsOurUnderstanding,
  //ManagementsStatementOnInternalControlMeetsOurUnderstanding,
  Market,
  OrganizationalStructureIsAppropriate,
  ProductionTecknologyLevel,
  RiskManagementFollowsLawsAndInternalInstructions,
  showAdministrativeIndependency,
  showAdministrativeParticipation,
  showInternalAuditingEvidence7c,
  showInternalAuditingInquiries7a,
  showInternalAuditingInquiriesFindings7b,
  SignificantChanges,
  SupervisionOfGroupEntitiesImplementedAppropriately,
} from './internalControlAndOperatingEnvironmentUtils';
import { PartOfTheGroup } from './permanentDataUtils';
import auditingJson from '../../../i18n/locales/fi/auditing.json';

type SectionFormType = InternalControlAndOperatingEnvironmentForm;
const sectionKey: AuditingSectionKey = 'internalControlAndOperatingEnvironment';

const vocabulary = auditingJson.form.internalControlAndOperatingEnvironment;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  inquiredRiskAssessmentProcess: '',
  inquiredRiskAssessmentProcessDate:
    auditing.auditingFrontPage?.form.auditingStartDate ?? null,
  riskAssessmentProcessDetails: '',
  market: null,
  marketDetails: '',
  cyclicalActivity: null,
  cyclicalActivityDetails: '',
  competitiveSituation: null,
  competitiveSituationDetails: '',
  customerBase: null,
  customerBaseDetails: '',
  productionTecknologyLevel: null,
  productionTecknologyLevelDetails: '',
  economicSituation: null,
  economicSituationDetails: '',
  significantChanges: null,
  significantChangesDetails: '',

  inquiredInternalControl: '',
  inquiredInternalControlDate:
    auditing.auditingFrontPage?.form.auditingStartDate ?? null,
  honestAndEthicalCulture: null,
  honestAndEthicalCultureDetails: '',
  commitmentToCompetence: null,
  commitmentToCompetenceDetails: '',
  managementInvolvement: null,
  managementInvolvementDetails: '',
  administrativeIndependency: null,
  administrativeIndependencyDetails: '',
  administrativeParticipation: null,
  administrativeParticipationDetails: '',
  differentiationOfWorkTasks: null,
  differentiationOfWorkTasksDetails: '',
  managementAttitudeToFinancialReporting: null,
  managementAttitudeToFinancialReportingDetails: '',
  communityHasInternalAuditing: null,
  communityHasInternalAuditingDetails: '',
  internalAuditingInquiries: null,
  internalAuditingInquiriesDetails: '',
  internalAuditingInquiriesFindings: null,
  internalAuditingInquiriesFindingsDetails: '',
  internalAuditingEvidence: null,
  internalAuditingEvidenceDetails: '',
  internalControlProvideAppropriateBasis: null,
  internalControlProvideAppropriateBasisDetails: '',
  organizationalStructureIsAppropriate: null,
  organizationalStructureIsAppropriateDetails: '',

  // group supervision
  groupSupervisionAndOwnershipControl: '',
  monitoringOfSubsidiariesAndPossibleDeviation: '',
  fullfillmentOfReportingObligationsOfSubsidiaries: '',
  adherenceToGroupInstructionsInMattersRequiringPriorApproval: '',
  managementsReportMeetsOurUnderstanding: null,
  managementsReportCommentField: '',
  summaryOfPotentialDeviationInManagementsReports: '',
  referencesToOtherRelevantWorkPapers: '',
  managementsReportsCorrectness: '',
  supervisionOfGroupEntitiesImplementedAppropriately: null,
  summaryOfPotentialDeviationInSupervisionOfGroupEntities: '',

  overallUnderstandingOfRiskManagement: '',
  riskManagementFollowsLawsAndInternalInstructions: vocabulary.riskManagementFollowsLawsAndInternalInstructionsOptions.yes,
  riskManagementFollowsLawsAndInternalInstructionsDetails: '',
  riskManagementCommentField: '',
  possibleReferencesAndOverallUnderstandingOfLevelOfRiskManagement: '',

  overallUnderstandingOfInternalControl: '',
  internalControlFollowsLawsAndInternalInstructions: null,
  summaryOfPossibleDeviationsInInternalControl: '',
  evaluationOfManagementsStatementOnInternalControl: '',
  managementsStatementOnInternalControlMeetsOurUnderstanding: null,
  summaryOfPossibleDeviationsInManagement: '',
  possibleReferencesAndOverallUnderstandingOfLevelOfControl: '',

  moreQuestions: null,

  // More questions accordionGroup
  strategyField: '',
  strategyFieldDetails: '',
  objectivesField: '',
  objectivesFieldDetails: '',
  risksField: '',
  risksFieldDetails: '',
  riskManagementField: '',
  riskManagementFieldDetails: '',
  significantField: '',
  significantFieldDetails: '',

  // toteutetaanko tilikauden aikainen tarkastus
  isInterimAudit: true,
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  
  formBuilder.boolean({
    accessor: 'isInterimAudit',
    plainLabel: 'Toteutetaanko tilikauden aikainen tarkastus?',
    options: ['Ei', 'Kyllä'],
  }),

  formBuilder.accordionGroup({
    open: true,
    //titleKey: 'understandingOfRiskAssessmentProcess',
    titleKey: 'riskAssessment',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'inquiredRiskAssessmentProcess',
            showContextInfo: 'top-right',
          }),
          formBuilder.dateField({
            accessor: 'inquiredRiskAssessmentProcessDate',
          }),
        ],
      }),

      formBuilder.textArea({
        accessor: 'riskAssessmentProcessDetails',
        showContextInfo: 'top-right',
      }),

      formBuilder.boolean({accessor: 'moreQuestions', plainLabel: 'Avaa lisäkysymykset'}),
      formBuilder.accordionGroup({
        title: 'Lisäkysymykset',
        hidden: !formState.moreQuestions,
        open: true,
        items: [
          formBuilder.group({
            items: [
              formBuilder.subtitle({title: 'Strategia ja tavoitteet on määritelty'}),
              formBuilder.textArea({accessor: 'strategyField', plainLabel: vocabulary.evaluation}),
              formBuilder.textArea({accessor: 'strategyFieldDetails', plainLabel: vocabulary.followUp}),

              formBuilder.subtitle({title: 'Tavoitteet ovat arvioitavissa ja mitattavissa'}),
              formBuilder.textArea({accessor: 'objectivesField', plainLabel: vocabulary.evaluation}),
              formBuilder.textArea({accessor: 'objectivesFieldDetails', plainLabel: vocabulary.followUp}),

              formBuilder.subtitle({title: 'Riskit on tunnistettu ja niiden merkitys arvioitu'}),
              formBuilder.textArea({accessor: 'risksField', plainLabel: vocabulary.evaluation}),
              formBuilder.textArea({accessor: 'risksFieldDetails', plainLabel: vocabulary.followUp}),

              formBuilder.subtitle({title: 'Merkittäville riskeille on määritetty riskienhallintatoimenpiteet'}),
              formBuilder.textArea({accessor: 'riskManagementField', plainLabel: vocabulary.evaluation}),
              formBuilder.textArea({accessor: 'riskManagementFieldDetails', plainLabel: vocabulary.followUp}),

              formBuilder.subtitle({title: 'Olennaiset ulkoisen toimintaympäristön muutokset tunnistetaan ja arvioidaan'}),
              formBuilder.textArea({accessor: 'significantField', plainLabel: vocabulary.evaluation}),
              formBuilder.textArea({accessor: 'significantFieldDetails', plainLabel: vocabulary.followUp}),


            ]
          })
        ]
      }),
      formBuilder.group({
        items: [
          formBuilder.textArea({
            accessor: 'overallUnderstandingOfRiskManagement',
            showContextInfo: 'top-right',
          }),
    
          formBuilder.checkboxGroup({
            accessor: 'riskManagementFollowsLawsAndInternalInstructions',
            options: Object.values(
              RiskManagementFollowsLawsAndInternalInstructions
            ),
            //detailsAccessor: 'riskManagementFollowsLawsAndInternalInstructionsDetails',
          }),
          formBuilder.textArea({
            accessor:
              'riskManagementFollowsLawsAndInternalInstructionsDetails',
            hidden: !formState.riskManagementFollowsLawsAndInternalInstructions?.yes
          }),
          formBuilder.textArea({
            accessor: 'riskManagementCommentField',
            plainLabel: 'Kommentit',
            hidden: !formState.riskManagementFollowsLawsAndInternalInstructions?.kommentti
          }),
    
          formBuilder.textArea({
            accessor:
              'possibleReferencesAndOverallUnderstandingOfLevelOfRiskManagement',
            showContextInfo: 'top-right',
          }),
        ]
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'communityAndOperatingEnvironment',
    items: [
      formBuilder.group({
        items: [
          formBuilder.checkboxGroup({
            accessor: 'market',
            options: Object.values(Market),
            //detailsAccessor: 'marketDetails',
          }),
          formBuilder.textArea({
            accessor: 'marketDetails',
            hidden: !formState.market?.kommentti
          }),

          formBuilder.checkboxGroup({
            accessor: 'cyclicalActivity',
            options: Object.values(CyclicalActivity),
            detailsAccessor: 'cyclicalActivityDetails',
            detailsHidden: !formState.cyclicalActivity?.seasonal && !formState.cyclicalActivity?.kommentti,
          }),

          formBuilder.checkboxGroup({
            accessor: 'competitiveSituation',
            options: Object.values(CompetitiveSituation),
            //detailsAccessor: 'competitiveSituationDetails',
          }),
          formBuilder.textArea({
            accessor: 'competitiveSituationDetails',
            hidden: !formState.competitiveSituation?.kommentti
          }),

          formBuilder.checkboxGroup({
            accessor: 'customerBase',
            options: Object.values(CustomerBase),
            detailsAccessor: 'customerBaseDetails',
            detailsHidden: !formState.customerBase?.dependentOnFewCustomers && !formState.customerBase?.kommentti,
          }),

          formBuilder.checkboxGroup({
            accessor: 'productionTecknologyLevel',
            options: Object.values(ProductionTecknologyLevel),
            //detailsAccessor: 'productionTecknologyLevelDetails',
          }),
          formBuilder.textArea({
            accessor: 'productionTecknologyLevelDetails',
            hidden: !formState.productionTecknologyLevel?.kommentti
          }),

          formBuilder.checkboxGroup({
            accessor: 'economicSituation',
            options: Object.values(EconomicSituation),
            //detailsAccessor: 'economicSituationDetails',
            //detailsHidden: formState.economicSituation !== EconomicSituation.detected,
          }),
          formBuilder.textArea({
            accessor: 'economicSituationDetails',
            hidden: !formState.economicSituation?.kommentti && !formState.economicSituation?.detected
          }),

          formBuilder.checkboxGroup({
            accessor: 'significantChanges',
            showContextInfo: 'bottom-left',
            options: Object.values(SignificantChanges),
            //detailsAccessor: 'significantChangesDetails',
          }),
          formBuilder.textArea({
            accessor: 'significantChangesDetails',
            hidden: !formState.significantChanges?.kommentti
          }),
        ],
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingInternalControlComponents',
    items: [
      formBuilder.group({
        items: [
          /* formBuilder.text({
            hidden:
              auditing?.permanentData?.form.companyInformation
                ?.internalControlAndRiskManagementInstructionUpToDate === true,
            textKey: 'understandingInternalControlComponentsNotUpToDate',
          }), */

          formBuilder.text({
            hidden:
              auditing?.permanentData?.form.companyInformation
                ?.internalControlAndRiskManagementInstructionUpToDate !== true,
            textKey: 'understandingInternalControlComponentsUpToDate',
          }),

          formBuilder.groupRow({
            items: [
              formBuilder.textInput({
                accessor: 'inquiredInternalControl',
                showContextInfo: 'top-right',
              }),
              formBuilder.dateField({
                accessor: 'inquiredInternalControlDate',
              }),
            ],
          }),

          // 1
          formBuilder.checkboxGroup({
            accessor: 'honestAndEthicalCulture',
            options: Object.values(HonestAndEthicalCulture),
            //detailsAccessor: 'honestAndEthicalCultureDetails',
          }),
          formBuilder.textArea({
            accessor: 'honestAndEthicalCultureDetails',
            hidden: !formState.honestAndEthicalCulture?.kommentti
          }),

          // 2
          formBuilder.checkboxGroup({
            accessor: 'commitmentToCompetence',
            showContextInfo: 'top-right',
            options: Object.values(CommitmentToCompetence),
            //detailsAccessor: 'commitmentToCompetenceDetails',
          }),
          formBuilder.textArea({
            accessor: 'commitmentToCompetenceDetails',
            hidden: !formState.commitmentToCompetence?.kommentti
          }),

          // 3
          formBuilder.checkboxGroup({
            accessor: 'managementInvolvement',
            showContextInfo: 'top-right',
            options: Object.values(ManagementInvolvement),
            //detailsAccessor: 'managementInvolvementDetails',
          }),
          formBuilder.textArea({
            accessor: 'managementInvolvementDetails',
            hidden: !formState.managementInvolvement?.kommentti
          }),

          // 4
          formBuilder.checkboxGroup({
            accessor: 'administrativeIndependency',
            options: Object.values(AdministrativeIndependency),
            detailsAccessor: 'administrativeIndependencyDetails',
            detailsHidden: !showAdministrativeIndependency(formState) && !formState.administrativeIndependency?.kommentti,
          }),

          // 4a
          formBuilder.checkboxGroup({
            accessor: 'administrativeParticipation',
            hidden: !showAdministrativeParticipation(formState),
            showContextInfo: 'top-right',
            options: Object.values(AdministrativeParticipation),
            detailsAccessor: 'administrativeParticipationDetails',
            showDetailsContextInfo: 'top-right',
            detailsHidden: !formState.administrativeParticipation?.notAppropriate && !formState.administrativeParticipation?.kommentti,
          }),

          // 5
          formBuilder.checkboxGroup({
            accessor: 'differentiationOfWorkTasks',
            options: Object.values(DifferentiationOfWorkTasks),
            detailsAccessor: 'differentiationOfWorkTasksDetails',
            showDetailsContextInfo: 'top-right',
            detailsHidden:
              !formState.differentiationOfWorkTasks?.belowNormal && !formState.differentiationOfWorkTasks?.kommentti,
          }),

          // 6
          formBuilder.checkboxGroup({
            accessor: 'managementAttitudeToFinancialReporting',
            showContextInfo: 'top-right',
            options: Object.values(ManagementAttitudeToFinancialReporting),
            detailsAccessor: 'managementAttitudeToFinancialReportingDetails',
            showDetailsContextInfo: 'top-right',
            detailsHidden:
              !formState.managementAttitudeToFinancialReporting?.belowNormal && !formState.managementAttitudeToFinancialReporting?.kommentti,
          }),

          // 7
          formBuilder.checkboxGroup({
            accessor: 'communityHasInternalAuditing',
            options: Object.values(CommunityHasInternalAuditing),
            detailsAccessor: 'communityHasInternalAuditingDetails',
            showDetailsContextInfo: 'top-right',
            detailsHidden: !(formState.communityHasInternalAuditing?.yes || formState.communityHasInternalAuditing?.yesOutsourced) && !formState.communityHasInternalAuditing?.kommentti
          }),

          // 7a
          formBuilder.checkboxGroup({
            accessor: 'internalAuditingInquiries',
            hidden: !showInternalAuditingInquiries7a(formState),
            options: Object.values(InternalAuditingInquiries),
            detailsAccessor: 'internalAuditingInquiriesDetails',
            detailsHidden:
              !formState.internalAuditingInquiries?.kommentti,
          }),

          // 7b
          formBuilder.checkboxGroup({
            accessor: 'internalAuditingInquiriesFindings',
            hidden: !showInternalAuditingInquiriesFindings7b(formState),
            options: Object.values(InternalAuditingInquiriesFindings),
            detailsAccessor: 'internalAuditingInquiriesFindingsDetails',
            detailsHidden: !formState.internalAuditingInquiriesFindings?.yes && !formState.internalAuditingInquiriesFindings?.kommentti,
          }),

          // 7c
          formBuilder.checkboxGroup({
            accessor: 'internalAuditingEvidence',
            hidden: !showInternalAuditingEvidence7c(formState),
            options: Object.values(InternalAuditingEvidence),
            detailsAccessor: 'internalAuditingEvidenceDetails',
            detailsHidden: !formState.internalAuditingEvidence?.yes && !formState.internalAuditingEvidence?.kommentti,
            showDetailsContextInfo: 'top-right',
          }),

          formBuilder.checkboxGroup({
            accessor: 'internalControlProvideAppropriateBasis',
            options: Object.values(InternalControlProvideAppropriateBasis),
            detailsAccessor: 'internalControlProvideAppropriateBasisDetails',
            detailsHidden: !formState.internalControlProvideAppropriateBasis?.no && !formState.internalControlProvideAppropriateBasis?.kommentti,
            showDetailsContextInfo: 'top-right',
          }),

          formBuilder.checkboxGroup({
            accessor: 'organizationalStructureIsAppropriate',
            options: Object.values(OrganizationalStructureIsAppropriate),
            detailsAccessor: 'organizationalStructureIsAppropriateDetails',
            detailsHidden: !formState.organizationalStructureIsAppropriate?.no && !formState.organizationalStructureIsAppropriate?.kommentti,
            showDetailsContextInfo: 'top-right',
          }),

          formBuilder.textArea({
            accessor: 'overallUnderstandingOfInternalControl',
            showContextInfo: 'top-right',
          }),

          formBuilder.checkboxGroup({
            accessor: 'internalControlFollowsLawsAndInternalInstructions',
            options: Object.values(
              InternalControlFollowsLawsAndInternalInstructions
            ),
            showDetailsContextInfo: 'top-right',
          }),

          formBuilder.textArea({
            accessor: 'summaryOfPossibleDeviationsInInternalControl',
          }),

          /* formBuilder.textArea({
            accessor: 'evaluationOfManagementsStatementOnInternalControl',
            showContextInfo: 'top-right',
          }),

          formBuilder.radioGroup({
            accessor: 'managementsStatementOnInternalControlMeetsOurUnderstanding',
            options: Object.values(
              ManagementsStatementOnInternalControlMeetsOurUnderstanding
            ),
            showDetailsContextInfo: 'top-right',
          }),

          formBuilder.textArea({
            accessor: 'summaryOfPossibleDeviationsInManagement',
          }), */

          formBuilder.textArea({
            accessor: 'possibleReferencesAndOverallUnderstandingOfLevelOfControl',
            showContextInfo: 'top-right',
          }),
        ],
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'groupSupervision',
    hidden:
      auditing?.permanentData?.form.isPartOfTheGroup !==
      PartOfTheGroup.yesIsParentCompany,
    items: [
      // 1
      formBuilder.subtitle({
        titleKey: 'groupInstructionsGuidelinesForGroupManagementTitle',
      }),

      formBuilder.text({
        hidden:
          auditing?.permanentData?.form?.companyInformation?.groupInstructionUpToDate === true,
        textKey: 'groupInstructionNotUpToDate',
      }),
      
      formBuilder.text({
        hidden:
          auditing?.permanentData?.form?.companyInformation?.groupInstructionUpToDate !== true,
        textKey: 'groupInstructionUpToDate',
      }),
      
      formBuilder.textArea({
        accessor: 'groupSupervisionAndOwnershipControl',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'monitoringOfSubsidiariesAndPossibleDeviation',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'fullfillmentOfReportingObligationsOfSubsidiaries',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'adherenceToGroupInstructionsInMattersRequiringPriorApproval',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'supervisionOfGroupEntitiesImplementedAppropriately',
        options: Object.values(
          SupervisionOfGroupEntitiesImplementedAppropriately
        ),
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPotentialDeviationInSupervisionOfGroupEntities',
      }),

      // 2
      formBuilder.subtitle({
        titleKey: 'managementsReportsCorrectnessTitle',
      }),

      formBuilder.textArea({
        accessor: 'managementsReportsCorrectness',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'managementsReportMeetsOurUnderstanding',
        options: Object.values(ManagementsReportMeetsOurUnderstanding),
      }),
      formBuilder.textArea({
        accessor: 'managementsReportCommentField',
        plainLabel: 'Kommentit',
        hidden: !formState.managementsStatementOnInternalControlMeetsOurUnderstanding?.comment
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPotentialDeviationInManagementsReports',
        //hidden: !formState.managementsStatementOnInternalControlMeetsOurUnderstanding?.yes
      }),

      // 3
      formBuilder.subtitle({
        titleKey: 'referencesToOtherRelevantWorkPapersTitle',
      }),

      formBuilder.textArea({
        accessor: 'referencesToOtherRelevantWorkPapers',
        showContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
