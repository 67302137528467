//import AuditingSection from '../AuditingSection';
import { IsDecisionMade } from './auditing/governmentTransfersAndTaxRevenuesUtils';
import {
  ManagementsReportMeetsOurUnderstanding,
  RiskManagementFollowsLawsAndInternalInstructions,
  SupervisionOfGroupEntitiesImplementedAppropriately,
} from './planning/internalControlAndOperatingEnvironmentUtils';
import {
  InternalControlFollowsLawsAndInternalInstructions,
  ManagementsStatementOnInternalControlMeetsOurUnderstanding,
} from './planning/internalControlAndOperatingEnvironmentUtils';
import {
  FailureToNotify,
  LegalSituation,
  TaxDebtInformation,
} from './planning/permanentDataUtils';
import {
  assetsLiabilitiesDifferenceAccessor,
  auditorInformationAccessor,
  hasWagesAndSalariesAccessor,
  makeRemarkRule,
  reviewOfPriorityBoardsNotesAccessor,
  reviewOfPriorityOfficialsNotesAccessor,
  councilMeetingMinutesNotesAccessor,
  boardMeetingMinutesNotesAccessor,
} from './remarkRuleUtils';

/**
 * Define remark rules for F3-presentationAndContent.
 */
const batchF3PresentationAndContentRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusion4', ['pm', 'overPm']]
  },
  {
    rule: ['inspectionConclusion3', ['two']]
  }
];

/**
 * Define remark rules for batch J1-turnOver.
 */
  const commonForms = [
    {
      rule: ["AMPTcommonForm1", ['pm', 'overPm']]
    },
    {
      rule: ["AMPTcommonForm2", ['pm', 'overPm']]
    },
    {
      rule: ["AMPTcommonForm3", ['pm', 'overPm']]
    },
    {
      rule: ["AMPTcommonForm4", ['pm', 'overPm']]
    },
    {
      rule: ["AMPTcommonForm5", ['pm', 'overPm']]
    },
    {
      rule: ["AMPTcommonForm6", ['pm', 'overPm']]
    }
  ]

const batchJ1TurnOverRules: AuditingSectionRule[] = [
  {
    // rule: ['accessor', ['remarkTriggers']],
    rule: ['inspectionConclusionInvoicing', ['pm', 'overPm']], // Liikevaihdon aineistotarkastus
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch J2-accountsReceivable.
 */
const batchJ2AccountsReceivableRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']], // Myyntireskontran
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']], // Myyntisaamisten läpikäynti
  },
  {
    rule: ['AMPT3', ['pm', 'overPm']], // Myyntisaamisten saldovahvistus
  },
  {
    rule: ['AMPT4', ['pm', 'overPm']], // Myyntisaamisten Maksuun tarkistus
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch JB-otherOperatingIncome.
 */
const batchJBOtherOperatingIncomeRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusion', ['pm', 'overPm']], // Yksittäisten tapahtumien tarkastus
  },
  {
    rule: ['inspectionConclusion2', ['pm', 'overPm']] // Katkon tarkastus
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch K1-materialsAndServices.
 */
const batchK1MaterialsAndServicesRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusionInterruption', ['pm', 'overPm']], // Ostojen katkon tarkastus
  },
  {
    rule: ['inspectionConclusionInspection', ['pm', 'overPm']], // Ostojen aineistotarkastus
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch K2-tradeCreditors.
 */
const batchK2TradeCreditorsRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusionPurchase', ['pm', 'overPm']], // Ostoreskontran täsmäytys taseelle
  },
  {
    rule: ['inspectionConclusionCorrectness', ['pm', 'overPm']], // Ostoreskontran matemaattinen
  },
  {
    rule: ['inspectionConclusionAccounts', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionExpenses', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionEvents', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionExistence', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionfication', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch KB-otherOperatingExpenses.
 */
const batchKBOtherOperatingExpensesRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusionExpenses', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionInspection', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusionFees', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch N-taxes.
 */
const batchNTaxesRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch O1-fixedAssets.
 */
const batchO1FixedAssetsRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch O3-investments.
 */
const batchO3investmentsRules: AuditingSectionRule[] = [
  ...commonForms as AuditingSectionRule[],
];
/**
 * Define remark rules for batch P1-warehouses.
 */
const batchP1WarehousesRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusion', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion2', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion3', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion4', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion5', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion6', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch O4-funding.
 */
const batchO4FundingRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch Q1-otherReceivables.
 */
const batchQ1OtherReceivablesRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT3', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT4', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch Q2-cashEquivalents.
 */
const batchQ2CashEquivalentsRules: AuditingSectionRule[] = [
  {
    rule: ['balanceConfirmationAMPT', ['pm', 'overPm']],
  },
  {
    rule: ['accountReconciliationAMPT', ['pm', 'overPm']],
  },
  {
    rule: ['balanceConfirmationThroughBankAMPT', ['pm', 'overPm']],
  },
  {
    rule: ['debetAndCreditConfirmationAMPT', ['pm', 'overPm']],
  },
  {
    rule: ['cashCounterConfirmationAMPT', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch Q3-loanReceivables.
 */
const batchQ3LoanReceivablesRules: AuditingSectionRule[] = [
  {
    rule: ['AMPT', ['pm', 'overPm']],
  },
  {
    rule: ['AMPT2', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch R2-capitalLoans.
 */
const batchR2CapitalLoansRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusion', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion2', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion3', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion4', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch S1-financialInstitutions.
 */
const batchS1FinancialInstitutionsRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusion', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion2', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion3', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion4', ['pm', 'overPm']],
  },
  {
    rule: ['inspectionConclusion5', ['pm', 'overPm']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch T1-otherLiabilities.
 */
const batchT1OtherLiabilitiesRules: AuditingSectionRule[] = [
  {
    rule: ['inspectionConclusionLoans', ['noIssuesOverPM', 'issuesOverPM']],
  },
  {
    rule: ['inspectionConclusionDebts', ['noIssuesOverPM', 'issuesOverPM']],
  },
  {
    rule: ['inspectionConclusionLiaibilities', ['noIssuesOverPM', 'issuesOverPM']],
  },
  {
    rule: ['inspectionConclusionClassification', ['noIssuesOverPM', 'issuesOverPM']],
  },
  {
    rule: ['inspectionConclusionOther', ['noIssuesOverPM', 'issuesOverPM']],
  },
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch T2-advancePayments.
 */
const batchT2AdvancePaymentsRules: AuditingSectionRule[] = [
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch XJ.
 */
const batchXJRules: AuditingSectionRule[] = [
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch XK.
 */
const batchXKRules: AuditingSectionRule[] = [
  ...commonForms as AuditingSectionRule[]
];
/**
 * Define remark rules for batch V-reserves.
 */
const batchVReservesRules: AuditingSectionRule[] = [
  ...commonForms as AuditingSectionRule[]
];


/**
 * Define remark rules for PERMANENT DATA section.
 */
const permanentDataRules: AuditingSectionRule[] = [
  {
    rule: ['auditorInformation.title', []],
    depends: [[auditorInformationAccessor, [true]]],
  },
  {
    // E.g. below field gets "remarked" IF its value is false (user answered "No")
    // AND its depending field (isFirstAuditing) has value true (user answered "Yes")
    rule: ['isCustomerApprovalAndCustomerIdentificationCompleted', [false]],
    depends: [['isFirstAuditing', [true]]],
  },
  {
    rule: ['isMattersThatAffectAssignment', [true]],
    depends: [['isFirstAuditing', [false]]],
  },
  { rule: ['areTermsAgreedAndUpToDate', [false]] },
  { rule: ['properIdentificationOfRepresentatives', [false]] },
  { rule: ['companyInformation.capitalStockUpToDate', [false]] },
  { rule: ['companyInformation.numberOfStocksUpToDate', [false]] },
  { rule: ['companyInformation.boardChairmanUpToDate', [false]] },
  { rule: ['companyInformation.boardMember1UpToDate', [false]] },
  { rule: ['companyInformation.boardMember2UpToDate', [false]] },
  { rule: ['companyInformation.boardMember3UpToDate', [false]] },
  { rule: ['companyInformation.boardDeputyMemberUpToDate', [false]] },
  { rule: ['companyInformation.ceoUpToDate', [false]] },
  { rule: ['companyInformation.deputyCeoUpToDate', [false]] },
  { rule: ['companyInformation.administrativeRuleUpToDate', [false]] },
  { rule: ['companyInformation.municipalStrategyUpToDate', [false]] },
  { rule: ['companyInformation.groupInstructionUpToDate', [false]] },
  {
    rule: [
      'companyInformation.internalControlAndRiskManagementInstructionUpToDate',
      [false],
    ],
  },
  { rule: ['companyInformation.purchaseInstructionUpToDate', [false]] },
  { rule: ['companyInformation.riskManagementGuidelinesUpToDate', [false]] },
  { rule: ['isEquityNegativityRegistered', ['no']] },
  {
    rule: ['upToDateListsOfSharesAndShareholders', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  {
    rule: ['advanceCollectionRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  { rule: ['liableForVATOnBusiness', [false]] },
  {
    rule: ['liableForVATOnProperty', [false]],
    depends: [['hasPropertyRentalActivities', [true]]],
  },
  {
    rule: ['employerRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  {
    rule: ['isShareholderAgreementDone', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  { rule: ['taxDebtInformation', [TaxDebtInformation.taxDebtOverLimit]] },
  { rule: ['failureToNotify', [FailureToNotify.defects]] },
  {
    rule: [
      'legalSituation',
      Object.values(LegalSituation).filter(
        value => value !== LegalSituation.normal
      ),
    ],
  },
];

/**
 * Define remark rules for Customer Verificaion section.
 */
const customerVerification: AuditingSectionRule[] = [
  { rule: ['properIdentificationOfRepresentatives', [false]] },
]

/**
 * Define remark rules for EvaluationAssignment section.
 */
const evaluationAssignment: AuditingSectionRule[] = [
  { rule: ['properIdentificationOfRepresentatives', [false]] },
  { rule: ['isMattersThatAffectAssignment', [true]] },
]

/**
 * Define remark rules for Community Information section.
 */
const communityInformation: AuditingSectionRule[] = [
  {
    rule: ['auditorInformation.title', []],
    depends: [[auditorInformationAccessor, [true]]],
  },
  {
    // E.g. below field gets "remarked" IF its value is false (user answered "No")
    // AND its depending field (isFirstAuditing) has value true (user answered "Yes")
    rule: ['isCustomerApprovalAndCustomerIdentificationCompleted', [false]],
    depends: [['isFirstAuditing', [true]]],
  },
  /*
  {
    rule: ['isMattersThatAffectAssignment', [true]],
    depends: [['isFirstAuditing', [false]]],
  },
  */
  { rule: ['areTermsAgreedAndUpToDate', [false]] },
  { rule: ['properIdentificationOfRepresentatives', [false]] },
  { rule: ['companyInformation.capitalStockUpToDate', [false]] },
  { rule: ['companyInformation.numberOfStocksUpToDate', [false]] },
  { rule: ['companyInformation.boardChairmanUpToDate', [false]] },
  { rule: ['companyInformation.boardMember1UpToDate', [false]] },
  { rule: ['companyInformation.boardMember2UpToDate', [false]] },
  { rule: ['companyInformation.boardMember3UpToDate', [false]] },
  { rule: ['companyInformation.boardDeputyMemberUpToDate', [false]] },
  { rule: ['companyInformation.ceoUpToDate', [false]] },
  { rule: ['companyInformation.deputyCeoUpToDate', [false]] },
  { rule: ['companyInformation.administrativeRuleUpToDate', [false]] },
  { rule: ['companyInformation.municipalStrategyUpToDate', [false]] },
  { rule: ['companyInformation.groupInstructionUpToDate', [false]] },
  {
    rule: [
      'companyInformation.internalControlAndRiskManagementInstructionUpToDate',
      [false],
    ],
  },
  { rule: ['companyInformation.purchaseInstructionUpToDate', [false]] },
  { rule: ['companyInformation.riskManagementGuidelinesUpToDate', [false]] },
  { rule: ['isEquityNegativityRegistered', ['no']] },
  {
    rule: ['upToDateListsOfSharesAndShareholders', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  {
    rule: ['advanceCollectionRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  { rule: ['liableForVATOnBusiness', [false]] },
  {
    rule: ['liableForVATOnProperty', [false]],
    depends: [['hasPropertyRentalActivities', [true]]],
  },
  {
    rule: ['employerRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  {
    rule: ['isShareholderAgreementDone', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  { rule: ['taxDebtInformation', [TaxDebtInformation.taxDebtOverLimit]] },
  { rule: ['failureToNotify', [FailureToNotify.defects]] },
  {
    rule: [
      'legalSituation',
      Object.values(LegalSituation).filter(
        value => value !== LegalSituation.normal
      ),
    ],
  },
];


/**
 * Define remark rules for INCOME STATEMENT AND BALANCE section.
 */
const incomeStatementAndBalanceRules: AuditingSectionRule[] = [
  {
    rule: [assetsLiabilitiesDifferenceAccessor, [true]],
    accessorKey: 'assetsLiabilitiesDifference',
  },
];

/**
 * Define remark rules for GOVERNMENT TRANSFERS AND TAX REVENUES section.
 */
const governmentTransfersAndTaxRevenuesRules: AuditingSectionRule[] = [
  {
    rule: [
      'settlingForGovernmentSharesStudentInformation',
      [IsDecisionMade.no],
    ],
  },
  {
    rule: ['settlingForGovernmentSharesBasicServices', [IsDecisionMade.no]],
  },
];

/**
 * Define remark rules for G - MANAGEMENT section.
 */
const managementRules: AuditingSectionRule[] = [
  { rule: ['municipalStrategyUpToDate', [false]] },
  { rule: ['administrativeRuleUpToDate', [false]] },
  { rule: ['budgetUpToDate', [false]] },
  { rule: ['financialStatementUpToDate', [false]] },
  { rule: ['auditBoardEvaluationReportUpToDate', [false]] },
  { rule: ['auditReportUpToDate', [false]] },
  { rule: ['municipalCooperationAgreementsUpToDate', [false]] },
  { rule: ['groupInstructionUpToDate', [false]] },
  { rule: ['boardCommitmentsUpToDate', [false]] },
  { rule: ['boardRewardJustificationsUpToDate', [false]] },
  { rule: ['serviceFeesUpToDate', [false]] },
  { rule: ['programmeOfMeasuresUpToDate', [false]] },
  { rule: ['administrativeRuleMeetsRequirementsUpToDate', [false]] },
  { rule: ['budgetAndMunicipalStrategyInLineUpToDate', [false]] },
  { rule: ['isBudgetRealisticUpToDate', [false]] },
  { rule: ['budgetAndChangesProperlyApprovedUpToDate', [false]] },
  { rule: ['councilGuidelinesForInternalInstructionsUpToDate', [false]] },
  { rule: ['internalInstructionsMeetCouncilGuidelinesUpToDate', [false]] },
  { rule: ['internalInstructionsUpToDateUpToDate', [false]] },
  { rule: ['currentPracticesAreValidUpToDate', [false]] },
  { rule: ['planningOfBudgetAndInvestmentsUpToDate', [false]] },
  { rule: ['groupSupervisionAndReportingUpToDate', [false]] },
  { rule: ['informingAndInformationAccessUpToDate', [false]] },
  {
    rule: [
      'obligationToGetMunicipalitysOpinionBeforeDecisionsUpToDate',
      [false],
    ],
  },
  { rule: ['groupsInternalServicesUpToDate', [false]] },
  { rule: ['subsidiaryBoardCompositionAndAppointmentUpToDate', [false]] },
  { rule: ['subsidiaryGoodManagementAndLeadershipUpToDate', [false]] },
  { rule: ['previousYearFinancialStatementConfirmedUpToDate', [false]] },
  { rule: ['nextYearBudgetApprovedUpToDate', [false]] },
  { rule: ['handlingOfBudgetChangesUpToDate', [false]] },
  { rule: ['isBudgetReviewedEveryQuarterUpToDate', [false]] },
  { rule: ['affiliationNoticesUpToDate', [false]] },
  { rule: ['evaluationReportUpToDate', [false]] },
  {
    rule: [reviewOfPriorityBoardsNotesAccessor, [true]],
    accessorKey: 'reviewOfPriorityBoardsNotesRemarks',
  },
  {
    rule: [reviewOfPriorityOfficialsNotesAccessor, [true]],
    accessorKey: 'reviewOfPriorityOfficialsNotesRemarks',
  },
  {
    rule: [councilMeetingMinutesNotesAccessor, [true]],
    accessorKey: 'councilMeetingMinutesNotesRemarks',
  },
  {
    rule: [boardMeetingMinutesNotesAccessor, [true]],
    accessorKey: 'boardMeetingMinutesNotesRemarks',
  },
];

/*
 * Define remark rules for INTERNAL CONTROL AND OPERATING ENVIRONMENT section.
 */
const internalControlAndOperatingEnvironmentRules: AuditingSectionRule[] = [
  {
    rule: [
      'managementsReportMeetsOurUnderstanding',
      [ManagementsReportMeetsOurUnderstanding.no],
    ],
  },
  {
    rule: [
      'supervisionOfGroupEntitiesImplementedAppropriately',
      [SupervisionOfGroupEntitiesImplementedAppropriately.no],
    ],
  },
  {
    rule: [
      'riskManagementFollowsLawsAndInternalInstructions',
      [RiskManagementFollowsLawsAndInternalInstructions.no],
    ],
  },
  {
    rule: [
      'internalControlFollowsLawsAndInternalInstructions',
      [InternalControlFollowsLawsAndInternalInstructions.no],
    ],
  },
  {
    rule: [
      'managementsStatementOnInternalControlMeetsOurUnderstanding',
      [ManagementsStatementOnInternalControlMeetsOurUnderstanding.no],
    ],
  },
];

export const REMARK_RULES: RemarkRule[] = [
  //
  ...permanentDataRules.map(makeRemarkRule('permanentData')),
  ...communityInformation.map(makeRemarkRule('communityInformation')),
  ...customerVerification.map(makeRemarkRule('CustomerVerification')),
  ...evaluationAssignment.map(makeRemarkRule('EvaluationAssignment')),
  //
  ...incomeStatementAndBalanceRules.map(makeRemarkRule('incomeStatementAndBalance')),
  ...incomeStatementAndBalanceRules.map(makeRemarkRule('incomeStatementAndBalanceScoping')),
  //
  ...governmentTransfersAndTaxRevenuesRules.map(makeRemarkRule('governmentTransfersAndTaxRevenues')),
  ...managementRules.map(makeRemarkRule('management')),
  ...internalControlAndOperatingEnvironmentRules.map(makeRemarkRule('internalControlAndOperatingEnvironment')),
  // Batch rules, alphabetical order!
  ...batchF3PresentationAndContentRules.map(makeRemarkRule('presentationAndContent')),
  ...batchJ1TurnOverRules.map(makeRemarkRule('turnOver')),
  ...batchJ2AccountsReceivableRules.map(makeRemarkRule('accountsReceivable')),
  ...batchJBOtherOperatingIncomeRules.map(makeRemarkRule('otherOperatingIncome')),
  ...batchK1MaterialsAndServicesRules.map(makeRemarkRule('materialsAndServices')),
  ...batchK2TradeCreditorsRules.map(makeRemarkRule('tradeCreditors')),
  ...batchKBOtherOperatingExpensesRules.map(makeRemarkRule('otherOperatingExpenses')),
  ...batchNTaxesRules.map(makeRemarkRule('taxes')),
  ...batchO1FixedAssetsRules.map(makeRemarkRule('fixedAssets')),
  ...batchO3investmentsRules.map(makeRemarkRule('investments')),
  ...batchP1WarehousesRules.map(makeRemarkRule('warehouses')),
  ...batchO4FundingRules.map(makeRemarkRule('funding')),
  ...batchQ1OtherReceivablesRules.map(makeRemarkRule('otherReceivables')),
  ...batchQ2CashEquivalentsRules.map(makeRemarkRule('cashEquivalents')),
  ...batchQ3LoanReceivablesRules.map(makeRemarkRule('loanReceivables')),
  // R1 Doesnt have necessary fields for remarking
  ...batchR2CapitalLoansRules.map(makeRemarkRule('capitalLoans')),
  ...batchS1FinancialInstitutionsRules.map(makeRemarkRule('financialInstitutions')),
  ...batchT1OtherLiabilitiesRules.map(makeRemarkRule('otherLiabilities')),
  ...batchT2AdvancePaymentsRules.map(makeRemarkRule('advancePayments')),
  ...batchXJRules.map(makeRemarkRule('financialIncome')),
  ...batchXKRules.map(makeRemarkRule('financialExpenses')),
  ...batchVReservesRules.map(makeRemarkRule('reserves')),
];
