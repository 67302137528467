import React from 'react';
import { useTranslation } from 'react-i18next';
import { getClassNameForFinancialStatementRow } from '../../utils';
import { AccountClassKey } from '../../views/auditingSections/planning/accountMapKeysScoping';
import AuditingTable from '../table/AuditingTable';
import { TableColumnDefs } from '../table/Table';
import { OnEditProps } from '../table/TableInputs';

type TableItem = AccountMapRowScoping & { className: string };

interface Props extends FormFieldProps<AccountMapScopingForm> {
  targetKey: keyof AccountMapScoping;
}

const sectionKey: AuditingSectionKey = 'accountMapScoping';

const AccountMapTable: React.FC<Props> = ({
  targetKey,
  formState,
  patchFormState,
}) => {
  const { t } = useTranslation();

  if (!formState?.accountMapScoping) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const data = formState.accountMapScoping[targetKey] ?? [];

  const formattedData = data.map(item => ({
    ...item,
    id: item.key,
    className: getClassNameForFinancialStatementRow(item.heading),
  }));

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = (formState.accountMapScoping[targetKey] ?? []).map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.key;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });

    patchFormState({
      ...formState,
      accountMapScoping: { ...formState.accountMapScoping, [targetKey]: patch },
    });
  }

  let tableColumns: TableColumnDefs<TableItem> = [
    {
      id: 'key',
      cell: ({ row: { original: row } }) => (
        <div className={row.className}>
          {row.key ? t(`auditing:accountKey.${row.key}`) : '-'}
        </div>
      ),
      className: 'text-left width-max',
      header: () => t(`${tBase}.key`),
    },
    {
      accessorKey: 'start',
      className: 'text-center width-min',
      header: () => t(`${tBase}.start`),
      disabled: (item: TableItem) => !('start' in item),
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
    },
    {
      accessorKey: 'end',
      className: 'text-center width-min',
      header: () => t(`${tBase}.end`),
      disabled: (item: TableItem) => !('end' in item),
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
    },
    {
      accessorKey: 'classKey',
      className: 'text-center width-min',
      header: () => t(`${tBase}.classKey`),
      hidden: (item: TableItem) => !('classKey' in item),
      selectProps: {
        options: Object.values(AccountClassKey),
        displayValue: (option: string) => t(`auditing:accountClass.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },
  ];

  return (
    <AuditingTable<TableItem>
      sectionKey={sectionKey}
      data={formattedData}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export default AccountMapTable;
