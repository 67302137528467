import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'communityInformation';

export const CompanyInformationTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.companyInformationTable ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.companyInformationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, companyInformationTable: patch });
  };

  const tableColumns: TableColumnDefs<CompanyInformationTable> = [
    {
      accessorKey: 'label',
      header: () => '',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => 'Asiakas',
      className: 'width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<CompanyInformationTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};

export const MembersInformationTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.membersInformationTable ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.membersInformationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, membersInformationTable: patch });
  };

  const tableColumns: TableColumnDefs<MembersInformationTable> = [
    {
      accessorKey: 'label',
      header: () => 'Hallitus ja edustajat',
      className: 'width-min text-bold nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      placeholder: 'Nimi',
      className: 'width-middle text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      membersInformationTable: [
        ...(formState.membersInformationTable ?? []),
        {
            label: "Lisäjäsen",
            id: uuid(),
            inputValue: "",
        }
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: MembersInformationTable) => {
    if (id === '1' || id === '3') return; // Prevent deleting the first row
    if (confirmDelete(label ?? '')) {
      const patch = (formState.membersInformationTable ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, membersInformationTable: patch });
    }
  };

  return (
    <>
      <AuditingTable<MembersInformationTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        onAddNewRow={handleAddNewRow}
        onRowDelete={handleDeleteRow}
      />
    </>
  )
};

export const BeneficialInformationTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  const { t } = useTranslation();
  
  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.beneficialInformationTable`;

  // Data for table
  const data = formState.beneficialInformationTable ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
      indexing: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.beneficialInformationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, beneficialInformationTable: patch });
  };

  const tableColumns: TableColumnDefs<BeneficialInformationTable> = [
    {
      accessorKey: 'label',
      header: () => 'Tosiasialliset edunsaajat',
      className: 'width-min text-bold',
    },
    {
      accessorKey: 'inputValue',
      header: () => 'Tiedot',
      className: 'width-min text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'indexing',
      header: () => 'Indeksointi',
      contextInfoProps: {
        position: 'top-right',
        text: t(`${tBase}.indexingContext`),
      },
      placeholder: 'XY - Hyperlinkki',
      className: 'width-min text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<BeneficialInformationTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};
export const BeneficialInformationTable1 = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  const { t } = useTranslation();
  
  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.communityInformation`;

  // Data for table
  const data = formState.beneficialInformationTable1 ?? [
    {
      id: '1',
      label: '',
      booleanValue: false,
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.beneficialInformationTable1.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, beneficialInformationTable1: patch });
  };

  const tableColumns: TableColumnDefs<BeneficialInformationTable1> = [
    {
      accessorKey: 'label',
      header: () => 'Tiedot',
      className: 'width-middle text-bold nowrap',
      contextInfoProps: {
        position: 'top-right',
        text: t(`${tBase}.BenelineContextTrue`),
      },
    },
    {
      accessorKey: 'booleanValue',
      header: () => 'Valitse',
      className: 'width-min text-bold',
      showRemark: (item: BeneficialInformationTable1) =>
        typeof item.booleanValue === 'boolean' && !item.booleanValue,
      selectProps: {
        options: ['N/A', 'false', 'true'],
        placeholder: 'N/A',
        clearable: true,
        displayValue: (option: string) => (option === 'true' ? 'Kyllä' : option === 'false' ? 'Ei' : 'N/A'),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        }
      }
    },
    {
      accessorKey: 'inputValue',
      header: () => 'Tiedot',
      className: 'width-max text-bold nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<BeneficialInformationTable1>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};
export const BeneficialInformationTable2 = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  const { t } = useTranslation();
  
  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.communityInformation`;

  // Data for table
  const data = formState.beneficialInformationTable2 ?? [
    {
      id: '1',
      label: '',
      booleanValue: false,
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.beneficialInformationTable2.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, beneficialInformationTable2: patch });
  };

  const tableColumns: TableColumnDefs<BeneficialInformationTable2> = [
    {
      accessorKey: 'label',
      header: () => 'Tiedot',
      className: 'width-middle text-bold nowrap',
      contextInfoProps: {
        position: 'top-right',
        text: t(`${tBase}.BenelineContextFalse`),
      },
    },
    {
      accessorKey: 'booleanValue',
      header: () => 'Valitse',
      className: 'width-min text-bold',
      showRemark: (item: BeneficialInformationTable2) =>
        typeof item.booleanValue === 'boolean' && !item.booleanValue,
      selectProps: {
        options: ['false', 'true'],
        placeholder: 'Valitse',
        clearable: true,
        displayValue: (option: string) => (option === 'true' ? 'Kyllä' : 'Ei'),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        }
      }
    },
    {
      accessorKey: 'inputValue',
      header: () => 'Tiedot',
      className: 'width-max text-bold nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<BeneficialInformationTable2>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};

export const OtherInformationTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.otherInformationTable ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.otherInformationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, otherInformationTable: patch });
  };

  const tableColumns: TableColumnDefs<OtherInformationTable> = [
    {
      accessorKey: 'label',
      header: () => 'Muut Tiedot',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-max text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<OtherInformationTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};
export const OtherInformationTable1 = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.otherInformationTable1 ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.otherInformationTable1.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, otherInformationTable1: patch });
  };

  const tableColumns: TableColumnDefs<OtherInformationTable1> = [
    {
      accessorKey: 'label',
      header: () => 'Tiedot',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-max text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<OtherInformationTable1>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};
export const OtherInformationTable2 = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.otherInformationTable2 ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.otherInformationTable2.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, otherInformationTable2: patch });
  };

  const tableColumns: TableColumnDefs<OtherInformationTable2> = [
    {
      accessorKey: 'label',
      header: () => 'Tiedot',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-max text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<OtherInformationTable2>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};
export const OtherInformationTable3 = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.otherInformationTable3 ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.otherInformationTable3.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, otherInformationTable3: patch });
  };

  const tableColumns: TableColumnDefs<OtherInformationTable3> = [
    {
      accessorKey: 'label',
      header: () => 'Tiedot',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-max text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<OtherInformationTable3>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};

export const CustomerActivitiesTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.customerActivitiesTable ?? [
    {
      id: '1',
      label: '',
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.customerActivitiesTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, customerActivitiesTable: patch });
  };

  const tableColumns: TableColumnDefs<CustomerActivitiesTable> = [
    {
      accessorKey: 'label',
      header: () => 'Toimintaan koskevat tiedot',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-max text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<CustomerActivitiesTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};

export const CustomerSeprateTable = ({ formState, patchFormState }: FormFieldProps<communityInformationForm>) => {
  if (!formState) return null;

  // Data for table
  const data = formState.customerSeprateTable ?? [
    {
      id: '1',
      label: '',
      booleanValue: null,
      inputValue: '',
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.customerSeprateTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, customerSeprateTable: patch });
  };

  const tableColumns: TableColumnDefs<CustomerSeprateTable> = [
    {
      accessorKey: 'label',
      header: () => 'Toteuta jokin seuraavista toimenpiteistä',
      className: 'width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'booleanValue',
      header: () => 'Valitse',
      className: 'width-min text-bold',
      showRemark: (item: CustomerSeprateTable) =>
        typeof item.booleanValue === 'boolean' && !item.booleanValue,
      selectProps: {
        options: ['false', 'true'],
        placeholder: 'Valitse',
        clearable: true,
        displayValue: (option: string) => (option === 'true' ? 'Kyllä' : 'Ei'),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        }
      }
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <AuditingTable<CustomerSeprateTable>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};