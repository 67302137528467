import { PlanningItems } from '../../../components/analyzes/DetailedPlan.BatchActionsTable';
import {
  batchName,
  defaultAuditingProcedures,
  FinancialStatementBatch,
  incomeStatementAndBalanceItemHasNoRisks,
  isDefaultBatch,
  transformRemarkToAuditingProcedure
} from '../auditing/auditingProceduresUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for auditingProcedures section!
*/

// NOTE: This section is using other section's form
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'detailedPlan';
const formSectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ store }) => ({
  auditingProcedures: [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(),
  ],
});

const formFields: FormFields<SectionFormType> = ({ auditing, formState }) => {
  const hasNoRisks = incomeStatementAndBalanceItemHasNoRisks(auditing);

  // Helper to build financial statement batches' accordion groups
  const batchAccordion = (
    batch: FinancialStatementBatch,
    props: { open?: boolean; hidden?: boolean } = {}
  ) =>
    formBuilder.accordionGroup({
      open: props?.open,
      title: batchName(batch),
      hidden: props?.hidden ?? (!isDefaultBatch(batch) && hasNoRisks(batch)),
      items: [
        formBuilder.custom(props => (
          <PlanningItems batchKey={batch} {...props} />
        )),
        /*
        // Excel download button
        formBuilder.custom(props => {
          const procedures: AuditingProcedure[] = formState.auditingProcedures.filter(
            p => p.batch === batch
          );
          const isExcelProcedures = procedures.filter(procedure => hasInExcel(procedure));
          const actionKeys = isExcelProcedures.map(procedure => procedure.actionKey);
          return (
            <>
              {isExcelProcedures.length > 0 && (
                <ExcelComponent
                  batchKey={batch}
                  inExcel={true}
                  //batchValue={batchName(batch)}
                  batchValue={batch}
                  actionKeys={actionKeys}
                  {...props}
                />
              )}
            </>
          );
        }),
        */
      ],
    });

  return [
    batchAccordion(FinancialStatementBatch['B-commonActions']),
    batchAccordion(FinancialStatementBatch['F-financialStatement']),
    batchAccordion(FinancialStatementBatch['F3-presentationAndContent']),
    batchAccordion(FinancialStatementBatch['G-management']),
    batchAccordion(FinancialStatementBatch['J-operatingIncome']),
    batchAccordion(FinancialStatementBatch['JB-otherOperatingIncome']),
    batchAccordion(FinancialStatementBatch['J1-turnover']),
    batchAccordion(FinancialStatementBatch['J2-accountsReceivable']),
    batchAccordion(FinancialStatementBatch['K-materialsAndServices']),
    batchAccordion(FinancialStatementBatch['KB-otherOperatingExpenses']),
    batchAccordion(FinancialStatementBatch['K1-materialsAndServices']),
    batchAccordion(FinancialStatementBatch['K2-tradeCreditors']),
    batchAccordion(FinancialStatementBatch['L-personelCosts']),
    batchAccordion(
      FinancialStatementBatch['M-governmentTransfersAndTaxRevenues']
    ),
    batchAccordion(FinancialStatementBatch['N-taxes']),
    batchAccordion(FinancialStatementBatch['O1-fixedAssets']),
    batchAccordion(FinancialStatementBatch['O3-investments']),
    batchAccordion(FinancialStatementBatch['O4-funding']),
    batchAccordion(FinancialStatementBatch['P-inventories']),
    batchAccordion(FinancialStatementBatch['P1-warehouses']),
    batchAccordion(FinancialStatementBatch['Q1-otherReceivables']),
    batchAccordion(FinancialStatementBatch['Q2-cashEquivalents']),
    batchAccordion(FinancialStatementBatch['Q3-loanReceivables']),
    batchAccordion(FinancialStatementBatch['R1-ownCapital']),
    batchAccordion(FinancialStatementBatch['R2-capitalLoans']),
    batchAccordion(FinancialStatementBatch['S1-financialInstitutions']),
    batchAccordion(FinancialStatementBatch['T-otherCreditors']),
    batchAccordion(FinancialStatementBatch['T1-otherLiabilities']),
    batchAccordion(FinancialStatementBatch['T2-advancePayments']),
    batchAccordion(FinancialStatementBatch['U-assignmentAssetsAndLiabilities']),
    batchAccordion(FinancialStatementBatch['V-reserves']),
    batchAccordion(FinancialStatementBatch['XJ-financialIncome']),
    batchAccordion(FinancialStatementBatch['XK-financialExpenses']),
  ];
};

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  formSectionKey,
  defaultFormState,
  formFields,
  depending: ['incomeStatementAndBalanceScoping'],
};

export default section;
