import { useCallback, useState } from 'react';
import { localizedDate } from '../date';
import { useDelayedAction } from './useDelayedAction';

export const useForm = <T>(
  initialState: T,
  onSubmit: (formState: T) => Promise<boolean | void>
) => {
  const [formState, setFormState] = useState(initialState);
  const [formSaveState, setFormSaveState] = useState<FormSaveState>('IDLE');
  const [unsavedChanges, setUnsavedChanges] = useState(0);

  const replaceFormState = (newState: T) => setFormState(newState);

  const patchFormState = (patch: Partial<T>) => {
    setFormState({ ...formState, ...patch });
    setUnsavedChanges(count => count + 1);
  };

  const initializeForm = (initialState: T) => {
    replaceFormState(initialState);
    setFormSaveState('IDLE');
    setUnsavedChanges(0);
  };

  // Submit the form after changes were made.
  // Action is run after a delay, since don't want to submit
  // after every letter when user is typing.
  useDelayedAction(
    useCallback(async () => {
      if (unsavedChanges && formSaveState !== 'SAVING') {
        setFormSaveState('SAVING');
        setUnsavedChanges(0);
        const success = await onSubmit(formState);
        setFormSaveState(success === false ? 'ERROR' : 'SAVED');
      }
    }, [formSaveState, formState, onSubmit, unsavedChanges])
  );

  const castFormPatch = (formPatch: unknown) => formPatch as Partial<T>;

  const handleSetFormState = (formPatch: unknown) => {
    patchFormState(castFormPatch(formPatch));
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const fieldName = event.currentTarget.name as keyof T;
    const formPatch = { [fieldName]: event.currentTarget.value };
    handleSetFormState(formPatch);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.currentTarget.name as keyof T;
    const date = localizedDate(event.currentTarget.value).toISO();
    const formPatch = { [fieldName]: date };
    handleSetFormState(formPatch);
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const fieldName = event.currentTarget.name as keyof T;
    const formPatch = { [fieldName]: Number(event.currentTarget.value) };
    handleSetFormState(formPatch);
  };

  const handleBooleanChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parentGroupKey?: keyof T
  ) => {
    const fieldName = event.currentTarget.name as keyof T;
    let fieldPatch = { [fieldName]: event.currentTarget.checked };
    let formPatch = {};
    if (parentGroupKey) {
      const group = formState[parentGroupKey];
      formPatch = { [parentGroupKey]: { ...group, ...fieldPatch } };
    } else {
      formPatch = fieldPatch;
    }
    handleSetFormState(formPatch);
  };

  const handleBooleanOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fieldName = event.currentTarget.name as keyof T;
    const formPatch = {
      [fieldName]: event.currentTarget.value === 'true' ? true : false,
    };
    handleSetFormState(formPatch);
  };

  const handleSelectChange =
    <SelectType>(fieldName: keyof T) =>
    (value: React.SetStateAction<SelectType | undefined>) => {
      const formPatch = { [fieldName]: value };
      handleSetFormState(formPatch);
    };

  return {
    formState,
    formSaveState,
    unsavedChanges,
    initializeForm,
    setFormState,
    replaceFormState,
    patchFormState,
    formChangeHandlers: {
      handleTextChange,
      handleDateChange,
      handleNumberChange,
      handleBooleanChange,
      handleBooleanOptionChange,
      handleSelectChange,
    },
  };
};
