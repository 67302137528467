import { formatDateTime, today } from './date';

// Normal import didn't work since type definitions are not found or something... :(
const html2pdf = require('html2pdf.js');

type PDF = any; // TODO

interface HeaderAndFooter {
  left?: string;
  center?: string;
  right?: string;
}
type Header = HeaderAndFooter;
type Footer = HeaderAndFooter;

interface SavePdfProps {
  margin?: number;
  filename?: string;
  header?: Header;
  footer?: Footer;
}

const setHeaderAndFooter = (
  pdf: PDF,
  timestamp: ISODate,
  header?: Header,
  footer?: Footer
) => {
  const totalPages = pdf.internal.getNumberOfPages();

  const width = pdf.internal.pageSize.getWidth();
  const height = pdf.internal.pageSize.getHeight();

  const [left, right, top, bottom] = [10, width - 10, 9, height - 9];
  const center = width / 2;

  const topLeft = (text = '') => [left, top, text, 'left'];
  const topCenter = (text = '') => [center, top, text, 'center'];
  const topRight = (text = '') => [right, top, text, 'right'];
  const bottomLeft = (text = '') => [left, bottom, text, 'left'];
  const bottomCenter = (text = '') => [center, bottom, text, 'center'];
  const bottomRight = (text = '') => [right, bottom, text, 'right'];

  for (let i = 1; i <= totalPages; i++) {
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.setTextColor(150);

    // Add header texts to each page
    pdf.text(...topLeft(header?.left));
    pdf.text(...topCenter(header?.center));
    pdf.text(...topRight(header?.right));

    // Add footer texts to each page
    pdf.text(...bottomLeft(footer?.left ?? formatDateTime(timestamp)));
    pdf.text(...bottomCenter(footer?.center));
    pdf.text(...bottomRight(footer?.right ?? `Sivu ${i}/${totalPages}`));
  }
};

export const savePdf = (elementId: string, props: SavePdfProps = {}) => {
  const { margin, filename, header, footer } = props;

  const element = document.getElementById(elementId);

  const timestamp = today().toISO();

  const opt = {
    margin: margin ?? 10,
    filename: `${filename ?? timestamp}.pdf`,
    html2canvas: { scale: 2 },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  };

  html2pdf()
    .set(opt)
    .from(element)
    .toPdf()
    .get('pdf')
    .then((pdf: any) => {
      setHeaderAndFooter(pdf, timestamp, header, footer);
    })
    .save();
};
