import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import { usingActualNumbersForMateriality } from '../../views/auditingSections/planning/materialityUtils';
import { usingActualNumbersForMaterialitySecond } from '../../views/auditingSections/planning/forMateriality/materialityUtilsSecond';
import { usingActualNumbersForMaterialityThird } from '../../views/auditingSections/planning/forMateriality/materialityUtilsThird';
import { Button } from '../inputs';

const UpdateMaterialityBaseNumberButton = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const { getFinancialNumberScoping, auditingLocked, isAuditingSectionFinished } =
    store.auditingStore;

  if (auditingLocked || isAuditingSectionFinished('materiality')) return null;

  const { materialityBasis, materialityBasedIn, materialityBaseNumber } =
    formState;

  const financialNumber =
    materialityBasis !== 'combinedRevenues' &&
    materialityBasis !== 'averageOfLiabilitiesAndRevenues' &&
    getFinancialNumberScoping(materialityBasis);

  const isChanged =
    financialNumber && financialNumber !== materialityBaseNumber;

  if (!usingActualNumbersForMateriality(materialityBasedIn) || !isChanged)
    return null;

  const tKey = 'auditing:form.materiality.updateMaterialityBaseNumber';

  return (
    <Button
      text={t(tKey, { value: formatCurrency(financialNumber) })}
      onClick={() => patchFormState({ materialityBaseNumber: financialNumber })}
      fullWidth
      variant="link"
    />
  );
};
export default UpdateMaterialityBaseNumberButton;

export const UpdateMaterialitySecondBaseNumberButton = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const { getFinancialNumberScoping, auditingLocked, isAuditingSectionFinished } =
    store.auditingStore;

  if (auditingLocked || isAuditingSectionFinished('materiality')) return null;

  const { materialitySecondBasis, materialitySecondBasedIn, materialitySecondBaseNumber } =
    formState;

  const financialNumber =
    materialitySecondBasis !== 'combinedRevenues' &&
    materialitySecondBasis !== 'averageOfLiabilitiesAndRevenues' &&
    getFinancialNumberScoping(materialitySecondBasis);

  const isChanged =
    financialNumber && financialNumber !== materialitySecondBaseNumber;

  if (!usingActualNumbersForMaterialitySecond(materialitySecondBasedIn) || !isChanged)
    return null;

  const tKey = 'auditing:form.materiality.updateMaterialityBaseNumber';

  return (
    <Button
      text={t(tKey, { value: formatCurrency(financialNumber) })}
      onClick={() => patchFormState({ materialitySecondBaseNumber: financialNumber })}
      fullWidth
      variant="link"
    />
  );
};

export const UpdateMaterialityThirdBaseNumberButton = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const { getFinancialNumberScoping, auditingLocked, isAuditingSectionFinished } =
    store.auditingStore;

  if (auditingLocked || isAuditingSectionFinished('materiality')) return null;

  const { materialityThirdBasis, materialityThirdBasedIn, materialityThirdBaseNumber } =
    formState;

  const financialNumber =
    materialityThirdBasis !== 'combinedRevenues' &&
    materialityThirdBasis !== 'averageOfLiabilitiesAndRevenues' &&
    getFinancialNumberScoping(materialityThirdBasis);

  const isChanged =
    financialNumber && financialNumber !== materialityThirdBaseNumber;

  if (!usingActualNumbersForMaterialityThird(materialityThirdBasedIn) || !isChanged)
    return null;

  const tKey = 'auditing:form.materiality.updateMaterialityBaseNumber';

  return (
    <Button
      text={t(tKey, { value: formatCurrency(financialNumber) })}
      onClick={() => patchFormState({ materialityThirdBaseNumber: financialNumber })}
      fullWidth
      variant="link"
    />
  );
};