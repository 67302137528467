import React, { useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';
import { formatCurrency } from '../../../../utils';


interface Props {
  classKey: AccountClassKey;
  auditing?: Auditing;
  addCommentField?: boolean;
  formState: any;
  patchFormState: any;
  showChange?: boolean;
  customAccountTitle?: string;
}

const ProcedureAccountsManual: React.FC<Props> = ({ addCommentField, formState, patchFormState, showChange, customAccountTitle }) => {
    /*
      This is like ProcedureAccounts, but you type the data manually.
      This component requires you to set procedureAccountsManual variable in defaultformstate, eg:
        procedureAccountsManualData: [{
          currentYear: 10000,
          priorYear: 10000,
          accountName: "esimerkki",
          comment: ""
        }],
    */
  const [loading, setLoading] = useState(false)
  var procedureAccountRows: any[] = [];
  
  for(var i=0; i<formState.procedureAccountsManualData!.length; i++){
    const object = formState.procedureAccountsManualData![i];
    if(showChange){
      const undefinedHandler = (item:number|undefined) => {
        if(item === undefined){
          return 0
        }else return item
      }
      object.changeInNumbers = undefinedHandler(object.currentYear) - undefinedHandler(object.priorYear);
      object.changeInPercent = Math.round(((undefinedHandler(object.currentYear)-undefinedHandler(object.priorYear))/undefinedHandler(object.priorYear))*100)
    }
    procedureAccountRows.push(object)
  }

  function handleEdit(props: any){
    var oldFormState = [...formState.procedureAccountsManualData]
    oldFormState[props.rowIndex][props.columnId] = props.value

    patchFormState({
      ...formState,
      procedureAccountsManualData: oldFormState
    })
  }

  function handleAddNewRow(){
    patchFormState({
      ...formState,
      procedureAccountsManualData: [...formState.procedureAccountsManualData, {
        currentYear: 0,
        priorYear: 0,
        accountName: "Uusi",
        comment: ""
      }]
    })
  }

  function handleDeleteRow(props: any){
    setLoading(true)

    var newFormState = formState.procedureAccountsManualData.filter((item: any) => item.accountName !== props.accountName)
    patchFormState({
      ...formState,
      procedureAccountsManualData: newFormState
    })
    setTimeout(() => setLoading(false), 1)
  }



  if (!procedureAccountRows?.length) return null;


  var tableColumns: TableColumnDefs<GroupedGeneralLedgerItem> = [
    {
      accessorKey: 'accountName',
      header: () => customAccountTitle ? customAccountTitle : "Selitys",
      onEdit: handleEdit
    },
    {
      accessorKey: 'currentYear',
      header: () => 'CY',
      className: 'width-min text-center nowrap',
      onNumberEdit: handleEdit
    },
    {
      accessorKey: 'priorYear',
      header: () => 'PY',
      className: 'width-min text-center nowrap',
      onNumberEdit: handleEdit
    },

  ];
  if(showChange){
    tableColumns.push({
      accessorKey: 'changeInNumbers',
      header: () => 'Muutos',
      className: 'width-middle text-center nowrap',
      accessorFn: (row: any) => formatCurrency(row.changeInNumbers),

    })
    tableColumns.push({
      accessorKey: 'changeInPercent',
      header: () => 'Muutos%',
      accessorFn: (row: any) => row.changeInPercent + " %",
      className: 'width-middle text-center nowrap',
    })
  }

  if(addCommentField){
    tableColumns.push({
      accessorKey: 'commentText',
      header: () => 'Muuta',
      className: 'width-middle text-center nowrap',
      onEdit: handleEdit
    })
  }

  return !loading ? (
    <AuditingTable
      data={procedureAccountRows}
      columns={tableColumns}
      showGlobalFilter={false}
      onAddNewRow={handleAddNewRow}
      onRowDelete={handleDeleteRow}
      animationDisabled={true}
      variant='default'
    />
  ) : null;
};

export default ProcedureAccountsManual;