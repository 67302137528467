export interface AddedPart {
  type: 'textArea' | 'textInput' | 'boolean' | 'select' | 'checkboxGroup' | 'custom'
  accessor: string;
  defaultValue?: any;
  options?: any;
  plainLabel?: string;
  customElement?: any;
  hidden?: boolean;
  hiddenByBooleanAccessor?: string;
}

interface FormElement {
hidden?: boolean;
accessor: string;
plainLabel?: string;
options?: Array<string>;
optionDisplayValue?: (option: string) => string | undefined;
}

export function getPartNameBasedOnActionKey(actionKey:string, auditing:any){
  // Gets the name of an added action based on the provided actionKey
  const parts = auditing.auditingProcedures.form.auditingProcedures;
  const part = parts.find((item: { actionKey: string | string[] }) => item.actionKey === actionKey);
  if(part){
    return part.action
  }else return "Nimeä ei löydetty."
}

export function getAddedPartsDefaultFormState(addedPartConstructList: any, auditing: any, batchString: string) {
  // This function gets the default form state for the added parts. This is used to initialize the form state

  if (auditing?.auditingProcedures?.form?.auditingProcedures) {
    // Filter auditing procedures based on the batchString, to filter out parts that are not a part of this batch
    const auditingProcedures = auditing.auditingProcedures.form.auditingProcedures.filter(
      (item: { batch: string }) => item.batch === batchString
    );
    const defaultFormState: Record<string, any> = {};



    const additionalParts = auditingProcedures.filter(
      // Normal added part(actionKey(identifier)) always start with "action-"" at the beginning of the actionKey.
      // here we filter out the ones that are not added parts.
      (item: { actionKey: string | string[] }) => item.actionKey.includes('action-')
    );

    for (let index = 0; index < additionalParts.length; index++) {
      // Here we check what type of added part we are dealing with, and based on that we give it an initial value.
      for (let i = 0; i < addedPartConstructList[0].length; i++) {
        const part = addedPartConstructList[0][i];
        let partValueInDefaultFormState;

        if (part.hasOwnProperty('defaultValue')) {
          partValueInDefaultFormState = part.defaultValue;
        } else {
          if (part.type === 'textArea' || part.type === 'textInput') {
            partValueInDefaultFormState = ''; 
          } else if (part.type === 'boolean' || part.type === 'select') {
            partValueInDefaultFormState = null;
          } else {
            partValueInDefaultFormState = null; 
          }
        }

        const accessor = part.accessor + index.toString();
        if(part.type !== 'custom'){
          defaultFormState[accessor] = partValueInDefaultFormState
        }
      }
    }


    const controlParts = auditingProcedures.filter(
      // Control parts always start with "control-". If we have enabled adding controls,
      // here we filter out the ones that are not added controls.

      (item: { actionKey: string | string[] }) => item.actionKey.includes('control-')
    );

    for (let index = 0; index < controlParts.length; index++) {
      for (let i = 0; i < addedPartConstructList[1].length; i++) {
        const part = addedPartConstructList[1][i];
        let partValueInDefaultFormState;

        if (part.hasOwnProperty('defaultValue')) {
          partValueInDefaultFormState = part.defaultValue;
        } else {
          if (part.type === 'textArea' || part.type === 'textInput') {
            partValueInDefaultFormState = ''; // need to add jsonVocab functionality here
          } else if (part.type === 'boolean' || part.type === 'select' ||part.type === 'checkboxGroup') {
            partValueInDefaultFormState = null;
          } else {
            partValueInDefaultFormState = null; // Need to add all other types too.
          }
        }

        const accessor = part.accessor + index.toString();
        defaultFormState[accessor] = partValueInDefaultFormState;
      }
    }
    return defaultFormState;
  }

return [];
}

export function getAddedPartsFormFields(addedPartConstructList: any, auditing: any,formState:any, batchString: string, formBuilder: any): any | null{
  // This function gets the form field objects for the added parts. It is used in the formBuilder.
  if (auditing?.auditingProcedures?.form?.auditingProcedures) {
    // Filter auditing procedures based on the batchString, to filter out parts that are not a part of this batch
    const auditingProcedures = auditing.auditingProcedures.form.auditingProcedures.filter(
      (item: { batch: string }) => item.batch === batchString
    );


    const formFields = [];

    const additionalParts = auditingProcedures.filter(
      (item: { actionKey: string | string[] }) => item.actionKey.includes('action-')// This allows us to use this for controls too
    );
    
    for(var index=0; index<additionalParts.length; index++){ 
      if(additionalParts[index].tp === true || additionalParts[index].period === true){
        const accordionGroupItems = [];
        for(var i=0; i<addedPartConstructList[0].length; i++) {
          const part = addedPartConstructList[0][i];
          const uniqueAccessor = part.accessor + index.toString()
          const inputForFormElement: FormElement = {
            accessor: uniqueAccessor,
          }
          if(part.hasOwnProperty('plainLabel')){
            inputForFormElement.plainLabel = part.plainLabel;
          }
          if(part.hasOwnProperty('options')){
            inputForFormElement.options = Object.keys(part.options);
            inputForFormElement.optionDisplayValue = (key: string) => part.options![key as keyof typeof part.options];
          }
  
  
          if(part.type === "textArea") {
            accordionGroupItems.push(formBuilder.textArea(inputForFormElement))
          } else if(part.type === "textInput") {
            accordionGroupItems.push(formBuilder.textInput(inputForFormElement))
          } else if (part.type === 'boolean') {
            accordionGroupItems.push(formBuilder.boolean(inputForFormElement))
          } else if (part.type === 'select') {
            accordionGroupItems.push(formBuilder.select(inputForFormElement))
          }
        }
        formFields.push(
          formBuilder.accordionGroup({title: additionalParts[index].action, items: accordionGroupItems, hidden: false})// need to add hiding functionality here
        )
      }
    }


    const controlParts = auditingProcedures.filter(
      (item: { actionKey: string | string[] }) => item.actionKey.includes('control-')// This allows us to use this for controls too
    );
    
    for(var k=0; k<controlParts.length; k++){ 
      if(controlParts[k].tp === true || controlParts[k].period === true){
        const accordionGroupItems = [];
        for(var j=0; j<addedPartConstructList[1].length; j++) {
          const part = addedPartConstructList[1][j];
          const uniqueAccessor = part.accessor + k.toString()
          const inputForFormElement: FormElement = {
            accessor: uniqueAccessor,
          }
          if(part.hasOwnProperty('plainLabel')){
            inputForFormElement.plainLabel = part.plainLabel;
          }
          if(part.hasOwnProperty('options')){
            inputForFormElement.options = Object.keys(part.options);
            inputForFormElement.optionDisplayValue = (key: string) => part.options![key as keyof typeof part.options];
          }
          if(part.hasOwnProperty('hidden')){
            inputForFormElement.hidden = part.hidden;
          }
          if(part.hasOwnProperty('hiddenByBooleanAccessor')){
            inputForFormElement.hidden = formState[part.hiddenByBooleanAccessor + k.toString()] === true
          }


          if(part.type === "textArea") {
            accordionGroupItems.push(formBuilder.textArea(inputForFormElement))
          } else if(part.type === "textInput") {
            accordionGroupItems.push(formBuilder.textInput(inputForFormElement))
          } else if (part.type === 'boolean') {
            accordionGroupItems.push(formBuilder.boolean(inputForFormElement))
          } else if (part.type === 'select') {
            accordionGroupItems.push(formBuilder.select(inputForFormElement))
          } else if (part.type === 'checkboxGroup') {
            accordionGroupItems.push(formBuilder.checkboxGroup(inputForFormElement))
          }else if (part.type === 'custom') {
            accordionGroupItems.push(formBuilder.custom(!inputForFormElement.hidden ? null : part.customElement))
          }
        }
        formFields.push(
          formBuilder.accordionGroup({title: controlParts[k].action, items: accordionGroupItems, hidden: false})
        )
    }
  }

    return formFields
  } else {
  return [];
  }
}