import { useCallback } from 'react';
//import autoAnimate, { AutoAnimateOptions } from '@formkit/auto-animate';

/**
 * AutoAnimate hook for adding dead-simple transitions and animations to react.
 *
 * @param options - Auto animate options or a plugin
 * @returns
 */

export const useAnimation = (
  options: Partial<any> = { duration: 100 }
) =>
  useCallback(
    (node: unknown) => {
      if (node instanceof HTMLElement) {
        //autoAnimate(node, options);
      }
    },
    // eslint-disable-next-line
    [options]
  );
