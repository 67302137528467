import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import Icon from '../Icon';
import { Button } from '../inputs';
import Table, { OnEditProps, TableColumnDefs } from '../table/Table';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.lg};
  }
`;

export const CustomerVerificationTable = ({ formState, patchFormState }: FormFieldProps<CustomerVerificationForm>) => {

  const store = useStore();
  const { auditingStore } = store;
  const { getAuditingSection } = auditingStore;

  if (!formState) return null;

  const communityInformation = getAuditingSection('communityInformation');

  const data = formState.customerVerificationTable ?? [
    {
      id: '1',
      label: '',
      inputValue: ''
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.customerVerificationTable.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, customerVerificationTable: patch });
  };
  
  const handleUpdateData = async () => {
    const membersInformationTable = communityInformation?.form.membersInformationTable ?? [];
    const updatedCustomerVerificationTable = await auditingStore.getPlaceCVData(membersInformationTable, formState);
    patchFormState({ ...formState, customerVerificationTable: updatedCustomerVerificationTable });
  };

  const tableColumns: TableColumnDefs<CustomerVerification> = [
    {
      accessorKey: 'label',
      header: () => 'Hallitus ja edustajat',
      className: 'width-half text-bold nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <Container>
      <Button
        text='Hae tiedot'
        icon={<Icon type="Refresh" />}
        variant="link"
        color="primary"
        onClick={handleUpdateData}
      />
      <p style={{textAlign: 'end', margin: 0,}}><i>Sivusto pitää valitettavasti päivittää, että tiedot näkyvät.</i></p>

      <Table<CustomerVerification>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </Container>
  )
};

export const CustomerVerificationTable1 = ({ formState, patchFormState }: FormFieldProps<CustomerVerificationForm>) => {
  
  if (!formState) return null;

  const data = formState.customerVerificationTable1 ?? [
    {
      id: '1',
      label: '',
      inputValue: ''
    }
  ];

  function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
    const patch = formState.customerVerificationTable1.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, customerVerificationTable1: patch });
  };

  const tableColumns: TableColumnDefs<CustomerVerification1> = [
    {
      accessorKey: 'label',
      header: () => 'Todelliset edunsaajat',
      className: 'width-min text-bold nowrap',
    },
    {
      accessorKey: 'inputValue',
      header: () => '',
      className: 'width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <>
      <Table<CustomerVerification1>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  )
};