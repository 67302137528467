import React from 'react';
import styled from 'styled-components';
import { Menu as HeadlessMenu } from '@headlessui/react';
import Icon from './Icon';
import { useAnimation } from '../utils/hooks';

type AlignMenuType = 'right' | 'left';

const MenuContainer = styled.div<{ align: AlignMenuType }>`
  position: relative;

  .menu-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100px;
    padding: ${p => p.theme.spacing.sm} ${p => p.theme.spacing.md};
    cursor: pointer;

    &.is-open {
      background-color: ${p => p.theme.color.primary};
    }
  }

  .menu-items {
    position: absolute;
    top: 48px;
    right: ${p => (p.align === 'right' ? '-4px' : undefined)};
    left: ${p => (p.align === 'left' ? '-4px' : undefined)};
    width: 200px;
    background-color: ${p => p.theme.color.background};
    ${p => p.theme.shadow.md};
    z-index: 9;
    border-radius: ${p => p.theme.borderRadius.sm};

    > * {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: ${p => p.theme.spacing.md};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }

      svg {
        margin-right: ${p => p.theme.spacing.md};
      }
    }
  }
`;

export interface MenuItem {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface MenuProps {
  id?: string;
  items: MenuItem[];
  align?: AlignMenuType;
}

const Menu: React.FC<MenuProps> = ({ id, items, align = 'right' }) => {
  const animationParent = useAnimation();

  return (
    <MenuContainer align={align} id={id} data-testid={id} ref={animationParent}>
      <HeadlessMenu>
        {({ open }) => (
          <>
            <HeadlessMenu.Button
              className={`menu-icon-button ${open ? 'is-open' : ''}`}
            >
              <Icon
                type="Bars"
                color={open ? 'background' : 'secondary'}
                size="1.5rem"
              />
            </HeadlessMenu.Button>

            <HeadlessMenu.Items className="menu-items">
              {items.map(({ label, icon, onClick }) => (
                <HeadlessMenu.Item key={label}>
                  <button onClick={onClick}>
                    {icon}
                    {label}
                  </button>
                </HeadlessMenu.Item>
              ))}
            </HeadlessMenu.Items>
          </>
        )}
      </HeadlessMenu>
    </MenuContainer>
  );
};

export default Menu;
