import { FormFieldBuilder } from '../FormFieldBuilder';
import auditingJson from '../../../i18n/locales/fi/auditing.json';

type SectionFormType = AssignmentsFirstTimeForm;
const sectionKey: AuditingSectionKey = 'assignmentsFirstTime';
export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.assignmentsFirstTime;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  conclusionSelect: null,
  findingsAndComments: jsonVocab.findingsAndCommentsDefaultText,

  select1: null,
  select2: null,
  select3: null,

  additionalInfo1: auditingJson.giveAdditionalInfo,
  additionalInfo2: jsonVocab.actionsAndReferenceValue,
  additionalInfo3: auditingJson.giveAdditionalInfo,

  reference: jsonVocab.reference2,
  reference2: auditingJson.reference,
})

const formFields: FormFields<SectionFormType> = ({ formState/* , auditing */ }) => [
  
  
    formBuilder.checkboxGroup({plainLabel: auditingJson.inspectionConclusion, accessor: 'conclusionSelect', options: Object.keys(jsonVocab.conclusionOptions), 
    optionDisplayValue: (key: string) => jsonVocab.conclusionOptions[key as keyof typeof jsonVocab.conclusionOptions]}),
    formBuilder.textArea({accessor: 'findingsAndComments', plainLabel: auditingJson.remarksAndComments}),

    formBuilder.checkboxGroup({plainLabel: jsonVocab.firstPart, accessor: 'select1', options: Object.keys(jsonVocab.firstPartOptions), 
    optionDisplayValue: (key: string) => jsonVocab.firstPartOptions[key as keyof typeof jsonVocab.firstPartOptions]}),
    formBuilder.textArea({accessor: 'additionalInfo1', plainLabel: auditingJson.giveAdditionalInfo, 
    hidden: (formState.select1 !== null && formState.select1.hasOwnProperty("moreInfo")) ? formState.select1.moreInfo !== true: true}),
    formBuilder.textArea({accessor: 'reference', plainLabel: auditingJson.reference}),

    formBuilder.checkboxGroup({plainLabel: jsonVocab.secondPart, accessor: 'select2', options: Object.keys(jsonVocab.secondPartOptions), 
    optionDisplayValue: (key: string) => jsonVocab.secondPartOptions[key as keyof typeof jsonVocab.secondPartOptions]}),
    formBuilder.textArea({accessor: 'additionalInfo2', plainLabel: auditingJson.giveAdditionalInfo, 
    hidden: (formState.select2 !== null && formState.select2.hasOwnProperty("otherActionsCompleted")) ? formState.select2.otherActionsCompleted !== true: true}),
    formBuilder.textArea({accessor: 'reference2', plainLabel: auditingJson.reference}),

    formBuilder.checkboxGroup({plainLabel: jsonVocab.thirdPart, accessor: 'select3', options: Object.keys(jsonVocab.thirdPartOptions), 
    optionDisplayValue: (key: string) => jsonVocab.thirdPartOptions[key as keyof typeof jsonVocab.thirdPartOptions]}),
    formBuilder.textArea({accessor: 'additionalInfo3', plainLabel: auditingJson.giveAdditionalInfo, 
    hidden: (formState.select3 !== null && formState.select3.hasOwnProperty("moreInfo")) ? formState.select3.moreInfo !== true: true}),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;