export const isDetailedInformationItem = (
  items: any
): items is DetailedInformationItem[] => {
  if (!(typeof items === 'object')) {
    return false;
  }
  if (items.length >= 1) {
    const expectedKeys = ['id', 'value', 'page'];
    const itemKeys = Object.keys(items[0]);
    const sameKeys =
      expectedKeys.length === itemKeys.length &&
      expectedKeys.every(key => itemKeys.includes(key));
    if (sameKeys) {
      return true;
    }
  }
  return false;
};

export const isEssentialNotesItem = (
  items: any
): items is EssentialNotesItem[] => {
  if (!(typeof items === 'object')) {
    return false;
  }
  if (items.length >= 1) {
    const expectedKeys = [
      'id',
      'minutes',
      'date',
      'section',
      'notes',
      'reference',
      'mustBeNoted',
    ];
    const itemKeys = Object.keys(items[0]);
    const sameKeys =
      expectedKeys.length === itemKeys.length &&
      expectedKeys.every(key => itemKeys.includes(key));
    if (sameKeys) {
      return true;
    }
  }
  return false;
};
