import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../theme';
import { formatCurrency, formatPercent } from '../../../utils';
import AuditingTable from '../../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../table/Table';

interface Props<FormType> extends FormFieldProps<FormType> {
  dataKey: keyof FormType;
  accountMappingValues: AccountMappingValues;
  thresholdComputationRules: ThresholdComputationRules;
}

function ThresholdTestTable<FormType>({
  formState,
  store,
  dataKey,
  accountMappingValues,
  thresholdComputationRules,
  patchFormState,
}: Props<FormType>) {
  const { t } = useTranslation(['auditing', 'common']);

  if (!formState?.[dataKey]) return null;

  const dataArray = formState[dataKey] as ThresholdTestRow[];

  const computationRuleProps: ThresholdComputationFnProps = {
    data: dataArray,
    accountMappingValues,
    getAccountMappingValues: {
      cy: (...keys: string[]) => keys.map(key => accountMappingValues[key]?.cy),
      py: (...keys: string[]) => keys.map(key => accountMappingValues[key]?.py),
    },
    auditingStore: store.auditingStore,
  };

  function handleEdit<T>({ columnId, itemId, value }: OnEditProps<T>) {
    patchFormState({
      ...formState,
      [dataKey]: dataArray.map((row: ThresholdTestRow) =>
        row.id === itemId ? { ...row, [columnId]: value } : row
      ),
    });
  }

  const bgColor = (row: ThresholdTestRow) => {
    if (!row.key.includes('CONCLUSION') || !row.computedValue) return;
    return row.computedValue === 'ABOVE_THRESHOLD'
      ? Colors.red
      : row.computedValue === 'BELOW_THRESHOLD'
      ? Colors.green
      : undefined;
  };

  const data: ThresholdTestRow[] = dataArray.map(row => {
    let computedValue: ThresholdTestRowValue | string =
      thresholdComputationRules[row.key]
        ? thresholdComputationRules[row.key](computationRuleProps)
        : row.value;

    if (row.key.includes('CONCLUSION') && typeof computedValue === 'number') {
      computedValue = computedValue < 0 ? 'ABOVE_THRESHOLD' : 'BELOW_THRESHOLD';
    } else if (row.unit && typeof computedValue === 'number') {
      switch (row.unit) {
        case '€':
          computedValue = formatCurrency(computedValue, {
            hideCurrencySymbol: true,
          });
          break;
        case '%':
          computedValue = formatPercent(computedValue, true);
          break;
        default:
        // Do nothing
      }
    }

    return { ...row, computedValue, bgColor };
  });

  const tableColumns: TableColumnDefs<ThresholdTestRow> = [
    {
      accessorKey: 'key',
      accessorFn: ({ key }) => {
        if (key.includes('CONCLUSION'))
          return t(`auditingProcedure.label.CONCLUSION`).toUpperCase();
        if (key.includes('THRESHOLD'))
          return t(`auditingProcedure.label.THRESHOLD`).toUpperCase();
        return t(`auditingProcedure.label.${key}`);
      },
      className: 'width-max',
      header: () => null,
    },
    {
      accessorKey: 'computedValue',
      accessorFn: ({ key, computedValue: value }) => {
        if (key.includes('CONCLUSION'))
          return t(`auditingProcedure.conclusion.${value}`).toUpperCase();
        return value;
      },
      className: 'width-min nowrap text-right',
      header: () => null,
      /* disabled: ({ disabled }) => disabled, */
    },
    {
      accessorKey: 'unit',
      className: 'width-min nowrap text-left',
      header: () => null,
    },
    {
      accessorKey: 'useCustomValue',
      className: 'width-min nowrap text-center',
      header: () => null,
      hidden: ({ disabled }) => disabled,
      onBooleanEdit: props => handleEdit<boolean>(props),
    },
    {
      accessorKey: 'value',
      className: 'width-min nowrap text-center',
      header: () => t('common:label.customizedValue'),
      hidden: ({ disabled, useCustomValue }) => disabled || !useCustomValue,
      onNumberEdit: editProps => handleEdit<number>(editProps),
    },
  ];

  return (
    <AuditingTable<ThresholdTestRow>
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
}

export default ThresholdTestTable;
