import React from 'react';
import { generateAccountMap, getAllAccountMapRowsMatchingClassKey } from '../../scoping/accountMapUtilsScoping';
import { AccountClassKey } from '../../planning/accountMapKeysScoping';
import { 
  generateAccountMap as originalAccountMap, 
  getAllAccountMapRowsMatchingClassKey as originalMatchingClassKey
} from '../../analyzes/accountMapUtils';
import { AccountClassKey as originalClassKeys } from '../../planning/accountMapKeys';
import { FinancialStatementBatch } from '../auditingProceduresUtils';
import ProcedureAccounts from './ProcedureAccounts';
import ProcedureAccountsManual from './ProcedureAccountsManual';
import { getIsInterimAudit } from '../../../../utils';

interface Props {
  auditing: any;
  addCommentField?: boolean;
  formState?: any;
  patchFormState?: Function;
  batchString: string;
  showChange?: boolean;
  customAccountTitle?: string;
  showTitles?: boolean;
}

export const ProcedureAccountsElement: React.FC<Props> = ({ auditing, addCommentField, formState, patchFormState, batchString, showChange, customAccountTitle, showTitles }) => {
  /*
    Using this function requires you to create a variable named procedureAccountsData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        procedureAccountsData: {},
      })
  */

      // Need to use this to change between "Tilinpäätös" & "Kaudenaikainen".
      // True = use "Kaudenaikainen", False = use "Tilinpäätös".
      const isInterimAudit = getIsInterimAudit(auditing?.internalControlAndOperatingEnvironment?.form.isInterimAudit);
      const batch = FinancialStatementBatch[batchString as keyof typeof FinancialStatementBatch];

      //----- Tilinpäätös -----
      var auditingGeneralLedgerScoping = auditing?.generalLedgerScoping?.form.groupedGeneralLedger;
      const auditingAccountMapScoping = auditing?.accountMapScoping?.form.accountMapScoping;
      const auditingTemplate = auditing?.template;
  
      const accountMap =
      !auditingAccountMapScoping && auditingTemplate
        ? generateAccountMap(auditingTemplate)
        : auditingAccountMapScoping;
  
        const accountsMapRows = getAllAccountMapRowsMatchingClassKey(
          accountMap!,
          AccountClassKey[FinancialStatementBatch[batchString as keyof typeof AccountClassKey]]
        );
  
        const procedureAccountRowsPreData = auditingGeneralLedgerScoping?.filter(({ account }:any) => {
          const hasMatchingAccountClass = !!accountsMapRows.find(
            ({ start, end }) => account >= start && account <= end
          );
          return hasMatchingAccountClass;
        });
      //-------------------------
      //----- Kaudenaikainen -----
        var auditingGeneralLedger = auditing?.generalLedger?.form.groupedGeneralLedger;
        const auditingAccountMap = auditing?.accountMap?.form.accountMap;
        const accountMapOriginal = !auditingAccountMap && auditingTemplate
          ? originalAccountMap(auditingTemplate)
          : auditingAccountMap;

        const accountsMapRowsOriginal = originalMatchingClassKey(
          accountMapOriginal!,
          originalClassKeys[FinancialStatementBatch[batchString as keyof typeof originalClassKeys]]
        );

        const procedureAccountRowsPreDataOriginal = auditingGeneralLedger?.filter(({ account }:any) => {
          const hasMatchingAccountClass = !!accountsMapRowsOriginal.find(
            ({ start, end }) => account >= start && account <= end
          );
          return hasMatchingAccountClass;
        });
      //-------------------------
      
      // Use isInterimAudit to determine which data to use.
      return isInterimAudit ?
        // This is when isInterimAudit is true. ( Kaudenaikainen )
        procedureAccountRowsPreDataOriginal !== undefined && procedureAccountRowsPreDataOriginal!.length !== 0 ? ( // Change back to procedureAccountRowsPreDataOriginal
          <ProcedureAccounts 
            showTitles={showTitles} 
            customAccountTitle={customAccountTitle} 
            showChange={showChange} 
            classKey={originalClassKeys[FinancialStatementBatch[batch as keyof typeof originalClassKeys]]} 
            formState={formState} 
            patchFormState={patchFormState} 
            auditing={auditing} 
            addCommentField={addCommentField} 
          />
        ) : 
          <ProcedureAccountsManual 
            customAccountTitle={customAccountTitle} 
            showChange={showChange} 
            classKey={originalClassKeys[FinancialStatementBatch[batch as keyof typeof originalClassKeys]]} 
            formState={formState} 
            patchFormState={patchFormState} 
            auditing={auditing} 
            addCommentField={addCommentField} 
          />
        :
        // This is when isInterimAudit is false. ( Tilinpäätös )
        procedureAccountRowsPreData !== undefined && procedureAccountRowsPreData!.length !== 0 ? (
          <ProcedureAccounts 
            showTitles={showTitles} 
            customAccountTitle={customAccountTitle} 
            showChange={showChange} 
            classKey={AccountClassKey[FinancialStatementBatch[batch as keyof typeof AccountClassKey]]} 
            formState={formState} 
            patchFormState={patchFormState} 
            auditing={auditing} 
            addCommentField={addCommentField} 
          />
        ) : 
          <ProcedureAccountsManual 
            customAccountTitle={customAccountTitle} 
            showChange={showChange} 
            classKey={AccountClassKey[FinancialStatementBatch[batch as keyof typeof AccountClassKey]]} 
            formState={formState} 
            patchFormState={patchFormState} 
            auditing={auditing} 
            addCommentField={addCommentField} 
          />
};