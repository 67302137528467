import { makeObservable, runInAction } from 'mobx';
import { Api } from '../api/RootApi';
import { AppStore } from './modules';
import { RootStore } from './RootStore';

export abstract class BaseStore {
  api: Api;
  rootStore: RootStore;
  appStore: AppStore;

  constructor(rootStore: RootStore, api: Api) {
    makeObservable(this, { rootStore: false, appStore: false, api: false });

    this.api = api;
    this.rootStore = rootStore;
    this.appStore = rootStore.appStore;
  }

  defaultAction = async <T>(props: {
    taskName?: PendingTaskName;
    taskType: PendingTaskType;
    isSilent?: boolean;
    apiRequest: () => Promise<Api.Problem | Api.Success<T>>;
    onSuccess?: (response: Api.Success<T>) => void;
    onError?: (error?: unknown) => void;
  }) => {
    const { taskName, taskType, isSilent, apiRequest, onSuccess, onError } =
      props;

    if (this.appStore.taskAlreadyPending(taskName)) return { success: false };

    const taskId = this.appStore.addPendingTask(taskType, taskName, isSilent);

    try {
      const response = await apiRequest();
      if (response.kind === 'OK') {
        runInAction(() => {
          if (onSuccess) onSuccess(response);
          this.appStore.finishPendingTask(taskId, 'success');
        });
        return { success: true, data: response.data };
      } else throw response;
    } catch (error) {
      runInAction(() => {
        if (onError) onError(error);
        this.appStore.finishPendingTask(taskId, 'error');
      });
      return { success: false };
    }
  };
}

export default BaseStore;
