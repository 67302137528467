import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = AdvancePaymentsForm;

const sectionKey: AuditingSectionKey = 'advancePayments';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'T2-advancePayments'
const jsonVocab = auditingJson.form.advancePayments;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [{
    currentYear: 0,
    priorYear: 0,
    accountName: "",
    comment: ""
  }],
  procedureAccountsData: {},
  tableColumnData: [
    {
      left: "Ennakot, jotka taseelle on merkitty ovat olemassa",
      right: auditingJson.OLE
    },
    {
      left: "Kaikki ennakot, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksellä on esitetty",
      right: auditingJson.TAY
    },
    {
      left: "Ennakot on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti.",
      right: auditingJson.ARV
    },
    {
    left: "Ennakot on luokiteltu ja tuloutettu oikein",
    right: auditingJson.LUOK
    },
  ],

  // Non part
  conclusion: jsonVocab.conclusionFieldValue,

  useSheetTab: null,
  useSheet1: null,
  useSheet2: null,
  useSheet3: null,
  spreadsheet1:[{
    cellData: [],
  }],
  spreadsheet2:[{
      cellData: [],
  }],
  spreadsheet3:[{
      cellData: [],
  }],
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement auditing={auditing} {...props} batchString={batchString} addCommentField />),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({accessor: 'conclusion', plainLabel: auditingJson.conclusion}),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'receivedAdvancesCheck',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet1', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet  id="spreadsheet1" {...props} /> : null),
      ]
    },
    {
      accordionId: 'classificationAndPresentationOfAdvances',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet2', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet  id="spreadsheet2" {...props} /> : null),
      ]
    },
    {
      accordionId: 'perfectionOfAdvances',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet  id="spreadsheet3" {...props} /> : null),
      ]
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
