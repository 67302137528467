import { DefaultTheme, ThemeProvider as Provider } from 'styled-components';
import { Breakpoints } from './theme.breakpoints';
import { Colors } from './theme.colors';
import { Fonts } from './theme.fonts';
import { fontSize, headingSize } from './theme.utils';

export const theme: DefaultTheme = {
  ui: {
    headerHeight: 70,
    sideNavWidth: 300,
  },

  color: Colors,

  borderRadius: {
    xsm: '0.15rem',
    sm: '0.25rem',
    md: '0.625rem',
    lg: '1rem',
    xl: '1.5rem',
    xxl: '3rem',
  },

  spacing: {
    xsm: '0.25rem',
    sm: '0.375rem',
    md: '0.625rem',
    lg: '1.5rem',
    xl: '2.5rem',
    xxl: '4rem',
  },

  breakpoint: {
    mobile: `(max-width: ${Breakpoints.Mobile}px)`,
    tablet: `(max-width: ${Breakpoints.Tablet}px)`,
    laptop: `(max-width: ${Breakpoints.Laptop}px)`,
  },

  shadow: {
    sm: 'box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);',
    md: 'box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.3);',
    lg: 'box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.3);',
  },

  font: {
    main: Fonts.Main,
    secondary: Fonts.Secondary,
    size: {
      12: fontSize(0.625, 0.75),
      14: fontSize(0.75, 0.875),
      16: fontSize(0.875, 1),
      18: fontSize(1, 1.125),
      20: fontSize(1.125, 1.25),
      24: fontSize(1.25, 1.5),
      28: fontSize(1.5, 1.75),
      32: fontSize(1.75, 2),
      38: fontSize(2, 2.375),
    },
    h1: headingSize('h1'),
    h2: headingSize('h2'),
    h3: headingSize('h3'),
    h4: headingSize('h4'),
    h5: headingSize('h5'),
    h6: headingSize('h6'),
  },
};

export const ThemeProvider = Provider;
