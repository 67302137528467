import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import {
  formatCurrency,
  formatPercent,
  getClassNameForFinancialStatementRow,
} from '../../utils';
import { Risk } from '../../views/auditingSections/analyzes/incomeStatementAndBalance';
import { Button } from '../inputs';
import ToggleButton from '../inputs/ToggleButton';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.xl};
  }
`;
const colorRed = '#b30000';
const colorGreen = '#009900';

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalance';

interface Props extends FormFieldProps<IncomeStatementAndBalanceForm> {
  targetKey: 'incomeStatement' | 'balanceAssets' | 'balanceLiabilities';
}

export const IncomeStatementAndBalanceTable = observer(
  ({ targetKey, formState, patchFormState }: Props) => {
    const { t } = useTranslation();

    const { appStore: { setComponentState, getComponentState }, } = useStore();
    const { auditingStore: { setAllRiskArraysToTrue, isAuditingSectionFinished }, } = useStore();

    if (!formState) return null;

    const componentKey = `incomeStatementAndBalanceTables-showStatements`;

    const tBase = `auditing:form.${sectionKey}`;

    const hideButton = isAuditingSectionFinished('incomeStatementAndBalance');

    const data = formState[targetKey] ?? [];

    const formattedData = data.map(item => ({
      ...item,
      currentYear: formatCurrency(item.currentYear),
      priorYear: formatCurrency(item.priorYear),
      change: formatCurrency(item.change),
      changePercent: formatPercent(item.changePercent),
      className: getClassNameForFinancialStatementRow(item.heading),
    }));

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
      const patch = (formState[targetKey] ?? []).map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          return { ...row, [columnId]: value };
        }
        return row;
      });
      patchFormState({ ...formState, [targetKey]: patch });
    }

    function handleRiskEdit<T>({
      /*
        If more than one rows have the same title, we will edit both rows with the same value. 
      */
      rowIndex,
      value,
    }: OnEditProps<T>) {
      const title = t("auditing:accountKey."+data[rowIndex].key);
      const arrayOfDuplicates = data.map((item, index) => ({...item, rowIndex: index, itemTitle: t("auditing:accountKey."+item.key)})).filter(item => item.itemTitle === title)
      const newFormState = {...formState}
      arrayOfDuplicates.forEach(duplicate => {
        const rowIndex = duplicate.rowIndex;
        const riskValue = value;
        newFormState[targetKey][rowIndex].risk = riskValue as Risk;
      })
      patchFormState(newFormState)
    
    }

    const statementKeys = targetKey === 'incomeStatement' ? [
      'tap',
      'tay',
      'oik',
      'arv',
      'luo',
      'esi',
      //'statementFraud',
      //'statementEstimate',
    ]: [
      'ole',
      'ove',
      'tay',
      'arv',
      'luo',
      'esi',
      //'statementFraud',
      //'statementEstimate',
    ]

    let tableColumns: TableColumnDefs<IncomeStatementAndBalanceTableItem> = [
      {
        id: 'key',
        cell: ({ row: { original: row } }) => (
          <div className={row.className}>
            {row.key ? t(`auditing:accountKey.${row.key}`) : '-'}
          </div>
        ),
        className: 'text-left width-max',
        header: () => t(`${tBase}.item`),
      },
      {
        accessorKey: 'currentYear',
        className: 'text-right small-text width-min nowrap text-bold',
        header: () => t(`${tBase}.currentYear`),
      },
      {
        accessorKey: 'priorYear',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.priorYear`),
      },
      {
        accessorKey: 'change',
        className: `text-right small-text width-min nowrap`,
        header: () => t(`${tBase}.change`),
        cell: ({ row: { original: row } }) => {
          const { change } = row;
          // Remove existing thousands separators and convert to a number
          const changeValue = parseFloat((change || '0').replace(/\s+/g, '').replace(',', '.'));
          // Format the number as currency
          const formattedChange = formatCurrency(changeValue, {
            currency: '€',
            decimalSeparator: ',',
            thousandsSeparator: ' ',
            hideCurrencySymbol: false,
            zeroIsUndefined: true,
          });
          // Determine color based on the value
          const color = changeValue > 0 ? colorGreen : colorRed;
          return <div style={{ color }}>{formattedChange}</div>;
        },
      },        
      {
        accessorKey: 'changePercent',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.changePercent`),
        cell: ({ row: { original: row } }) => {
          const { changePercent } = row;
      
          // Remove existing percent symbol and any thousands separators
          const cleanedChangePercent = (changePercent || '0').replace(/[^0-9.-]/g, '');
      
          // Convert to a number and round to one decimal place
          const changePercentValue = parseFloat(cleanedChangePercent);
          const roundedChangePercent = (changePercentValue / 10).toFixed(1);
      
          // Replace the decimal point with a comma
          const formattedChangePercent = `${roundedChangePercent.replace('.', ',')} %`;
      
          // Determine color based on the value
          const color = changePercentValue > 0 ? colorGreen : colorRed;
      
          return <div style={{ color }}>{formattedChangePercent}</div>;
        },
      },      
      {
        accessorKey: 'risk',
        className: 'text-center width-min',
        header: () => t(`${tBase}.risk`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        selectProps: {
          options: [Risk.notSignificant, Risk.limitedRisk, Risk.significant],
          displayValue: (option: string) => t(`${tBase}.riskOptions.${option}`),
          setValue: (editProps: OnEditProps<string>) => 
          handleRiskEdit<string>(editProps)
        }},
      ...statementKeys.map(key => ({
        accessorKey: key,
        className: 'text-center width-min no-cell-padding',
        header: () => t(`${tBase}.${key}`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        onBooleanEdit: (editProps: OnEditProps<boolean>) =>
          handleEdit<boolean>(editProps),
      })),
      {
        accessorKey: 'statementDescription',
        header: () => t(`${tBase}.statementDescription`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        onEdit: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    ];

    const showStatements = getComponentState(componentKey)?.isOpen ?? true;

    if (!showStatements) {
      tableColumns = tableColumns.filter(
        (column: any) =>
          ![...statementKeys].includes(
            column.accessorKey
          )
      );
    }

    return (
      <>
        <Container>
          <div style={{
            position: 'relative',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'space-between',
            marginLeft: 10,
          }}>
            <div style={{
              marginRight: 25,
            }}>
            {!hideButton && <Button onClick={setAllRiskArraysToTrue} text="Aseta kaikki kannanotot" variant='link' color='primary' icon='Plus'/>}
            </div>

          <ToggleButton
            toggled={showStatements}
            text={t(`${tBase}.statementsAndDescriptions`)}
            onClick={() =>
              setComponentState({ key: componentKey, isOpen: !showStatements })
            }
          />
          </div>
          <AuditingTable<IncomeStatementAndBalanceTableItem>
            sectionKey={sectionKey}
            data={formattedData}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            animationDisabled={false}
          />
        </Container>
      </>
    );
  }
);