import AuditingTable from "../table/AuditingTable";
import { useTranslation } from "react-i18next";
import { OnEditProps, TableColumnDefs } from "../table/Table";
import { AccountKeyScoping } from "../../views/auditingSections/planning/accountMapKeysScoping";
import styled from "styled-components";
import Button from "../inputs/Button";

const sectionKey: AuditingSectionKey = "indicators";

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
`;

interface TableItem {
    id?: UUID;
    key: AccountKeyScoping | string;
    label: string;
    industry?: string;
    value?: number | string;
    secondValue?: number | string;
    boolean?: boolean;
    estimate?: Estimate;
}

interface EstimateThresholds {
    good: number;
    middle: number;
    bad: number;
}

export enum Estimate {
    Good = 'Hyvä',
    Moderate = 'Kohtalainen',
    Poor = 'Heikko'
}

// Helper function to calculate the estimate
function getEstimate(value: number, secondValue: number, thresholds: EstimateThresholds): Estimate {
    const change = ((value - secondValue) / secondValue) * 100;
    if (change > thresholds.good) return Estimate.Good;
    if (change < thresholds.middle) return Estimate.Moderate;
    return Estimate.Poor;
}
// Helper for `materialMargin` estimate
function getMaterialMarginEstimate(value: number, secondValue: number, thresholds: EstimateThresholds): Estimate {
    const change = Math.abs(((value - secondValue) / secondValue) * 100);
    if (change === thresholds.good) {
      return Estimate.Good;
    } else if (change <= thresholds.middle) {
      return Estimate.Moderate;
    } else {
      return Estimate.Poor;
    }
}
// Helper for `tyel` and `sotu` estimate
function getEstimateTYEL(value: number, expectedValue: number, thresholds: EstimateThresholds): Estimate {
    const change = Math.abs(((value - expectedValue) / expectedValue) * 100);
    if (change === thresholds.good) {
      return Estimate.Good;
    } else if (change <= thresholds.middle) {
      return Estimate.Moderate;
    } else {
      return Estimate.Poor;
    }
}

export const IndicatorsClassTable = ({
    formState,
    patchFormState,
}: FormFieldProps<IndicatorsForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}.indicatorsClassTable`;

    const data = formState.indicatorsClassTable ?? [
        {
            id: '1',
            companyImplementation: '',
            lastBenchmark: '',
            industry: '',
            estimate: ''
        }
    ];

    function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
        const patch = formState.indicatorsClassTable.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
          return row;
        });
        patchFormState({ ...formState, indicatorsClassTable: patch });
      };

    const tableColumns: TableColumnDefs<IndicatorsClassTable> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.class`),
            className: 'text-bold nowrap width-min',
            showRemark: (item: IndicatorsClassTable) =>
              typeof item.boolean === 'boolean' && !item.boolean,
            selectProps: {
              options: [
                '1. Tilinpäätös ja kirjanpito', 
                '2. Sisäinen valvonta ja hallinto', 
                '3. Liiketoiminnan tunnusluvut', 
                '4. Laki ja verotus', 
                '5. Tarkastuksen yleiset ja muut'
              ],
              placeholder: 'Valitse',
              clearable: true,
              displayValue: (option: string) => option,
              setValue: (editProps: OnEditProps<string>) => {
                const value = editProps.value;
                handleEdit<string | null>({
                  ...editProps,
                  value: value,
                });
              }
            }
        },
        {
            accessorKey: 'companyImplementation',
            header: () => t(`${tBase}.companyImplementation`),
            className: 'width-min text-bold nowrap',
            onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
          },
          {
            accessorKey: 'lastBenchmark',
            header: () => t(`${tBase}.lastBenchmark`),
            className: 'width-min text-bold nowrap',
            onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
          },
          {
            accessorKey: 'industry',
            header: () => t(`${tBase}.industry`),
            className: 'width-min text-bold nowrap',
            onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
          },
          {
            accessorKey: 'estimate',
            header: () => t(`${tBase}.estimate`),
            className: 'width-min text-bold nowrap',
            onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
          },
    ];

    return (
        <AuditingTable<IndicatorsClassTable>
          sectionKey={sectionKey}
          data={data}
          columns={tableColumns}
          showGlobalFilter={false}
          disableSort
          variant='default'
        />
    )
};

export const IndicatorsTable = ({
    store,
    formState,
    patchFormState,
}: FormFieldProps<IndicatorsForm>) => {
    const { t } = useTranslation();

    const { getIndicators } = store.auditingStore;

    const tBase = `auditing:form.${sectionKey}`;

    const data: IndicatorsItem[] = formState?.indicators ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedValue = columnId === 'industry' ? String(value) : value;
                return { ...currentRow, [columnId]: updatedValue };
            }
            return currentRow;
        });
        const newFormState = { 
            ...formState, 
            indicators: patch,
        };
        patchFormState(newFormState);
    }

    const headerTexts = {
        turnoverChange: "Liikevaihdon muutos (%)",
        operatingProfit: "Liiketulos (teur)",
        operatingProfitChange: "Liiketuloksen muutos (%)",
        operatingPercent: "Liiketulos (%)",
        financialResult: "Rahoitustulos (teur)",
        financialResultPercent: "Rahoitustulos (%)",
        netResult: "Nettotulos (teur)",
        netPercent: "Nettotulos (%)",
        investedCapitalPercent: 'Sijoitetun pääoman tuottoprosentti',
        equityPercent: 'Oman pääoman tuottoprosentti',
        adjustedEquity: 'Oikaistu oma pääoma (omat varat)',
        interestDebts: 'Korolliset velat (sijoitettu vieras pääoma)',
        selfRatePercent: 'Omavaraisuusaste (%)',
        relativeIndebtedness: 'Suhteellinen velkaantuneisuus (%)',
        workingCapital: 'Käyttöpääoma',
        workingCapitalPercent: 'Käyttöpääomaprosentti (%)',
        currentRatio: 'Current ratio',
        quickRatio: 'Quick ratio',
        accountsReceivableCycleTime: 'Myyntisaamisten kiertoaika (pv)',
        accountsPayableCycleTime: 'Ostovelkojen kiertoaika (pv)',
        materialMargin: 'Materiaalikate (%)',
        tyel: 'TyEL (%)',
        sotu: 'Sotu (%)',
        estimateFiveYears: 'Arvio yli 5.v päästä erääntyvistä lainoista',
        accountsReceivableVSturnOver: 'Myyntisaamiset vs Liikevaihto',
        accountsPayableVSpurchases: 'Ostovelat vs Ostot',
        shareCapitalOver: 'Osakepääoma yli 50.000',
        svopOver: 'SVOP yli 50.000',
        ratioOfHoliday: 'Lomapalkkavelan suhde palkkoihin',
    };

    let disabledKeys: string[] = [
        'profitability',
        'financialStructure',
        'financingWorkingCapital',
        'financingWillingnessToPay',
    ];
    let undefinedKeys: string[] = [
        'undefined',
        'undefined1',
        'undefined2',
        'undefined3',
        'undefined4',
    ];
    const tableColumns: TableColumnDefs<TableItem> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.profitabilityResult`),
            className: 'text-bold nowrap width-min',
            hidden: ({ key } : TableItem) => undefinedKeys.includes(key),
        },
        {
            accessorKey: 'companyImplementation',
            header: () => t(`${tBase}.companyImplementation`),
            cell: ({ row: { original: row } }) => row.value,
            className: 'text-right nowrap width-min',
            showRemark: ({ key }: TableItem) =>
                store.auditingStore.isMarkedAsRemark(sectionKey, key),
            hidden: ({ key } : TableItem) => undefinedKeys.includes(key),
        },
        {
            accessorKey: 'lastBenchmark',
            header: () => t(`${tBase}.lastBenchmark`),
            cell: ({row: { original: row }}) => row.secondValue,
            className: 'text-right nowrap width-min',
            //hidden: ({ key } : TableItem) => undefinedKeys.includes(key),
        },
        {
            accessorKey: 'industry',
            header: () => t(`${tBase}.industry`),
            className: 'text-middle nowrap width-min',
            // Update the industry field
            onEdit: ({ itemId, rowIndex, columnId, value }: OnEditProps<string>) => {
                handleEdit<string>({ itemId, rowIndex, columnId, value });
            },
            hidden: ({ key } : TableItem) => disabledKeys.includes(key) || undefinedKeys.includes(key),
        },
        {
            accessorKey: 'estimate',
            header: () => t(`${tBase}.estimate`),
            className: 'text-middle nowrap width-min',
            hidden: ({ key } : TableItem) => disabledKeys.includes(key) || undefinedKeys.includes(key),
        },
    ];
    // AuditinStore.ts getIndicators
    const rows: TableItem[] = [
        {   // Liikevaidon muutos (%)
            id: '1',
            key: AccountKeyScoping.turnover,
            label: headerTexts['turnoverChange'],
            value: getIndicators(AccountKeyScoping.turnover) + ' %',
            industry: formState.indicators[0]?.industry ?? '',
        },
        {   // Liiketulos (teur)
            id: '2',
            key: 'operatingChange',
            label: headerTexts['operatingProfit'],
            value: getIndicators('operatingChange') + ' €',
            secondValue: getIndicators('operatingChangePY') + ' €',
            estimate: getEstimate(getIndicators('operatingChange'), getIndicators('operatingChangePY'), {
                good: 2,
                middle: 0,
                bad: -1,
            }),
            industry: formState.indicators[1]?.industry ?? '',
        },
        {   // Liiketuloksen muutos (%)
            id: '3',
            key: 'operatingProfitChange',
            label: headerTexts['operatingProfitChange'],
            value: getIndicators('operatingProfitChange') + ' %',
            industry: formState.indicators[2]?.industry ?? '',
        },
        {   // Liiketulos (%)
            id: '4',
            key: 'operatingPercent',
            label: headerTexts['operatingPercent'],
            value: getIndicators('operatingPercent') + ' %',
            secondValue: getIndicators('operatingPercentPY') + ' %',
            estimate: getEstimate(getIndicators('operatingPercent'), getIndicators('operatingPercentPY'), {
                good: 10,
                middle: 5,
                bad: -Infinity,
            }),
            industry: formState.indicators[3]?.industry ?? '',
        },
        {   // Rahoitustulos (teur)
            id: '5',
            key: 'financialResult',
            label: headerTexts['financialResult'],
            value: getIndicators('financialResult') + ' €',
            secondValue: getIndicators('financialResultPY') + ' €',
            industry: formState.indicators[4]?.industry ?? '',
        },
        {   // Rahoitustulos (%)
            id: '6',
            key: 'financialResultPercent',
            label: headerTexts['financialResultPercent'],
            value: getIndicators('financialResultPercent') + ' %',
            secondValue: getIndicators('financialResultPercentPY') + ' %',
            industry: formState.indicators[5]?.industry ?? '',
        },
        {   // Nettotulos (teur)
            id: '7',
            key: 'netResult',
            label: headerTexts['netResult'],
            value: getIndicators('netResult') + ' €',
            secondValue: getIndicators('netResultPY') + ' €',
            industry: formState.indicators[6]?.industry ?? '',
        },
        {   // Nettotulos (%)
            id: '8',
            key: 'netPercent',
            label: headerTexts['netPercent'],
            value: getIndicators('netPercent') + ' %',
            secondValue: getIndicators('netPercentPY') + ' %',
            industry: formState.indicators[7]?.industry ?? '',
        },
        {
            // Kannattavuus/pääoman tuotto (string)
            key: 'profitability',
            label: 'Kannattavuus/pääoman tuotto',
        },
        {   // Sijoitetun pääoman tuottoprosentti
            id: '9',
            key: 'investedCapitalPercent',
            label: headerTexts['investedCapitalPercent'],
            value: getIndicators('investedCapitalPercent') + ' %',
            secondValue: getIndicators('investedCapitalPercentPY') + ' %',
            industry: formState.indicators[9]?.industry ?? '',
        },
        {   // Oman pääoman tuottoprosentti
            id: '10',
            key: 'equityPercent',
            label: headerTexts['equityPercent'],
            value: getIndicators('equityPercent') + ' %',
            secondValue: getIndicators('equityPercentPY') + ' %',
            industry: formState.indicators[10]?.industry ?? '',
        },
        { // Rahoitus/pääomarakenne (string)
            key: 'financialStructure',
            label: 'Rahoitus/pääomarakenne',
        },
        {   // Oikaistu oma pääoma (omat varat)
            id: '11',
            key: 'adjustedEquity',
            label: headerTexts['adjustedEquity'],
            value: getIndicators('adjustedEquity') + ' €',
            secondValue: getIndicators('adjustedEquityPY') + ' €',
            industry: formState.indicators[12]?.industry ?? '',
        },
        {   // Korolliset velat (sijoitettu vieras pääoma)
            id: '12',
            key: 'interestDebts',
            label: headerTexts['interestDebts'],
            value: getIndicators('interestDebts') + ' €',
            secondValue: getIndicators('interestDebtsPY') + ' €',
            industry: formState.indicators[13]?.industry ?? '',
        },
        {   // Omavaraisuusaste (%)
            id: '13',
            key: 'selfRatePercent',
            label: headerTexts['selfRatePercent'],
            value: getIndicators('selfRatePercent') + ' %',
            secondValue: getIndicators('selfRatePercentPY') + ' %',
            estimate: getEstimate(getIndicators('selfRatePercent'), getIndicators('selfRatePercentPY'), {
                good: 40,
                middle: 20,
                bad: -Infinity,
            }),
            industry: formState.indicators[14]?.industry ?? '',
        },
        {   // Suhteellinen velkaantuneisuus (%)
            id: '14',
            key: 'relativeIndebtedness',
            label: headerTexts['relativeIndebtedness'],
            value: getIndicators('relativeIndebtedness') + ' %',
            secondValue: getIndicators('relativeIndebtednessPY') + ' %',
            estimate: getEstimate(getIndicators('relativeIndebtedness'), getIndicators('relativeIndebtednessPY'), {
                good: -Infinity,
                middle: 40,
                bad: 80,
            }),
            industry: formState.indicators[15]?.industry ?? '',
        },
        {   // Rahoitus/käyttöpääoma (string)
            key: 'financingWorkingCapital',
            label: 'Rahoitus/käyttöpääoma',
        },
        {   // Käyttöpääoma
            id: '15',
            key: 'workingCapital',
            label: headerTexts['workingCapital'],
            value: getIndicators('workingCapital') + ' €',
            secondValue: getIndicators('workingCapitalPY') + ' €',
            industry: formState.indicators[17]?.industry ?? '',
        },
        {   // Käyttöpääomaprosentti (%)
            id: '16',
            key: 'workingCapitalPercent',
            label: headerTexts['workingCapitalPercent'],
            value: getIndicators('workingCapitalPercent') + ' %',
            secondValue: getIndicators('workingCapitalPercentPY') + ' %',
            industry: formState.indicators[18]?.industry ?? '',
        },
        {   // Rahoitus/maksuvalmius (string)
            key: 'financingWillingnessToPay',
            label: 'Rahoitus/maksuvalmius',
        },
        {   // Current ratio
            id: '17',
            key: 'currentRatio',
            label: headerTexts['currentRatio'],
            value: getIndicators('currentRatio'),
            secondValue: getIndicators('currentRatioPY'),
            estimate: getEstimate(getIndicators('currentRatio'), getIndicators('currentRatioPY'), {
                good: 2,
                middle: 1,
                bad: -Infinity,
            }),
            industry: formState.indicators[20]?.industry ?? '',
        },
        {   // Quick ratio
            id: '18',
            key: 'quickRatio',
            label: headerTexts['quickRatio'],
            value: getIndicators('quickRatio'),
            secondValue: getIndicators('quickRatioPY'),
            estimate: getEstimate(getIndicators('quickRatio'), getIndicators('quickRatioPY'), {
                good: 1,
                middle: 0.5,
                bad: -Infinity,
            }),
            industry: formState.indicators[21]?.industry ?? '',
        },
        {   // Myyntisaamisten kiertoaika (pv)
            id: '19',
            key: 'accountsReceivableCycleTime',
            label: headerTexts['accountsReceivableCycleTime'],
            value: getIndicators('accountsReceivableCycleTime') + ' pv',
            secondValue: getIndicators('accountsReceivableCycleTimePY') + ' pv',
            industry: formState.indicators[22]?.industry ?? '',
        },
        {   // Ostovelkojen kiertoaika (pv)
            id: '20',
            key: 'accountsPayableCycleTime',
            label: headerTexts['accountsPayableCycleTime'],
            value: getIndicators('accountsPayableCycleTime') + ' pv',
            secondValue: getIndicators('accountsPayableCycleTimePY') + ' pv',
            industry: formState.indicators[23]?.industry ?? '',
        },
        {
            key: 'undefined',
            label: 'undefined',
        },
        { // Materiaalikate (%)
            id: '21',
            key: 'materialMargin',
            label: headerTexts['materialMargin'],
            value: getIndicators('materialMargin') + ' %',
            secondValue: getIndicators('materialMarginPY') + ' %',
            estimate: getMaterialMarginEstimate(getIndicators('materialMargin'), getIndicators('materialMarginPY'), {
                good: 0,
                middle: 1,
                bad: 2,
            }),
            industry: formState.indicators[24]?.industry ?? '',
        },
        {
            key: 'undefined1',
            label: 'undefined1',
        },
        { // TyEL (%)
            id: '22',
            key: 'tyel',
            label: headerTexts['tyel'],
            value: getIndicators('tyel') + ' %',
            secondValue: getIndicators('tyelPY') + ' %',
            estimate: getEstimateTYEL(getIndicators('tyel'), getIndicators('tyelPY'), {
                good: 0,
                middle: 1,
                bad: 2,
            }),
            industry: formState.indicators[25]?.industry ?? '',
        },
        { // Sotu (%)
            id: '23',
            key: 'sotu',
            label: headerTexts['sotu'],
            value: getIndicators('sotu') + ' %',
            secondValue: getIndicators('sotuPY') + ' %',
            estimate: getEstimateTYEL(getIndicators('tyel'), getIndicators('tyelPY'), {
                good: 0,
                middle: 0.5,
                bad: -Infinity,
            }),
            industry: formState.indicators[26]?.industry ?? '',
        },
        {
            key: 'undefined2',
            label: 'undefined2',
        },
        { // Arvio yli 5.v päästä erääntyvistä lainoista
            id: '24',
            key: 'estimateFiveYears',
            label: headerTexts['estimateFiveYears'],
            value: getIndicators('estimateFiveYears') + ' €',
            secondValue: getIndicators('estimateFiveYearsPY') + ' €',
            industry: formState.indicators[27]?.industry ?? '',
        },
        {
            key: 'undefined3',
            label: 'undefined3',
        },
        { // Myyntisaamiset vs Liikevaihto
            id: '25',
            key: 'accountsReceivableVSturnOver',
            label: headerTexts['accountsReceivableVSturnOver'],
            value: getIndicators('accountsReceivableVSturnOver') + ' €',
            secondValue: getIndicators('accountsReceivableVSturnOverPY') + ' €',
            industry: formState.indicators[28]?.industry ?? '',
        },
        { // Ostovelat vs Ostot
            id: '26',
            key: 'accountsPayableVSpurchases',
            label: headerTexts['accountsPayableVSpurchases'],
            value: getIndicators('accountsPayableVSpurchases') + ' €',
            secondValue: getIndicators('accountsPayableVSpurchasesPY') + ' €',
            industry: formState.indicators[29]?.industry ?? '',
        },
        {// Osakepääoma yli 50.000
            id: '27',
            key: 'shareCapitalOver',
            label: headerTexts['shareCapitalOver'],
            value: getIndicators('shareCapitalOver') + ' €',
            secondValue: getIndicators('shareCapitalOverPY') + ' €',
            industry: formState.indicators[30]?.industry ?? '',
        },
        { // SVOP yli 50.000
            id: '28',
            key: 'svopOver',
            label: headerTexts['svopOver'],
            value: getIndicators('svopOver') + ' €',
            secondValue: getIndicators('svopOverPY') + ' €',
            industry: formState.indicators[31]?.industry ?? '',
        },
        {
            key: 'undefined4',
            label: 'undefined4',
        },
        { // Lomapalkkavelan suhde palkkoihin
            id: '29',
            key: 'ratioOfHoliday',
            label: headerTexts['ratioOfHoliday'],
            value: getIndicators('ratioOfHoliday'),
            secondValue: getIndicators('ratioOfHolidayPY'),
            estimate: getEstimateTYEL(getIndicators('tyel'), getIndicators('tyelPY'), {
                good: 12,
                middle: 1,
                bad: 2,
            }),
            industry: formState.indicators[32]?.industry ?? '',
        },
    ];

    // Push rows to the data but make it optimized
    const rowsFiltered = rows.filter((row) => !undefinedKeys.includes(row.key));
    const rowsMap = new Map(rowsFiltered.map((row) => [row.key, row]));
    const uniqueRows = Array.from(rowsMap.values());
    uniqueRows.forEach((row) => {
        if (!data.find((item) => item.key === row.key)) {
            data.push(row);
        }
    });

    function processRows(rows: TableItem[], undefinedKeys: string[], data: IndicatorsItem[]) {
        const rowsFiltered = rows.filter((row) => !undefinedKeys.includes(row.key));
        const rowsMap = new Map(rowsFiltered.map((row) => [row.key, row]));
        const uniqueRows = Array.from(rowsMap.values());
        uniqueRows.forEach((row) => {
            if (!data.find((item) => item.key === row.key)) {
                data.push(row);
            }
        });
        return data;
    }
    function pushDataToFormState() {
        const updatedData = processRows(rows, undefinedKeys, data);
        patchFormState({ ...formState, indicators: updatedData });
    }

    return (
      <>
        <Container>
            <div className="button-container">
                <Button 
                    text='Tallenna'
                    onClick={pushDataToFormState}
                />
            </div>
            <div className="additional-info">Otathan huomioon, kaikki ei saata tallentua heti.</div>
        </Container>
        <AuditingTable
          sectionKey={sectionKey}
          data={rows}
          columns={tableColumns}
          showGlobalFilter={false}
          disableSort
          //variant='default'
        />
      </>
    );
}