import { DateTime } from 'luxon';
import { action, computed, makeObservable, toJS } from 'mobx';
import { Api } from '../../api/RootApi';
import { t } from '../../i18n';
import { path } from '../../i18n/utils';
import {
  AuditingTemplate,
  formatDateTime,
  getPercent,
  round,
  today,
} from '../../utils';
import { generateAccountMap } from '../../views/auditingSections/analyzes/accountMapUtils';
import {
  FinancialStatementBatch,
  hasInExcel,
  hasPeriodOrTP,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';
import { AccountKeyScoping } from '../../views/auditingSections/planning/accountMapKeysScoping';
import {
  getAverageOfLiabilitiesAndRevenues,
  getCombinedRevenues,
} from '../../views/auditingSections/planning/materialityUtils';
import { LegalSituation } from '../../views/auditingSections/planning/permanentDataUtils';
import { REMARK_RULES } from '../../views/auditingSections/remarkRules';
import { BaseEntityStore } from '../BaseEntityStore';
import { RootStore } from '../RootStore';
export class AuditingStore extends BaseEntityStore<Auditing> {
  /**
   * Observables
   */

  constructor(rootStore: RootStore, api: Api) {
    super(rootStore, api, api.auditing, 'AUDITING');

    makeObservable(this, {
      auditing: computed,
      companyName: computed,
      auditingStatus: computed,
      auditingTemplate: computed,
      auditingLocked: computed,
      isAuditingSelected: computed,

      clearAuditing: action,

      resetSectionForm: action,
      setAuditingSectionStatus: action,

      getLinkToAuditingSection: action,
      getFinancialYear: action,
      getFinancialPeriodMonthCount: action,
      getFinancialPeriodMonthCountFromVacationPeriodStart: action,
      getFinancialItem: action,
      getFinancialNumber: action,
      getFinancialItemScoping: action,
      getFinancialNumberScoping: action,
      getAuditingSection: action,
      getAuditingSectionStatus: action,
      isAuditingSectionFinished: action,
      getAuditingStatusInformation: action,
      getAuditingSectionFormField: action,
      getAccountMap: action,
      getAccountMapScoping: action,
      getAuditingProceduresBatch: action,
      getAuditingRemarks: action,
      isMarkedAsRemark: action,
      fetchTaxRegisterInformation: action,

      getPlaceCVData: action,

      getIndicators: action,
      getIndicatorsItem: action,

      materialityComputeds: action,

      updateCommentField: action,
      getCommentFieldText: action,

      updateFileField: action,
      getFiles: action,
    });
  }

  /**
   * Computeds
   */

  get auditing() {
    return this.selectedEntity;
  }

  get companyName() {
    return this.selectedEntity?.customerCompany?.name ?? '';
  }

  get auditingStatus() {
    return this.selectedEntity?.status;
  }

  get auditingTemplate() {
    return this.selectedEntity?.template;
  }

  get auditingLocked() {
    const auditing = this.selectedEntity;
    return auditing && this.isFinishedStatus(auditing.status);
  }

  get isAuditingSelected() {
    return this.entity;
  }

  /**
   * Actions
   */
  updateFileField = async (
    sectionKey: AuditingSectionKey,
    files: File[],
    showUpdate: boolean
  ) => {
    const id = this.entity?.id;
    if (!id) return;

    const section = this.getAuditingSection(sectionKey);

    if (section) {
      section.form = section.form ?? {}; // Create an empty form object if it doesn't exist
      section.form.files = files;
    }

    const updatedForm = section?.form;

    const auditingPatch = {
      [sectionKey]: { ...section, form: updatedForm },
    };

    await this.editEntity({ id, data: auditingPatch }, { isSilent: !showUpdate, isBackground: true });
  };

  getFiles = (sectionKey: AuditingSectionKey) => {
    const section = this.getAuditingSection(sectionKey);
    return section?.form?.files ?? [];
  };

  updateCommentField = async (sectionKey: AuditingSectionKey, newText: string, showUpdate: boolean) => { 
    const id = this.entity?.id;
    if (!id) return;
  
    var section = this.getAuditingSection(sectionKey); 
    
    if (section) {
      section.form = section.form ?? {}; // Create an empty form object if it doesn't exist
      section.form.commentFieldText = newText;
    }
  
    const updatedForm = section?.form;
  
    const auditingPatch = {
      [sectionKey]: { ...section, form: updatedForm },
    };
  
    await this.editEntity({
      id, data: auditingPatch 
    }, { isSilent: !showUpdate, isBackground: true });
  }

  getCommentFieldText = async (sectionKey: AuditingSectionKey) => {
    var section = this.getAuditingSection(sectionKey);
    return section?.form.commentFieldText
  }

  clearAuditing = () => {
    this.entity = undefined;
  };

  resetSectionForm = async (sectionKey: AuditingSectionKey) => {
    const id = this.entity?.id;

    if (!id) return;

    const section = this.getAuditingSection(sectionKey);

    const auditingPatch = {
      [sectionKey]: { ...section, form: [], status: 'not_started' },
    };

    await this.editEntity({ id, data: auditingPatch }, { isSilent: true });
  };

  setAuditingSectionStatus = (
    sectionKey: AuditingSectionKey,
    status: AuditingSectionStatus
  ) => {
    const id = this.entity?.id;
    if (!id) return;

    const userId = this.rootStore.authStore.userId;

    const section = this.getAuditingSection(sectionKey);
    const isFinished = status === 'finished';
    const isApproved = status === 'approved';

    const auditingPatch = {
      [sectionKey]: {
        ...section,
        updatedAt: today(),
        updatedBy: userId,
        finishedAt: isFinished ? today() : section?.finishedAt,
        finishedBy: isFinished ? userId : section?.finishedBy,
        approvedAt: isApproved ? today() : section?.approvedAt,
        approvedBy: isApproved ? userId : section?.approvedBy,
        status,
      },
    };
    this.editEntity({ id, data: auditingPatch }, { isBackground: true });
  };

  /**
   * Getter actions
   */

  getLinkToAuditingSection = (props: {
    auditingId?: number;
    sectionKey: AuditingSectionKey;
  }) => {
    const id = props.auditingId ?? this.selectedEntity?.id;
    if (id) {
      const sectionPath = path(`auditing.${props.sectionKey}`);
      return `/${path('auditing')}/${id}/${sectionPath}`;
    }
    return `/${path('auditing')}`;
  };

  getFinancialYear = (auditing?: Auditing) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return '';

    const format = 'D';
    const startDate = formatDateTime(entity.startDate, { format });
    const endDate = formatDateTime(entity.endDate, { format });
    return `${startDate}-${endDate}`;
  };

  /**
   * Function to set all riskArrays (statementKeys) to true
   * riskArray = ['tap', 'tay', 'oik', 'arv', 'luo', 'esi', 'ole', 'ove'];
   */
  setAllRiskArraysToTrue = async () => {
    const id = this.entity?.id;
    if (!id) return;

    const auditing = this.getAuditingSection('incomeStatementAndBalance');
    const incomeStatementAndBalance = auditing?.form;

    if (!incomeStatementAndBalance) return;

    const incomeStatement = incomeStatementAndBalance.incomeStatement;
    const balanceAssets = incomeStatementAndBalance.balanceAssets;
    const balanceLiabilities = incomeStatementAndBalance.balanceLiabilities;

    if (incomeStatement) {
      for (let i = 0; i < incomeStatement.length; i++) {
        incomeStatement[i].tap = true;
        incomeStatement[i].tay = true;
        incomeStatement[i].oik = true;
        incomeStatement[i].arv = true;
        incomeStatement[i].luo = true;
        incomeStatement[i].esi = true;
        incomeStatement[i].ole = true;
        incomeStatement[i].ove = true;
      }
    }
    if (balanceAssets) {
      for (let i = 0; i < balanceAssets.length; i++) {
        balanceAssets[i].tap = true;
        balanceAssets[i].tay = true;
        balanceAssets[i].oik = true;
        balanceAssets[i].arv = true;
        balanceAssets[i].luo = true;
        balanceAssets[i].esi = true;
        balanceAssets[i].ole = true;
        balanceAssets[i].ove = true;
      }
    }
    if (balanceLiabilities) {
      for (let i = 0; i < balanceLiabilities.length; i++) {
        balanceLiabilities[i].tap = true;
        balanceLiabilities[i].tay = true;
        balanceLiabilities[i].oik = true;
        balanceLiabilities[i].arv = true;
        balanceLiabilities[i].luo = true;
        balanceLiabilities[i].esi = true;
        balanceLiabilities[i].ole = true;
        balanceLiabilities[i].ove = true;
      }
    }

    // Create the patch
    const auditingPatch = {
      incomeStatementAndBalance: { ...auditing, form: incomeStatementAndBalance },
    };

    // Update the auditing
    await this.editEntity({ id, data: auditingPatch }, { isSilent: false });

    // Refresh the page
    window.location.reload();
  };

  setAllRiskArraysScopingToTrue = async () => {
    const id = this.entity?.id;
    if (!id) return;

    const auditing = this.getAuditingSection('incomeStatementAndBalanceScoping');
    const incomeStatementAndBalanceScoping = auditing?.form;

    if (!incomeStatementAndBalanceScoping) return;

    const incomeStatement = incomeStatementAndBalanceScoping.incomeStatement;
    const balanceAssets = incomeStatementAndBalanceScoping.balanceAssets;
    const balanceLiabilities = incomeStatementAndBalanceScoping.balanceLiabilities;

    if (incomeStatement) {
      for (let i = 0; i < incomeStatement.length; i++) {
        incomeStatement[i].tap = true;
        incomeStatement[i].tay = true;
        incomeStatement[i].oik = true;
        incomeStatement[i].arv = true;
        incomeStatement[i].luo = true;
        incomeStatement[i].esi = true;
        incomeStatement[i].ole = true;
        incomeStatement[i].ove = true;
      }
    }
    if (balanceAssets) {
      for (let i = 0; i < balanceAssets.length; i++) {
        balanceAssets[i].tap = true;
        balanceAssets[i].tay = true;
        balanceAssets[i].oik = true;
        balanceAssets[i].arv = true;
        balanceAssets[i].luo = true;
        balanceAssets[i].esi = true;
        balanceAssets[i].ole = true;
        balanceAssets[i].ove = true;
      }
    }
    if (balanceLiabilities) {
      for (let i = 0; i < balanceLiabilities.length; i++) {
        balanceLiabilities[i].tap = true;
        balanceLiabilities[i].tay = true;
        balanceLiabilities[i].oik = true;
        balanceLiabilities[i].arv = true;
        balanceLiabilities[i].luo = true;
        balanceLiabilities[i].esi = true;
        balanceLiabilities[i].ole = true;
        balanceLiabilities[i].ove = true;
      }
    }

    // Create the patch
    const auditingPatch = {
      incomeStatementAndBalanceScoping: { ...auditing, form: incomeStatementAndBalanceScoping },
    };

    // Update the auditing
    await this.editEntity({ id, data: auditingPatch }, { isSilent: false });

    // Refresh the page
    window.location.reload();
  };

  /**
   * Function to copy 'data' from 'accountMap' to 'accountMapScoping'
   * @returns void
  */
 /**
  getAccountMap = (): AccountMap =>
    this.selectedEntity?.accountMap?.form.accountMap ??
    generateAccountMap(this.auditingTemplate ?? AuditingTemplate.private);

  getAccountMapScoping = () : AccountMapScoping =>
    this.selectedEntity?.accountMapScoping?.form.accountMapScoping ??
    generateAccountMap(this.auditingTemplate ?? AuditingTemplate.private);
  */
  copyAccountMapToScoping = async () => {
    const id = this.entity?.id;
    if (!id) return;
  
    const accountMapSection = this.getAccountMap();
    const accountMapScopingSection = this.getAccountMapScoping();

    console.log('accountMapSection', accountMapSection);
    console.log('accountMapScopingSection', accountMapScopingSection);
  
    if (!accountMapSection || !accountMapScopingSection) return;

    // Copy the accountMap to accountMapScoping
    const auditingPatch = {
      accountMapScoping: { 
        ...accountMapScopingSection, 
        form: { 
          accountMapScoping: accountMapSection 
        } 
      },
    };

    // Update the auditing
    await this.editEntity({ id, data: auditingPatch }, { isSilent: false });

    // Refresh the page after 5 secs
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  /*
  * Function to copy 'risk' data from 'incomeStatementAndBalance' to 'incomeStatementAndBalanceScoping'
  */
  copyIncomeStatementAndBalanceToScoping = async () => {
    // Get the id of the auditing
    const id = this.entity?.id;
  
    // If there is no auditing, return
    if (!id) return;
  
    // Get the incomeStatementAndBalance and incomeStatementAndBalanceScoping sections
    const incomeStatementAndBalance = this.getAuditingSection('incomeStatementAndBalance');
    const incomeStatementAndBalanceScoping = this.getAuditingSection('incomeStatementAndBalanceScoping');
  
    // If there is no incomeStatementAndBalance or incomeStatementAndBalanceScoping, return
    if (!incomeStatementAndBalance || !incomeStatementAndBalanceScoping) return;

      const riskArray = ['tap', 'tay', 'oik', 'arv', 'luo', 'esi', 'ole', 'ove'/* , 'statementFraud', 'statementEstimate' */];

    if (incomeStatementAndBalance.form?.incomeStatement) {
      for (let i = 0; i < incomeStatementAndBalance.form.incomeStatement.length; i++) {
        for (let j = 0; j < incomeStatementAndBalanceScoping.form.incomeStatement.length; j++) {
          if (incomeStatementAndBalanceScoping.form.incomeStatement[j].key === incomeStatementAndBalance.form.incomeStatement[i].key) {
            incomeStatementAndBalanceScoping.form.incomeStatement[j].risk = incomeStatementAndBalance.form.incomeStatement[i].risk;
            for (let k = 0; k < riskArray.length; k++) {
              // eslint-disable-next-line
              incomeStatementAndBalanceScoping.form.incomeStatement[j][riskArray[k]] = incomeStatementAndBalance.form.incomeStatement[i][riskArray[k]];
            }
          }
        }
      }
    }
    // eslint-disable-next-line
    if (incomeStatementAndBalance.form?.balanceAssets) {
      for (let i = 0; i < incomeStatementAndBalance.form.balanceAssets.length; i++) {
        for (let j = 0; j < incomeStatementAndBalanceScoping.form.balanceAssets.length; j++) {
          if (incomeStatementAndBalanceScoping.form.balanceAssets[j].key === incomeStatementAndBalance.form.balanceAssets[i].key) {
            // eslint-disable-next-line
            incomeStatementAndBalanceScoping.form.balanceAssets[j].risk = incomeStatementAndBalance.form.balanceAssets[i].risk;
            for (let k = 0; k < riskArray.length; k++) {
              // eslint-disable-next-line
              incomeStatementAndBalanceScoping.form.balanceAssets[j][riskArray[k]] = incomeStatementAndBalance.form.balanceAssets[i][riskArray[k]];
            }
          }
        }
      }
    }
    if (incomeStatementAndBalance.form?.balanceLiabilities) {
      for (let i = 0; i < incomeStatementAndBalance.form.balanceLiabilities.length; i++) {
        for (let j = 0; j < incomeStatementAndBalanceScoping.form.balanceLiabilities.length; j++) {
          if (incomeStatementAndBalanceScoping.form.balanceLiabilities[j].key === incomeStatementAndBalance.form.balanceLiabilities[i].key) {
            // eslint-disable-next-line
            incomeStatementAndBalanceScoping.form.balanceLiabilities[j].risk = incomeStatementAndBalance.form.balanceLiabilities[i].risk;
            for (let k = 0; k < riskArray.length; k++) {
              // eslint-disable-next-line
              incomeStatementAndBalanceScoping.form.balanceLiabilities[j][riskArray[k]] = incomeStatementAndBalance.form.balanceLiabilities[i][riskArray[k]];
            }
          }
        }
      }
    }
  
    // Create the patch
    const auditingPatch = {
      // Copy the incomeStatementAndBalanceScoping section and update the form
      incomeStatementAndBalanceScoping: { ...incomeStatementAndBalanceScoping, form: incomeStatementAndBalanceScoping!.form },
    };
  
    // Update the auditing
    await this.editEntity({ id, data: auditingPatch }, { isSilent: false });
    
    // Refresh the page after the update (5 seconds)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  getFinancialPeriodMonthCount = (auditing?: Auditing) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return null;

    const startDate = DateTime.fromISO(entity.startDate);
    const endDate = DateTime.fromISO(entity.endDate);
    return round(endDate.diff(startDate, 'months').months, 1);
  };

  getFinancialPeriodMonthCountFromVacationPeriodStart = (
    auditing?: Auditing
  ) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return null;

    const vacationPeriodStart = { day: 1, month: 4 };

    const startDate = DateTime.fromISO(entity.startDate).set(
      vacationPeriodStart
    );
    const endDate = DateTime.fromISO(entity.endDate);
    return round(endDate.diff(startDate, 'months').months, 1);
  };

  // Used for Indicators
  getIndicatorsItem = (key: AccountKeyScoping) => {
    const form = this.entity?.incomeStatementAndBalanceScoping?.form;
    const incomeStatement = form?.incomeStatement;
    const balanceAssets = form?.balanceAssets;
    const balanceLiabilities = form?.balanceLiabilities;
    return (
      incomeStatement?.find(item => item.key === key) ??
      balanceAssets?.find(item => item.key === key) ??
      balanceLiabilities?.find(item => item.key === key)
    );
  };
  getIndicators = (
    key: AccountKeyScoping | null | any,
    value: 'currentYear' | 'priorYear' = 'currentYear'
  ) => {
    if (!key) return;

    let number: any = undefined;

    // Get CY and PY values
    const [currentYear, priorYear] = [
      this.getIndicatorsItem(key)?.[value],
      this.getIndicatorsItem(key)?.priorYear,
    ];

    // financialResult
    const [operatingChangeResult, depreciationAndAmortizationResult, financialIncomeResult, financialExpensesResult, incomeTaxesResult, otherTaxesResult,
      operatingChangeResultPY, depreciationAndAmortizationResultPY, financialIncomeResultPY, financialExpensesResultPY, incomeTaxesResultPY, otherTaxesResultPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.depreciationAndAmortization)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.financialIncome)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.otherTaxes)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.depreciationAndAmortization)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.financialIncome)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.otherTaxes)?.priorYear,
    ];
    let calculatedFinancialResult = (operatingChangeResult ?? 0) + (depreciationAndAmortizationResult ?? 0) + (financialIncomeResult ?? 0) - (financialExpensesResult ?? 0) - (incomeTaxesResult ?? 0) - (otherTaxesResult ?? 0);
    let calculatedFinancialResultPY = (operatingChangeResultPY ?? 0) + (depreciationAndAmortizationResultPY ?? 0) + (financialIncomeResultPY ?? 0) - (financialExpensesResultPY ?? 0) - (incomeTaxesResultPY ?? 0) - (otherTaxesResultPY ?? 0);

    // netResult
    const [operatingChange, financialIncome, financialExpenses, incomeTaxes, otherTaxes,
      operatingChangePY, financialIncomePY, financialExpensesPY, incomeTaxesPY, otherTaxesPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.financialIncome)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.otherTaxes)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.financialIncome)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.otherTaxes)?.priorYear,
    ];
    let calculatedNetResult = (operatingChange ?? 0) + (financialIncome ?? 0) - (financialExpenses ?? 0) - (incomeTaxes ?? 0) - (otherTaxes ?? 0);
    let calculatedNetResultPY = (operatingChangePY ?? 0) + (financialIncomePY ?? 0) - (financialExpensesPY ?? 0) - (incomeTaxesPY ?? 0) - (otherTaxesPY ?? 0);

    // adjustedEquity
    const [equity, appropriations, equityPY, appropriationsPY] = [
      this.getIndicatorsItem(AccountKeyScoping.equity)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.appropriations)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.equity)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.appropriations)?.priorYear,
    ];
    let calculatedAdjustedEquity = (equity ?? 0) + (appropriations ?? 0);
    let calculatedAdjustedEquityPY = (equityPY ?? 0) + (appropriationsPY ?? 0);

    // interestDebts
    const [longTermCreditors, longTermCreditors_amountsOwedToGroupMemberCompanies, longTermCreditors_pensionLoans, longTermCreditors_advancesReceived, longTermCreditors_accrualsAndDeferredIncome, shortTermCreditors, shortTermCreditors_tradeCreditors, shortTermCreditors_accrualsAndDeferredIncome,
      longTermCreditorsPY, longTermCreditors_amountsOwedToGroupMemberCompaniesPY, longTermCreditors_pensionLoansPY, longTermCreditors_advancesReceivedPY, longTermCreditors_accrualsAndDeferredIncomePY, shortTermCreditorsPY, shortTermCreditors_tradeCreditorsPY, shortTermCreditors_accrualsAndDeferredIncomePY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_amountsOwedToGroupMemberCompanies)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_pensionLoans)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_accrualsAndDeferredIncome)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_tradeCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_accrualsAndDeferredIncome)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_amountsOwedToGroupMemberCompanies)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_pensionLoans)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_accrualsAndDeferredIncome)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_tradeCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_accrualsAndDeferredIncome)?.priorYear,
    ];
    let calculatedInterestDebts = (longTermCreditors ?? 0) - (longTermCreditors_amountsOwedToGroupMemberCompanies ?? 0) - (longTermCreditors_pensionLoans ?? 0) - (longTermCreditors_advancesReceived ?? 0) - (longTermCreditors_accrualsAndDeferredIncome ?? 0) + (shortTermCreditors ?? 0) - (shortTermCreditors_tradeCreditors ?? 0) - (shortTermCreditors_accrualsAndDeferredIncome ?? 0);
    let calculatedInterestDebtsPY = (longTermCreditorsPY ?? 0) - (longTermCreditors_amountsOwedToGroupMemberCompaniesPY ?? 0) - (longTermCreditors_pensionLoansPY ?? 0) - (longTermCreditors_advancesReceivedPY ?? 0) - (longTermCreditors_accrualsAndDeferredIncomePY ?? 0) + (shortTermCreditorsPY ?? 0) - (shortTermCreditors_tradeCreditorsPY ?? 0) - (shortTermCreditors_accrualsAndDeferredIncomePY ?? 0);

    // relativeIndebtedness
    const [relativeIndebtedness] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.currentYear,
    ];
    let calculatedRelativeIndebtedness = (relativeIndebtedness ?? 0);
    // relativeIndebtednessPY
    const [relativeIndebtednessPY] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.priorYear,
    ];
    let calculatedRelativeIndebtednessPY = (relativeIndebtednessPY ?? 0);

    // workingCapital (stocks + (longTerm_tradeReceivables) - (longTermCreditors_tradeCreditors) - (longTermCreditors_advancesReceived))
    const [stocksCapital, longTerm_tradeReceivablesCapital, longTermCreditors_tradeCreditorsCapital, longTermCreditors_advancesReceivedCapital,
      stocksCapitalPY, longTerm_tradeReceivablesCapitalPY, longTermCreditors_tradeCreditorsCapitalPY, longTermCreditors_advancesReceivedCapitalPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.stocks)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTerm_tradeReceivables)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.stocks)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTerm_tradeReceivables)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.priorYear,
    ];
    let calculatedWorkingCapital = (stocksCapital ?? 0) + (longTerm_tradeReceivablesCapital ?? 0) - (longTermCreditors_tradeCreditorsCapital ?? 0) - (longTermCreditors_advancesReceivedCapital ?? 0);
    let calculatedWorkingCapitalPY = (stocksCapitalPY ?? 0) + (longTerm_tradeReceivablesCapitalPY ?? 0) - (longTermCreditors_tradeCreditorsCapitalPY ?? 0) - (longTermCreditors_advancesReceivedCapitalPY ?? 0);

    // currentRatio ((stocks + shortTermReceivables + currentAssets_investments + cashInHandAndAtBanks) / shortTermCreditors)
    const [stocksRatio, shortTermReceivablesRatio, currentAssets_investmentsRatio, cashInHandAndAtBanksRatio, shortTermCreditorsRatio,
      stocksRatioPY, shortTermReceivablesRatioPY, currentAssets_investmentsRatioPY, cashInHandAndAtBanksRatioPY, shortTermCreditorsRatioPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.stocks)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermReceivables)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.currentAssets_investments)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.cashInHandAndAtBanks)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.stocks)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermReceivables)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.currentAssets_investments)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.cashInHandAndAtBanks)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.priorYear,
    ];
    let calculatedCurrentRatio = ((stocksRatio ?? 0) + (shortTermReceivablesRatio ?? 0) + (currentAssets_investmentsRatio ?? 0) + (cashInHandAndAtBanksRatio ?? 0)) / (shortTermCreditorsRatio ?? 0);
    let calculatedCurrentRatioPY = ((stocksRatioPY ?? 0) + (shortTermReceivablesRatioPY ?? 0) + (currentAssets_investmentsRatioPY ?? 0) + (cashInHandAndAtBanksRatioPY ?? 0)) / (shortTermCreditorsRatioPY ?? 0);

    // quickRatio (shortTermReceivables + currentAssets_investments + cashInHandAndAtBanks) / (shortTermCreditors - shortTermCreditors_advancesReceived)
    const [shortTermReceivablesQuick, currentAssets_investmentsQuick, cashInHandAndAtBanksQuick, shortTermCreditorsQuick, shortTermCreditors_advancesReceivedQuick,
      shortTermReceivablesQuickPY, currentAssets_investmentsQuickPY, cashInHandAndAtBanksQuickPY, shortTermCreditorsQuickPY, shortTermCreditors_advancesReceivedQuickPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.shortTermReceivables)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.currentAssets_investments)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.cashInHandAndAtBanks)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_advancesReceived)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermReceivables)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.currentAssets_investments)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.cashInHandAndAtBanks)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_advancesReceived)?.priorYear,
    ];
    let calculatedQuickRatio = ((shortTermReceivablesQuick ?? 0) + (currentAssets_investmentsQuick ?? 0) + (cashInHandAndAtBanksQuick ?? 0)) / ((shortTermCreditorsQuick ?? 0) - (shortTermCreditors_advancesReceivedQuick ?? 0));
    let calculatedQuickRatioPY = ((shortTermReceivablesQuickPY ?? 0) + (currentAssets_investmentsQuickPY ?? 0) + (cashInHandAndAtBanksQuickPY ?? 0)) / ((shortTermCreditorsQuickPY ?? 0) - (shortTermCreditors_advancesReceivedQuickPY ?? 0));

    // accountsReceivableCycleTime ((365 * longTerm_tradeReceivables) / turnover)
    const [longTerm_tradeReceivablesCycleTime, turnoverCycleTime, longTerm_tradeReceivablesCycleTimePY, turnoverCycleTimePY] = [
      this.getIndicatorsItem(AccountKeyScoping.longTerm_tradeReceivables)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTerm_tradeReceivables)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
    ];
    let calculatedAccountsReceivableCycleTime = (365 * (longTerm_tradeReceivablesCycleTime ?? 0)) / (turnoverCycleTime ?? 0);
    let calculatedAccountsReceivableCycleTimePY = (365 * (longTerm_tradeReceivablesCycleTimePY ?? 0)) / (turnoverCycleTimePY ?? 0);

    // accountsPayableCycleTime ((365 * longTermCreditors_tradeCreditors) / purchases + externalServices)
    const [longTermCreditors_tradeCreditorsCycleTime, purchasesCycleTime, externalServicesCycleTime,
      longTermCreditors_tradeCreditorsCycleTimePY, purchasesCycleTimePY, externalServicesCycleTimePY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.externalServices)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.externalServices)?.priorYear,
    ];
    let calculatedAccountsPayableCycleTime = (365 * (longTermCreditors_tradeCreditorsCycleTime ?? 0)) / ((purchasesCycleTime ?? 0) + (externalServicesCycleTime ?? 0));
    let calculatedAccountsPayableCycleTimePY = (365 * (longTermCreditors_tradeCreditorsCycleTimePY ?? 0)) / ((purchasesCycleTimePY ?? 0) + (externalServicesCycleTimePY ?? 0));

    // materialMargin (liikevaihto + varaston muutos + valmistus omaan käyttöön - materiaalit ja palvelut - henkilöstökulut) / liikevaihto * 100
    const [turnoverMaterialMargin, stockChangeMaterialMargin, manufacturingForOwnUseMaterialMargin, materialsAndServicesMaterialMargin, personnelCostsMaterialMargin,
      turnoverMaterialMarginPY, stockChangeMaterialMarginPY, manufacturingForOwnUseMaterialMarginPY, materialsAndServicesMaterialMarginPY, personnelCostsMaterialMarginPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.stocksChange)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.manufactureForOwnUse)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.materialsAndServices)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.personelCosts)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.stocksChange)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.manufactureForOwnUse)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.materialsAndServices)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.personelCosts)?.priorYear,
    ];
    let calculatedMaterialMargin = ((turnoverMaterialMargin ?? 0) + (stockChangeMaterialMargin ?? 0) + (manufacturingForOwnUseMaterialMargin ?? 0) - (materialsAndServicesMaterialMargin ?? 0) - (personnelCostsMaterialMargin ?? 0)) / (turnoverMaterialMargin ?? 0) * 100;
    let calculatedMaterialMarginPY = ((turnoverMaterialMarginPY ?? 0) + (stockChangeMaterialMarginPY ?? 0) + (manufacturingForOwnUseMaterialMarginPY ?? 0) - (materialsAndServicesMaterialMarginPY ?? 0) - (personnelCostsMaterialMarginPY ?? 0)) / (turnoverMaterialMarginPY ?? 0) * 100;

    // estimateFiveYears (longTermCreditors_loansFromCreditInstitutions + shortTermCreditors_loansFromCreditInstitutions) –(5 x shortTermCreditors_loansFromCreditInstitutions)
    const [longTermCreditors_loansFromCreditInstitutions, shortTermCreditors_loansFromCreditInstitutions,
      longTermCreditors_loansFromCreditInstitutionsPY, shortTermCreditors_loansFromCreditInstitutionsPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_loansFromCreditInstitutions)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_loansFromCreditInstitutions)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_loansFromCreditInstitutions)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_loansFromCreditInstitutions)?.priorYear,
    ];
    let calculatedEstimateFiveYears = (longTermCreditors_loansFromCreditInstitutions ?? 0) + (shortTermCreditors_loansFromCreditInstitutions ?? 0) - (5 * (shortTermCreditors_loansFromCreditInstitutions ?? 0));
    let calculatedEstimateFiveYearsPY = (longTermCreditors_loansFromCreditInstitutionsPY ?? 0) + (shortTermCreditors_loansFromCreditInstitutionsPY ?? 0) - (5 * (shortTermCreditors_loansFromCreditInstitutionsPY ?? 0));

    // accountsReceivableVSturnOver (longTerm_tradeCreditors / purchases)
    const [longTerm_tradeCreditorsVSpurchases, purchasesVSpurchases, longTerm_tradeCreditorsVSpurchasesPY, purchasesVSpurchasesPY] = [
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_tradeCreditors)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.priorYear,
    ];
    let calculatedAccountsReceivableVSturnOver = (longTerm_tradeCreditorsVSpurchases ?? 0) / (purchasesVSpurchases ?? 0);
    let calculatedAccountsReceivableVSturnOverPY = (longTerm_tradeCreditorsVSpurchasesPY ?? 0) / (purchasesVSpurchasesPY ?? 0);

    // accountsPayableVSpurchases (ostovelat/ (materiaalit ja palveut + muut kulut))
    const [
      purchasesaccountsPayableVSpurchases, materialsAndServicesaccountsPayableVSpurchases, otherCostsaccountsPayableVSpurchases,
      purchasesaccountsPayableVSpurchasesPY, materialsAndServicesaccountsPayableVSpurchasesPY, otherCostsaccountsPayableVSpurchasesPY
    ] = [
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.materialsAndServices)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.otherExpenses)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.purchases)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.materialsAndServices)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.otherExpenses)?.priorYear,
    ];
    let accountsPayableVSpurchases = (purchasesaccountsPayableVSpurchases ?? 0) / ((materialsAndServicesaccountsPayableVSpurchases ?? 0) + (otherCostsaccountsPayableVSpurchases ?? 0));
    let accountsPayableVSpurchasesPY = (purchasesaccountsPayableVSpurchasesPY ?? 0) / ((materialsAndServicesaccountsPayableVSpurchasesPY ?? 0) + (otherCostsaccountsPayableVSpurchasesPY ?? 0));

    // ratioOfHoliday (lomapalkkavelka / palkat)
    const [holidayPayRatio, salariesRatio, holidayPayRatioPY, salariesRatioPY] = [
      this.getIndicatorsItem(AccountKeyScoping.periodizationOfHolidayPay)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.wagesAndSalaries)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.periodizationOfHolidayPay)?.priorYear,
      this.getIndicatorsItem(AccountKeyScoping.wagesAndSalaries)?.priorYear,
    ];
    let calculatedRatioOfHoliday = (holidayPayRatio ?? 0) / (salariesRatio ?? 0);
    let calculatedRatioOfHolidayPY = (holidayPayRatioPY ?? 0) / (salariesRatioPY ?? 0);

    // TYEL
    // SOTU
    // shareCapitalOver
    const [subscribedEquity, subscribedEquityPY] = [
      this.getIndicatorsItem(AccountKeyScoping.subscribedEquity)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.subscribedEquity)?.priorYear,
    ];
    let calculatedShareCapitalOver = (subscribedEquity ?? 0);
    let calculatedShareCapitalOverPY = (subscribedEquityPY ?? 0);
    // svopOver
    const [svop, svopPY] = [
      this.getIndicatorsItem(AccountKeyScoping.svop)?.currentYear,
      this.getIndicatorsItem(AccountKeyScoping.svop)?.priorYear,
    ];
    let calculatedSvopOver = (svop ?? 0);
    let calculatedSvopOverPY = (svopPY ?? 0);

    if (key === AccountKeyScoping.turnover) {
        const [turnover] = [
          this.getIndicatorsItem(AccountKeyScoping.turnover)?.[value],
        ];
      //
      if (turnover !== undefined)
        number = (((currentYear ?? 0) - (priorYear ?? 0)) / (priorYear ?? 1)) * 100;
    }
    // Liiketulos (teur)
    else if (key === 'operatingChange') {
      const [operatingChange] = [
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.currentYear,
      ];
      if (operatingChange !== undefined)
        number = operatingChange;
    }
    else if (key === 'operatingChangePY') {
      const [operatingChangePY] = [
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.priorYear,
      ];
      if (operatingChangePY !== undefined)
        number = operatingChangePY;
    }
    // // Liiketuloksen muutos (%)
    else if (key === 'operatingProfitChange') {
      const [operatingChange, operatingChangePY] = [
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.priorYear,
      ];
      if (operatingChange !== undefined && operatingChangePY !== undefined)
        number = ((operatingChange - operatingChangePY) / operatingChangePY) * 100;
    }
    // Liiketulos (%) (operatingChange / turnover * 100)
    else if (key === 'operatingPercent') {
      const [operatingChange, turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      ];
      if (operatingChange !== undefined && turnover !== undefined)
        number = operatingChange / turnover * 100;
    }
    else if (key === 'operatingPercentPY') {
      const [operatingChangePY, turnoverPY] = [
        this.getIndicatorsItem(AccountKeyScoping.operatingProfitOrLoss)?.priorYear,
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      ];
      if (operatingChangePY !== undefined && turnoverPY !== undefined)
        number = operatingChangePY / turnoverPY * 100;
    }
    // Rahoitustulos (teur) (operatingChange+depreciationAndAmortization+financialIncome-financialExpenses-incomeTaxes-otherTaxes)
    else if (key === 'financialResult') {
      number = calculatedFinancialResult;
    }
    else if (key === 'financialResultPY') {
      number = calculatedFinancialResultPY;
    }
    // Rahoitustulos (%) (financialResult / turnover * 100)
    else if (key === 'financialResultPercent') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      ];
      if (calculatedFinancialResult && turnover !== undefined)
        number = calculatedFinancialResult / turnover * 100;
      else number = 0;
    }
    else if (key === 'financialResultPercentPY') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      ];
      if (calculatedFinancialResultPY && turnover !== undefined)
        number = calculatedFinancialResultPY / turnover * 100;
      else number = 0;
    }
    // Nettotulos (teur) (operatingChange+financialIncome-financialExpenses-incomeTaxes-otherTaxes)
    else if (key === 'netResult') {
      number = calculatedNetResult;
    }
    else if (key === 'netResultPY') {
      number = calculatedNetResultPY;
    }
    // Nettotulos (%) (netResult / turnover * 100)
    else if (key === 'netPercent') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      ];
      if (calculatedNetResult && turnover !== undefined)
        number = calculatedNetResult / turnover * 100;
      else number = 0;
    }
    else if (key === 'netPercentPY') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      ];
      if (calculatedNetResultPY && turnover !== undefined)
        number = calculatedNetResultPY / turnover * 100;
      else number = 0;
    }

    // Sijoitetun pääoman tuottoprosentti ((nettotulos + financialExpenses + taxRevenues) [12kk] / (omat varat + korolliset velat))
    else if (key === 'investedCapitalPercent') {
      const [financialExpenses, taxRevenues] = [
        this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.currentYear,
      ];
      if (calculatedNetResult && financialExpenses !== undefined && taxRevenues !== undefined && calculatedAdjustedEquity && calculatedInterestDebts)
        number = (calculatedNetResult + financialExpenses + taxRevenues) * 12 / (calculatedAdjustedEquity + calculatedInterestDebts) * 100;
      else number = 0;
    }
    else if (key === 'investedCapitalPercentPY') {
      const [financialExpensesPY, taxRevenuesPY] = [
        this.getIndicatorsItem(AccountKeyScoping.financialExpenses)?.priorYear,
        this.getIndicatorsItem(AccountKeyScoping.incomeTaxes)?.priorYear,
      ];
      if (calculatedNetResultPY && financialExpensesPY !== undefined && taxRevenuesPY !== undefined && calculatedAdjustedEquityPY && calculatedInterestDebtsPY)
        number = (calculatedNetResultPY + financialExpensesPY + taxRevenuesPY) * 12 / (calculatedAdjustedEquityPY + calculatedInterestDebtsPY) * 100;
      else number = 0;
    }
    // Oman pääoman tuottoprosentti (nettotulos [12kk] / omat varat)
    else if (key === 'equityPercent') {
      if (calculatedNetResult && calculatedAdjustedEquity)
        number = (calculatedNetResult * 12 / calculatedAdjustedEquity) * 100;
      else number = 0;
    }
    else if (key === 'equityPercentPY') {
      if (calculatedNetResultPY && calculatedAdjustedEquityPY)
        number = (calculatedNetResultPY * 12 / calculatedAdjustedEquityPY) * 100;
      else number = 0;
    }

    // Oikaistu oma pääoma (omat varat) (equity+(appropriations*1))
    else if (key === 'adjustedEquity') {
      number = calculatedAdjustedEquity;
    }
    else if (key === 'adjustedEquityPY') {
      number = calculatedAdjustedEquityPY;
    }
    // Korolliset velat (sijoitettu vieras pääoma) (longTermCreditors-longTermCreditors_amountsOwedToGroupMemberCompanies-longTermCreditors_pensionLoans-longTermCreditors_advancesReceived-longTermCreditors_accrualsAndDeferredIncome+shortTermCreditors-shortTermCreditors_tradeCreditors-shortTermCreditors_accrualsAndDeferredIncome)
    else if (key === 'interestDebts') {
      number = calculatedInterestDebts;
    }
    else if (key === 'interestDebtsPY') {
      number = calculatedInterestDebtsPY;
    }
    // Omavaraisuusaste (%) (omat varat / (omat varat + pakolliset varaukset + vieras pääoma - pitkäaikaiset ja lyhytaikaiset saadut ennakot))
    else if (key === 'selfRatePercent') {
      const [provisions, creditors, longTermCreditors_advancesReceived, shortTermCreditors_advancesReceived] = [
        this.getIndicatorsItem(AccountKeyScoping.provisions)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.creditors)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.currentYear,
        this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_advancesReceived)?.currentYear,
      ];
      if (calculatedAdjustedEquity && provisions !== undefined && creditors !== undefined && longTermCreditors_advancesReceived !== undefined && shortTermCreditors_advancesReceived !== undefined)
        number = calculatedAdjustedEquity / (calculatedAdjustedEquity + provisions + creditors - longTermCreditors_advancesReceived - shortTermCreditors_advancesReceived) * 100;
      else number = 0;
    }
    else if (key === 'selfRatePercentPY') {
      const [provisionsPY, creditorsPY, longTermCreditors_advancesReceivedPY, shortTermCreditors_advancesReceivedPY] = [
        this.getIndicatorsItem(AccountKeyScoping.provisions)?.priorYear,
        this.getIndicatorsItem(AccountKeyScoping.creditors)?.priorYear,
        this.getIndicatorsItem(AccountKeyScoping.longTermCreditors_advancesReceived)?.priorYear,
        this.getIndicatorsItem(AccountKeyScoping.shortTermCreditors_advancesReceived)?.priorYear,
      ];
      if (calculatedAdjustedEquityPY && provisionsPY !== undefined && creditorsPY !== undefined && longTermCreditors_advancesReceivedPY !== undefined && shortTermCreditors_advancesReceivedPY !== undefined)
        number = calculatedAdjustedEquityPY / (calculatedAdjustedEquityPY + provisionsPY + creditorsPY - longTermCreditors_advancesReceivedPY - shortTermCreditors_advancesReceivedPY) * 100;
      else number = 0;
    }
    // Suhteellinen velkaantuneisuus (%) ((longTermCreditors_tradeCreditors-longTermCreditors_advancesReceived)/turnover * 100)
    else if (key === 'relativeIndebtedness') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      ];
      if (calculatedRelativeIndebtedness && turnover !== undefined)
        number = (calculatedRelativeIndebtedness / turnover) * 100;
      else number = 0;
    }
    else if (key === 'relativeIndebtednessPY') {
      const [turnoverPY] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      ];
      if (calculatedRelativeIndebtednessPY && turnoverPY !== undefined)
        number = (calculatedRelativeIndebtednessPY / turnoverPY) * 100;
      else number = 0;
    }
    // Käyttöpääoma
    else if (key === 'workingCapital') {
      number = calculatedWorkingCapital;
    }
    else if (key === 'workingCapitalPY') {
      number = calculatedWorkingCapitalPY;
    }
    // Käyttöpääomaprosentti (%) (workingCapital / turnover * 100)
    else if (key === 'workingCapitalPercent') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.currentYear,
      ];
      if (calculatedWorkingCapital && turnover !== undefined)
        number = calculatedWorkingCapital / turnover * 100;
      else number = 0;
    }
    else if (key === 'workingCapitalPercentPY') {
      const [turnover] = [
        this.getIndicatorsItem(AccountKeyScoping.turnover)?.priorYear,
      ];
      if (calculatedWorkingCapitalPY && turnover !== undefined)
        number = calculatedWorkingCapitalPY / turnover * 100;
      else number = 0;
    }
    // currentRatio
    else if (key === 'currentRatio') {
      number = calculatedCurrentRatio;
    }
    else if (key === 'currentRatioPY') {
      number = calculatedCurrentRatioPY;
    }
    // quickRatio (saamiset + rahoitusarvopaperit + rahat ja pankkisaamiset) / (lyhytaikaiset velat - ennakkomaksut)
    else if (key === 'quickRatio') {
      number = calculatedQuickRatio;
    }
    else if (key === 'quickRatioPY') {
      number = calculatedQuickRatioPY;
    }
    // accountsReceivableCycleTime ((365 * longTerm_tradeReceivables) / turnover)
    else if (key === 'accountsReceivableCycleTime') {
      number = calculatedAccountsReceivableCycleTime;
    }
    else if (key === 'accountsReceivableCycleTimePY') {
      number = calculatedAccountsReceivableCycleTimePY;
    }
    // accountsPayableCycleTime ((365 * longTermCreditors_tradeCreditors) / purchases + externalServices)
    else if (key === 'accountsPayableCycleTime') {
      number = calculatedAccountsPayableCycleTime;
    }
    else if (key === 'accountsPayableCycleTimePY') {
      number = calculatedAccountsPayableCycleTimePY;
    }
    // materialMargin
    else if (key === 'materialMargin') {
      number = calculatedMaterialMargin;
    }
    else if (key === 'materialMarginPY') {
      number = calculatedMaterialMarginPY;
    }
    // TyEL
    else if (key === 'tyel') {
      number = 0;
    }
    else if (key === 'tyelPY') {
      number = 0;
    }
    // SOTU
    else if (key === 'sotu') {
      number = 0;
    }
    else if (key === 'sotuPY') {
      number = 0;
    }
    // estimateFiveYears
    else if (key === 'estimateFiveYears') {
      number = calculatedEstimateFiveYears;
    }
    else if (key === 'estimateFiveYearsPY') {
      number = calculatedEstimateFiveYearsPY;
    }
    // accountsReceivableVSturnOver (myyntisaamiset/liikevaihto)
    else if (key === 'accountsReceivableVSturnOver') {
      number = calculatedAccountsReceivableVSturnOver;
    }
    else if (key === 'accountsReceivableVSturnOverPY') {
      number = calculatedAccountsReceivableVSturnOverPY;
    }
    // accountsPayableVSpurchases
    else if (key === 'accountsPayableVSpurchases') {
      number = accountsPayableVSpurchases;
    }
    else if (key === 'accountsPayableVSpurchasesPY') {
      number = accountsPayableVSpurchasesPY;
    }
    // shareCapitalOver
    else if (key === 'shareCapitalOver') {
      number = calculatedShareCapitalOver;
    }
    else if (key === 'shareCapitalOverPY') {
      number = calculatedShareCapitalOverPY;
    }
    // svopOver
    else if (key === 'svopOver') {
      number = calculatedSvopOver;
    }
    else if (key === 'svopOverPY') {
      number = calculatedSvopOverPY;
    }
    // ratioOfHoliday
    else if (key === 'ratioOfHoliday') {
      number = calculatedRatioOfHoliday;
    }
    else if (key === 'ratioOfHolidayPY') {
      number = calculatedRatioOfHolidayPY;
    }

    else {
      number = this.getIndicatorsItem(key)?.[value];
    }

    return number ? round(number) : number;
  };

  getFinancialItem = (key: AccountKey | 'other') => {
    const form = this.entity?.incomeStatementAndBalance?.form;
    const incomeStatement = form?.incomeStatement;
    const balanceAssets = form?.balanceAssets;
    const balanceLiabilities = form?.balanceLiabilities;
    return (
      incomeStatement?.find(item => item.key === key) ??
      balanceAssets?.find(item => item.key === key) ??
      balanceLiabilities?.find(item => item.key === key)
    );
  };
  //----------------------------------------//
  getFinancialNumber = (
    key: AccountKey | 'other' | 'assetsLiabilitiesDifference' | null,
    value: 'currentYear' | 'priorYear' = 'currentYear'
  ) => {
    if (!key) return;

    let number: number | undefined = undefined;

    if (key === 'assetsLiabilitiesDifference') {
      const [assets, liabilities] = [
        this.getFinancialItem(AccountKey.balanceAssets)?.[value],
        this.getFinancialItem(AccountKey.balanceLiabilities)?.[value],
      ];
      if (assets !== undefined && liabilities !== undefined)
        number = assets - liabilities;
    }
    else if (key === AccountKey.taxRevenues) {
      const [turnover, otherIncome] = [
        this.getFinancialItem(AccountKey.turnover)?.[value],
        this.getFinancialItem(AccountKey.otherIncome)?.[value],
      ];
      if (turnover !== undefined && otherIncome !== undefined)
        number = turnover + otherIncome;
    }
    else if (key === AccountKey.turnover) {
      const [turnover, shortTerm, cashInHand, currentAssets, Creditors] = [
        this.getFinancialItem(AccountKey.turnover)?.[value],
        this.getFinancialItem(AccountKey.shortTermReceivables)?.[value],
        this.getFinancialItem(AccountKey.cashInHandAndAtBanks)?.[value],
        this.getFinancialItem(AccountKey.currentAssets_investments)?.[value],
        this.getFinancialItem(AccountKey.creditors)?.[value],
      ]; 
      if (turnover !== undefined && shortTerm !== undefined && cashInHand !== undefined && currentAssets !== undefined && Creditors !== undefined)
        number = (turnover + shortTerm + cashInHand + currentAssets) / Creditors;
    } else {
      number = this.getFinancialItem(key)?.[value];
    }

    return number ? round(number) : number;
  };
  // Used in IncomeStatementAndBalanceScoping.ComputedsTable.tsx
  getFinancialItemScoping = (key: AccountKeyScoping | 'other') => {
    const form = this.entity?.incomeStatementAndBalanceScoping?.form;
    const incomeStatement = form?.incomeStatement;
    const balanceAssets = form?.balanceAssets;
    const balanceLiabilities = form?.balanceLiabilities;
    return (
      incomeStatement?.find(item => item.key === key) ??
      balanceAssets?.find(item => item.key === key) ??
      balanceLiabilities?.find(item => item.key === key)
    );
  };
  // Used in IncomeStatementAndBalanceScoping.ComputedsTable.tsx
  getFinancialNumberScoping = (
    key: AccountKeyScoping | 'other' | 'assetsLiabilitiesDifference' | null,
    value: 'currentYear' | 'priorYear' = 'currentYear'
  ) => {
    if (!key) return;

    let number: number | undefined = undefined;

    if (key === 'assetsLiabilitiesDifference') {
      const [assets, liabilities] = [
        this.getFinancialItemScoping(AccountKeyScoping.balanceAssets)?.[value],
        this.getFinancialItemScoping(AccountKeyScoping.balanceLiabilities)?.[value],
      ];
      if (assets !== undefined && liabilities !== undefined)
        number = assets - liabilities;
    } 
    else if (key === AccountKeyScoping.turnover) {
      const [turnover, shortTerm, cashInHand, currentAssets, Creditors] = [
        this.getFinancialItemScoping(AccountKeyScoping.turnover)?.[value],
        this.getFinancialItemScoping(AccountKeyScoping.shortTermReceivables)?.[value],
        this.getFinancialItemScoping(AccountKeyScoping.cashInHandAndAtBanks)?.[value],
        this.getFinancialItemScoping(AccountKeyScoping.currentAssets_investments)?.[value],
        this.getFinancialItemScoping(AccountKeyScoping.creditors)?.[value],
      ]; 
      if (turnover !== undefined && shortTerm !== undefined && cashInHand !== undefined && currentAssets !== undefined && Creditors !== undefined)
        number = (turnover + shortTerm + cashInHand + currentAssets) / Creditors;
    } else {
      number = this.getFinancialItemScoping(key)?.[value];
    }

    return number ? round(number) : number;
  };
  //----------------------------------------//
  getAuditingSection = (sectionKey: AuditingSectionKey) => {
    return toJS(this.entity?.[sectionKey]);
  };

  getAuditingSectionStatus = (sectionKey: AuditingSectionKey) => {
    const section = this.getAuditingSection(sectionKey);
    return section?.status ?? 'not_started';
  };

  isFinishedStatus = (status: AuditingStatus | AuditingSectionStatus) =>
    ['finished', 'approved'].includes(status);

  isAuditingSectionFinished = (sectionKey: AuditingSectionKey) => {
    const status = this.getAuditingSectionStatus(sectionKey);
    return this.isFinishedStatus(status);
  };

  getAuditingStatusInformation = (props: {
    essentialsSectionKeys: AuditingSectionKey[];
    inspectionSectionKeys: AuditingSectionKey[];
    analyzesSectionKeys: AuditingSectionKey[];
    scopingSectionKeys: AuditingSectionKey[];
    planningSectionKeys: AuditingSectionKey[];
    auditingSectionKeys: AuditingSectionKey[];
    reportingSectionKeys: AuditingSectionKey[];
  }) => {
    const { essentialsSectionKeys, inspectionSectionKeys, analyzesSectionKeys, scopingSectionKeys, planningSectionKeys, auditingSectionKeys, reportingSectionKeys } =
      props;

    const initialStatus: AuditingStatusInformation = {
      approved: 0,
      finished: 0,
      started: 0,
      not_started: 0,
    };

    const totalStatus: AuditingStatusInformation = { ...initialStatus };
    const essentialsStatus: AuditingStatusInformation = { ...initialStatus };
    const inspectionStatus: AuditingStatusInformation = { ...initialStatus };
    const analyzesStatus: AuditingStatusInformation = { ...initialStatus };
    const scopingStatus: AuditingStatusInformation = { ...initialStatus };
    const plannningStatus: AuditingStatusInformation = { ...initialStatus };
    const auditingStatus: AuditingStatusInformation = { ...initialStatus };
    const reportingStatus: AuditingStatusInformation = { ...initialStatus };

    const addStatus =
      (statusGroup: AuditingStatusInformation) =>
      (sectionKey: AuditingSectionKey) => {
        const status =
          this.selectedEntity?.[sectionKey]?.status ?? 'not_started';
        totalStatus[status] += 1;
        statusGroup[status] += 1;
      };

    essentialsSectionKeys.forEach(addStatus(essentialsStatus));
    inspectionSectionKeys.forEach(addStatus(inspectionStatus));
    analyzesSectionKeys.forEach(addStatus(inspectionStatus));
    scopingSectionKeys.forEach(addStatus(inspectionStatus));
    planningSectionKeys.forEach(addStatus(plannningStatus));
    auditingSectionKeys.forEach(addStatus(auditingStatus));
    reportingSectionKeys.forEach(addStatus(reportingStatus));

    return {
      totalStatus,
      essentialsStatus,
      inspectionStatus,
      analyzesStatus,
      scopingStatus,
      plannningStatus,
      auditingStatus,
      reportingStatus,
    };
  };

  getAuditingSectionFormField = (
    sectionKey: AuditingSectionKey,
    accessor: AuditingSectionFormAccessor
  ) => {
    const auditing = this.selectedEntity;

    if (typeof accessor === 'function') {
      return accessor(auditing?.[sectionKey]);
    } else {
      const accessors = accessor.split('.');
      switch (accessors.length) {
        case 2:
          const [a1of2, a2of2] = accessors;
          return auditing?.[sectionKey]?.form[a1of2]?.[a2of2];
        case 3:
          const [a1of3, a2of3, a3of3] = accessors;
          return auditing?.[sectionKey]?.form[a1of3]?.[a2of3]?.[a3of3];
        // Add more cases if need to access deeper levels.
        default:
          return auditing?.[sectionKey]?.form[accessor];
      }
    }
  };

  getAccountMap = (): AccountMap =>
    this.selectedEntity?.accountMap?.form.accountMap ??
    generateAccountMap(this.auditingTemplate ?? AuditingTemplate.private);

  getAccountMapScoping = () : AccountMapScoping =>
    this.selectedEntity?.accountMapScoping?.form.accountMapScoping ??
    generateAccountMap(this.auditingTemplate ?? AuditingTemplate.private);

  getAuditingProceduresBatch = (
    batch: FinancialStatementBatch,
    shouldHavePeriodOrTP = true,
  ): AuditingProcedure[] => {
    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const auditingProcedures: AuditingProcedure[] =
      this.getAuditingSectionFormField(sectionKey, 'auditingProcedures') ?? [];

      return auditingProcedures.filter(procedure => {
        if (hasInExcel(procedure)) {
          return false;
        }
        return procedure.batch === batch &&
          (shouldHavePeriodOrTP ? hasPeriodOrTP(procedure) : true);
      });
  };

  private resolveDependentRemarkRule =
    (parentSectionKey: AuditingSectionKey) =>
    (dependingRemarkRule: DependingRemarkRule) => {
      const { remarkTriggers, accessor } = dependingRemarkRule;
      const sectionKey = dependingRemarkRule.sectionKey ?? parentSectionKey;
      const value = this.getAuditingSectionFormField(sectionKey, accessor);
      return remarkTriggers.includes(value);
    };

  private resolveRemarkRule = (
    remarkRule: RemarkRule
  ): AuditingRemark & { isRemark: boolean } => {
    const { sectionKey, accessor, accessorKey, remarkTriggers, depends } =
      remarkRule;
    const isDepending = !!depends?.length;
    const value = this.getAuditingSectionFormField(sectionKey, accessor);

    const bypass = !remarkTriggers.length;
    const remarkTriggered = bypass || remarkTriggers.includes(value);
    const dependingRemarks = depends
      ?.map(this.resolveDependentRemarkRule(sectionKey))
      .filter(dependentRemarkTriggered => dependentRemarkTriggered);
    const allDependingRemarksTriggered =
      dependingRemarks?.length === depends?.length;

    const isRemark =
      (!isDepending && remarkTriggered) ||
      (isDepending && allDependingRemarksTriggered && remarkTriggered);

    const key = `${sectionKey}.${accessorKey}`;

    return {
      key,
      label: t(`auditing:form.${key}`),
      sectionKey,
      accessor,
      accessorKey,
      value,
      isRemark,
    };
  };

  getAuditingRemarks = (): AuditingRemark[] => {
    return REMARK_RULES.map(this.resolveRemarkRule)
      .filter(({ isRemark }) => isRemark)
      .map(({ isRemark, ...rest }) => rest);
  };

  isMarkedAsRemark = (sectionKey: AuditingSectionKey, formFieldKey: string) => {
    return !!this.getAuditingRemarks().find(
      remark =>
        remark.sectionKey === sectionKey &&
        [remark.accessor, remark.accessorKey].includes(formFieldKey)
    );
  };

  private transformLegalSituation = (
    legalSituationNumber?: YtjLegalSituation
  ) => {
    switch (legalSituationNumber) {
      case 0:
        return LegalSituation.normal;
      case 1:
        return LegalSituation.bankruptcy;
      case 2:
        return LegalSituation.liquidation;
      case 3:
        return LegalSituation.restructuringProcedure;
      default:
        return null;
    }
  };

  fetchTaxRegisterInformation = async () => {
    const businessId = this.selectedEntity?.customerCompany?.businessId;

    const ytjData = businessId
      ? await this.rootStore.commonStore.fetchFromYtj({ businessId })
      : undefined;

    if (!ytjData) return;

    return {
      taxRegisterFetchedDate: today().toISO(),
      tradeRegister: ytjData.tradeRegister.value ?? null,
      tradeRegisterDate: ytjData.tradeRegister.date ?? null,
      taxAdministrationInformation:
        ytjData.taxAdministrationInformation.value ?? null,
      taxAdministrationInformationDate:
        ytjData.taxAdministrationInformation.date ?? null,
      advanceCollectionRegister:
        ytjData.advanceCollectionRegister.value ?? null,
      advanceCollectionRegisterDate:
        ytjData.advanceCollectionRegister.date ?? null,
      liableForVATOnBusiness: ytjData.liableForVatOnBusiness.value ?? null,
      liableForVATOnBusinessDate: ytjData.liableForVatOnBusiness.date ?? null,
      liableForVATOnProperty: ytjData.liableForVATOnProperty.value ?? null,
      liableForVATOnPropertyDate: ytjData.liableForVATOnProperty.date ?? null,
      employerRegister: ytjData.employerRegister.value ?? null,
      employerRegisterDate: ytjData.employerRegister.date ?? null,
      legalSituation: this.transformLegalSituation(ytjData.legalSituation),
    };
  };

  getPlaceCVData = (membersInformationTable: any, formState: any) => {
    const chairman = membersInformationTable.find((member: { label: string; }) => member.label === 'Puheenjohtaja')?.inputValue ?? '';
    const members = membersInformationTable.find((member: { label: string; }) => member.label === 'Jäsenet')?.inputValue ?? '';

    return formState.customerVerificationTable.map((item: { id: string; }) => {
        if (item.id === '1') {
            return {
                ...item,
                inputValue: `Puheenjohtaja: ${chairman}, Jäsenet: ${members}`
            };
        }
        return item;
    });
  };

  /**
   * Wrapped section computeds
   */
  materialityComputeds = (currentForm?: MaterialityForm) => {
    const form = currentForm ?? this.entity?.materiality?.form;
    const {
      materialityBaseNumber,
      materialityPercent,
      workingMaterialityPercent,
      singleErrorMaterialityPercent,

      materialitySecondBaseNumber,
      materialitySecondPercent,
      workingMaterialitySecondPercent,
      singleErrorMaterialitySecondPercent,

      materialityThirdBaseNumber,
      materialityThirdPercent,
      workingMaterialityThirdPercent,
      singleErrorMaterialityThirdPercent,

      alternatives,
    } = form ?? {};

    const {
      turnoverMaterialityPercent,
      operatingProfitOrLossMaterialityPercent,
      equityMaterialityPercent,
      liabilitiesMaterialityPercent,
      combinedRevenuesMaterialityPercent,
      averageOfLiabilitiesAndRevenuesMaterialityPercent,
      annualContributionMarginMaterialityPercent,
    } = alternatives ?? {};

    const materiality = getPercent(materialityBaseNumber, materialityPercent);

    const materialitySecond = getPercent(materialitySecondBaseNumber, materialitySecondPercent);

    const materialityThird = getPercent(materialityThirdBaseNumber, materialityThirdPercent);

    return {
      materiality,
      workingMateriality: getPercent(materiality, workingMaterialityPercent),
      singleErrorMateriality: getPercent(materiality, singleErrorMaterialityPercent),

      materialitySecond,
      workingMaterialitySecond: getPercent(materialitySecond, workingMaterialitySecondPercent),
      singleErrorMaterialitySecond: getPercent(materialitySecond, singleErrorMaterialitySecondPercent),

      materialityThird,
      workingMaterialityThird: getPercent(materialityThird, workingMaterialityThirdPercent),
      singleErrorMaterialityThird: getPercent(materialityThird, singleErrorMaterialityThirdPercent),

      alternatives: {
        turnoverMateriality: getPercent(
          this.getFinancialNumber(AccountKey.turnover),
          turnoverMaterialityPercent
        ),
        operatingProfitOrLossMateriality: getPercent(
          this.getFinancialNumber(AccountKey.operatingProfitOrLoss),
          operatingProfitOrLossMaterialityPercent
        ),
        equityMateriality: getPercent(
          this.getFinancialNumber(AccountKey.equity),
          equityMaterialityPercent
        ),
        liabilitiesMateriality: getPercent(
          this.getFinancialNumber(AccountKey.balanceLiabilities),
          liabilitiesMaterialityPercent
        ),
        combinedRevenuesMateriality: getPercent(
          getCombinedRevenues(this.rootStore),
          combinedRevenuesMaterialityPercent
        ),
        averageOfLiabilitiesAndRevenuesMateriality: getPercent(
          getAverageOfLiabilitiesAndRevenues(this.rootStore),
          averageOfLiabilitiesAndRevenuesMaterialityPercent
        ),
        annualContributionMarginMateriality: getPercent(
          this.getFinancialNumber(AccountKey.annualContributionMargin),
          annualContributionMarginMaterialityPercent
        ),
      },
    };
  };
}
