type VisibleData = {
  [key: string]: (keyof MandateCompanyInformation)[];
};

export const visibleData: VisibleData = {
  MandateCompanyInformationVisibleData: [
    'name',
    'businessId',
    'financialPeriod',
    'selectedAuditor',
    'inspectionTeamMembers',
    'contactEmail',
    'accountant',
  ]
};