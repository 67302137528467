import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextArea, TextInput } from "../../../../components/inputs";
import { formatDateTime, useDelayedAction } from "../../../../utils";
import { ContextInfoProps } from "../../../../components/ContextInfo";

interface Props extends FormFieldProps<MaterialsAndServicesForm> {
    processText: string;
    processDate: ISODate | null;
    processValue: string;
}

const sectionKey: AuditingSectionKey = 'materialsAndServices';

const tBase = `auditing:form.${sectionKey}.processDescription`;

export const ProcessDescriptionTable = ({ formState, patchFormState }: Props) => {

    const { t } = useTranslation();

    const originalProcessText = formState.processText ?? '';
    const [processText, setProcessText] = useState(originalProcessText);

    const originalProcessDate = formState.processDate ?? '';
    const [processDate, setProcessDate] = useState(originalProcessDate);

    const originalProcessValue = formState.processValue ?? '';
    const [processValue, setProcessValue] = useState(originalProcessValue);

    // Send form patch after a short delay to detect when user stopped typing
    useDelayedAction(() => {
        const textChanged = processText !== originalProcessText;
        const dateChanged = processDate !== originalProcessDate;
        const valueChanged = processValue !== originalProcessValue;

        const changedMade = textChanged || dateChanged || valueChanged;
        if (changedMade) {
            patchFormState({
                processText: processText,
                processDate: processDate,
                processValue: processValue,
            })
        }
    }, 500);

    if (!formState) return null;

    const getContextInfo = (): ContextInfoProps | undefined => {
        const text = t(`${tBase}.kindInfo`);
        if (text.length) return { position: 'bottom-right', text };
    };
    const formatDate = (isoDate: ISODate | null) => {
        return isoDate?.length ? formatDateTime(isoDate, { format: 'DDD' }) : '';
    }

    const contextInfo = getContextInfo();
    const communicatedDateLabel = t(`${tBase}.communicatedDate`, {
        value: formatDate(processDate),
    })

    // Detect link in description
    const regex = /https?:\/\/[www]?\S+\.\w{1,3}\S+/g
    const detectedLinks = String(processValue).match(regex)

    return (
        <>
        <h6>Osto ja ostovelat</h6>

        <div className="flex-row flex-row-gap">
            <div className="flex-1">
                <TextInput
                    label={t(`${tBase}.communicatedDetails`)}
                    value={processText}
                    setValue={setProcessText}
                    placeholder={t(`${tBase}.communicatedDetailsInfo`)}
                    contextInfo={{
                        text: t(`${tBase}.communicatedDetailsInfo`),
                    }}
                    fullWidth
                />
            </div>

            <div className="flex-1">
                <TextInput 
                    type="date"
                    label={communicatedDateLabel}
                    value={processDate ?? ''}
                    setValue={setProcessDate}
                    fullWidth
                />
            </div>
        </div>

        <TextArea
            label={t('common:label.description')}
            value={processValue}
            setValue={setProcessValue}
            fullWidth
            placeholder={contextInfo?.text}
            contextInfo={contextInfo}
        />
        {/* Render links here */}
        {detectedLinks && detectedLinks.map((link, index) => {
            return (
            <a key={index} href={link} target="__blank">
                {link}
                <br />
            </a>
            );
        }
        )}
        </>
    );
};