import React from 'react';
import { useTranslation } from 'react-i18next';
import { confirm } from '../../utils';
import Icon from '../Icon';
import { Button } from '../inputs';

const sectionKey: AuditingSectionKey = 'accountMapScoping';

export const tBase = `auditing:form.${sectionKey}`;

interface Props extends FormFieldProps<AccountMapScopingForm> {}

const AccountMapScopingResetButton: React.FC<Props> = ({ store }) => {
  const { t } = useTranslation();

  const { auditingTemplate } = store.auditingStore;
  const template = t(`auditing:template.${auditingTemplate}`);

  const handleClick = async () => {
    if (confirm(t(`${tBase}.resetConfirm`, { template }))) {
      await store.auditingStore.resetSectionForm(sectionKey);
      window.location.reload();
    }
  };

  return (
    <Button
      text={t(`${tBase}.reset`, { template })}
      icon={<Icon type="Undo" color="error" />}
      color="error"
      variant="link"
      onClick={handleClick}
    />
  );
};

export default AccountMapScopingResetButton;
