import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { validatePassword } from '../../utils';
import { Button, TextInput } from '../inputs';

interface Props {
  token: string;
  onSubmit?: () => void;
}

const PasswordResetForm: React.FC<Props> = observer(({ token, onSubmit }) => {
  const { t } = useTranslation(['login', 'action']);

  const {
    authStore: { resetPassword },
    notificationStore: { notify },
  } = useStore();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const validateMessages = validatePassword(password, passwordConfirm);

    if (validateMessages) {
      validateMessages.forEach(message => notify(message, 'error'));
    } else {
      resetPassword({ token, newPassword: password });
      if (onSubmit) onSubmit();
    }
  };

  return (
    <form onSubmit={handleResetPassword}>
      <TextInput
        type="password"
        label={t('login:password')}
        value={password}
        setValue={setPassword}
        autoFocus
        required
        fullWidth
      />
      <TextInput
        type="password"
        label={t('login:passwordConfirm')}
        value={passwordConfirm}
        setValue={setPasswordConfirm}
        required
        fullWidth
      />
      <Button text={t('action:save')} type="submit" />
    </form>
  );
});

export default PasswordResetForm;
