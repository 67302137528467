import styled from 'styled-components';

interface WrapperProps {
  width?: 'sm' | 'md' | 'lg';
}

export const Wrapper = styled.div<WrapperProps>`
  width: ${p => (p.width === 'sm' ? 50 : p.width === 'md' ? 70 : 90)}%;
  margin-left: ${p => p.theme.spacing.xl};
`;

export default Wrapper;
