import { useTranslation } from 'react-i18next';
import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import { Colors } from '../../theme';

export function formatNumber(num: any) {
  return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
};

export function formatSingleDigitNumber(num: any) {
  return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
};

const sectionKey: AuditingSectionKey = 'presentationAndContent';

// Define 'notApplicable' type to its own value
type NotApplicable = 'notApplicable';
let myValue: NotApplicable;
myValue = 'notApplicable';

enum OptionValues {
  true = 'yes',
  false = 'no',
  notApplicable = 'notApplicable',
}

export const MikroPMATable = ({
    formState,
    patchFormState,
  }: FormFieldProps<PresentationAndContentForm>) => {
    const { t } = useTranslation();
  
    if (!formState) return null;
  
    const tBase = `auditing:form.${sectionKey}.mikroTable`;

    //const data = formState.mikroPMATable ?? [];

    const getRowColor = (row: MikroPMATable) => {
      switch (row.shownAttatchment) {
        case true:
          return Colors.green;
        case false:
          return Colors.red;
        case OptionValues.notApplicable:
          return Colors.yellow;
        default:
          return undefined;
      }
    }

    const addRowProps = (row: MikroPMATable) => ({
      ...row,
      bgColor: getRowColor,
    });

    const data: MikroPMATable[] = formState.mikroPMATable.map(addRowProps);
    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
      const patch = formState.mikroPMATable.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
              if (idMatch || rowIndexMatch) {
                  const updatedRow = { ...row, [columnId]: value };
                  return updatedRow;
              }
              return row;
          });
          const newFormState = { ...formState, mikroPMATable: patch };
          patchFormState(newFormState);
      }

      let tableColumns: TableColumnDefs<MikroPMATable> = [
        {
                accessorKey: 'label',
                header: () => t(`${tBase}.PMAMikro`),
                className: 'text-left wrap width-middle',
                placeholder: '',
                onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
        },
        {
                // Boolean
                accessorKey: 'shownAttatchment',
                header: () => t(`${tBase}.shownAttatchment`),
                className: 'text-center width-min',
                showRemark: (item: MikroPMATable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
                enableGlobalFilter: false,
                selectProps: {
                    options: Object.values(OptionValues),
                    placeholder: 'Ei sovellu',
                    clearable: true,
                    displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                    setValue: (editProps: OnEditProps<string>) => {
                        const value = editProps.value;
                        handleEdit<any>({
                            ...editProps,
                            value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                        });
                    },
                },
        },
        {
                accessorKey: 'other',
                header: () => t(`${tBase}.other`),
                className: 'text-center width-middle',
                onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
        },
      ];

      const handleAddNewRow = () => {
        patchFormState({
          ...formState,
          mikroPMATable: [
            ...(formState.mikroPMATable ?? []),
            {
                label: "",
                id: uuid(),
                shownAttatchment: null,
                other: "",
            }
          ],
        });
      };
    
      const handleDeleteRow = ({ id, label }: MikroPMATable) => {
        if (confirmDelete(label ?? '')) {
          const patch = (formState.mikroPMATable ?? []).filter(
            row => row.id !== id
          );
          patchFormState({ ...formState, mikroPMATable: patch });
        }
      };
    
      return (
        <>
        <AuditingTable<MikroPMATable>
          sectionKey={sectionKey}
          title=''
          onAddNewRow={handleAddNewRow}
          data={data}
          columns={tableColumns}
          onRowDelete={handleDeleteRow}
          showGlobalFilter={false}
          variant='default'
        />
        </>
      );
};
export const MikroCommentTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroCommentTable ?? [];

  const getRowColor = (row: MikroCommentTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroCommentTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroCommentTable[] = formState.mikroCommentTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroCommentTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroCommentTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroCommentTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.PMASubtitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroPMATable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroCommentTable: [
          ...(formState.mikroCommentTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroCommentTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroCommentTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroCommentTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroCommentTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};

export const MikroGroupTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroGroupTable ?? [];

  const getRowColor = (row: MikroGroupTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroGroupTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroGroupTable[] = formState.mikroGroupTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroGroupTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroGroupTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroGroupTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.groupSubTitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroGroupTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroGroupTable: [
          ...(formState.mikroGroupTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroGroupTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroGroupTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroGroupTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroGroupTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroGroupTableSecond = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroGroupTableSecond ?? [];

  const getRowColor = (row: MikroGroupTableSecond) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroGroupTableSecond) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroGroupTableSecond[] = formState.mikroGroupTableSecond.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroGroupTableSecond.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroGroupTableSecond: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroGroupTableSecond> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.groupSubTitle2`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroGroupTableSecond) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroGroupTableSecond: [
          ...(formState.mikroGroupTableSecond ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroGroupTableSecond) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroGroupTableSecond ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroGroupTableSecond: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroGroupTableSecond>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroGroupTableThird = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroGroupTableThird ?? [];

  const getRowColor = (row: MikroGroupTableThird) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroGroupTableThird) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroGroupTableThird[] = formState.mikroGroupTableThird.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroGroupTableThird.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroGroupTableThird: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroGroupTableThird> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.groupSubTitle3`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroGroupTableThird) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroGroupTableThird: [
          ...(formState.mikroGroupTableThird ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroGroupTableThird) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroGroupTableThird ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroGroupTableThird: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroGroupTableThird>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};

export const MikroKPATable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPATable ?? [];

  const getRowColor = (row: MikroKPATable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPATable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPATable[] = formState.mikroKPATable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPATable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPATable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPATable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPATable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPATable: [
          ...(formState.mikroKPATable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPATable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPATable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPATable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPATable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPASecondTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPASecondTable ?? [];

  const getRowColor = (row: MikroKPASecondTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPASecondTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPASecondTable[] = formState.mikroKPASecondTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPASecondTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPASecondTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPASecondTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle2`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPASecondTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPASecondTable: [
          ...(formState.mikroKPASecondTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPASecondTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPASecondTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPASecondTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPASecondTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAThirdTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAThirdTable ?? [];

  const getRowColor = (row: MikroKPAThirdTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAThirdTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAThirdTable[] = formState.mikroKPAThirdTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAThirdTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAThirdTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAThirdTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle3`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAThirdTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAThirdTable: [
          ...(formState.mikroKPAThirdTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAThirdTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAThirdTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAThirdTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAThirdTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAFourthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAFourthTable ?? [];

  const getRowColor = (row: MikroKPAFourthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAFourthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAFourthTable[] = formState.mikroKPAFourthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAFourthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAFourthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAFourthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle4`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAFourthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAFourthTable: [
          ...(formState.mikroKPAFourthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAFourthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAFourthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAFourthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAFourthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAOtherTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAOtherTable ?? [];

  const getRowColor = (row: MikroKPAOtherTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAOtherTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAOtherTable[] = formState.mikroKPAOtherTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAOtherTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAOtherTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAOtherTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitleOther`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAOtherTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAOtherTable: [
          ...(formState.mikroKPAOtherTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAOtherTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAOtherTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAOtherTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAOtherTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAFifthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAFifthTable ?? [];

  const getRowColor = (row: MikroKPAFifthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAFifthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAFifthTable[] = formState.mikroKPAFifthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAFifthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAFifthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAFifthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle5`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAFifthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAFifthTable: [
          ...(formState.mikroKPAFifthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAFifthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAFifthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAFifthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAFifthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAFifthTableTwo = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAFifthTableTwo ?? [];

  const getRowColor = (row: MikroKPAFifthTableTwo) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAFifthTableTwo) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAFifthTableTwo[] = formState.mikroKPAFifthTableTwo.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAFifthTableTwo.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAFifthTableTwo: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAFifthTableTwo> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle5`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAFifthTableTwo) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAFifthTableTwo: [
          ...(formState.mikroKPAFifthTableTwo ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAFifthTableTwo) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAFifthTableTwo ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAFifthTableTwo: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAFifthTableTwo>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPASixthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPASixthTable ?? [];

  const getRowColor = (row: MikroKPASixthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPASixthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPASixthTable[] = formState.mikroKPASixthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPASixthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPASixthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPASixthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle6`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPASixthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPASixthTable: [
          ...(formState.mikroKPASixthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPASixthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPASixthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPASixthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPASixthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPASeventhTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPASeventhTable ?? [];

  const getRowColor = (row: MikroKPASeventhTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPASeventhTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPASeventhTable[] = formState.mikroKPASeventhTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPASeventhTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPASeventhTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPASeventhTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle7`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPASeventhTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPASeventhTable: [
          ...(formState.mikroKPASeventhTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPASeventhTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPASeventhTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPASeventhTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPASeventhTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const MikroKPAEightTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.mikroKPAEightTable ?? [];

  const getRowColor = (row: MikroKPAEightTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: MikroKPAEightTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: MikroKPAEightTable[] = formState.mikroKPAEightTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.mikroKPAEightTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, mikroKPAEightTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<MikroKPAEightTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.KPASubtitle8`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: MikroKPAEightTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        mikroKPAEightTable: [
          ...(formState.mikroKPAEightTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: MikroKPAEightTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.mikroKPAEightTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, mikroKPAEightTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<MikroKPAEightTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};

export const SmallBusinessTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.smallBusinessTable ?? [];

  const getRowColor = (row: SmallBusinessTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: SmallBusinessTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: SmallBusinessTable[] = formState.smallBusinessTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.smallBusinessTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, smallBusinessTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<SmallBusinessTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.smallBusiness`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: SmallBusinessTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        smallBusinessTable: [
          ...(formState.smallBusinessTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: SmallBusinessTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.smallBusinessTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, smallBusinessTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<SmallBusinessTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const SmallBusinessTableSecond = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.smallBusinessSecondTable ?? [];

  const getRowColor = (row: SmallBusinessSecondTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: SmallBusinessSecondTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: SmallBusinessSecondTable[] = formState.smallBusinessSecondTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.smallBusinessSecondTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, smallBusinessSecondTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<SmallBusinessSecondTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.smallBusinessSubTitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: SmallBusinessSecondTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        smallBusinessSecondTable: [
          ...(formState.smallBusinessSecondTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: SmallBusinessSecondTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.smallBusinessSecondTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, smallBusinessSecondTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<SmallBusinessSecondTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};

export const ActionReportTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportTable ?? [];

  const getRowColor = (row: ActionReportTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportTable[] = formState.actionReportTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReport`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportTable: [
          ...(formState.actionReportTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportSecondTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportSecondTable ?? [];

  const getRowColor = (row: ActionReportSecondTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportSecondTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportSecondTable[] = formState.actionReportSecondTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportSecondTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportSecondTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportSecondTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportSecondTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportSecondTable: [
          ...(formState.actionReportSecondTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportSecondTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportSecondTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportSecondTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportSecondTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportThirdTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportThirdTable ?? [];

  const getRowColor = (row: ActionReportThirdTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportThirdTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportThirdTable[] = formState.actionReportThirdTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportThirdTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportThirdTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportThirdTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportThirdTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportThirdTable: [
          ...(formState.actionReportThirdTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportThirdTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportThirdTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportThirdTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportThirdTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportFourthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportFourthTable ?? [];

  const getRowColor = (row: ActionReportFourthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportFourthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportFourthTable[] = formState.actionReportFourthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportFourthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportFourthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportFourthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportFourthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportFourthTable: [
          ...(formState.actionReportFourthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportFourthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportFourthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportFourthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportFourthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportFifthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportFifthTable ?? [];

  const getRowColor = (row: ActionReportFifthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportFifthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportFifthTable[] = formState.actionReportFifthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportFifthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportFifthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportFifthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle2`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportFifthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportFifthTable: [
          ...(formState.actionReportFifthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportFifthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportFifthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportFifthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportFifthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportSixthTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportSixthTable ?? [];

  const getRowColor = (row: ActionReportSixthTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportSixthTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportSixthTable[] = formState.actionReportSixthTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportSixthTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportSixthTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportSixthTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle2`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportSixthTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportSixthTable: [
          ...(formState.actionReportSixthTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportSixthTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportSixthTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportSixthTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportSixthTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportSeventhTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportSeventhTable ?? [];

  const getRowColor = (row: ActionReportSeventhTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportSeventhTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportSeventhTable[] = formState.actionReportSeventhTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportSeventhTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportSeventhTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportSeventhTable> = [
      {
              accessorKey: 'label',
              header: () => '2) Muut kuin taloudelliset tunnusluvut ympäristövaikutuksista',
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportSeventhTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportSeventhTable: [
          ...(formState.actionReportSeventhTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportSeventhTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportSeventhTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportSeventhTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportSeventhTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportOtherTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportOtherTable ?? [];

  const getRowColor = (row: ActionReportOtherTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportOtherTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportOtherTable[] = formState.actionReportOtherTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportOtherTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportOtherTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportOtherTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle4`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportOtherTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportOtherTable: [
          ...(formState.actionReportOtherTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportOtherTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportOtherTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportOtherTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportOtherTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportOther2Table = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportOther2Table ?? [];

  const getRowColor = (row: ActionReportOther2Table) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportOther2Table) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportOther2Table[] = formState.actionReportOther2Table.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportOther2Table.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportOther2Table: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportOther2Table> = [
      {
              accessorKey: 'label',
              header: () => '3) Selvitys tutkimus- ja kehitystoiminnan laajuudesta',
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportOther2Table) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportOther2Table: [
          ...(formState.actionReportOther2Table ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportOther2Table) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportOther2Table ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportOther2Table: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportOther2Table>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportOther3Table = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportOther3Table ?? [];

  const getRowColor = (row: ActionReportOther3Table) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportOther3Table) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportOther3Table[] = formState.actionReportOther3Table.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportOther3Table.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportOther3Table: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportOther3Table> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportSubTitle4`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportOther3Table) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportOther3Table: [
          ...(formState.actionReportOther3Table ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportOther3Table) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportOther3Table ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportOther3Table: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportOther3Table>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionReportOfficerTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.mikroTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionReportOfficerTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionReportOfficerTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionReportOfficerTable[] = formState.actionReportOfficerTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionReportOfficerTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionReportOfficerTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionReportOfficerTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.actionReportOfficer`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionReportOfficerTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.mikroOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionReportOfficerTable: [
          ...(formState.actionReportOfficerTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionReportOfficerTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionReportOfficerTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionReportOfficerTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionReportOfficerTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionTable = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.actionTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionTable) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionTable) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionTable[] = formState.actionTable.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionTable: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionTable> = [
      {
              accessorKey: 'label',
              header: () => t(`${tBase}.companiesAct`),
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionTable) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.actionOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionTable: [
          ...(formState.actionTable ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionTable) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionTable ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionTable: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionTable>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionTable2 = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.actionTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionTable2) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionTable2) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionTable2[] = formState.actionTable2.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionTable2.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionTable2: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionTable2> = [
      {
              accessorKey: 'label',
              header: () => 'Kirjanpitolain mukaiset 3:1a § toimintakertomuksessa on kuvattava kirjanpitovelvollisen',
              className: 'text-left wrap width-middle',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionTable2) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.actionOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-middle',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionTable2: [
          ...(formState.actionTable2 ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionTable2) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionTable2 ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionTable2: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionTable2>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionTable3 = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.actionTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionTable3) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionTable3) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionTable3[] = formState.actionTable3.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionTable3.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionTable3: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionTable3> = [
      {
              accessorKey: 'label',
              header: () => '',
              className: 'text-left wrap width-half',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionTable3) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.actionOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-half',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionTable3: [
          ...(formState.actionTable3 ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionTable3) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionTable3 ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionTable3: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionTable3>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionTable4 = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.actionTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionTable4) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionTable4) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionTable4[] = formState.actionTable4.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionTable4.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionTable4: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionTable4> = [
      {
              accessorKey: 'label',
              header: () => '',
              className: 'text-left wrap width-half',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionTable4) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.actionOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-half',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionTable4: [
          ...(formState.actionTable4 ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionTable4) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionTable4 ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionTable4: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionTable4>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};
export const ActionTable5 = ({
  formState,
  patchFormState,
}: FormFieldProps<PresentationAndContentForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.actionTable`;

  //const data = formState.actionReportOfficerTable ?? [];

  const getRowColor = (row: ActionTable5) => {
    switch (row.shownAttatchment) {
      case true:
        return Colors.green;
      case false:
        return Colors.red;
      case OptionValues.notApplicable:
        return Colors.yellow;
      default:
        return undefined;
    }
  }

  const addRowProps = (row: ActionTable5) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: ActionTable5[] = formState.actionTable5.map(addRowProps);
  function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
    const patch = formState.actionTable5.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...row, [columnId]: value };
                return updatedRow;
            }
            return row;
        });
        const newFormState = { ...formState, actionTable5: patch };
        patchFormState(newFormState);
    }

    let tableColumns: TableColumnDefs<ActionTable5> = [
      {
              accessorKey: 'label',
              header: () => '',
              className: 'text-left wrap width-half',
              placeholder: '',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
      {
              // Boolean
              accessorKey: 'shownAttatchment',
              header: () => t(`${tBase}.shownAttatchment`),
              className: 'text-center width-min',
              showRemark: (item: ActionTable5) => typeof item.shownAttatchment === 'boolean' ? !item.shownAttatchment : false,
              selectProps: {
                  options: Object.values(OptionValues),
                  placeholder: 'Ei sovellu',
                  clearable: true,
                  displayValue: (option: string) => t(`auditing:form.presentationAndContent.actionOptions.${option}`),
                  setValue: (editProps: OnEditProps<string>) => {
                      const value = editProps.value;
                      handleEdit<any>({
                          ...editProps,
                          value: value === 'yes' ? true : value === 'no' ? false : value === 'notApplicable' ? myValue : null,
                      });
                  },
              },
      },
      {
              accessorKey: 'other',
              header: () => t(`${tBase}.other`),
              className: 'text-center width-half',
              onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      },
    ];

    const handleAddNewRow = () => {
      patchFormState({
        ...formState,
        actionTable5: [
          ...(formState.actionTable5 ?? []),
          {
              label: "",
              id: uuid(),
              shownAttatchment: null,
              other: "",
          }
        ],
      });
    };
  
    const handleDeleteRow = ({ id, label }: ActionTable5) => {
      if (confirmDelete(label ?? '')) {
        const patch = (formState.actionTable5 ?? []).filter(
          row => row.id !== id
        );
        patchFormState({ ...formState, actionTable5: patch });
      }
    };
  
    return (
      <>
      <AuditingTable<ActionTable5>
        sectionKey={sectionKey}
        title=''
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
        variant='default'
      />
      </>
    );
};