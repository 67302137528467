import { AxiosRequestConfig } from 'axios';


export interface ApiConfig extends AxiosRequestConfig<any> {}

export const DEFAULT_API_CONFIG: AxiosRequestConfig<any> = {
  //URL Tulee olla "http://" tyylissä että axios löytää tämän osoitteen (API osoitteen)
  //Viety .env tiedostoon
  baseURL: 'https://talvea-api-cb08fe09ffea.herokuapp.com',
  timeout: 30000,
};
