import { TaxRegisterInformation } from '../../../components/auditing/PermanentData.TaxRegisterInformation';
import {
  BeneficialInformationTable,
  BeneficialInformationTable1,
  BeneficialInformationTable2,
  CompanyInformationTable,
  CustomerActivitiesTable,
  CustomerSeprateTable,
  MembersInformationTable,
  OtherInformationTable,
  OtherInformationTable1,
  OtherInformationTable2,
  OtherInformationTable3
} from '../../../components/essentials/communityInformation.CustomerInformation';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { AuditingTemplate, getAddress } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ApplicableLaws, CustomerActivityMonitoring, FailureToNotify, FinancialStatementsBasis, IndustrySpecificRegulation, MunicipalApplicableLaws, MunicipalFinancialStatementsBasis, TaxDebtInformation } from '../planning/permanentDataUtils';

type SectionFormType = communityInformationForm;

const sectionKey: AuditingSectionKey = 'communityInformation';

export const tBase = `auditing:form.${sectionKey}`;
const vocabulary = auditingJson.form.communityInformation;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  companyInformationTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.name,
      inputValue: auditing.customerCompany?.name ?? '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.businessId,
      inputValue: auditing.customerCompany?.businessId ?? '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.registryAuthority,
      inputValue: '',
    },
    {
      id: '4',
      label: vocabulary.communityInformation.label.registrationDate,
      inputValue: '',
    },
    {
      id: '5',
      label: 'Kotipaikka',
      inputValue: auditing.customerCompany?.city ?? '',
    },
    {
      id: '6',
      label: vocabulary.communityInformation.label.address,
      inputValue: auditing.customerCompany
      ? getAddress(auditing.customerCompany)
      : '',
    },
    {
      id: '7',
      label: vocabulary.communityInformation.label.registration,
      inputValue: `${auditing.customerCompany?.name ?? ''}  -  ${auditing.startDate ? new Date(auditing.startDate).toLocaleDateString('fi-FI', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}`,
    },
  ],
  membersInformationTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.chairman,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.members,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.altermembers,
      inputValue: '',
    },
    {
      id: '4',
      label: vocabulary.communityInformation.label.managingDirector,
      inputValue: '',
    },
  ],
  beneficialInformationTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.beneficiaries,
      inputValue: '',
      indexing: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.detailedOwnership,
      inputValue: '',
      indexing: '',
    },
  ],
  beneficiariesRegistered: '',
  beneficiariesRegisteredText: '',
  beneficialInformationTable1: [ // beneficiariesRegistered === true
    {
      id: '1',
      label: vocabulary.communityInformation.label.Beneline4,
      booleanValue: null,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.Beneline5,
      booleanValue: null,
      inputValue: '',
    },
  ],
  beneficialInformationTable2: [ // beneficiariesRegistered === false
    {
      id: '1',
      label: vocabulary.communityInformation.label.Beneline1,
      booleanValue: null,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.Beneline2,
      booleanValue: null,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.Beneline3,
      booleanValue: null,
      inputValue: '',
    },
  ],
  otherInformationTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.capitalStock,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.numberOfShares,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.listOfShareholders,
      inputValue: '',
    },
  ],
  upToDate: false,
  upToDateInfo: '',
  otherInformationTable1: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.shareholderAgreement,
      inputValue: '',
    },
  ],
  shareholderDrawnUp: null,
  shareholderDrawnUpInfo: '',
  otherInformationTable2: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.groupStructure,
      inputValue: '',
    },
  ],
  isGroupStructure: null,
  isGroupStructureInfo: '',
  isEmo: null,
  isEmoInfo: '',
  otherInformationTable3: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.articlesAssociation,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.signatureRights,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.procurators,
      inputValue: '',
    },
  ],
  customerActivitiesTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.customerIndustry,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.customerIndustryInfo,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.groundsForUsingService,
      inputValue: '',
    },
    {
      id: '4',
      label: vocabulary.communityInformation.label.othersToKnowCustomer,
      inputValue: '',
    },
  ],
  customerSeprateTable: [
    {
      id: '1',
      label: vocabulary.communityInformation.label.line1,
      booleanValue: null,
      inputValue: '',
    },
    {
      id: '2',
      label: vocabulary.communityInformation.label.line2,
      booleanValue: null,
      inputValue: '',
    },
    {
      id: '3',
      label: vocabulary.communityInformation.label.line3,
      booleanValue: null,
      inputValue: '',
    },
  ],
  registerExtractUpToDate: null,
  registerExtractUpToDateInfo: '',
  isRemoteSensing: 'yes',
  continuousMonitoring: '',

  customerActivityMonitoring: null,
  customerActivityMonitoringDetails: '',

  registeredOffice: '',

  applicableLaws: {
    limitedCompanyAct: null,
    accountingAct: null,
    taxLaws: null,
    housingCompanyAct: null,
    other: null,
  },
  applicableLawsDetails: '',
  industrySpecificRegulation: {
    regulatedIndustry: null,
    politicalRegulation: null,
    numerousRegulations: null,
    noImportantPoliticalRegulation: null,
  },
  industrySpecificRegulationDetails: '',
  financialStatementsBasis: {
    accountingRegulation: null,
    smallBusinessRegulation: null,
    ifrs: null,
    other: null,
  },
  financialStatementsBasisDetails: '',
  municipalApplicableLaws: {
    municipalLaw: null,
    administrativeLaw: null,
    accountingLaw: null,
    taxLaw: null,
    stateContributionsRegulations: null,
    purchaseLaw: null,
    other: null,
  },
  municipalApplicableLawsDetails: '',
  municipalFinancialStatementsBasis: {
    municipalLaw: null,
    decreeOnMunicipalitysFinancialStatements: null,
    accountingRegulation: null,
    municipalKilaGeneralInstructions: null,
  },

  hasPropertyRentalActivities: null,
  taxDebtInformation: null,
  failureToNotify: null,
  legalSituation: null,
  possibleInformationAboutTheSuspensionOfBusiness: '',

  articlesOfAssociation: null,
  articlesOfAssociationInfo: 'Kirjaa tiedot.',

})

const formFields: FormFields<SectionFormType> = ({ formState }) => [
    // PART I
    formBuilder.accordionGroup({
      titleKey: 'customer.title',
      customRemarkTriggers: ['customer.title'],
      items: [formBuilder.custom(props => (<CompanyInformationTable {...props} />))],
    }),
    // PART II - Hallitus ja edustajat
    formBuilder.accordionGroup({
      titleKey: 'boardRepresentatives.title',
      customRemarkTriggers: ['boardRepresentatives.title'],
      items: [formBuilder.custom(props => (<MembersInformationTable {...props} />))],
    }),
    // PART III - Edunsaajat
    formBuilder.accordionGroup({
      titleKey: 'actualBeneficiaries.title',
      customRemarkTriggers: ['actualBeneficiaries.title'],
      items: [
        formBuilder.custom(props => (<BeneficialInformationTable {...props} />)),
        formBuilder.group({
          items: [
            formBuilder.select({
              plainLabel: vocabulary.communityInformation.label.beneficiariesRegistered,
              accessor: 'beneficiariesRegistered',
              options: Object.keys(auditingJson.yesOrNoOptions), 
              optionDisplayValue: (key: string) => auditingJson.yesOrNoOptions[key as keyof typeof auditingJson.yesOrNoOptions]
            }),
            formBuilder.textArea({
              showContextInfo: 'top-right',
              accessor: 'beneficiariesRegisteredText', 
              plainLabel: 'Tiedot'
            }),
            ...(formState.beneficiariesRegistered === 'no' ? [
              formBuilder.custom(props => (<BeneficialInformationTable1 {...props} />))
            ] : []),
            ...(formState.beneficiariesRegistered === 'yes' ? [
              formBuilder.custom(props => (<BeneficialInformationTable2 {...props} />))
            ] : []),
          ],
        }),
      ],
    }),
    // PART IV - Muut tiedot
    formBuilder.accordionGroup({
      titleKey: 'otherInfo.title',
      customRemarkTriggers: ['otherInfo.title'],
      items: [
        formBuilder.custom(props => (<OtherInformationTable {...props} />)),
        formBuilder.boolean({
          accessor: 'upToDate',
          options: ['no', 'yes'],
          detailsAccessor: 'upToDateInfo',
          detailsHidden: formState.upToDate === true,
        }),
        formBuilder.text({}),
        // Osakesopimus
        formBuilder.group({
          items: [
            formBuilder.custom(props => (<OtherInformationTable1 {...props} />)),
            formBuilder.boolean({
              accessor: 'shareholderDrawnUp',
              options: ['no', 'yes'],
              detailsAccessor: 'shareholderDrawnUpInfo',
              detailsHidden: formState.shareholderDrawnUp === true,
            }),
          ]
        }),
        formBuilder.text({}),
        // Konsernirakenne + Emoyhtiö
        formBuilder.group({
          items: [
            formBuilder.custom(props => (<OtherInformationTable2 {...props} />)),
            formBuilder.boolean({
              accessor: 'isGroupStructure',
              options: ['no', 'yes'],
              detailsAccessor: 'isGroupStructureInfo',
              detailsHidden: formState.isGroupStructure === true,
            }),
            formBuilder.boolean({
              accessor: 'isEmo',
              hidden: formState.isGroupStructure === false,
              options: ['no', 'yes'],
              detailsAccessor: 'isEmoInfo',
              detailsHidden: formState.isEmo === true,
            }),
          ]
        }),
        formBuilder.text({}),
        // Yhtiöjärjestys
        formBuilder.custom(props => (<OtherInformationTable3 {...props} />)),
        formBuilder.boolean({
          accessor: 'articlesOfAssociation',
          options: ['no', 'yes'],
          detailsAccessor: 'articlesOfAssociationInfo',
          detailsHidden: !formState.articlesOfAssociation,
        }),
      ],
    }),
    // PART V - Asiakkaan toimintaa koskevat tiedot
    formBuilder.accordionGroup({
      titleKey: 'informationCustomerActivities.title',
      customRemarkTriggers: ['informationCustomerActivities.title'],
      items: [
        formBuilder.custom(props => (<CustomerActivitiesTable {...props} />)),
      ],
    }),

    // PART IV - Company
    formBuilder.accordionGroup({
      titleKey: 'legalAndRegulatoryNorms',
      hiddenInTemplate: [AuditingTemplate.public],
      items: [
        formBuilder.checkboxGroup({
          accessor: 'applicableLaws',
          options: Object.values(ApplicableLaws),
          detailsAccessor: 'applicableLawsDetails',
          detailsHidden: !formState.applicableLaws?.other,
        }),
  
        formBuilder.checkboxGroup({
          accessor: 'industrySpecificRegulation',
          options: Object.values(IndustrySpecificRegulation),
          showContextInfo: 'top-right',
          detailsAccessor: 'industrySpecificRegulationDetails',
          detailsHidden: !(
            formState.industrySpecificRegulation?.regulatedIndustry ||
            formState.industrySpecificRegulation?.politicalRegulation
          ),
        }),
  
        formBuilder.checkboxGroup({
          accessor: 'financialStatementsBasis',
          options: Object.values(FinancialStatementsBasis),
          detailsAccessor: 'financialStatementsBasisDetails',
          detailsHidden: !formState.financialStatementsBasis?.other,
        }),
      ],
    }),
  
    // PART IV - Municipality
    formBuilder.accordionGroup({
      titleKey: 'legalAndRegulatoryNorms',
      hiddenInTemplate: [AuditingTemplate.private],
      items: [
        formBuilder.checkboxGroup({
          accessor: 'municipalApplicableLaws',
          options: Object.values(MunicipalApplicableLaws),
          detailsAccessor: 'municipalApplicableLawsDetails',
          detailsHidden: !formState.municipalApplicableLaws?.other,
        }),
  
        formBuilder.checkboxGroup({
          accessor: 'industrySpecificRegulation',
          options: Object.values(IndustrySpecificRegulation),
          showContextInfo: 'top-right',
          detailsAccessor: 'industrySpecificRegulationDetails',
          detailsHidden: !(
            formState.industrySpecificRegulation?.regulatedIndustry ||
            formState.industrySpecificRegulation?.politicalRegulation
          ),
        }),
  
        formBuilder.checkboxGroup({
          accessor: 'municipalFinancialStatementsBasis',
          options: Object.values(MunicipalFinancialStatementsBasis),
        }),
      ],
    }),

  /**
   * Tiedot verohallinnon rekistereistä
  */
  // PART VI - Company
  formBuilder.accordionGroup({
    titleKey: 'taxRegisterInformation',
    hiddenInTemplate: [AuditingTemplate.public],
    items: [
      /*
      formBuilder.boolean({
        accessor: 'hasPropertyRentalActivities',
        options: ['no', 'yes'],
      }),
      */
      formBuilder.custom(props => <TaxRegisterInformation
          visibleData={[
            'tradeRegister',
            'taxAdministrationInformation',
            'advanceCollectionRegister',
            'liableForVATOnBusiness',
            'liableForVATOnProperty',
            'employerRegister',
          ]}
          {...props}
        />
      ),

      formBuilder.radioGroup({
        accessor: 'taxDebtInformation',
        options: Object.values(TaxDebtInformation),
      }),

      formBuilder.radioGroup({
        accessor: 'failureToNotify',
        options: Object.values(FailureToNotify),
      }),

      formBuilder.textInput({
        accessor: 'possibleInformationAboutTheSuspensionOfBusiness',
      }),
    ],
  }),
  // PART VI - Municipality
  formBuilder.accordionGroup({
    titleKey: 'taxRegisterInformation',
    hiddenInTemplate: [AuditingTemplate.private],
    items: [
      formBuilder.boolean({
        accessor: 'hasPropertyRentalActivities',
        options: ['no', 'yes'],
      }),

      formBuilder.custom(props => <TaxRegisterInformation
          visibleData={[
            'taxAdministrationInformation',
            'advanceCollectionRegister',
            'liableForVATOnBusiness',
            'liableForVATOnProperty',
            'employerRegister',
          ]}
          {...props}
        />
      ),

      formBuilder.radioGroup({
        accessor: 'taxDebtInformation',
        options: Object.values(TaxDebtInformation),
      }),

      formBuilder.radioGroup({
        accessor: 'failureToNotify',
        options: Object.values(FailureToNotify),
      }),

      formBuilder.textInput({
        accessor: 'possibleInformationAboutTheSuspensionOfBusiness',
      }),
    ],
  }),

    // PART VII - Tietojen ajantasaisuus ja seuranta
    formBuilder.accordionGroup({
      titleKey: 'separateQuestionFields.title',
      customRemarkTriggers: ['separateQuestionFields.title'],
        items: [
          formBuilder.boolean({
            accessor: 'registerExtractUpToDate',
            options: ['no', 'yes'],
            detailsAccessor: 'registerExtractUpToDateInfo',
            detailsHidden: formState.registerExtractUpToDate === true,
          }),
          formBuilder.radioGroup({
            accessor: 'customerActivityMonitoring',
            options: Object.values(CustomerActivityMonitoring),
            detailsAccessor: 'customerActivityMonitoringDetails',
            detailsHidden:
              formState.customerActivityMonitoring !==
              CustomerActivityMonitoring.other,
          }),
          formBuilder.select({
            plainLabel: vocabulary.communityInformation.label.remoteSensing,
            accessor: 'isRemoteSensing',
            options: Object.keys(auditingJson.yesOrNoOptions), 
            optionDisplayValue: (key: string) => auditingJson.yesOrNoOptions[key as keyof typeof auditingJson.yesOrNoOptions]
          }),
          ...(formState.isRemoteSensing === 'yes' ? [
            formBuilder.custom(props => (<CustomerSeprateTable {...props} />)),
          ] : []),
          formBuilder.textArea({accessor: 'continuousMonitoring', plainLabel: vocabulary.communityInformation.label.continuousMonitoring}),
        ],
    })
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;