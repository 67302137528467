import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'auditFirms',
};

export class AuditFirmApi extends ModuleBaseApi<AuditFirm> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}
