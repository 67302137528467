import Todo from '../../../components/Todo';
import AuditingProcedureItem from '../../../components/auditing/AuditingProcedureItem';
import AccountMappings from '../../../components/auditing/procedures/AccountMappings';
import BalanceMatching from '../../../components/auditing/procedures/BalanceMatching';
import ProcedureAccounts from './reUsables/ProcedureAccounts';
import i18n from '../../../i18n';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { AccountClassKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import {
  IsDecisionMade,
  TaxRevenueDifference,
  makeDefaultMatchingOfGovernmentSharesRows,
  makeDefaultMatchingOfTaxRevenuesRows,
} from './governmentTransfersAndTaxRevenuesUtils';

type SectionFormType = GovernmentTransfersAndTaxRevenuesForm;

const sectionKey: AuditingSectionKey = 'governmentTransfersAndTaxRevenues';
const batch = FinancialStatementBatch['M-governmentTransfersAndTaxRevenues'];

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = () => {
  return {
    accountMappings: {
      taxBasedVos: '',
      basicServicesGovernmentShare: '5501-5519', // @TODO: pitäskö tilit hakea AccountKey:n kautta ?
      taxRevenueLossesCompensation: '5700-5899',
      financingOfEducationalAndCulturalActivities: '5700-5899',
      otherGovernmentShares: '5800-5870',
      municipalIncomeTax: '5000-5099',
      propertyTax: '5100-5199',
      corporateTaxRevenue: '5200-5299',
    },
    organisationOfDataCollection: '',
    definitionOfCandidates: '',
    inspectionNotes: '',
    increasedGovernmentShares: '',
    verificationDocumentationPreservation: '',
    settlingForGovernmentSharesStudentInformation: null,
    settlingForGovernmentSharesBasicServices: null,
    allocationAndProcessingOfCommonCosts: '',
    prosessingOfErrorReports: '',
    organisingDataCollection: '',
    connectionToAccountingConfirmed: '',
    basicServicesConclusion: '',
    reliabilityOfGovernmentSharesStudentInformationOtherNotes: '',
    reliabilityOfGovernmentSharesBasicServicesOtherNotes: '',

    balanceMatchingOfTaxRevenues: {
      useCalculationBase: true,
      noCalculationBaseReason: '',
      balanceComparisons: makeDefaultMatchingOfTaxRevenuesRows(),
    },
    balanceMatchingOfTaxRevenuesDifferences: null,
    balanceMatchingOfTaxRevenuesDifferencesDetails: '',

    balanceMatchingOfGovernmentShares: {
      useCalculationBase: true,
      noCalculationBaseReason: '',
      balanceComparisons: makeDefaultMatchingOfGovernmentSharesRows(),
    },
    balanceMatchingOfGovernmentSharesDifferences: null,
    balanceMatchingOfGovernmentSharesDifferencesDetails: '',

    overallAssessmentComments: '',
  };
};

const formFields: FormFields<SectionFormType> = ({
  store,
  auditing,
  formState,
}) => [
  formBuilder.custom(
    <ProcedureAccounts classKey={AccountClassKey[batch]} auditing={auditing} />
  ),

  formBuilder.custom(props => (
    <AccountMappings
      classKey={AccountClassKey[batch]}
      auditing={auditing}
      accountMappings={formState.accountMappings}
      {...props}
    />
  )),

  ...store.auditingStore.getAuditingProceduresBatch(batch).map(procedure => {
    const { actionKey } = procedure;
    const commonItems = [
      formBuilder.custom(
        <AuditingProcedureItem auditingProcedure={procedure} />
      ),
    ];
    let items: FormField<SectionFormType>[] = [];

    switch (actionKey) {
      case 'reliabilityOfGovernmentSharesStudentInformation':
        items = [
          // I
          formBuilder.textArea({
            accessor: 'organisationOfDataCollection',
            showContextInfo: 'top-right',
          }),
          formBuilder.textArea({
            accessor: 'definitionOfCandidates',
            showContextInfo: 'top-right',
          }),
          formBuilder.textArea({
            accessor: 'inspectionNotes',
            showContextInfo: 'top-right',
          }),
          // II
          formBuilder.textArea({
            accessor: 'increasedGovernmentShares',
            showContextInfo: 'top-right',
          }),
          // III
          formBuilder.textArea({
            accessor: 'verificationDocumentationPreservation',
            showContextInfo: 'top-right',
          }),
          // IV
          formBuilder.textArea({
            accessor: 'reliabilityOfGovernmentSharesStudentInformationOtherNotes',
          }),
        ];
        break;
      case 'reliabilityOfGovernmentSharesBasicServices':
        items = [
          // I
          formBuilder.textArea({
            accessor: 'organisingDataCollection',
            showContextInfo: 'top-right',
          }),
          formBuilder.textArea({
            accessor: 'connectionToAccountingConfirmed',
            showContextInfo: 'top-right',
          }),
          formBuilder.textArea({
            accessor: 'allocationAndProcessingOfCommonCosts',
            showContextInfo: 'top-right',
          }),
          formBuilder.textArea({
            accessor: 'prosessingOfErrorReports',
          }),
          // II
          formBuilder.textArea({
            accessor: 'basicServicesConclusion',
            showContextInfo: 'top-right',
          }),
          // III
          formBuilder.textArea({
            accessor: 'reliabilityOfGovernmentSharesBasicServicesOtherNotes',
          }),
        ];
        break;
      case 'settlingForGovernmentSharesStudentInformation':
        items = [
          formBuilder.radioGroup({
            accessor: 'settlingForGovernmentSharesStudentInformation',
            options: Object.values(IsDecisionMade),
          }),
        ];
        break;
      case 'settlingForGovernmentSharesBasicServices':
        items = [
          formBuilder.radioGroup({
            accessor: 'settlingForGovernmentSharesBasicServices',
            options: Object.values(IsDecisionMade),
          }),
        ];
        break;
      case 'taxReconciliationAccountingVersusTaxAuthority':
        items = [
          formBuilder.custom(props => {
            const { formState, patchFormState } = props;
            return (
              <BalanceMatching
                formState={formState.balanceMatchingOfTaxRevenues}
                patchForm={patch =>
                  patchFormState({
                    ...formState,
                    balanceMatchingOfTaxRevenues: {
                      ...formState.balanceMatchingOfTaxRevenues,
                      ...patch,
                    },
                  })
                }
              />
            );
          }),

          formBuilder.radioGroup({
            accessor: 'balanceMatchingOfTaxRevenuesDifferences',
            options: Object.values(TaxRevenueDifference),
            detailsAccessor: 'balanceMatchingOfTaxRevenuesDifferencesDetails',
            detailsHidden: !(
              formState.balanceMatchingOfTaxRevenuesDifferences ===
              TaxRevenueDifference.yes
            ),
            showDetailsContextInfo: 'top-right',
          }),
        ];
        break;
      case 'govermentSharesReconciliationStudentInformation':
        items = [
          formBuilder.custom(props => {
            const { formState, patchFormState } = props;
            return (
              <BalanceMatching
                formState={formState.balanceMatchingOfGovernmentShares}
                patchForm={patch =>
                  patchFormState({
                    ...formState,
                    balanceMatchingOfGovernmentShares: {
                      ...formState.balanceMatchingOfGovernmentShares,
                      ...patch,
                    },
                  })
                }
              />
            );
          }),

          formBuilder.radioGroup({
            accessor: 'balanceMatchingOfGovernmentSharesDifferences',
            options: Object.values(TaxRevenueDifference),
            detailsAccessor:
              'balanceMatchingOfGovernmentSharesDifferencesDetails',
            detailsHidden: !(
              formState.balanceMatchingOfGovernmentSharesDifferences ===
              TaxRevenueDifference.yes
            ),
            showDetailsContextInfo: 'top-right',
          }),
        ];
        break;
      case 'govermentSharesReconciliationBasicServices':
        items = [
          formBuilder.custom(
            <Todo text="Suunnittelu-välilehden otsikot eivät vastaa tarkastuksen kohtia Excel-taulukossa." />
          ),
        ];
        break;
    }

    return formBuilder.accordionGroup({
      title: i18n.t(
        `auditing:auditingProcedureAction.${batch}.${procedure.actionKey}`
      ),
      items: [...commonItems, ...items],
    });
  }),

  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['detailedPlan'],
};

export default section;
