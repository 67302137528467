import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import PasswordForgotForm from './forms/PasswordForgotForm';

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordForgotModal: React.FC<Props> = ({ email, setEmail }) => {
  const { t } = useTranslation(['common', 'action']);

  return (
    <Modal
      buttonProps={{
        text: t('login:forgotPassword'),
        variant: 'link',
      }}
      title={t('login:forgotPassword')}
      description={t('login:forgotPasswordDescription')}
    >
      {({ closeModal }) => (
        <PasswordForgotForm
          email={email}
          setEmail={setEmail}
          onSubmit={closeModal}
        />
      )}
    </Modal>
  );
};

export default PasswordForgotModal;
