import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import AuditingHeader from '../components/layout/AuditingHeader';
import Header from '../components/layout/Header';
import Main from '../components/layout/Main';
import SideNav from '../components/layout/SideNav';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;

  @media screen {
    overflow-y: hidden;
  }

  @media print {
    overflow-y: none;
    height: 10000%; // Shoudn't matter, just make sure it's big enough to cover all content
  }
`;

interface LayoutProps {
  noAuditingHeader?: boolean;
  noSideNav?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  noAuditingHeader,
  noSideNav,
}) => {
  return (
    <Container>
      <Header />
      {!noAuditingHeader && <AuditingHeader />}

      <ContentContainer>
        {!noSideNav && <SideNav />}
        <Main>
          <Outlet />
        </Main>
      </ContentContainer>
    </Container>
  );
};

export default Layout;
