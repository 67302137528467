import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/turnOver.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = OtherOperatingIncomeForm;

const sectionKey: AuditingSectionKey = 'otherOperatingIncome';
export const tBase = `auditing:form.${sectionKey}`;

const batchString = 'JB-otherOperatingIncome';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    procedureAccountsData: {},
      firstTable:[ {
        id: '1',
        account: '',
        CY: 0,
        PY: 0,
        other: '',
      }
    ],
    samplingTable: [
      {
          id: '1',
          label: 'Suurimmat yksiköt',
          one: false,
          more: false,
      },
      {
          id: '2',
          label: 'Monetary unit sampling',
          one: false,
          more: false,
      },
      {
          id: '3',
          label: 'Muu otantamenetelmä',
          one: false,
          more: false,
      },
    ],
    tableColumnData: [
      {
        left: 'Kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle',
        right: auditingJson.TAP,
      },
      {
        left: 'Tapahtumat, jotka olisi pitänyt kirjata, on kirjattu, ja kaikki niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksessä, on esitetty siinä.',
        right: auditingJson.TAY,
      },
      {
        left: 'Tapahtumiin liittyvät rahamäärät ja muu tieto on kirjattu asianmukaisesti, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Tapahtumat on kirjattu oikealle tilikaudelle.',
        right: auditingJson.KAT,
      },
      {
        left: 'Tapahtumat on kirjattu oikeille tileille  / oikeaan erään kirjanpidossa',
        right: auditingJson.LUOK,
      },
      {
        left: 'Tapahtumat on yhdistelty tai jaoteltu asianmukaisesti ja kuvattu selvästi, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston vaatimukset huomioon ottaen',
        right: auditingJson.ESI,
      },
    ],
    textArea: 'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liiketoiminnan muiden tuottojen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella liiketoiminnan muut tuotot ovat ollennaisin osin oikein',

    higherTextField: 'Tarkastukseen on valittu olennaisen virheellisyyden riskin sisältävät, yli PM:n ylittävät yksiköt.',
    monetaryTextField: 'Tarkastettavat yksiköt on valittu monetary unit samplingilla. Käytetyllä otantamenetelmällä suuremmilla laskuilla on suurempi todennäköisyys tulla valituiksi. Viite tiedostoon, jossa otanta määritetty esim. JB.',
    otherTextFieldTwo: 'Tarkastettavat yksiköt on valittu käyttäen perusteena XX  esimerkiksi tarkastettu kaikki yli PM olevat tapahtumat, jonka jälkeen tarkastamaton populaatio on XXX euroa. Valittu tarkastukseen loppupopulaatiosta tapahtumia, niin että tarkastamaton saldo on alle PM:n, tämän jälkeen erään ei sisälly olennaisen virheen riskiä.',

    // Sheets
    useSheet: null,
    sheetdata1: [{
        cellData: [],
    }],
    useSheetTab: null,
  };
};

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  // First Area
  formBuilder.group({
    items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} addCommentField={true}/>),
      formBuilder.custom(props => <TableColumn title='Tarkastuksen tavoitteeina on varmistua seuraavista seikoista / kannanotoista:' {...props} />),
      formBuilder.textArea({
        accessor: 'textArea',
      }),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'individualTransactionInspection',
      lowerElements: [
        formBuilder.boolean({accessor: "useSheet", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => props.formState.useSheet ?
          <SpreadSheet id="sheetdata1" {...props} />
        : null),
      ],
      upperElements: [
        formBuilder.custom(props => <SamplingTableColumn {...props} />),
        ...formState.samplingTable.flatMap(item => 
            item.label === 'Suurimmat yksiköt' && (item.one || item.more) ? [
                formBuilder.textArea({accessor: 'higherTextField'}),
            ] : []
        ),
        ...formState.samplingTable.flatMap(item =>
            item.label === 'Monetary unit sampling' && (item.one || item.more) ? [
                formBuilder.textArea({accessor: 'monetaryTextField'}),
            ] : []
        ),
        ...formState.samplingTable.flatMap(item => 
            item.label === 'Muu otantamenetelmä' && (item.one || item.more) ? [
                formBuilder.textArea({accessor: 'otherTextFieldTwo'}),
            ] : []
        ),
      ]
    },
  ])
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;