import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/turnOver.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { TurnoverProcessDescriptionTable } from './notReUsables/turnOverProcess';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = TurnOverForm;

const sectionKey: AuditingSectionKey = 'turnOver';

export const tBase = `auditing:form.${sectionKey}`;
const batchString = 'J1-turnover';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
    procedureAccountsData: {},
    procedureAccountsManualData: [{
      currentYear: 0,
      priorYear: 0,
      accountName: "",
      comment: ""
    }],
    secondTableColumnData: [
      {
        left: '',
        right: ''
      }
    ],
    samplingTable: [
      {
          id: '1',
          label: 'Suurimmat yksiköt',
          one: false,
          more: false,
      },
      {
          id: '2',
          label: 'Monetary unit sampling',
          one: false,
          more: false,
      },
      {
          id: '3',
          label: 'Muu otantamenetelmä',
          one: false,
          more: false,
      },
    ],
    tableColumnData: [
        {
          left: 'Kirjatut ja esitetyt liikevaihtotapahtumat eivät ole toteutuneet tai ne eivät kuulu asiakkaalle.',
          right: auditingJson.TAP,
        },
        {
          left: 'Liikevaihtotapahtumia, jotka olisi pitänyt kirjata, ei ole kirjattu, tai niihin liittyviä tietoja ei ole esitetty tilinpäätöksessä.',
          right: auditingJson.TAY,
        },
        {
          left: 'Liikevaihtotapahtumiin liittyviä rahamääriä ei ole kirjattu asianmukaisesti, tai niihin liittyviä tietoja ei ole määritetty ja kuvattu tilinpäätöksessä asianmukaisesti.',
          right: auditingJson.OIK_ARV,
        },
        {
          left: 'Liikevaihtotapahtumat on kirjattu väärälle tilikaudelle. Katkoriski voi liittyä joko ennenaikaiseen tuloutukseen tai liian myöhään tapahtuneeseen tuloutukseen.',
          right: auditingJson.KAT,
        },
        {
          left: 'Liikevaihtotapahtumat on kirjattu väärille tileille.',
          right: auditingJson.LUOK,
        },
        {
          left: 'Liikevaihtotapahtumat on yhdistelty tai jaoteltu väärin tai niitä ei ole kuvattu selvästi, tai niihin liittyvät tiedot tilinpäätöksessä eivät ole relevantteja tai ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
          right: auditingJson.ESI,
        },
      ],
      conclusion: 'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liikevaihdon oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella liikevaihto on ollennaisin osin oikein',

      // Liikevaihdon analyyttinen tarkastus
      useSheet: null,
      sheetdata1: [{
          cellData: [],
      }],
      
      // Liikevaihdon katko
      useSheet3: null,
      sheetdata3: [{
          cellData: [],
      }],

      // Hyvityslaskujen tarkastus
      useSheet4: null,
      sheetdata4: [{
          cellData: [],
      }],

      // Liikevaihdon aineistotarkastus
      useSheet2: null,
      sheetdata2: [{
          cellData: [],
      }],
      higherTextField: 'Tarkastukseen on valittu olennaisen virheellisyyden riskin sisältävät, yli PM:n ylittävät yksiköt.',
      monetaryTextField: 'Tarkastettavat yksiköt on valittu monetary unit samplingilla. Käytetyllä otantamenetelmällä suuremmilla laskuilla on suurempi todennäköisyys tulla valituiksi. Viite tiedostoon, jossa otanta määritetty esim. J1.',
      otherTextFieldTwo: 'Tarkastettavat yksiköt on valittu X-menetelmällä. Viite tiedostoon, jossa otanta määritetty esim. J1.',

      processText: '',
      processDate: '',
      processValue: '',
      useSheetTab: null,
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({
      items: [
        formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
        formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} addCommentField />),
        formBuilder.custom(props => <TableColumn title='Mahdollinen virheellisyyden riski liikevaihdossa' headerText='Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan (ISA 315.11)' {...props} />),
        formBuilder.textArea({ accessor: 'conclusion', plainLabel: auditingJson.conclusionTurnover}),
      ]
    }),

    // It ja prosessit
    formBuilder.accordionGroup({
      title: auditingJson.form.itAndProcesses.processDescript,
      items: [
        formBuilder.custom(props => <TurnoverProcessDescriptionTable processText='' processDate={''} processValue='' {...props} />),
      ],
      hidden: hidePartBasedOnItAndProcesses(auditing, "turnover"),
    }),

  ...displayAuditingProcedures(store, batchString, formBuilder, 
    [
      {
        // Liikevaihdon analyyttinen tarkastus
        accordionId: 'processMappingWtAndMainAccountingPrinciples',
        lowerElements: [
          formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheet'}), 
          formBuilder.custom(props => formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null)
        ]
      },
      {
        // Liikevaihdon katko
        accordionId: 'reviewOfIndividualTransactions',
        lowerElements: [
          formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="sheetdata3" {...props} /> : null)
        ]
      },
      {
        // Liikevaihdon aineistotarkastus
        accordionId: 'matchingOfSalesToInvoicing',
        upperElements: [
          formBuilder.custom(props => <SamplingTableColumn {...props} />),
          ...formState.samplingTable.flatMap(item => 
              item.label === 'Suurimmat yksiköt' && (item.one || item.more) ? [
                  formBuilder.textArea({accessor: 'higherTextField'}),
              ] : []
          ),
          ...formState.samplingTable.flatMap(item =>
              item.label === 'Monetary unit sampling' && (item.one || item.more) ? [
                  formBuilder.textArea({accessor: 'monetaryTextField'}),
              ] : []
          ),
          ...formState.samplingTable.flatMap(item => 
              item.label === 'Muu otantamenetelmä' && (item.one || item.more) ? [
                  formBuilder.textArea({accessor: 'otherTextFieldTwo'}),
              ] : []
          ),
        ],
        lowerElements: [
          formBuilder.boolean({accessor: "useSheet2", plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="sheetdata2" {...props} /> : null)

        ]
      },
      {
        // Hyvityslaskujen tarkastus
        accordionId: 'reviewOfRevenueRecognition',
        lowerElements: [
          formBuilder.boolean({accessor: 'useSheet4', plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="sheetdata4" {...props} /> : null)
        ]
      },
    ])
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;