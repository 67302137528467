import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Settings as LuxonSettings } from 'luxon';
import { DEFAULT_LANGUAGE } from '../utils';

const composeResources = (lang: LanguageCode) => ({
  action: require(`./locales/${lang}/action.json`),
  auditFirms: require(`./locales/${lang}/auditFirms.json`),
  customerCompanies: require(`./locales/${lang}/customerCompanies.json`),
  raportit: require(`./locales/${lang}/raportit.json`),
  auditing: require(`./locales/${lang}/auditing.json`),
  common: require(`./locales/${lang}/common.json`),
  frontPage: require(`./locales/${lang}/frontPage.json`),
  login: require(`./locales/${lang}/login.json`),
  route: require(`./locales/${lang}/route.json`),
  myProfile: require(`./locales/${lang}/myProfile.json`),
  notification: require(`./locales/${lang}/notification.json`),
  users: require(`./locales/${lang}/users.json`),
  validate: require(`./locales/${lang}/validate.json`),
  404: require(`./locales/${lang}/404.json`),
  disconnect: require(`./locales/${lang}/disconnect.json`),
  customer: require(`./locales/${lang}/customer.json`),
});

LuxonSettings.defaultLocale = DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  lng: DEFAULT_LANGUAGE,
  returnNull: false,

  ns: ['common'],
  defaultNS: 'common',

  debug: false,

  resources: {
    fi: composeResources('fi'),
  },
});

export const t = i18n.t;

export const i18nChangeLanguage = (language: LanguageCode) => {
  LuxonSettings.defaultLocale = language;
  i18n.changeLanguage(language, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
  });
};

export default i18n;
