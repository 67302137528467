import { RootStore } from '../../../store/RootStore';
import { AuditingTemplate, uuid } from '../../../utils';
import { AccountKeyScoping } from './accountMapKeysScoping';

export enum MaterialityBasedIn {
  actualNumbers = 'actualNumbers',
  budgetedNumbers = 'budgetedNumbers',
}

export type MaterialityBasis =
  | AccountKeyScoping
  | 'combinedRevenues'
  | 'averageOfLiabilitiesAndRevenues'
  | 'other';

export const isSameMaterialityForAllItems = (
  template?: AuditingTemplate
): boolean | null => {
  switch (template) {
    case AuditingTemplate.public:
      return false;
    default:
      return null;
  }
};

export const usingActualNumbersForMateriality = (
  value: MaterialityBasedIn | null
) => value === MaterialityBasedIn.actualNumbers;

// Sum of operating income, tax revenues and government transfers
export const getCombinedRevenues = (
  { auditingStore }: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) =>
  [
    AccountKeyScoping.operatingIncome,
    AccountKeyScoping.taxRevenues,
    AccountKeyScoping.governmentTransfers,
  ].reduce(
    (sum, key) => sum + (auditingStore.getFinancialNumberScoping(key, value) ?? 0),
    0
  );

export const getAverageOfLiabilitiesAndRevenues = (
  store: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) => {
  const { getFinancialNumberScoping } = store.auditingStore;
  const liabilities = getFinancialNumberScoping(AccountKeyScoping.balanceLiabilities, value);
  const revenues = getCombinedRevenues(store, value);
  return liabilities && revenues ? (liabilities + revenues) / 2 : null;
};

const computeMaterialityBaseNumber = ({
  materialityBasedIn,
  materialityBasis,
  store,
}: {
  materialityBasedIn: MaterialityBasedIn | null;
  materialityBasis: MaterialityBasis | null;
  store: RootStore;
}) => {
  if (!usingActualNumbersForMateriality(materialityBasedIn)) return null;

  const { getFinancialNumberScoping } = store.auditingStore;

  switch (materialityBasis) {
    case 'combinedRevenues':
      return getCombinedRevenues(store);
    case 'averageOfLiabilitiesAndRevenues':
      return getAverageOfLiabilitiesAndRevenues(store);
    case 'other':
      return null;
    default:
      return getFinancialNumberScoping(materialityBasis);
  }
};

export function handleMaterialityBasedInChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialityBasedIn = selectedValue as MaterialityBasedIn;

  if (materialityBasedIn !== formState.materialityBasedIn) {
    patchFormState({
      materialityBasedIn,
      materialityBaseNumber: computeMaterialityBaseNumber({
        materialityBasedIn,
        materialityBasis: formState.materialityBasis,
        store,
      }),
    });
  }
}

export const getMaterialityBasisOptions = (
  template?: AuditingTemplate
): MaterialityBasis[] => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        AccountKeyScoping.balanceLiabilities,
        'combinedRevenues',
        'averageOfLiabilitiesAndRevenues',
        AccountKeyScoping.annualContributionMargin,
        'other',
      ];
    case AuditingTemplate.private:
      return [
        AccountKeyScoping.turnover,
        AccountKeyScoping.operatingProfitOrLoss,
        AccountKeyScoping.equity,
        AccountKeyScoping.balanceLiabilities,
        'other',
      ];
    default:
      return [];
  }
};

export function handleMaterialityBasisChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialityBasis = selectedValue as MaterialityBasis;

  if (materialityBasis !== formState.materialityBasis) {
    patchFormState({
      materialityBasis,
      materialityBaseNumber: computeMaterialityBaseNumber({
        materialityBasedIn: formState.materialityBasedIn,
        materialityBasis,
        store,
      }),
    });
  }
}

export const emptySeparateMateriality = (
  separateMaterialy?: Partial<SeparateMateriality>
) => ({
  id: uuid(),
  name: '',
  methodOrBasis: '',
  materiality: null,
  workingMateriality: null,
  singleErrorMateriality: null,
  description: '',
  ...separateMaterialy,
});

export const getDefaultSeparateMaterialities = (
  template?: AuditingTemplate
) => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        emptySeparateMateriality({
          name: 'Olennaisuuden määrittely talousarvion noudattamisen tarkastusta varten',
          methodOrBasis:
            'Sitovuustasolla olennaisena voidaan pitää lähtökohtana vähintään 5 %:n ylitystä. Poikkeaman tulee olla myös euromääräisesti olennainen erä. Olennaisuusrajan määrittelyssä on käytetetty päävastuullisen tilintarkastajan harkintaa ja yhteisöstä muodostettua käsitystä.',
          description:
            'Kunnan toiminnassa ja taloudenhoidossa on noudatettava talousarviota (KuntaL 110 § 5 mom.). Kunnan hallintoa ei ole hoidettu lain ja valtuuston päätösten mukaisesti, jos talousarviopoikkeamat sitovuustasolla ovat olennaisia tai talousarvion määrärahoja on ylitetty tai tuloarvioita alitettu toistuvasti.',
        }),
      ];
    default:
      return [emptySeparateMateriality()];
  }
};
