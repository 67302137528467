import { formatCurrency } from "../../utils";
import AuditingTable from "../table/AuditingTable";
import { OnEditProps, TableColumnDefs } from "../table/Table";



interface CalculatorPart {
    investment: string,
    valueAtPurchase: number,
    currentMarketValue: number,
    lowerValue?: number
}

interface ResultPart {
    lowestValue: number,
    investmentValueOnReport: Object
    resultAfterSubtraction: number
}
interface Props {
    formState: {
        currentInvestments: Record<string, any>;
        investmentValueOnReport: Record<string, any>
    };
    patchFormState: Function;
    id: string;
}

export const CalculationForm = ({ formState, patchFormState, id }:Props) => {
    var investmentValueOnReport = formState.investmentValueOnReport[id];
    var currentMarketValueAlltogether = 0;
    var valueAtPurchaseAlltogether = 0;
    const sectionKey: AuditingSectionKey = 'funding';

    let lowerValue = 0;
    const data: CalculatorPart[] = []

    formState.currentInvestments[id].forEach((investmentRow: CalculatorPart) => {
        let lowerValueOfObject = (
            investmentRow.currentMarketValue < investmentRow.valueAtPurchase ? investmentRow.currentMarketValue : 
            investmentRow.valueAtPurchase
        )
        investmentRow.lowerValue = lowerValueOfObject
        lowerValue += lowerValueOfObject
        data.push(investmentRow)
    })

    const patchForm = async (patch: any) => {
        const newLowerValue = formState.currentInvestments[id].reduce((accumulator:number, investment:CalculatorPart) => 
            accumulator += (investment.valueAtPurchase > investment.currentMarketValue ? investment.currentMarketValue : investment.valueAtPurchase), 0)
    
        lowerValue = newLowerValue
        patchFormState({
            ...formState,
            currentInvestments: {
                ...formState.currentInvestments,
                [id]: patch
            },
        })
    
    }
    
    
    


    const handleAddNewRow = () => {
        patchForm([
            ...formState.currentInvestments[id],
            {
                investment: 'Uusi Osake ' + (formState.currentInvestments[id].length + 1).toString(),
                valueAtPurchase: 0,
                currentMarketValue: 0,
            }
        ])
      };
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
    }: OnEditProps<T>) {
        const updatedInvestments = [...formState.currentInvestments[id]];
        updatedInvestments[rowIndex][columnId] = value
        patchForm(updatedInvestments)
    }

    function handleEditInvestmentValueOnReport<T>({
        value,
    }: OnEditProps<T>) {
        patchFormState({
            ...formState,
            investmentValueOnReport: {
                ...formState.investmentValueOnReport,
                [id]: value,
            }
        });
    }


      const handleDeleteRow = async (data: any) => {
        const newDataList = [...formState.currentInvestments[id]].filter((item: { investment: any; }) => {
                console.log(item.investment, data.investment)
                return item.investment !== data.investment });
        if (newDataList.length > 0) {
            patchForm(newDataList)
        } else {
            alert("Et voi poistaa kaikkia osakkeita.");
        }
      }




    const resultColumns: TableColumnDefs<ResultPart> = [
        {
            accessorKey: 'investmentValueOnReport',
            header: () => 'Rahoituspaperi pääkirjalla (EUR)',
            onNumberEdit: handleEditInvestmentValueOnReport
        },
        {
            accessorKey: 'lowestValue',
            header: () => 'Alin arvo',
            accessorFn: (row: any) => formatCurrency(row.lowestValue),
        },
        {
            accessorKey: 'resultAfterSubtraction',
            header: () => 'Erotus',
            accessorFn: (row: any) => formatCurrency(row.resultAfterSubtraction),
        },
    ]


    formState.currentInvestments[id].forEach((item: CalculatorPart) => {
        currentMarketValueAlltogether += item.currentMarketValue;
        valueAtPurchaseAlltogether += item.valueAtPurchase;
    })

    

    const calculatorPartColumns: TableColumnDefs<CalculatorPart> = [
        {
            accessorKey: 'investment',
            header: () => 'Arvopaperi',
            onEdit: handleEdit
        },
        {
            accessorKey: 'valueAtPurchase',
            header: () => 'Hankintameno',
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'currentMarketValue',
            header: () => 'Markkina arvo',
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'lowerValue',
            header: () => 'Alin arvo',
            accessorFn: (row: any) => formatCurrency(row.lowerValue),
        },
    ]


    const calculatorResultColumns: TableColumnDefs<CalculatorPart> = [
        {
            accessorKey: 'valueAtPurchase',
            header: () => 'Hankintamenot yhteensä',
            accessorFn: (row: any) => formatCurrency(row.valueAtPurchase),
        },
        {
            accessorKey: 'currentMarketValue',
            header: () => 'Markkina arvo yhteensä',
            accessorFn: (row: any) => formatCurrency(row.currentMarketValue),
        },
        {
            accessorKey: 'lowerValue',
            header: () => 'Alin arvo yhteensä',
            accessorFn: (row: any) => formatCurrency(row.lowerValue),
        },
    ]



    return (
        <>
            <AuditingTable<ResultPart>
                sectionKey={sectionKey}
                data={[{
                    lowestValue: lowerValue,
                    investmentValueOnReport: investmentValueOnReport === undefined ? 0 : investmentValueOnReport,
                    resultAfterSubtraction: ((investmentValueOnReport === undefined ? 0 : investmentValueOnReport) - lowerValue)
                }]}
                columns={resultColumns}
                showGlobalFilter={false}
                disableSort
            />
            <AuditingTable<CalculatorPart>
                sectionKey={sectionKey}
                data={data}
                columns={calculatorPartColumns}
                showGlobalFilter={false}
                onAddNewRow={handleAddNewRow}
                onRowDelete={handleDeleteRow}
                animationDisabled={true}
            />
            <br/>
            <AuditingTable<CalculatorPart>
                sectionKey={sectionKey}
                data={[{
                    investment: 'Yhteensä',
                    valueAtPurchase: valueAtPurchaseAlltogether,
                    currentMarketValue: currentMarketValueAlltogether,
                    lowerValue: lowerValue
                }]}
                columns={calculatorResultColumns}
                disableSort
                showGlobalFilter={false}
            />
        </>
    )
}