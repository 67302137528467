import { round } from './math';

// Sanitize text (to lower case & remove [åäö]) and replace ' ' -> '-'.
export const slug = (text: string) =>
  text
    .toLowerCase()
    .trim()
    .replace(/[äå]/g, 'a')
    .replace(/[ö]/g, 'o')
    .replace(/\s\s*/g, ' ')
    .replace(/[\s_]/g, '-')
    .replace(/[^\w-]/g, '')
    .replace(/--*/g, '-')
    .replace(/(^-|-$)/g, '');

interface FormatCurrencyOptions {
  currency?: '€' | '$';
  decimalSeparator?: ',' | '.';
  thousandsSeparator?: ',' | ' ';
  zeroIsUndefined?: boolean;
  hideCurrencySymbol?: boolean;
}
export const formatCurrency = (
  value?: number | null,
  options: FormatCurrencyOptions = {}
) => {
  if (
    value === undefined ||
    value === null ||
    (options.zeroIsUndefined && value === 0)
  )
    return undefined;

  const {
    currency = '€',
    decimalSeparator = ',',
    thousandsSeparator = ' ',
  } = options;

  const roundedValue = round(value).toFixed(2);

  const currencySymbol = options.hideCurrencySymbol ? '' : `${currency}`;

  return `${roundedValue} ${currencySymbol}`
    .replace('.', decimalSeparator)
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
};

export const formatPercent = (value?: number, hidePercentSymbol?: boolean) => {
  if (value === undefined) return undefined;
  const percentSymbol = hidePercentSymbol ? '' : ' %';
  return `${round(value, 10)}${percentSymbol}`.replace('.', ',');
};
