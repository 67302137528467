import { useEffect } from 'react';

const DEFAULT_DELAY = process.env.NODE_ENV === 'development' ? 1000 : 2000;

export const useDelayedAction = (action: () => void, delay = DEFAULT_DELAY) => {
  useEffect(() => {
    const timeout = setTimeout(async () => {
      action();
    }, delay);

    return () => clearTimeout(timeout);
  }, [action, delay]);
};
