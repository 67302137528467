import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../store/RootContext';

const Container = styled.div`
  margin: ${p => p.theme.spacing.lg} 0;
  ul {
    list-style-type: circle;
    padding-left: ${p => p.theme.spacing.lg};
    li {
      margin: ${p => p.theme.spacing.md} 0;
    }
  }
`;

interface Props {
  id?: string;
  sectionKeys: AuditingSectionKey[];
}

const UnfinishedDependingSections: React.FC<Props> = ({ id, sectionKeys }) => {
  const { t } = useTranslation();

  const {
    auditingStore: { getLinkToAuditingSection },
  } = useStore();

  return (
    <Container id={id} data-testid={id}>
      <strong>{t('auditing:finishDependingFormsFirst')}</strong>
      <ul>
        {sectionKeys.map(key => (
          <li key={key}>
            <Link to={getLinkToAuditingSection({ sectionKey: key })}>
              {t(`route:auditing.${key}`)}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default UnfinishedDependingSections;
