import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/styled';
import Icon, { IconType } from '../Icon';
import Spinner from '../Spinner';

const DEFAULT_BG_COLOR = 'primary';
const DEFAULT_COLOR = 'background';

interface StyledButtonProps {
  bg: keyof Colors;
  color: keyof Colors;
  fullWidth?: boolean;
  minWidth?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${p => p.theme.spacing.xsm} 0;
  cursor: pointer;
  user-select: none;

  ${p => p.theme.font.size[16]};

  &.button-variant {
    &__filled,
    &__outlined,
    &__link {
      svg {
        margin-right: ${p => p.theme.spacing.sm};
      }
    }

    &__filled,
    &__outlined {
      width: ${p => (p.fullWidth ? '100%' : p.minWidth ? 'auto' : '240px')};
      border-radius: 10rem;
      padding: ${p => p.theme.spacing.md} ${p => p.theme.spacing.lg};
      border: 1px solid ${p => p.theme.color.background};
    }

    &__filled {
      background-color: ${p => p.theme.color[p.bg]};
      color: ${p => p.theme.color[p.color]};
      :hover:not(.is-disabled) {
        border: 1px solid ${p => p.theme.color[p.bg]};
      }
      :active:not(.is-disabled) {
        border: 1px solid ${p => p.theme.color.background};
      }
    }

    &__outlined {
      border: 1px solid ${p => p.theme.color.primary};
      color: ${p => p.theme.color.primary};
      :hover:not(.is-disabled) {
        background-color: ${p => p.theme.color.primary100};
      }
      :active:not(.is-disabled) {
        background-color: ${p => p.theme.color.primary200};
      }
    }

    &__link {
      color: ${p =>
        p.color === DEFAULT_COLOR ? undefined : p.theme.color[p.color]};
    }

    &__icon-button {
      width: auto;
      margin: 0;
      padding: ${p => p.theme.spacing.md};
      border: none;
      :hover:not(.is-disabled),
      :active:not(.is-disabled) {
        border: none;
      }
      :active:not(.is-disabled) {
        background-color: ${p => p.theme.color.grey200};
      }
      svg {
        margin-right: 0;
      }
    }
  }

  &.is-disabled {
    background-color: ${p => p.theme.color.grey};
    color: ${p => p.theme.color.background};
    border: 1px solid ${p => p.theme.color.background};
    cursor: not-allowed;
    svg {
      fill: ${p => p.theme.color.background};
    }
  }

  @media print {
    display: none;
  }
`;

export interface ButtonProps {
  id?: string;
  text?: string;
  icon?: IconType | React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'filled' | 'link' | 'outlined';
  onClick?: () => void;
  bg?: keyof Colors;
  color?: keyof Colors;
  fullWidth?: boolean;
  minWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  text,
  icon,
  type = 'button',
  variant = 'filled',
  onClick,
  bg = DEFAULT_BG_COLOR,
  color = DEFAULT_COLOR,
  fullWidth = false,
  minWidth = false,
  isLoading = false,
  disabled = false,
}) => {
  const getClassName = () => {
    let className = '';
    switch (variant) {
      case 'link':
        className += ' button-variant__link';
        break;
      case 'outlined':
        className += ' button-variant__outlined';
        break;
      default:
        className += ' button-variant__filled';
        break;
    }

    if (icon && !text) className += ' button-variant__icon-button';
    if (disabled) className += ' is-disabled';

    return className;
  };

  return (
    <StyledButton
      id={id}
      data-testid={id}
      type={type}
      onClick={onClick}
      bg={bg}
      color={color}
      fullWidth={fullWidth}
      minWidth={minWidth}
      className={getClassName()}
      disabled={disabled}
    >
      {isLoading && <Spinner size="sm" negativeColor />}
      {!isLoading && (
        <>
          {typeof icon === 'string' ? (
            // At this point we know icon is for sure type of "IconType"
            // since the icon prop is either IconType or ReactNode
            // (and we just checked it's not ReactNode but string)
            <Icon type={icon as unknown as IconType} />
          ) : (
            icon
          )}
          {text}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
