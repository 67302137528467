import { observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { path, rt } from '../../i18n/utils';
import appRoutes from '../../router/routes';
import { useStore } from '../../store/RootContext';
import { getUserRoutes } from '../../utils/route';
import Accordion from '../Accordion';
import Icon from '../Icon';

const SideNavManage: React.FC = observer(() => {
  const {
    authStore: { role },
  } = useStore();

  const navItems = getUserRoutes(appRoutes.manageRoutes, role.type).filter(
    ({ isHidden }) => !isHidden
  );

  if (!navItems.length) return null;

  return (
    <Accordion title={rt('manage')} defaultOpen>
      {navItems.map(item => (
        <NavLink
          key={item.path}
          to={`/${path('manage', item.path)}`}
          className={({ isActive }) => (isActive ? 'is-active' : '')}
        >
          {({ isActive }) => (
            <>
              {item.iconType && (
                <Icon
                  type={item.iconType}
                  color={isActive ? 'text' : 'grey300'}
                  size={20}
                />
              )}
              {item.title}
            </>
          )}
        </NavLink>
      ))}
    </Accordion>
  );
});

export default SideNavManage;
