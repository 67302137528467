import { BusinessProcesses } from '../../../components/auditing/ItAndProcesses.BusinessProcesses';
import { ProcessDescription } from '../../../components/auditing/ItAndProcesses.ProcessDescription';
import { ControlsToBeTested } from './auditingPlanningUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  BusinessProcessKind,
  CompletedProcedures,
  getDefaultBusinessProcesses,
  ServiceHasRelevanControls,
  ServiceRelatedControls,
  ServiceRelatedControlsNAProcedures,
  ServiceRelatedControlsNoProcedures,
  showServiceRelatedControls,
  showServiceRelatedControlsNAProcedures,
  showServiceRelatedControlsNoProcedures,
} from './itAndProcessesUtils';


type SectionFormType = ItAndProcessesForm;

const sectionKey: AuditingSectionKey = 'itAndProcesses';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    // PART I
    communicatedDetails: '',
    communicatedDate: null,
    businessProcesses: getDefaultBusinessProcesses(auditing.template),
    appropriateItSystems: null,
    appropriateItSystemsDetails: '',
    // PART II
    usingServiceOrganization: null,
    usingServiceOrganizationDetails: '',
    serviceProvider: '',
    serviceDescription: '',
    serviceHasEvidence: null,
    completedProcedures: null,
    completedProceduresDetails: '',
    serviceHasEvidenceDetails: '',
    serviceHasRelevanControls:
      auditing?.auditingPlanning?.form.isControlsToBeTested &&
      auditing?.auditingPlanning?.form.isControlsToBeTested ===
        ControlsToBeTested.controlsNotTrusted
        ? ServiceHasRelevanControls.no
        : null,
    serviceHasRelevanControlsDetails: '',
    serviceRelatedControls: null,
    serviceRelatedControlsDetails: '',
    serviceRelatedControlsNoProcedures: null,
    serviceRelatedControlsNoProceduresDetails: '',
    serviceRelatedControlsNAProcedures: null,
    serviceRelatedControlsNAProceduresDetails: '',
    itAndProcessManual: '',
  };
};

// eslint-disable-next-line
const isITSystemsProcess = ({ kind }: BusinessProcess) => kind === BusinessProcessKind.itSystems;

export const renderProcessDescriptionField =
  (auditing?: Auditing) =>
  (businessProcess: BusinessProcess): FormField<any> =>
      formBuilder.custom(props => (
        <ProcessDescription
          {...props}
          formState={props.formState}
          businessProcess={businessProcess}
          manageRecognisedControls={ auditing?.auditingPlanning?.form.isControlsToBeTested === ControlsToBeTested.controlsDetected }
        />
      ));

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.boolean({
    plainLabel: 'Ohje',
    accessor: 'itAndProcessManual',
    showContextInfo: 'bottom-right',
  }),
  /**
   * PART I
   */
  formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingItEnvironment',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'communicatedDetails',
            showContextInfo: 'top-right',
          }),
          formBuilder.dateField({ accessor: 'communicatedDate' }),
        ],
      }),

      formBuilder.custom(props => <BusinessProcesses {...props} />),

      formBuilder.group({
        items: [
        // Render ONLY "IT system" process description here (rest of descriptions are shown at the end of this form)
        /* ...(formState.businessProcesses ?? [])
          .filter(process => isITSystemsProcess(process) && process.selected)
          .map(renderProcessDescriptionField(auditing)), */

          // Check if there are any processes selected, if toDocument is true, then don't render the process description field
          ...(formState.businessProcesses ?? [])
          .filter(process => {
            if (process.selected) {
              if (process.toDocument) {
                return false;
              } else {
                return true;
              }
            }
            return false;
          })
          .map(renderProcessDescriptionField(auditing)),
        ]
      }),

      formBuilder.boolean({
        accessor: 'appropriateItSystems',
        options: ['no', 'yes'],
        detailsAccessor: 'appropriateItSystemsDetails',
        showDetailsContextInfo: 'bottom-right',
        detailsHidden:
          formState.appropriateItSystems === null ||
          formState.appropriateItSystems,
      }),
    ],
  }),

  /**
   * PART II
   */
  formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingServiceOrganizations',
    items: [
      formBuilder.boolean({
        accessor: 'usingServiceOrganization',
        showContextInfo: 'top-left',
        options: ['no', 'yes'],
        detailsAccessor: 'usingServiceOrganizationDetails',
      }),

      // 1
      formBuilder.textInput({
        accessor: 'serviceProvider',
        showContextInfo: 'top-right',
        hidden: !formState.usingServiceOrganization,
      }),

      // 2
      formBuilder.textArea({
        accessor: 'serviceDescription',
        showContextInfo: 'top-right',
        hidden: !formState.usingServiceOrganization,
      }),

      // 3
      formBuilder.boolean({
        accessor: 'serviceHasEvidence',
        options: ['no', 'yes'],
        hidden: !formState.usingServiceOrganization,
        detailsAccessor: 'serviceHasEvidenceDetails',
      }),

      // 3a
      formBuilder.radioGroup({
        accessor: 'completedProcedures',
        showContextInfo: 'bottom-right',
        options: Object.keys(CompletedProcedures),
        hidden:
          !formState.usingServiceOrganization ||
          formState.serviceHasEvidence === null ||
          formState.serviceHasEvidence,
        detailsAccessor: 'completedProceduresDetails',
        showDetailsContextInfo:
          formState.completedProcedures === CompletedProcedures.acquiredReport
            ? 'bottom-right'
            : undefined,
        detailsHidden:
          formState.completedProcedures === null ||
          formState.completedProcedures ===
            CompletedProcedures.contactedServiceOrganization,
      }),

      // 4
      formBuilder.radioGroup({
        accessor: 'serviceHasRelevanControls',
        options: Object.keys(ServiceHasRelevanControls),
        hidden: !formState.usingServiceOrganization,
        detailsAccessor: 'serviceHasRelevanControlsDetails',
      }),

      // 4a
      formBuilder.radioGroup({
        accessor: 'serviceRelatedControls',
        options: Object.keys(ServiceRelatedControls),
        hidden: !showServiceRelatedControls(formState),
        detailsAccessor: 'serviceRelatedControlsDetails',
      }),

      // 4a-1 : procedures (No)
      formBuilder.radioGroup({
        accessor: 'serviceRelatedControlsNoProcedures',
        options: Object.keys(ServiceRelatedControlsNoProcedures),
        hidden: !showServiceRelatedControlsNoProcedures(formState),
        detailsAccessor: 'serviceRelatedControlsNoProceduresDetails',
        detailsHidden:
          formState.serviceRelatedControlsNoProcedures !==
          ServiceRelatedControlsNoProcedures.other,
      }),

      // 4a-2 : procedures (N/A)
      formBuilder.radioGroup({
        accessor: 'serviceRelatedControlsNAProcedures',
        showContextInfo:
          formState.serviceRelatedControlsNAProcedures ===
          ServiceRelatedControlsNAProcedures.acquiredReport
            ? 'bottom-right'
            : undefined,
        options: Object.keys(ServiceRelatedControlsNAProcedures),
        hidden: !showServiceRelatedControlsNAProcedures(formState),
        detailsAccessor: 'serviceRelatedControlsNAProceduresDetails',
        showDetailsContextInfo:
          formState.serviceRelatedControlsNAProcedures ===
          ServiceRelatedControlsNAProcedures.acquiredReport
            ? 'bottom-right'
            : undefined,
        detailsHidden: !(
          formState.serviceRelatedControlsNAProcedures ===
            ServiceRelatedControlsNAProcedures.other ||
          formState.serviceRelatedControlsNAProcedures ===
            ServiceRelatedControlsNAProcedures.acquiredReport
        ),
      }),
    ],
  }),

  /**
   * PART III
   */

  /* formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingProcessesAndControls',
    items: [
      // Render all selected business processes, except "IT systems", which is shown above at the start of this form.
      ...(formState.businessProcesses ?? [])
        .filter(process => !isITSystemsProcess(process) && process.selected)
        .map(renderProcessDescriptionField(auditing)),
    ],
  }), */
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['auditingPlanning'],
};

export default section;
