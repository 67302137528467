import { t } from '../i18n';
import {} from '../theme';
import { Breakpoints } from '../theme/theme.breakpoints';

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  const isMobile = width <= Breakpoints.Mobile;
  const isTablet = width <= Breakpoints.Tablet;

  return { width, height, isMobile, isTablet };
};

export const confirm = (text: string) => {
  return window.confirm(text);
};

export const confirmDelete = (item: string) => {
  return window.confirm(t('common:confirmDelete', { item }));
};
