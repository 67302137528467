import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = FinancialIncomeForm;
const sectionKey: AuditingSectionKey = 'financialIncome';
export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'XJ-financialIncome';
const jsonVocab = auditingJson.form.financialIncome;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
    procedureAccountsManualData: [{
        currentYear: 0,
        priorYear: 0,
        accountName: "",
        comment: ""
    }],
    procedureAccountsData: {},
    tableColumnData: [
    {
        left: "Kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.",
        right: auditingJson.TAP
    },
    {
        left: "Tapahtumat, jotka olisi pitänyt kirjata, on kirjattu, ja kaikki niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksessä, on esitetty siinä.",
        right: auditingJson.TAY
    },
    {
        left: "Tapahtumiin liittyvät rahamäärät ja muu tieto on kirjattu asianmukaisesti, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti..",
        right: auditingJson.OIK_ARV
    },
    {
        left: "Tapahtumat on kirjattu oikealle tilikaudelle.",
        right: auditingJson.KAT
    },
    {
        left: "Tapahtumat on kirjattu oikeille tileille  / oikeaan erään kirjanpidossa.",
        right: auditingJson.LUOK
    },
    {
        left: "Tapahtumat on yhdistelty tai jaoteltu asianmukaisesti ja kuvattu selvästi, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston vaatimukset huomioon ottaen.",
        right: auditingJson.ESI
    }
    ],

    conclusion: jsonVocab.conclusionFieldValue,

    useSheetTab: null,
    useSheet1: null,
    useSheet2: null,
    useSheet3: null,
    useSheet4: null,
    useSheet5: null,
    useSheet6: null,
    useSheet7: null,
    spreadsheet1:[{
        cellData: [],
    }],
    spreadsheet2:[{
        cellData: [],
    }],
    spreadsheet3:[{
        cellData: [],
    }],
    spreadsheet4:[{
        cellData: [],
    }],
    spreadsheet5:[{
        cellData: [],
    }],
    spreadsheet6:[{
        cellData: [],
    }],
    spreadsheet7:[{
        cellData: [],
    }],
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({
        items: [
            formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
            formBuilder.custom(props => <ProcedureAccountsElement auditing={auditing} {...props} batchString={batchString} addCommentField />),
            formBuilder.custom(props => <TableColumn {...props} />),
            formBuilder.textArea({accessor: 'conclusion', plainLabel: auditingJson.conclusion}),
        ]
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
        {
            accordionId: 'checkingDividendIncome',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet1', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet  id="spreadsheet1" {...props} /> : null),
            ]
        },
        {
            accordionId: 'reconciliationOfInterestExpenses',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet2', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet  id="spreadsheet2" {...props} /> : null),
            ]
        },
        {
            accordionId: 'analyticalExamination',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet  id="spreadsheet3" {...props} /> : null),
            ]
        },
        {
            accordionId: 'analyticalExaminationOfFinancialExpenses',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet4', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet  id="spreadsheet4" {...props} /> : null),
            ]
        },
        {
            accordionId: 'reconciliationOfFinancialExpenses',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet5', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet5 ? <SpreadSheet  id="spreadsheet5" {...props} /> : null),
            ]
        },
        {
            accordionId: 'examinationOfPermanent',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet6', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet6 ? <SpreadSheet  id="spreadsheet6" {...props} /> : null),
            ]
        },
        {
            accordionId: 'examinationOfImpairments',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet7', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet7 ? <SpreadSheet  id="spreadsheet7" {...props} /> : null),
            ]
        },
    ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;