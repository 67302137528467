import React from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../components/layout/Wrapper';
import PasswordChangeForm from '../components/forms/PasswordChangeForm';
import UserForm from '../components/forms/UserForm';
import { useStore } from '../store/RootContext';
import Divider from '../components/Divider';

export const UserProfile: React.FC = () => {
  const { t } = useTranslation('myProfile');

  const {
    authStore: { user },
    userStore: { updateMe },
  } = useStore();

  const handleUserFormSubmit = (
    user: Partial<User & { currentPassword?: string }>
  ) => {
    updateMe(user);
  };

  return (
    <Wrapper width="md">
      <h1 data-testid="view-title">{t('title')}</h1>
      <Divider />

      <h2>{t('myData')}</h2>
      {user && (
        <UserForm
          user={user}
          onSubmit={handleUserFormSubmit}
          disabledFields={['role']}
        />
      )}

      <Divider size="lg" />

      <h2>{t('changePassword')}</h2>
      <PasswordChangeForm onSubmit={handleUserFormSubmit} />

      <Divider size="lg" />
    </Wrapper>
  );
};

export default UserProfile;
