import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { DebtInstallmentsTableColumn } from './reUsables/DebtInstallmentsColumn';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { innerCircleElementDFS } from './reUsables/innerCircle';

type SectionFormType = OtherReceivablesForm;

const sectionKey: AuditingSectionKey = 'otherReceivables';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.otherReceivables;
const batchString = "Q1-otherReceivables"


const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [{
    currentYear: 0,
    priorYear: 0,
    accountName: "",
    comment: ""
  }],
  procedureAccountsData: {},
  debtInstallments: {
    "one": [{
      label: "",
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: false,
      addNewMaterial: false,
    }],
    "two": [{
      label: "",
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: false,
      addNewMaterial: false,
    }],
    "three": [{
      label: "",
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      backgroundMaterial3: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: false,
      addNewMaterial: false,
    }],
},
  tableColumnData: [
    {
      left: "Taseelle merkityt varat ovat olemassa",
      right: auditingJson.OLE,
    },
    {
      left: "Yhtiö on oikeutettu saamaan suorituksen kolmannelta osapuolelta taseelle merkityssä määrässä ja määrä edustaa asiakkaiden velkaa raportointikauden lopussa.",
      right: auditingJson.OVE,
    },
    {
      left: "Saamiset on kirjattu ja kirjaukset on tehty oikeassa summassa ja mahdolliset alaskirjaustarpeet on tunnistettu.",
      right: auditingJson.TAY_ARV,
    },
    {
    left: "Saamiset on luokiteltu taseella asianmukaisesti, kyseisiä eriä koskevat tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti.",
    right: auditingJson.ESI_LUO,
    },
  ],


  conclusion: jsonVocab.conclusionValue,
  // Käsityksen muodostaminen muista saamisista ja siirtosaamisista
  describePeriod: jsonVocab.describePeriodValue,

  // Muiden saamisten tarkastus 
  AMPT: null,
  reference: auditingJson.defaultReferenceValue1,
  ideasGoalsAndCompletedWork: auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  findingsAndComments: auditingJson.defaultFindingsAndCommentsValue,
  useCalculation: null,

  // Siirtosaamisten tarkastus 
  AMPT2: null,
  reference2: auditingJson.defaultReferenceValue1,
  ideasGoalsAndCompletedWork2: auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  findingsAndComments2: auditingJson.defaultFindingsAndCommentsValue,
  useCalculation2: null,

  // Saamisten luokittelun ja esittämisen tarkastus 
  AMPT3: null,
  reference3: jsonVocab.referenceValue2,
  ideasGoalsAndCompletedWork3: jsonVocab.ideasGoalsAndCompletedWork3Value,
  findingsAndComments3: auditingJson.addComment,

  // Muiden saamisten ja siirtosaamisten täydellisyys 
  AMPT4: null,
  reference4: jsonVocab.referenceDebts,
  ideasGoalsAndCompletedWork4: jsonVocab.ideasGoalsAndCompletedWork4Value,
  findingsAndComments4: auditingJson.addComment,

  // "inspectionOtherReceivables": "Muiden saamisten ja siirtosaamisten tarkastus",
  AMPT5: null,
  reference6: auditingJson.defaultReferenceValue1,
  ideasGoalsAndCompletedWork5: auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  findingsAndComments5: auditingJson.defaultFindingsAndCommentsValue,
  useCalculation5: null,

  // Lähipiiri
  ...innerCircleElementDFS,

  spreadsheet1:[{
    cellData: [],
  }],
  spreadsheet2:[{
    cellData: [],
  }],
  spreadsheet3:[{
    cellData: [],
  }],
  spreadsheet4:[{
    cellData: [],
  }],
  spreadsheet5:[{
    cellData: [],
  }],
  // Sheets
  useSheetTab: null,
  useSheet1: null,
  useSheet2: null,
  useSheet3: null,
  useSheet4: null,
  useSheet5: null,
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} addCommentField={true} />),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({ accessor: 'conclusion', plainLabel: auditingJson.conclusion}),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Muiden saamisten tarkastus
      accordionId: 'verificationOfReceivables',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculation'}),
        formBuilder.custom(props => (formState.useCalculation === true ? <DebtInstallmentsTableColumn sectionKey={sectionKey} formState={formState} patchFormState={props.patchFormState} id="one" /> : null)),
        formBuilder.boolean({accessor: 'useSheet1', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet id="spreadsheet1" {...props} /> : null),
      ]
    },
    {
      // checkingMovedReceivables
      accordionId: 'checkingMovedReceivables',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculation2'}),
        formBuilder.custom(props => (formState.useCalculation2 === true ? <DebtInstallmentsTableColumn sectionKey={sectionKey} formState={formState} patchFormState={props.patchFormState} id="two" /> : null)),
        formBuilder.boolean({accessor: 'useSheet2', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="spreadsheet2" {...props} /> : null),
      ]
    },
    {
      // classificationAndPresentation
      accordionId: 'classificationAndPresentation',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="spreadsheet3" {...props} /> : null),
      ]
    },
    {
      // perfectionOfOtherReceivables
      accordionId: 'perfectionOfOtherReceivables',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet4', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="spreadsheet4" {...props} /> : null),
      ]
    },
    {
      // inspectionOtherReceivables
      accordionId: 'inspectionOtherReceivables',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculation5'}),
        formBuilder.custom(props => (formState.useCalculation5 === true ? <DebtInstallmentsTableColumn sectionKey={sectionKey} formState={formState} patchFormState={props.patchFormState} id="three" /> : null)),
        formBuilder.boolean({accessor: 'useSheet5', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet5 ? <SpreadSheet id="spreadsheet5" {...props} /> : null),
      ]
    }
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
