import React, { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { FETCH_URL } from "../utils";
// Styles
import styled from "styled-components";
import Logo from "../components/Logo";
import { GlobalStyle, ThemeProvider, theme } from '../theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);

    p {
        font-size: 1rem;
        margin: 0 0 0.5rem 0;
        text-align: center;
    }
`;

// If we have Disconnected from the server, we will display this page
export const Disconnect: React.FC = observer(() => {
    const { t } = useTranslation("disconnect");
    const { setIsDisconnected } = useContext(AppContext);

    useEffect(() => {
        const checkConnection = () => {
          fetch(FETCH_URL)
            .then((response) => {
              if (response.ok) {
                setIsDisconnected(false);
              } else {
                setIsDisconnected(true);
              }
            })
            .catch((error) => {
              setIsDisconnected(true);
            });
        };
    // Run the check immediately
    checkConnection();

    // Then run it every 15 seconds
    const intervalId = setInterval(checkConnection, 15000);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [setIsDisconnected]);

  return (
    <>
    <ThemeProvider theme={theme}>
        <Container>
            <Logo height={75} />
            <h1 data-testid="view-title">{t('title')}</h1>
            <p>{t('issues')}</p>
            <br></br>
            <p>{t('update')}</p>
            <p>{t('contact')}</p>
            <br></br>
            <p>{t('savingProblem')}</p>
            <GlobalStyle />
        </Container>
    </ThemeProvider>
    </>
  );
});