import AuditingTable from "../table/AuditingTable"
import { TableColumnDefs, OnEditProps } from "../table/Table"
// eslint-disable-next-line
import { formatCurrency } from "../../utils";
import { useState } from "react";

interface Row {
    balance: string;
    balanceAtReskontra: number;
    balanceAtAudit: number;
    comment: string;
    calculatedAmount?: number;
}

interface OtherTypeOfRow {
    customer: string,
    billNumber: number,
    balanceAtReskontra: number,
    paymentDateOnStatement: string,
    paymentConfirmationOnStatement: number,
    calculatedAmount?: number,
    comment: string
}

interface Props {
    formState: any;
    patchFormState: Function;
    id: string;
}



export const AccountsReceivableCalculationForm = ({ formState, patchFormState, id }:Props) => {
    const sectionKey: AuditingSectionKey = 'accountsReceivable';
    const [loading, setLoading] = useState(false)
    if(!formState || !formState?.calculationFormData) return null;

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: [
                    ...formState.calculationFormData[id],
                    {
                        balance: "Uusi asiakas",
                        balanceAtReskontra: 0,
                        balanceAtAudit: 0,
                    }    
                ]
            }
        })
      }
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
        const duplicateData = [...formState.calculationFormData[id]]
        if(columnId === "comment"){
            duplicateData[rowIndex].comment = value
        } else if(columnId === "balance"){
            duplicateData[rowIndex].balance = value
        } else if(columnId === "balanceAtReskontra"){
            duplicateData[rowIndex].balanceAtReskontra = value
        } else if(columnId === "balanceAtAudit"){
            duplicateData[rowIndex].balanceAtAudit = value
        }
        
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: duplicateData
            }
        })
      }

      const handleDeleteRow = async (data: any) => {
        setLoading(true)
          const newDataList = [...formState.calculationFormData[id]].filter((item: { balance: any; }) =>
                item.balance !== data.balance);
          if (newDataList.length > 0) {
            patchFormState({
                ...formState,
                calculationFormData:{
                    ...formState.calculationFormData,
                    [id]: newDataList
                }
            })
          } else {
            alert("Et voi poistaa kaikkia osakkeita.");
          }
        setTimeout(() => setLoading(false), 1)
      }



    const resultColumns: TableColumnDefs<Row> = [
        {
            accessorKey: 'balance',
            header: () => 'Asiakas',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'balanceAtReskontra',
            header: () => 'Saldo reskontralla (EUR)',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'balanceAtAudit',
            header: () => 'Saldo vahvistuksella (EUR)',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'calculatedAmount',
            header: () => 'Erotus',
            className: 'text-left width-middle nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount),
        },
        {
            accessorKey: 'comment',
            header: () => 'Muuta',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },

    ]

    const data = []
    for(var i=0; i<formState.calculationFormData[id].length; i++){
        const dataObject = formState.calculationFormData[id][i]
        data.push({
            balance: dataObject.balance,
            balanceAtReskontra: dataObject.balanceAtReskontra,
            balanceAtAudit: dataObject.balanceAtAudit,
            calculatedAmount: dataObject.balanceAtReskontra - dataObject.balanceAtAudit,
            comment: dataObject.comment
        })
    }

    return !loading ? (
        <>
            <AuditingTable<Row>
                sectionKey={sectionKey}
                data={data}
                columns={resultColumns}
                showGlobalFilter={false}
                disableSort
                onRowDelete={handleDeleteRow}
                onAddNewRow={handleAddNewRow}
            />
        </>
    ): null
}




export const PaymentConfirmationCalculationForm = ({ formState, patchFormState, id }:Props) => {
    const sectionKey: AuditingSectionKey = 'accountsReceivable';
    const [loading, setLoading] = useState(false)
    if(!formState || !formState?.calculationFormData) return null;

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: [
                    ...formState.calculationFormData[id],
                    {
                        balance: "Uusi asiakas",
                        balanceAtReskontra: 0,
                        balanceAtAudit: 0,
                    }    
                ]
            }
        })
      }
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
        const duplicateData = [...formState.calculationFormData[id]]
        duplicateData[rowIndex][columnId] = value
        
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: duplicateData
            }
        })
      }

      const handleDeleteRow = async (data: any) => {
        setLoading(true)
          const newDataList = [...formState.calculationFormData[id]].filter((item: { balance: any; }) =>
                item.balance !== data.balance);
          if (newDataList.length > 0) {
            patchFormState({
                ...formState,
                calculationFormData:{
                    ...formState.calculationFormData,
                    [id]: newDataList
                }
            })
          } else {
            alert("Et voi poistaa kaikkia osakkeita.");
          }
        setTimeout(() => setLoading(false), 1)
      }



    const resultColumns: TableColumnDefs<OtherTypeOfRow> = [
        {
            accessorKey: 'customer',
            header: () => 'Asiakas',
            onEdit: handleEdit,
            className: 'text-left width-max nowrap',
        },
        {
            accessorKey: 'billNumber',
            header: () => 'Laskunumero',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'balanceAtReskontra',
            header: () => 'Saldo reskontralla',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'paymentDateOnStatement',
            header: () => 'Maksupäivä tiliotteella',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'paymentConfirmationOnStatement',
            header: () => 'Maksu suoritus tiliotteella',
            onNumberEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'calculatedAmount',
            header: () => 'Erotus',
            className: 'text-left width-min nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount),
        },
        {
            accessorKey: 'comment',
            header: () => 'Muuta',
            onEdit: handleEdit,
            className: 'text-left width-max nowrap',
        },

    ]

    const data = []
    for(var i=0; i<formState.calculationFormData[id].length; i++){
        const dataObject = formState.calculationFormData[id][i]
        data.push({
            customer: dataObject.customer,
            billNumber: dataObject.billNumber,
            balanceAtReskontra: dataObject.balanceAtReskontra,
            paymentDateOnStatement: dataObject.paymentDateOnStatement,
            paymentConfirmationOnStatement: dataObject.paymentConfirmationOnStatement,
            calculatedAmount: dataObject.balanceAtReskontra - dataObject.paymentConfirmationOnStatement,
            comment: dataObject.comment
        })
    }
    console.log(data)

    return !loading ? (
        <>
            <AuditingTable<OtherTypeOfRow>
                sectionKey={sectionKey}
                data={data}
                columns={resultColumns}
                showGlobalFilter={false}
                disableSort
                onRowDelete={handleDeleteRow}
                onAddNewRow={handleAddNewRow}
            />
        </>
    ): null
}



export const AccountsReceivableBalanceCalculationForm = ({ formState, patchFormState, id }:Props) => {
    const sectionKey: AuditingSectionKey = 'accountsReceivable';
    const [loading, setLoading] = useState(false)
    if(!formState || !formState?.calculationFormData) return null;

    


    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: [
                    ...formState.calculationFormData[id],
                    {
                        balance: "Myyntisaamiset tili ????",
                        balanceAtReskontra: 0,
                        balanceAtAudit: 0,
                    }    
                ]
            }
        })
      }
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
        const duplicateData = [...formState.calculationFormData[id]]
        if(columnId === "comment"){
            duplicateData[rowIndex].comment = value
        } else if(columnId === "balance"){
            duplicateData[rowIndex].balance = value
        } else if(columnId === "balanceAtReskontra"){
            duplicateData[rowIndex].balanceAtReskontra = value
        } else if(columnId === "balanceAtAudit"){
            duplicateData[rowIndex].balanceAtAudit = value
        }
        
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: duplicateData
            }
        })
      }

      const handleDeleteRow = async (data: any) => {
        setLoading(true)
          const newDataList = [...formState.calculationFormData[id]].filter((item: { balance: any; }) =>
                item.balance !== data.balance);
          if (newDataList.length > 0) {
            patchFormState({
                ...formState,
                calculationFormData:{
                    ...formState.calculationFormData,
                    [id]: newDataList
                }
            })
          } else {
            alert("Et voi poistaa kaikkia osakkeita.");
          }
        setTimeout(() => setLoading(false), 1)
      }



    const resultColumns: TableColumnDefs<Row> = [
        {
            accessorKey: 'balance',
            header: () => 'Tili',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'balanceAtReskontra',
            header: () => 'Saldo reskontralla tai erilliskirjanpidossa (EUR)',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'balanceAtAudit',
            header: () => 'Saldo pääkirjanpidossa (EUR)',
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'calculatedAmount',
            header: () => 'Erotus',
            className: 'text-left width-middle nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount),
        },
    ]

    const data = []
    for(var i=0; i<formState.calculationFormData[id].length; i++){
        const dataObject = formState.calculationFormData[id][i]
        data.push({
            balance: dataObject.balance,
            balanceAtReskontra: dataObject.balanceAtReskontra,
            balanceAtAudit: dataObject.balanceAtAudit,
            calculatedAmount: dataObject.balanceAtReskontra - dataObject.balanceAtAudit,
            comment: dataObject.comment
        })
    }

    return !loading ? (
        <>
            <AuditingTable<Row>
                sectionKey={sectionKey}
                data={data}
                columns={resultColumns}
                showGlobalFilter={false}
                disableSort
                onRowDelete={handleDeleteRow}
                onAddNewRow={handleAddNewRow}
            />
        </>
    ): null
}