import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon, { IconType } from './Icon';

interface Props {
  id?: string;
  status?: AuditingStatus;
  isSmall?: boolean;
  animate?: boolean;
}

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  min-width: ${p => (p.isSmall ? 132 : 162)}px;
  justify-content: center;
  padding: ${p => p.theme.spacing.sm} ${p => p.theme.spacing.md};
  border-radius: ${p => p.theme.borderRadius.md};
  border: 2px solid ${p => p.theme.color.success};
  ${p => p.theme.font.size[p.isSmall ? 12 : 16]};
  text-transform: uppercase;
  font-weight: bold;
  user-select: none;

  transform: rotate(-3deg);
  transition: 0.5s;
  animation: ${p =>
    p.animate ? '0.5s ease-out 0s 1 slideFromTop' : undefined};

  ${p => {
    switch (p.status) {
      case 'finished':
        return `
          background-color: ${p.theme.color.background};
          color: ${p.theme.color.success};
          svg {
            fill: ${p.theme.color.success}
          }
        `;
      case 'approved':
        return `
          background-color: ${p.theme.color.success500};
          color: ${p.theme.color.background};
          svg {
            fill: ${p.theme.color.background}
          }
        `;
    }
  }};

  svg {
    margin-right: ${p => (p.isSmall ? p.theme.spacing.sm : p.theme.spacing.md)};
  }
`;

const StatusBadge: React.FC<Props> = ({ id, status, isSmall, animate }) => {
  const { t } = useTranslation();

  if (!status || !['finished', 'approved'].includes(status)) return null;

  const getProps = () => {
    let icon: IconType | undefined = undefined;
    switch (status) {
      case 'finished':
        icon = 'Check';
        break;
      case 'approved':
        icon = 'Approved';
        break;
    }
    return { icon };
  };

  const { icon } = getProps();

  return (
    <Container
      id={id}
      data-testid={id}
      status={status}
      isSmall={isSmall}
      animate={animate}
    >
      {icon && <Icon type={icon} color="success" size={isSmall ? 14 : 20} />}
      {t(`auditing:status.${status}`)}
    </Container>
  );
};

export default StatusBadge;
