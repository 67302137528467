import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/MaterialsAndServices.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils/auditing';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ProcessDescriptionTable } from './reUsables/ProcessDescription';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = MaterialsAndServicesForm;

const sectionKey: AuditingSectionKey = 'materialsAndServices';

export const tBase = `auditing:form.${sectionKey}`;

const vocabulary = auditingJson.form.materialsAndServices;
const batchString = 'K1-materialsAndServices';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
    return {
      procedureAccountsData: {},
        firstTable:[ {
            id: '1',
            purchases: '4000 Ostot',
            CY: 0,
            PY: 0,
        }],
        samplingTable: [
            {
                id: '1',
                label: 'Suurimmat yksiköt',
                one: false,
                more: false,
            },
            {
                id: '2',
                label: 'Muu otantamenetelmä',
                one: false,
                more: false,
            },
        ],
        secondTableColumnData: [
          {
            left: '',
            right: '',
          }
        ],
        tableColumnData: [
            {
              left: 'Kirjatut ja esitetyt ostotapahtumat eivät ole toteutuneet tai ne eivät kuulu asiakkaalle.',
              right: auditingJson.TAP,
            },
            {
              left: 'Ostotapahtumia, jotka olisi pitänyt kirjata, ei ole kirjattu, tai niihin liittyviä tietoja ei ole esitetty tilinpäätöksessä.',
              right: auditingJson.TAY,
            },
            {
              left: 'Ostot on kirjattu väärän arvoisena.',
              right: auditingJson.OIK_ARV,
            },
            {
              left: 'Ostotapahtumat on kirjattu väärälle tilikaudelle.',
              right: auditingJson.KAT,
            },
            {
              left: 'Ostotapahtumat on kirjattu väärille tileille.',
              right: auditingJson.LUOK,
            },
            {
              left: 'Ostotapahtumat on yhdistelty tai jaoteltu väärin tai niitä ei ole kuvattu selvästi, tai niihin liittyvät tiedot tilinpäätöksessä eivät ole relevantteja tai ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
              right: auditingJson.ESI,
            },
        ],
        textArea: 'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi materiaalien ja palveluiden oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella materiaalit ja palvelut ovat ollennaisin osin oikein.',
        // Ostojen analyyttinen tarkastus
        inspectionConclusionPurchases: '1',
        // Kateanalyysi
        inspectionConclusionAnalysis: '1',
 
        // Ostojen aineistotarkastus
        useSheet: null,
        sheetdata1: [{
            cellData: [],
        }],
        useSheet2: null,
        sheetdata2: [{
            cellData: [],
        }],
        useSheet3: null,
        sheetdata3: [{
            cellData: [],
        }],
        sheetdata4: [{
            cellData: [],
        }],
        inspectionObservationsInspection: 'Esitä tarkastushavainnot ja kommentit.',
        moreTextField: 'Valittu tarkastukseen olennaiset, yli PM:n ylittävät ostotilit.',
        moreTextFieldTwo: 'Tarkastettavat yksiköt on valittu X-menetelmällä. Viite tiedostoon, jossa otanta määritetty esim. K1.',
        moreTextFieldTwoInfo: 'Tarkastus kattoi yhteensä XX eur eli X % materiaaleista ja palveluista. Ammatillisen harkinnan perusteella arvioimme kattavuuden riittäväksi.',

        processText: auditing?.otherOperatingExpenses?.form.processText ?? '',
        processDate: auditing?.otherOperatingExpenses?.form.processDate ?? '',
        processValue: auditing?.otherOperatingExpenses?.form.processValue ?? '',
        useSheetTab: null
    }
};

//formBuilder.custom(props => (<FirstTableColumn {...props} />)),

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({
      items: [
        formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
        formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} addCommentField={true}/>),
        formBuilder.custom(props => <TableColumn title='Mahdollinen virheellisyyden riski ostoissa' headerText='Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan (ISA 315.11)' {...props} />),
        formBuilder.textArea({
            accessor: 'textArea',
        }),
      ]
    }),

    // Here we use "hidePartBasedOnItAndProcesses" to hide the part if IT and Processes "toDocument" is false
    formBuilder.accordionGroup({
      title: auditingJson.form.itAndProcesses.processDescript,
      items: [
        formBuilder.group({
          items: [
              formBuilder.custom(props => 
                <ProcessDescriptionTable 
                processText={auditing?.otherOperatingExpenses?.form.processText ?? ''} 
                processDate={auditing?.otherOperatingExpenses?.form.processDate ?? ''}
                processValue={auditing?.otherOperatingExpenses?.form.processValue ?? ''}
                {...props} 
                />
              ),
          ]
        })
      ],
      hidden: hidePartBasedOnItAndProcesses(auditing, "purchases"),
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, 
      [
        {
          accordionId: 'analyticalInspectionOfPurchases',
          upperElements: [
            formBuilder.checkboxGroup({
              accessor: 'inspectionConclusionPurchases',
              options: Object.keys(vocabulary.inspectionConclusionPurchasesOptions)
            })
          ],
          lowerElements: [
            formBuilder.boolean({accessor: "useSheet", plainLabel: auditingJson.useSheet}),
            formBuilder.custom(props => formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null),
          ]
        },
        {
          // Kateanalyysi
          accordionId: 'marginAnalysis',
          upperElements: [
            formBuilder.checkboxGroup({
              accessor: 'inspectionConclusionAnalysis', 
              options: Object.keys(vocabulary.inspectionConclusionAnalysisOptions)
          }),
          ],
          lowerElements: [
            formBuilder.boolean({accessor: "useSheet2", plainLabel: auditingJson.useSheet}),
            formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="sheetdata2" {...props} /> : null),
          ]
        },
        {
          // Ostojen katkon tarkastus
          accordionId: 'inspectionOfInterruption',
          lowerElements: [
            formBuilder.boolean({accessor: "useSheetTab", plainLabel: auditingJson.useSheet}),
            formBuilder.custom(props => formState.useSheetTab ? <SpreadSheet id="sheetdata4" {...props} /> : null)
          ]
        },
        {
          // Ostojen ainestotarkastus
          accordionId: 'materialInspection',
          upperElements: [
            formBuilder.custom(props => <SamplingTableColumn {...props} />),
            ...formState.samplingTable.flatMap(item => 
                item.label === 'Suurimmat yksiköt' && (item.one || item.more) ? [
                    formBuilder.textArea({accessor: 'moreTextField'}),
                ] : []
            ),
            ...formState.samplingTable.flatMap(item => 
                item.label === 'Muu otantamenetelmä' && (item.one || item.more) ? [
                    formBuilder.textArea({accessor: 'moreTextFieldTwo'}),
                    formBuilder.textArea({accessor: 'moreTextFieldTwoInfo'}),
                ] : []
            ),
          ],
          lowerElements: [
            formBuilder.boolean({accessor: "useSheet3", plainLabel: auditingJson.useSheet}),
            formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="sheetdata3" {...props} /> : null),
          
          ]
        }
      ]
    ),

  ];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;