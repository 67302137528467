import Papa from 'papaparse';
import { isInRange, round } from '../../../utils/math';
import { FileType } from '../../../utils/file';
import { generateAccountMap } from './accountMapUtilsScoping';
import { AuditingTemplate } from '../../../utils';

const XLSX = require('xlsx');

const ENCODING = 'utf8'; // 'iso-8859-1'

export const GROUPED_ACCOUNTS_EXAMPLE_CSV = `
;A;B;C;D
1;tili;selite;CY;PY
2;1089;Muut pitkävaikutteiset menot;69 204,96;138 408,48
3;1131;Rakennusten tekniset laitteet 20% menoj;42 521,68;53 151,76
4;1171;Pakettiauto (poito 10%);693 557,52;770 619,60
5;1201;Kalusto ja muu irtain;387 196,08;496 661,52
6;1501;Aineet ja tarvikkeet;710 622,56;409 191,84
7;1511;Keskeneräiset tuotteet;-;204 175,04
8;1635;Pitkäaikaiset konsernilainasaamiset;-;24 000,00
`;

export const NETVISOR_EXAMPLE_CSV = `
;A;B;C;D;E;E;F;G;H;I;J;K
1;Y Oy;;Pääkirja (01.01.2021 - 31.12.2021);;;;;;;;;
2;;;;;;;;;;;;
3;Päivämääräväli;;01.01.2021 - 31.12.2021;;;;;;;;;
4;;;;;;;;;;;;
5;Tili;Nimi;...;...;...;...;...;...;Debet;Kredit;...;...
6;1089;Muut pitkävaikutteiset menot;...;...;...;...;...;...;0;69,57;...;...
7;1089;Muut pitkävaikutteiset menot;...;...;...;...;...;...;0;651,3;...;...
8;1089;Muut pitkävaikutteiset menot;...;...;...;...;...;...;0;69,57;...;...
`;

export enum ParseMethod {
  groupedAccounts = 'groupedAccounts',
  netvisor = 'netvisor',
}

export type ParserCallbackFn = (
  groupedGeneralLedger?: GroupedGeneralLedgerScoping,
  errorKey?: string
) => void;

type CsvData = string[][];

export interface ParserOptions {
  encoding?: string;
  accountMap?: AccountMapScoping;
}

interface PruneDataProps {
  headerRowIndex?: number;
  rowLength: number;
}

export const getExampleCSV = (type?: ParseMethod) => {
  switch (type) {
    case ParseMethod.groupedAccounts:
      return GROUPED_ACCOUNTS_EXAMPLE_CSV.trim();
    case ParseMethod.netvisor:
      return NETVISOR_EXAMPLE_CSV.trim();
    default:
      return undefined;
  }
};

const pruneData = (
  data: CsvData,
  { headerRowIndex, rowLength }: PruneDataProps
) =>
  data
    // Remove header row and all rows above it
    .slice(headerRowIndex !== undefined ? headerRowIndex + 1 : 0)
    // Remove empty rows
    .filter(row => row.length >= rowLength && !!row[0]);

const validAccountCell = (value?: string) =>
  !!value && ['tili'].includes(value.trim().toLowerCase());

/**
 * Classify given account based in account mappings.
 *
 * @param accountRow Account item containing account number.
 * @returns class corresponding to given account number, or null if not found.
 */
const classifyAccount = (accountNumber: number, accountMap: AccountMapScoping) => {
  const flatAccountMap = [
    ...accountMap.incomeStatementAccountMap,
    ...accountMap.balanceSheetAssetsAccountMap,
    ...accountMap.balanceSheetLiabilitiesAccountMap,
  ];

  // Find map item that matches account number.
  // (Item should have start, end and classKey properties set)
  const mapItem = flatAccountMap.find(
    mapItem =>
      'start' in mapItem &&
      'end' in mapItem &&
      mapItem.classKey &&
      isInRange(accountNumber, [mapItem.start, mapItem.end])
  );

  return mapItem?.classKey ?? null;
};

/**
 * Count kredit & debet together.
 */
const kreditMinusDebet = (accountRow: GeneralLedgerScopingItem) => {
  return accountRow.kredit - accountRow.debet;
};

/**
 * Groups general ledger data by accounts.
 * Each account is one group with all debet & kredit values summarised.
 *
 * @param generalLedger General ledger data
 * @returns General ledger data grouped by accounts
 */
const groupGeneralLedgerData = (
  generalLedger: GeneralLedgerScoping,
  accountMap: AccountMapScoping
): GroupedGeneralLedgerScoping => {
  const groupedData: { [key: number]: GroupedGeneralLedgerScopingItem } = {};

  generalLedger.forEach(accountRow => {
    const existingGroup = groupedData[accountRow.account];
    if (existingGroup) {
      groupedData[accountRow.account] = {
        ...existingGroup,
        currentYear:
          (existingGroup.currentYear ?? 0) + kreditMinusDebet(accountRow),
      };
    } else {
      groupedData[accountRow.account] = {
        account: accountRow.account,
        accountName: accountRow.accountName,
        currentYear: kreditMinusDebet(accountRow),
        classKey: classifyAccount(accountRow.account, accountMap),
      };
    }
  });

  return Object.values(groupedData).map(data => ({
    ...data,
    currentYear: data.currentYear ? round(data.currentYear) : undefined,
    priorYear: undefined, // TODO: Get prior year's value from somewhere.
  }));
};

/**
 * Parses Netvisor's general ledger CSV file and transforms it to match our DB schema.
 *
 * @param file CSV file from Netvisor
 * @param callback Callback function having transformed data as a parameter
 */
const parseNetvisorData = (
  file: File,
  callback: ParserCallbackFn,
  options: ParserOptions = {}
) => {
  const {
    encoding = ENCODING,
    accountMap = generateAccountMap(AuditingTemplate.private),
  } = options;

  const row = {
    header: 4,
  };

  const col = {
    account: 0,
    accountName: 1,
    debet: 8,
    kredit: 9,
  };

  const formatNumber = (value: string) =>
    Number(value.replaceAll(/\s/g, '').replace(',', '.'));

  Papa.parse(file, {
    encoding,
    complete: ({ data }: { data: CsvData }) => {
      try {
        const isValidData = validAccountCell(data[row.header][col.account]);

        if (!isValidData) throw new Error('Invalid CSV data');

        const prunedData = pruneData(data, {
          headerRowIndex: row.header,
          rowLength: col.kredit,
        });

        const generalLedger: GeneralLedgerScoping = prunedData.map(row => ({
          account: formatNumber(row[col.account]),
          accountName: row[col.accountName],
          debet: formatNumber(row[col.debet]),
          kredit: formatNumber(row[col.kredit]),
        }));

        const groupedGeneralLedger = groupGeneralLedgerData(
          generalLedger,
          accountMap
        );

        callback(groupedGeneralLedger);
      } catch (error) {
        callback(undefined, 'invalidCsvData');
      }
    },
    error: (error, file) => {
      callback(undefined, 'unknownCsvError');
    },
  });
};

/**
 * Helper to format/parse numbers in csv/excel data
 */
const formatNumber = (value?: string | number) => {
  let number: number | undefined;
  switch (typeof value) {
    case 'string':
      number = parseFloat(value.replaceAll(/\s/g, '').replaceAll(',', '.'));
      break;
    default:
      number = value;
  }
  return number && !isNaN(number) ? number : undefined;
};

/**
 * Parses data where accounts are already grouped and transforms it to match our DB schema.
 *
 * @param data CSV/Excel data as array of arrays
 */
export const parseGroupedAccountsData = (data: CsvData, accountMap: AccountMapScoping) => {
  const row = { header: 0 };
  const col = { account: 0, accountName: 1, currentYear: 2, priorYear: 3 };

  try {
    const isValidData = validAccountCell(data[row.header][col.account]);

    if (!isValidData) throw new Error('Invalid CSV data');

    const prunedData = pruneData(data, {
      headerRowIndex: row.header,
      rowLength: col.priorYear,
    });

    const groupedGeneralLedger: GroupedGeneralLedgerScopingItem[] = prunedData.map(
      row => {
        const account = Number(row[col.account]);
        return {
          account,
          accountName: row[col.accountName],
          currentYear: formatNumber(row[col.currentYear]),
          priorYear: formatNumber(row[col.priorYear]),
          classKey: classifyAccount(account, accountMap),
        };
      }
    );

    return groupedGeneralLedger;
  } catch (error) {
    throw error;
  }
};

/**
 * Read & parse grouped data from EXCEL file
 */
const parseGroupedExcelFile = (
  file: File,
  callback: ParserCallbackFn,
  options: ParserOptions = {}
) => {
  const { accountMap = generateAccountMap(AuditingTemplate.private) } = options;

  const reader = new FileReader();
  reader.onload = evt => {
    const binaryString = evt.target?.result;
    const workbook = XLSX.read(binaryString, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const workSheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
    try {
      callback(parseGroupedAccountsData(data, accountMap));
    } catch (error) {
      callback(undefined, 'invalidDataFormat');
    }
  };
  reader.readAsBinaryString(file);
};

/**
 * Read & parse grouped data from CSV file
 */
const parseGroupedCsvFile = (
  file: File,
  callback: ParserCallbackFn,
  options: ParserOptions = {}
) => {
  const {
    encoding = ENCODING,
    accountMap = generateAccountMap(AuditingTemplate.private),
  } = options;

  Papa.parse(file, {
    encoding,
    complete: ({ data }: { data: CsvData }) => {
      try {
        callback(parseGroupedAccountsData(data, accountMap));
      } catch (error) {
        callback(undefined, 'invalidDataFormat');
      }
    },
    error: (error, file) => {
      callback(undefined, 'unknownCsvError');
    },
  });
};

/**
 * Parses CSV file where accounts are already grouped and transforms it to match our DB schema.
 *
 * @param file CSV file from Netvisor
 * @param callback Callback function having transformed data as a parameter
 */
const parseGroupedAccounts = (
  file: File,
  callback: ParserCallbackFn,
  options: ParserOptions = {}
) => {
  switch (file.type) {
    case FileType.xls:
    case FileType.xlsx:
      parseGroupedExcelFile(file, callback, options);
      break;
    case FileType.csv:
      parseGroupedCsvFile(file, callback, options);
      break;
    default:
    // do nothing
  }
};

export const generalLedgerParser = {
  parseNetvisorData,
  parseGroupedAccounts,
};
