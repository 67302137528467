import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border: 4px dashed #ff9900;
  border-radius: ${p => p.theme.borderRadius.lg};
  padding: ${p => p.theme.spacing.md};
  background-color: #fff5e6;
  color: #ff9900;
  text-align: center;
  white-space: pre-wrap;
  strong {
    ${p => p.theme.font.h4};
  }
`;

interface Props {
  text?: string;
}

const Todo: React.FC<Props> = ({ text }) => {
  return (
    <Container>
      <strong>TODO</strong>
      {text && <p>{text}</p>}
    </Container>
  );
};

export default Todo;
