import styled from 'styled-components';
import logo from '../assets/logo.jpg';

interface LogoProps {
  height?: number; // px
}

const StyledLogo = styled.img<LogoProps>`
  height: ${p => p.height ?? p.theme.ui.headerHeight - 10}px;
`;

const Logo: React.FC<LogoProps> = ({ height }) => (
  <StyledLogo
    data-testid="app-logo"
    className="app-logo"
    src={logo}
    alt="Logo"
    height={height}
  />
);

export default Logo;
