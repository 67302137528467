import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { ReservesTableColumn } from '../../../components/auditing/ReservesTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = ReservesForm;
const sectionKey: AuditingSectionKey = 'reserves';
export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'V-reserves';
const jsonVocab = auditingJson.form.reserves;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
    procedureAccountsManualData: [{
        currentYear: 0,
        priorYear: 0,
        accountName: "",
        comment: ""
      }],
      procedureAccountsData: {},
      tableColumnData: [
        {
            left: "Velat, jotka taseelle on merkitty ovat olemassa",
            right: auditingJson.OLE
        },
        {
            left: "Taseella esitetyt velat ovat yhtiön velvotteita",
            right: auditingJson.OVE
        },
        {
            left: "Kaikki velat, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jorka olisi pitänyt esittää tilinpäätöksellä on esitetty.",
            right: auditingJson.TAY
        },
        {
            left: "Velat on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti. ",
            right: auditingJson.ARV
        },
        {
            left: "Velat on luokiteltu oikein.",
            right: auditingJson.LUOK
        },
        {
            left: "velat on yhdistelty tai jaoteltu asianmukaisesti ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja.",
            right: auditingJson.ESI
        }
      ],
      reservesTable: [{
        id: '1',
        label: '',
        generalLedger: 0,
        confirmation: 0,
        minus: 0,
        other: '',
      }],
      useReadyMe: false,

      conclusion: jsonVocab.conclusionFieldValue,
      describePeriod: jsonVocab.describePeriodValue,

      inspectionConclusionReservation: 'ampt',
      confirmations: null,
      confirmationComment: 'Tarkempi kuvaus, suunnitellut vaihtoehtoiset toimenpiteet',

      referenceReservation: 'Viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon',
      ideasGoalsAndCompletedWorkReservation: 'Kuvaa tehty työ esim. pakollinen varaus kirjataan johdannaisen negatiivista markkina-arvoa vastavaavassa määrässä. Täsmäytetään kirjattu varaus pankin saldovahvistukselle',
      notesAndComments: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja',

    spreadsheet1:[{
        cellData: [],
    }],
    spreadsheet2:[{
        cellData: [],
    }],
    spreadsheet3:[{
        cellData: [],
    }],
    useSheetTab: null,
    useSheet1: null,
    useSheet2: null,
    useSheet3: null,
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({
        items: [
            formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
            formBuilder.custom(props => <ProcedureAccountsElement auditing={auditing} {...props} batchString={batchString} addCommentField />),
            formBuilder.custom(props => <TableColumn {...props} />),
            formBuilder.textArea({accessor: 'conclusion', plainLabel: auditingJson.conclusion}),
        ]
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
        {
            accordionId: 'mandatoryReservationCheck',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet2', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="spreadsheet2" {...props} /> : null),
            ]
        },
        {
            accordionId: 'auditOfVoluntaryReservations',
            lowerElements: [
                formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="spreadsheet3" {...props} /> : null),
            ]
        },
        {
            accordionId: 'checkOfReservation',
            upperElements: [
                formBuilder.group({
                    items: [
                        formBuilder.boolean({
                            accessor: 'confirmations', 
                            options: ['askedConfirmationOptions.one','askedConfirmationOptions.two'],
                        }),
                        formBuilder.textArea({
                            accessor: 'confirmationComment', 
                            plainLabel: jsonVocab.confirmationComment,
                            hidden: !formState.confirmations,
                        }),
                    ]
                })
            ],
            lowerElements: [
                formBuilder.group({
                    items: [
                        formBuilder.boolean({accessor: 'useReadyMe'}),
                        formBuilder.custom(props => formState.useReadyMe ? <ReservesTableColumn {...props} /> : null),
                    ],
                }),
                formBuilder.boolean({accessor: 'useSheet1', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet id="spreadsheet1" {...props} /> : null),
            ]
        }
    ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;