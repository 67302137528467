import { divide } from '../../../utils';
import { AccountKey } from '../planning/accountMapKeys';

/**
 * Define rows for auditing procedure unit rows.
 */
export const analyticalReviewOfWagesRows = () => [
  {
    key: 'wagesTotal',
  },
  {
    key: 'pensionExpenses',
  },
  {
    key: 'otherPersonelSideCosts',
  },
  {
    key: 'personelSideCostsTotal',
  },
  {
    key: 'personelCostsTotal',
  },
  {
    key: 'turnover',
  },
  {
    key: 'wagePerPersonInMonth',
  },
  {
    key: 'salesPerPerson',
  },
];

/**
 * Calculate common params for table rows
 */
const getPercentagesAndDiffs = (
  cyValue: number,
  pyValue: number,
  cyWagesAndSalaries: number,
  pyWagesAndSalaries: number
) => {
  const diffEur = cyValue - pyValue;
  return {
    cyPercentageOfWages: divide(cyValue, cyWagesAndSalaries) * 100,
    pyPercentageOfWages: divide(pyValue, pyWagesAndSalaries) * 100,
    diffEur: Math.abs(diffEur),
    diffPercentage: divide(diffEur, pyValue) * 100,
  };
};

/**
 * Define computation rules/formulas for each row.
 */
const computationRules: AnalyticalReviewOfWagesComputationRules = {
  wagesTotal: props => {
    const { auditingStore } = props;
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const percentagesAndDiffs = getPercentagesAndDiffs(
      cyWagesAndSalaries,
      pyWagesAndSalaries,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'wagesTotal',
      cyEur: Math.abs(cyWagesAndSalaries),
      pyEur: Math.abs(pyWagesAndSalaries),
      ...percentagesAndDiffs,
    };
  },
  pensionExpenses: props => {
    const { getAccountMappingValues, auditingStore } = props;
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const [pensionExpensesCy] = getAccountMappingValues.cy('pensionExpenses');
    const [pensionExpensesPy] = getAccountMappingValues.py('pensionExpenses');
    const percentagesAndDiffs = getPercentagesAndDiffs(
      pensionExpensesCy,
      pensionExpensesPy,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'pensionExpenses',
      cyEur: Math.abs(pensionExpensesCy),
      pyEur: Math.abs(pensionExpensesPy),
      ...percentagesAndDiffs
    };
  },
  otherPersonelSideCosts: props => {
    const { auditingStore } = props;
    const cyWagesAndSalaries =
    auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const otherSocialSecurityCosts = auditingStore.getFinancialItem(
      AccountKey.otherSocialSecurityCosts
    );
    const cyEur = otherSocialSecurityCosts?.currentYear
      ? otherSocialSecurityCosts?.currentYear
      : 0;    
    const pyEur = otherSocialSecurityCosts?.priorYear
      ? otherSocialSecurityCosts?.priorYear
      : 0;
    const percentagesAndDiffs = getPercentagesAndDiffs(
      cyEur,
      pyEur,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'otherPersonelSideCosts',
      cyEur: Math.abs(cyEur),
      pyEur: Math.abs(pyEur),
      ...percentagesAndDiffs
    };
  },
  personelSideCostsTotal: props => {
    const { getAccountMappingValues, auditingStore } = props;
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const [pensionExpensesCy] = getAccountMappingValues.cy('pensionExpenses');
    const [pensionExpensesPy] = getAccountMappingValues.py('pensionExpenses');
    const otherSocialSecurityCosts = auditingStore.getFinancialItem(
      AccountKey.otherSocialSecurityCosts
    );
    const personelSideCostsCompinedCy = otherSocialSecurityCosts?.currentYear
      ? pensionExpensesCy + otherSocialSecurityCosts?.currentYear
      : 0;
    const personelSideCostsCompinedPy = otherSocialSecurityCosts?.priorYear
      ? pensionExpensesPy + otherSocialSecurityCosts?.priorYear
      : 0;
    const percentagesAndDiffs = getPercentagesAndDiffs(
      personelSideCostsCompinedCy,
      personelSideCostsCompinedPy,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'personelSideCostsTotal',
      cyEur: Math.abs(personelSideCostsCompinedCy),
      pyEur: Math.abs(personelSideCostsCompinedPy),
      ...percentagesAndDiffs
    };
  },
  personelCostsTotal: props => {
    const { getAccountMappingValues, auditingStore } = props;
    const [personelCostsCy] = getAccountMappingValues.cy('personelCosts');
    const [personelCostsPy] = getAccountMappingValues.py('personelCosts');
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const percentagesAndDiffs = getPercentagesAndDiffs(
      personelCostsCy,
      personelCostsPy,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'personelCostsTotal',
      cyEur: Math.abs(personelCostsCy),
      pyEur: Math.abs(personelCostsPy),
      ...percentagesAndDiffs,
      cyPercentageOfWages: null,
      pyPercentageOfWages: null,
    };
  },
  turnover: props => {
    const { auditingStore } = props;
    const turnover = auditingStore.getFinancialItem(AccountKey.turnover);
    const cyWagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const pyWagesAndSalaries =
      auditingStore.getFinancialNumber(
        AccountKey.wagesAndSalaries,
        'priorYear'
      ) ?? 0;
    const percentagesAndDiffs = getPercentagesAndDiffs(
      turnover?.currentYear ?? 0,
      turnover?.priorYear ?? 0,
      cyWagesAndSalaries,
      pyWagesAndSalaries
    );
    return {
      key: 'turnover',
      cyEur: turnover?.currentYear,
      pyEur: turnover?.priorYear,
      ...percentagesAndDiffs,
      cyPercentageOfWages: null,
      pyPercentageOfWages: null
    };
  },
  wagePerPersonInMonth: props => {
    // ( palkat yhteensä / ( henkilöiden lkm x ( tilikauden pituus + 0,5 ) )
    const { auditingStore } = props;
    const wagesAndSalaries =
      auditingStore.getFinancialNumber(AccountKey.wagesAndSalaries) ?? 0;
    const numberOfEmployeesCy =
      auditingStore.auditing?.personelCosts?.form.averageNumberOfPersonsCY ?? 0;
    const accountingPeriod = auditingStore.getFinancialPeriodMonthCount() ?? 0;
    const wagePerPersonInMonthCy =
      wagesAndSalaries / (numberOfEmployeesCy * (accountingPeriod + 0.5));
    return {
      key: 'wagePerPersonInMonth',
      cyEur: Math.abs(wagePerPersonInMonthCy),
      pyEur: null,
      cyPercentageOfWages: null,
      pyPercentageOfWages: null,
      diffEur: null,
      diffPercentage: null,
    };
  },
  salesPerPerson: props => {
    const { auditingStore } = props;
    const turnover = auditingStore.getFinancialItem(AccountKey.turnover);
    const cyNumberOfEmployees =
      auditingStore.auditing?.personelCosts?.form.averageNumberOfPersonsCY ?? 0;
    const pyNumberOfEmployees =
      auditingStore.auditing?.personelCosts?.form.averageNumberOfPersonsPY ?? 0;      
    const cyEur = divide(turnover?.currentYear, cyNumberOfEmployees);
    const pyEur = divide(turnover?.priorYear, pyNumberOfEmployees);
    const diffEur = cyEur - pyEur;
    const diffPercentage = (diffEur / pyEur) * 100;
    return {
      key: 'salesPerPerson',
      cyEur: Math.abs(cyEur),
      pyEur: Math.abs(pyEur),
      cyPercentageOfWages: null,
      pyPercentageOfWages: null,
      diffEur: Math.abs(diffEur),
      diffPercentage: diffPercentage,
    };
  },
};

export const analyticalReviewOfWagesRowsComputationRules = computationRules;
