import React from 'react';
import { useTranslation } from 'react-i18next';

export const NotFound404: React.FC = () => {
  const { t } = useTranslation('404');

  return (
    <div>
      <h1 data-testid="view-title">{t('title')}</h1>
    </div>
  );
};

export default NotFound404;
