import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  children: React.ReactNode;
}

/**
 * Sets title for the document
 */
const ViewWrapper: React.FC<Props> = ({ title, children }) => {
  const { t } = useTranslation('common');

  /**
   * Set document title
   */
  useEffect(() => {
    if (title !== undefined && title !== null) {
      const baseTitle = t('appName') ?? '';
      document.title = title.length ? `${baseTitle} - ${title}` : baseTitle;
    }
  }, [t, title]);

  return <>{children}</>;
};

export default ViewWrapper;
