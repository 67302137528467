import { useTranslation } from 'react-i18next';
//import { confirmDelete, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'materialsAndServices';

export const SamplingTableColumn = ({ formState, patchFormState }: FormFieldProps<TurnOverForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}.samplingTable`;

    // Data for table
    const data = formState.samplingTable ?? [    
        {
            id: '1',
            label: 'Suurimmat yksiköt',
            one: false,
            more: false,
        },
        {
            id: '2',
            label: 'Monetary unit sampling',
            one: false,
            more: false,
        },
        {
            id: '3',
            label: 'Muu otantamenetelmä',
            one: false,
            more: false,
        },
    ];

    function handleEdit<T>({ itemId, rowIndex, columnId, value, }: OnEditProps<T>) {
        const patch = formState.samplingTable.map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
        });
        patchFormState({ ...formState, samplingTable: patch });
    }

    const tableColumns: TableColumnDefs<SamplingTableColumn> = [
        {
            accessorKey: 'label',
            header: () => '',
            className: 'width-max text-bold',
        },
        {
            accessorKey: 'one',
            header: () => t(`${tBase}.one`),
            className: 'width-min text-center',
            onBooleanEdit: (editProps: OnEditProps<boolean>) =>
                handleEdit<boolean>(editProps),
        },
/*         {
            accessorKey: 'more',
            header: () => t(`${tBase}.more`),
            className: 'width-min text-center',
            onBooleanEdit: (editProps: OnEditProps<boolean>) =>
                handleEdit<boolean>(editProps),
        }, */
    ];

    return (
        <AuditingTable<SamplingTableColumn>
        sectionKey={sectionKey}
        title='Käytetty otantomenetelmä'
        //onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        //onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
    );
};