import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import {
  AuditorInformationKind,
  emptyAuditorInformationItem,
} from '../../views/auditingSections/planning/permanentDataUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

type FormType = PermanentDataForm;
type TableItemType = AuditorInformationItem;

const sectionKey: AuditingSectionKey = 'permanentData';
const formFieldKey: keyof FormType = 'auditorInformation';

const makeEmptyItem = emptyAuditorInformationItem;

export const AuditorInformation = ({
  formState,
  patchFormState,
}: FormFieldProps<FormType>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.${formFieldKey}`;

  const data = formState[formFieldKey] ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.auditorInformation.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, [formFieldKey]: patch });
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      [formFieldKey]: [...(formState[formFieldKey] ?? []), makeEmptyItem()],
    });
  };

  const handleDeleteRow = ({ id, kind, value }: TableItemType) => {
    const kindText = kind ? t(`${tBase}.kindOptions.${kind}`) : '';
    if (confirmDelete(`${kindText} ${value ?? ''}`)) {
      const patch = (formState[formFieldKey] ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, [formFieldKey]: patch });
    }
  };

  const tableColumns: TableColumnDefs<TableItemType> = [
    {
      accessorKey: 'kind',
      className: 'width-min',
      header: () => t(`${tBase}.kind`),
      selectProps: {
        options: Object.values(AuditorInformationKind),
        displayValue: (option: string) => t(`${tBase}.kindOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },
    {
      accessorKey: 'value',
      header: () => t(`${tBase}.value`),
      className: 'width-half',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'upToDate',
      className: 'width-min',
      header: () => t(`${tBase}.upToDate`),
      contextInfoProps: {
        position: 'top-left',
        text: t(`${tBase}.upToDateInfo`),
      },
      showRemark: (item: TableItemType) =>
        typeof item.upToDate === 'boolean' ? !item.upToDate : false,
      selectProps: {
        options: ['false', 'true'],
        placeholder: 'N/A',
        clearable: true,
        displayValue: (option: string) =>
          t(`auditing:form.permanentData.upToDateOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        },
      },
    },
    {
      accessorKey: 'source',
      header: () => t(`${tBase}.source`),
      className: 'width-half',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <AuditingTable<TableItemType>
      sectionKey={sectionKey}
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
