import { useTranslation } from 'react-i18next';
import { confirmDelete, formatCurrency, uuid } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';
import React, { useState } from 'react';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
  .custom-button {
    margin-bottom: 5px;
    text-decoration: underline;
  }
`;

const sectionKey: AuditingSectionKey = 'otherLiabilities';

export const DebtInstallmentsTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<OtherLiabilitiesForm>) => {

  const [showBackgroundMaterial2, setShowBackgroundMaterial2] = useState(true);
  const [buttonText, setButtonText] = useState('Piilota Saldo taustamateriaali 2');

  const [showBackgroundMaterial3, setShowBackgroundMaterial3] = useState(false);
  const [buttonText2, setButtonText2] = useState('Näytä Saldo taustamateriaali 3');

  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.debtInstallments`;
  const data = formState.debtInstallments ?? [];

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
  }: OnEditProps<T>) {
      const patch = formState.debtInstallments.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) {
              const updatedRow = { ...row, [columnId]: value };
              // MINUS - PÄÄKIRJA - TAUSTAMATERIAALI1 - TAUSTAMATERIAALI2
              if (columnId === 'difference' || columnId === 'ledgerBalance' || columnId === 'backgroundMaterial1' || columnId === 'backgroundMaterial2' || columnId === 'backgroundMaterial3') {
                  let materials = updatedRow.backgroundMaterial1 + updatedRow.backgroundMaterial2 + (!updatedRow.backgroundMaterial3 ? 0: updatedRow.backgroundMaterial3) || 0;
                  let difference = updatedRow.ledgerBalance - materials || 0;
                  updatedRow.difference = difference;
                  // Format and seprate Tenths, Hundredths and Thousandths with commas (updatedRow.difference = number (not a string))
                  formatNumber(updatedRow.difference);
              }
              return updatedRow;
          }
          return row;
      });
      const newFormatState = { ...formState, debtInstallments: patch };
      patchFormState(newFormatState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  };

  const toggleBackgroundMaterial2 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial2 = !showBackgroundMaterial2;
    setShowBackgroundMaterial2(updatedShowBackgroundMaterial2);
    setButtonText(updatedShowBackgroundMaterial2 ? 'Näytä Saldo taustamateriaali 2' : 'Piilota Saldo taustamateriaali 2');
    const updatedFormState = formState.debtInstallments.map((row) => {
      const materials = showBackgroundMaterial2 ? row.backgroundMaterial1 + row.backgroundMaterial2 : row.backgroundMaterial1;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        hidden: updatedShowBackgroundMaterial2 ? false : true,
        backgroundMaterial2: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, debtInstallments: updatedFormState };
    patchFormState(newFormatState);
  };

  const addNewMaterial3 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial3 = !showBackgroundMaterial3;
    setShowBackgroundMaterial3(updatedShowBackgroundMaterial3);
    setButtonText2(updatedShowBackgroundMaterial3 ? 'Piilota Saldo taustamateriaali 3' : 'Näytä Saldo taustamateriaali 3');
    const updatedFormState = formState.debtInstallments.map((row) => {
      const materials = row.backgroundMaterial1 + row.backgroundMaterial2;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        addNewMaterial: updatedShowBackgroundMaterial3 ? true : false,
        backgroundMaterial3: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, debtInstallments: updatedFormState };
    patchFormState(newFormatState);
  };

  let tableColumns: TableColumnDefs<DebtInstallmentsTable> = [
    {
        accessorKey: 'label',
        header: () => t(`${tBase}.label`),
        className: 'text-center width-middle',
        placeholder: '2921...',
        onEdit: (editProps: OnEditProps<string>) => {
            handleEdit<string>(editProps);
    },
    },
    {
        accessorKey: 'ledgerBalance',
        header: () => t(`${tBase}.ledgerBalance`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        cell: ({ cell }) => <div>{formatNumber(cell.row.original.ledgerBalance)} €</div>,
    },
    {
        accessorKey: 'backgroundMaterial1',
        header: () => t(`${tBase}.backgroundMaterial1`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        //cell: ({ cell }) => <div>{formatNumber(cell.row.original.backgroundMaterial1)} €</div>,
    },
    ...(formState.debtInstallments.find((row) => row.hidden === true)
      ? [
          {
            accessorKey: 'backgroundMaterial2',
            header: () => t(`${tBase}.backgroundMaterial2`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    ...(formState.debtInstallments.find((row) => row.addNewMaterial === true)
      ? [
          {
            accessorKey: 'backgroundMaterial3',
            header: () => t(`${tBase}.backgroundMaterial3`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    {
      accessorKey: 'difference',
      header: () => t(`${tBase}.difference`) + ' €',
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.difference);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.difference)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.backgroundMaterial`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
    },
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.other`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    // Check if hidden is true or false
    const hidden = showBackgroundMaterial2 ? false : true;
    patchFormState({
      ...formState,
      debtInstallments: [
        ...(formState.debtInstallments ?? []),
        {
            label: "",
            id: uuid(),//Math.floor(Math.random()*100).toString(),
            ledgerBalance: 0,
            backgroundMaterial1: 0,
            backgroundMaterial2: 0,
            backgroundMaterial3: 0,
            difference: 0,
            backgroundMaterial: '',
            other: '',
            hidden: hidden,
            addNewMaterial: false,
        }
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: DebtInstallmentsTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.debtInstallments ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, debtInstallments: patch });
    }
  };

  return (
    <>
    <AuditingTable<DebtInstallmentsTable>
      sectionKey={sectionKey}
      title='Velkaerien tarkastus'
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
      variant='default'
    />
    <Container>
      <button className="custom-button" onClick={toggleBackgroundMaterial2}>{buttonText}</button><br></br>
      {/* If toggleBackgroundMaterial2 is true show new button (addNewMaterial3) */}
      {!showBackgroundMaterial2 &&
        <button className="custom-button" onClick={addNewMaterial3}>{buttonText2}</button>
      }
      <div className="additional-info">Erotus: Pääkirjan saldo - Saldo taustamateriaalilla </div>
    </Container>
    </>
  );
};

export const OtherDebtsTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<OtherLiabilitiesForm>) => {

  const [showBackgroundMaterial2, setShowBackgroundMaterial2] = useState(true);
  const [buttonText, setButtonText] = useState('Piilota Saldo taustamateriaali 2');

  const [showBackgroundMaterial3, setShowBackgroundMaterial3] = useState(false);
  const [buttonText2, setButtonText2] = useState('Näytä Saldo taustamateriaali 3');

  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.debtInstallments`;

  const data = formState.otherDebts ?? [];

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
  }: OnEditProps<T>) {
      const patch = formState.otherDebts.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) {
              const updatedRow = { ...row, [columnId]: value };
              // MINUS - PÄÄKIRJA - TAUSTAMATERIAALI1 - TAUSTAMATERIAALI2
              if (columnId === 'difference' || columnId === 'ledgerBalance' || columnId === 'backgroundMaterial1' || columnId === 'backgroundMaterial2' || columnId === 'backgroundMaterial3') {
                  let materials = updatedRow.backgroundMaterial1 + updatedRow.backgroundMaterial2 + (!updatedRow.backgroundMaterial3 ? 0: updatedRow.backgroundMaterial3) || 0;
                  let difference = updatedRow.ledgerBalance - materials || 0;
                  updatedRow.difference = difference;
                  // Format and seprate Tenths, Hundredths and Thousandths with commas (updatedRow.difference = number (not a string))
                  formatNumber(updatedRow.difference);
              }
              return updatedRow;
          }
          return row;
      });
      const newFormatState = { ...formState, otherDebts: patch };
      patchFormState(newFormatState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  };

  const toggleBackgroundMaterial2 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial2 = !showBackgroundMaterial2;
    setShowBackgroundMaterial2(updatedShowBackgroundMaterial2);
    setButtonText(updatedShowBackgroundMaterial2 ? 'Näytä Saldo taustamateriaali 2' : 'Piilota Saldo taustamateriaali 2');
    const updatedFormState = formState.otherDebts.map((row) => {
      const materials = showBackgroundMaterial2 ? row.backgroundMaterial1 + row.backgroundMaterial2 : row.backgroundMaterial1;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        hidden: updatedShowBackgroundMaterial2 ? false : true,
        backgroundMaterial2: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, otherDebts: updatedFormState };
    patchFormState(newFormatState);
  };

  const addNewMaterial3 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial3 = !showBackgroundMaterial3;
    setShowBackgroundMaterial3(updatedShowBackgroundMaterial3);
    setButtonText2(updatedShowBackgroundMaterial3 ? 'Piilota Saldo taustamateriaali 3' : 'Näytä Saldo taustamateriaali 3');
    const updatedFormState = formState.otherDebts.map((row) => {
      const materials = row.backgroundMaterial1 + row.backgroundMaterial2;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        addNewMaterial: updatedShowBackgroundMaterial3 ? true : false,
        backgroundMaterial3: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, otherDebts: updatedFormState };
    patchFormState(newFormatState);
  };

  let tableColumns: TableColumnDefs<OtherdebtsTable> = [
    {
        accessorKey: 'label',
        header: () => t(`${tBase}.label`),
        className: 'text-center width-middle',
        placeholder: '2921...',
        onEdit: (editProps: OnEditProps<string>) => {
            handleEdit<string>(editProps);
    },
    },
    {
        accessorKey: 'ledgerBalance',
        header: () => t(`${tBase}.ledgerBalance`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        cell: ({ cell }) => <div>{formatNumber(cell.row.original.ledgerBalance)} €</div>,
    },
    {
        accessorKey: 'backgroundMaterial1',
        header: () => t(`${tBase}.backgroundMaterial1`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        //cell: ({ cell }) => <div>{formatNumber(cell.row.original.backgroundMaterial1)} €</div>,
    },
    ...(formState.otherDebts.find((row) => row.hidden === true)
      ? [
          {
            accessorKey: 'backgroundMaterial2',
            header: () => t(`${tBase}.backgroundMaterial2`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    ...(formState.otherDebts.find((row) => row.addNewMaterial === true)
      ? [
          {
            accessorKey: 'backgroundMaterial3',
            header: () => t(`${tBase}.backgroundMaterial3`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    {
      accessorKey: 'difference',
      header: () => t(`${tBase}.difference`) + ' €',
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.difference);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.difference)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.backgroundMaterial`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
    },
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.other`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    // Check if hidden is true or false
    const hidden = showBackgroundMaterial2 ? false : true;
    patchFormState({
      ...formState,
      otherDebts: [
        ...(formState.otherDebts ?? []),
        {
            label: "",
            id: uuid(),//Math.floor(Math.random()*100).toString(),
            ledgerBalance: 0,
            backgroundMaterial1: 0,
            backgroundMaterial2: 0,
            backgroundMaterial3: 0,
            difference: 0,
            backgroundMaterial: '',
            other: '',
            hidden: hidden,
            addNewMaterial: false,
        }
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: OtherdebtsTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.otherDebts ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, otherDebts: patch });
    }
  };

  return (
    <>
    <AuditingTable<OtherdebtsTable>
      sectionKey={sectionKey}
      title='Velkaerien tarkastus'
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
      variant='default'
    />
    <Container>
      <button className="custom-button" onClick={toggleBackgroundMaterial2}>{buttonText}</button><br></br>
      {/* If toggleBackgroundMaterial2 is true show new button (addNewMaterial3) */}
      {!showBackgroundMaterial2 &&
        <button className="custom-button" onClick={addNewMaterial3}>{buttonText2}</button>
      }
      <div className="additional-info">Erotus: Pääkirjan saldo - Saldo taustamateriaalilla </div>
    </Container>
    </>
  );
};

export const OtherDebtsTableColumn2 = ({
  formState,
  patchFormState,
}: FormFieldProps<OtherLiabilitiesForm>) => {

  const [showBackgroundMaterial2, setShowBackgroundMaterial2] = useState(true);
  const [buttonText, setButtonText] = useState('Piilota Saldo taustamateriaali 2');

  const [showBackgroundMaterial3, setShowBackgroundMaterial3] = useState(false);
  const [buttonText2, setButtonText2] = useState('Näytä Saldo taustamateriaali 3');

  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.debtInstallments`;

  const data = formState.otherDebts2 ?? [];

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
  }: OnEditProps<T>) {
      const patch = formState.otherDebts2.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) {
              const updatedRow = { ...row, [columnId]: value };
              // MINUS - PÄÄKIRJA - TAUSTAMATERIAALI1 - TAUSTAMATERIAALI2
              if (columnId === 'difference' || columnId === 'ledgerBalance' || columnId === 'backgroundMaterial1' || columnId === 'backgroundMaterial2' || columnId === 'backgroundMaterial3') {
                  let materials = updatedRow.backgroundMaterial1 + updatedRow.backgroundMaterial2 + (!updatedRow.backgroundMaterial3 ? 0: updatedRow.backgroundMaterial3) || 0;
                  let difference = updatedRow.ledgerBalance - materials || 0;
                  updatedRow.difference = difference;
                  // Format and seprate Tenths, Hundredths and Thousandths with commas (updatedRow.difference = number (not a string))
                  formatNumber(updatedRow.difference);
              }
              return updatedRow;
          }
          return row;
      });
      const newFormatState = { ...formState, otherDebts2: patch };
      patchFormState(newFormatState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  };

  const toggleBackgroundMaterial2 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial2 = !showBackgroundMaterial2;
    setShowBackgroundMaterial2(updatedShowBackgroundMaterial2);
    setButtonText(updatedShowBackgroundMaterial2 ? 'Näytä Saldo taustamateriaali 2' : 'Piilota Saldo taustamateriaali 2');
    const updatedFormState = formState.otherDebts2.map((row) => {
      const materials = showBackgroundMaterial2 ? row.backgroundMaterial1 + row.backgroundMaterial2 : row.backgroundMaterial1;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        hidden: updatedShowBackgroundMaterial2 ? false : true,
        backgroundMaterial2: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, otherDebts2: updatedFormState };
    patchFormState(newFormatState);
  };

  const addNewMaterial3 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial3 = !showBackgroundMaterial3;
    setShowBackgroundMaterial3(updatedShowBackgroundMaterial3);
    setButtonText2(updatedShowBackgroundMaterial3 ? 'Piilota Saldo taustamateriaali 3' : 'Näytä Saldo taustamateriaali 3');
    const updatedFormState = formState.otherDebts2.map((row) => {
      const materials = row.backgroundMaterial1 + row.backgroundMaterial2;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        addNewMaterial: updatedShowBackgroundMaterial3 ? true : false,
        backgroundMaterial3: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, otherDebts2: updatedFormState };
    patchFormState(newFormatState);
  };

  let tableColumns: TableColumnDefs<OtherdebtsTable2> = [
    {
        accessorKey: 'label',
        header: () => t(`${tBase}.label`),
        className: 'text-center width-middle',
        placeholder: '2921...',
        onEdit: (editProps: OnEditProps<string>) => {
            handleEdit<string>(editProps);
    },
    },
    {
        accessorKey: 'ledgerBalance',
        header: () => t(`${tBase}.ledgerBalance`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        cell: ({ cell }) => <div>{formatNumber(cell.row.original.ledgerBalance)} €</div>,
    },
    {
        accessorKey: 'backgroundMaterial1',
        header: () => t(`${tBase}.backgroundMaterial1`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        //cell: ({ cell }) => <div>{formatNumber(cell.row.original.backgroundMaterial1)} €</div>,
    },
    ...(formState.otherDebts2.find((row) => row.hidden === true)
      ? [
          {
            accessorKey: 'backgroundMaterial2',
            header: () => t(`${tBase}.backgroundMaterial2`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    ...(formState.otherDebts2.find((row) => row.addNewMaterial === true)
      ? [
          {
            accessorKey: 'backgroundMaterial3',
            header: () => t(`${tBase}.backgroundMaterial3`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    {
      accessorKey: 'difference',
      header: () => t(`${tBase}.difference`) + ' €',
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.difference);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.difference)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.backgroundMaterial`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
    },
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.other`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    // Check if hidden is true or false
    const hidden = showBackgroundMaterial2 ? false : true;
    patchFormState({
      ...formState,
      otherDebts2: [
        ...(formState.otherDebts2 ?? []),
        {
            label: "",
            id: uuid(),//Math.floor(Math.random()*100).toString(),
            ledgerBalance: 0,
            backgroundMaterial1: 0,
            backgroundMaterial2: 0,
            backgroundMaterial3: 0,
            difference: 0,
            backgroundMaterial: '',
            other: '',
            hidden: hidden,
            addNewMaterial: false,
        }
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: OtherdebtsTable2) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.otherDebts2 ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, otherDebts2: patch });
    }
  };

  return (
    <>
    <AuditingTable<OtherdebtsTable2>
      sectionKey={sectionKey}
      title='Velkaerien tarkastus'
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
      variant='default'
    />
    <Container>
      <button className="custom-button" onClick={toggleBackgroundMaterial2}>{buttonText}</button><br></br>
      {/* If toggleBackgroundMaterial2 is true show new button (addNewMaterial3) */}
      {!showBackgroundMaterial2 &&
        <button className="custom-button" onClick={addNewMaterial3}>{buttonText2}</button>
      }
      <div className="additional-info">Erotus: Pääkirjan saldo - Saldo taustamateriaalilla </div>
    </Container>
    </>
  );
};

export const AuditAccuredTableColumn = ({
  formState,
  patchFormState,
}: FormFieldProps<OtherLiabilitiesForm>) => {

  const [showBackgroundMaterial2, setShowBackgroundMaterial2] = useState(true);
  const [buttonText, setButtonText] = useState('Piilota Saldo taustamateriaali 2');

  const [showBackgroundMaterial3, setShowBackgroundMaterial3] = useState(false);
  const [buttonText2, setButtonText2] = useState('Näytä Saldo taustamateriaali 3');

  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.debtInstallments`;

  const data = formState.auditAccured ?? [];

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
  }: OnEditProps<T>) {
      const patch = formState.auditAccured.map((row, index) => {
          const idMatch = itemId !== undefined && itemId === row.id;
          const rowIndexMatch = itemId === undefined && rowIndex === index;
          if (idMatch || rowIndexMatch) {
              const updatedRow = { ...row, [columnId]: value };
              // MINUS - PÄÄKIRJA - TAUSTAMATERIAALI1 - TAUSTAMATERIAALI2
              if (columnId === 'difference' || columnId === 'ledgerBalance' || columnId === 'backgroundMaterial1' || columnId === 'backgroundMaterial2' || columnId === 'backgroundMaterial3') {
                  let materials = updatedRow.backgroundMaterial1 + updatedRow.backgroundMaterial2 + (!updatedRow.backgroundMaterial3 ? 0: updatedRow.backgroundMaterial3) || 0;
                  let difference = updatedRow.ledgerBalance - materials || 0;
                  updatedRow.difference = difference;
                  // Format and seprate Tenths, Hundredths and Thousandths with commas (updatedRow.difference = number (not a string))
                  formatNumber(updatedRow.difference);
              }
              return updatedRow;
          }
          return row;
      });
      const newFormatState = { ...formState, auditAccured: patch };
      patchFormState(newFormatState);
  }

  function formatNumber(num: any) {
    return num.toLocaleString('fi-FI', { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  };

  const toggleBackgroundMaterial2 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial2 = !showBackgroundMaterial2;
    setShowBackgroundMaterial2(updatedShowBackgroundMaterial2);
    setButtonText(updatedShowBackgroundMaterial2 ? 'Näytä Saldo taustamateriaali 2' : 'Piilota Saldo taustamateriaali 2');
    const updatedFormState = formState.auditAccured.map((row) => {
      const materials = showBackgroundMaterial2 ? row.backgroundMaterial1 + row.backgroundMaterial2 : row.backgroundMaterial1;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        hidden: updatedShowBackgroundMaterial2 ? false : true,
        backgroundMaterial2: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, auditAccured: updatedFormState };
    patchFormState(newFormatState);
  };

  const addNewMaterial3 = (event: any) => {
    event.preventDefault();
    const updatedShowBackgroundMaterial3 = !showBackgroundMaterial3;
    setShowBackgroundMaterial3(updatedShowBackgroundMaterial3);
    setButtonText2(updatedShowBackgroundMaterial3 ? 'Piilota Saldo taustamateriaali 3' : 'Näytä Saldo taustamateriaali 3');
    const updatedFormState = formState.auditAccured.map((row) => {
      const materials = row.backgroundMaterial1 + row.backgroundMaterial2;
      const newDifference = row.ledgerBalance - materials;
      return {
        ...row,
        addNewMaterial: updatedShowBackgroundMaterial3 ? true : false,
        backgroundMaterial3: 0,
        difference: newDifference
      };
    });
    const newFormatState = { ...formState, auditAccured: updatedFormState };
    patchFormState(newFormatState);
  };

  let tableColumns: TableColumnDefs<AuditAccuredTable> = [
    {
        accessorKey: 'label',
        header: () => t(`${tBase}.label`),
        className: 'text-center width-middle',
        placeholder: '2921...',
        onEdit: (editProps: OnEditProps<string>) => {
            handleEdit<string>(editProps);
    },
    },
    {
        accessorKey: 'ledgerBalance',
        header: () => t(`${tBase}.ledgerBalance`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        cell: ({ cell }) => <div>{formatNumber(cell.row.original.ledgerBalance)} €</div>,
    },
    {
        accessorKey: 'backgroundMaterial1',
        header: () => t(`${tBase}.backgroundMaterial1`) + ' €',
        className: 'text-center width-middle',
        onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
        //cell: ({ cell }) => <div>{formatNumber(cell.row.original.backgroundMaterial1)} €</div>,
    },
    ...(formState.auditAccured.find((row) => row.hidden === true)
      ? [
          {
            accessorKey: 'backgroundMaterial2',
            header: () => t(`${tBase}.backgroundMaterial2`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    ...(formState.auditAccured.find((row) => row.addNewMaterial === true)
      ? [
          {
            accessorKey: 'backgroundMaterial3',
            header: () => t(`${tBase}.backgroundMaterial3`) + ' €',
            className: 'text-center width-middle',
            onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
          },
        ]
      : []),
    {
      accessorKey: 'difference',
      header: () => t(`${tBase}.difference`) + ' €',
      className: 'text-center width-middle',
      accessorFn: (row: any) => {
        return formatCurrency(row.difference);
      },
      cell: ({ cell }) => <div>{formatNumber(cell.row.original.difference)} €</div>,
    },
    {
      accessorKey: 'backgroundMaterial',
      header: () => t(`${tBase}.backgroundMaterial`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.backgroundMaterial`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
    },
    },
    {
      accessorKey: 'other',
      header: () => t(`${tBase}.other`),
      className: 'text-center width-middle',
      placeholder: t(`${tBase}.other`) + '...',
      onEdit: (editProps: OnEditProps<string>) => {
          handleEdit<string>(editProps);
      },
    },
  ];

  const handleAddNewRow = () => {
    // Check if hidden is true or false
    const hidden = showBackgroundMaterial2 ? false : true;
    patchFormState({
      ...formState,
      auditAccured: [
        ...(formState.auditAccured ?? []),
        {
            label: "",
            id: uuid(),//Math.floor(Math.random()*100).toString(),
            ledgerBalance: 0,
            backgroundMaterial1: 0,
            backgroundMaterial2: 0,
            backgroundMaterial3: 0,
            difference: 0,
            backgroundMaterial: '',
            other: '',
            hidden: hidden,
            addNewMaterial: false,
        }
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: AuditAccuredTable) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.auditAccured ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, auditAccured: patch });
    }
  };

  return (
    <>
    <AuditingTable<AuditAccuredTable>
      sectionKey={sectionKey}
      title='Velkaerien tarkastus'
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
      variant='default'
    />
    <Container>
      <button className="custom-button" onClick={toggleBackgroundMaterial2}>{buttonText}</button><br></br>
      {/* If toggleBackgroundMaterial2 is true show new button (addNewMaterial3) */}
      {!showBackgroundMaterial2 &&
        <button className="custom-button" onClick={addNewMaterial3}>{buttonText2}</button>
      }
      <div className="additional-info">Erotus: Pääkirjan saldo - Saldo taustamateriaalilla </div>
    </Container>
    </>
  );
};