import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import { AUDITING_SECTION_STATUS_OPTIONS } from '../../utils';
import { savePdf } from '../../utils/pdf';
import { Button, Select } from '../inputs';
import SaveState from '../SaveState';
import StatusHistory from '../StatusHistory';
import { CommentField } from '../CommentField';

const NO_STATUS_SECTIONS: AuditingSectionKey[] = [
  'auditingFrontPage',
  'instructions',
  'feedback',
];
const NO_STATUS_HISTORY_SECTIONS: AuditingSectionKey[] = [
  'auditingFrontPage',
  'instructions',
  'feedback',
];

// When screen width is below this value, side content doesn't fit to right column, but should be rendered elsewhere.
// Currently "Income statement and balance" view is the widest, so this value is (roughly) based on its width.
const SCREEN_THRESHOLD_PX = 1700;

const Container = styled.div`
  display: flex;

  > div.main-content {
    flex: 1;
    padding-bottom: 100px;
  }

  > div.side-content {
    position: relative;
    width: 290px;

    > div.floating-element {
      position: fixed;
      width: 290px;
      padding-top: ${p => p.theme.spacing.md};
      padding-left: ${p => p.theme.spacing.xl};
      padding-right: ${p => p.theme.spacing.md};

      .save-state,
      .actions,
      .action-buttons,
      .status-history {
        :not(:last-child) {
          margin-bottom: ${p => p.theme.spacing.lg};
        }
      }
    }
  }

  @media (max-width: ${SCREEN_THRESHOLD_PX}px) {
    flex-direction: column-reverse;

    > div.side-content {
      width: 100%;
      > div.floating-element {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column-reverse;

        margin: ${p => p.theme.spacing.lg} 0;
        padding-left: 0;
        padding-right: 0;

        background-color: ${p => p.theme.color.background};
        border: 1px solid ${p => p.theme.color.grey200};
        border-radius: ${p => p.theme.borderRadius.md};
        ${p => p.theme.shadow.sm};

        .save-state,
        .actions,
        .status-history {
          margin: 0 !important;
          padding-left: ${p => p.theme.spacing.lg};
          padding-right: ${p => p.theme.spacing.lg};
        }

        .save-state {
          border-top: 1px dashed ${p => p.theme.color.grey300};
          display: flex;
          justify-content: center;
        }

        .actions {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: flex-end;

          > * {
            width: 50%;
          }

          > .action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 6px;
            > button {
              width: 240px;
              :not(:first-child) {
                margin-left: ${p => p.theme.spacing.md};
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  sectionKey: AuditingSectionKey;
  formSaveState?: FormSaveState;
  disabled?: boolean;
}

const AuditingSectionWrapper: React.FC<PropsWithChildren<Props>> = observer(
  ({ sectionKey, formSaveState, disabled, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: {
        auditingLocked,
        companyName,
        getFinancialYear,
        getAuditingSection,
        isAuditingSectionFinished,
        setAuditingSectionStatus,
        resetSectionForm,
      },
    } = useStore();

    const [status, setStatus] = useState<AuditingSectionStatus | undefined>();

    const sectionKeyRef = useRef<string>();

    const currentSection = getAuditingSection(sectionKey);

    const sectionDisabled = disabled || auditingLocked;

    // Update status if section changed
    useEffect(() => {
      const sectionChanged = sectionKeyRef.current !== sectionKey;
      if (sectionChanged) {
        sectionKeyRef.current = sectionKey;
        setStatus(currentSection?.status ?? 'not_started');
      }
    }, [currentSection?.status, sectionKey]);

    // Check if section's status changed externally
    useEffect(() => {
      const statusChanged =
        currentSection?.status && currentSection.status !== status;
      if (statusChanged) {
        setStatus(currentSection.status);
      }
    }, [currentSection?.status, status]);

    const handleStatusChange = (
      newStatus: React.SetStateAction<AuditingSectionStatus | undefined>
    ) => {
      setStatus(newStatus);
      if (typeof newStatus === 'string')
        setAuditingSectionStatus(sectionKey, newStatus);
    };

    const handleSavePdf = () => {
      const year = getFinancialYear();
      const financialYear = `${t('auditing:financialYear')}: ${year}`;
      const auditingText = `${companyName}, ${financialYear}`;
      const sectionText = `route:auditing.${sectionKey}`;
      const statusText = status ? t(`auditing:status.${status}`) : '';

      savePdf('main-content-container', {
        filename: `${auditingText}, ${t(sectionText)}`,
        header: {
          left: auditingText,
          right: `${t(sectionText)} (${statusText})`,
        },
      });
    };

    const handleResetForm = async () => {
      await resetSectionForm(sectionKey);
      window.location.reload();
    };

    const hideStatus = NO_STATUS_SECTIONS.includes(sectionKey);
    const hideStatusHistory = NO_STATUS_HISTORY_SECTIONS.includes(sectionKey);

    const sectionTitle = t(`route:auditing.${sectionKey}`);

    const statusSelectLabel = t('common:label.auditingSectionStatus', {
      sectionTitle,
    });

    return (
      <Container>
        <div id="main-content-container" className="main-content">
          <h1>{sectionTitle}</h1>

          {children}
        </div>

        <div className="side-content no-print">
          <div className="floating-element">
            {formSaveState && (
              <div className="save-state">
                <SaveState saveState={formSaveState} />
              </div>
            )}

            <div className="actions">
              <div className="action-buttons">
                <Button
                  text={t('action:download').toUpperCase() + ' PDF'}
                  icon="Pdf"
                  variant="outlined"
                  onClick={handleSavePdf}
                  fullWidth
                />

                {process.env.NODE_ENV === 'development' && !auditingLocked && (
                  <Button
                    text={t('action:resetForm')}
                    icon="Undo"
                    variant="outlined"
                    onClick={handleResetForm}
                    disabled={
                      sectionDisabled || isAuditingSectionFinished(sectionKey)
                    }
                    fullWidth
                  />
                )}
              </div>

              {!hideStatus && (
                <Select<AuditingSectionStatus>
                  label={statusSelectLabel}
                  options={AUDITING_SECTION_STATUS_OPTIONS}
                  value={status}
                  setValue={handleStatusChange}
                  displayValue={(status?: AuditingSectionStatus) =>
                    status ? t(`auditing:status.${status}`) : ''
                  }
                  disabled={sectionDisabled}
                  fullWidth
                />
              )}
              <CommentField sectionKey={sectionKey} />
            </div>

            <div className="status-history">
              {!hideStatusHistory && <StatusHistory {...currentSection} />}
            </div>
          </div>
        </div>
      </Container>
    );
  }
);

export default AuditingSectionWrapper;
