import AuditingTable from "../../../../components/table/AuditingTable";
import { TableColumnDefs, OnEditProps } from "../../../../components/table/Table";
// eslint-disable-next-line
import { formatCurrency } from "../../../../utils";
import { useState } from "react";

interface ReusableProps {
    formState: any;
    patchFormState: Function;
    id: string;
    sectionKey: any;
    addCommentField?: boolean;
    fieldTitles: Array<string>
}

interface ReusableRow {
    firstField: string;
    secondField: number;
    thirdField: number;
    comment: string;
    calculatedAmount?: number;
}
interface ReusableRowWithDoubleCalculators {
    firstField: string;
    secondField: number;
    thirdField: number;
    comment: string;
    calculatedAmount?: number;

    secondField2: number;
    thirdField2: number;
    calculatedAmount2?: number;

}

export const ReusableCalculationForm = ({ formState, patchFormState, id, sectionKey, fieldTitles, addCommentField }:ReusableProps) => {
    const [loading, setLoading] = useState(false)
    if(!formState || !formState?.calculationFormData) return null;

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: [
                    ...formState.calculationFormData[id],
                    {
                        firstField: "Uusi asiakas",
                        secondField: 0,
                        thirdField: 0,
                    }    
                ]
            }
        })
      }
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
        const duplicateData = [...formState.calculationFormData[id]]
        if(columnId === "comment"){
            duplicateData[rowIndex].comment = value
        } else if(columnId === "firstField"){
            duplicateData[rowIndex].firstField = value
        } else if(columnId === "secondField"){
            duplicateData[rowIndex].secondField = value
        } else if(columnId === "thirdField"){
            duplicateData[rowIndex].thirdField = value
        }
        
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: duplicateData
            }
        })
      }

      const handleDeleteRow = async (data: any) => {
        setLoading(true)
          const newDataList = [...formState.calculationFormData[id]].filter((item: { firstField: any; }) =>
                item.firstField !== data.firstField);
          if (newDataList.length > 0) {
            patchFormState({
                ...formState,
                calculationFormData:{
                    ...formState.calculationFormData,
                    [id]: newDataList
                }
            })
          } else {
            alert("Et voi poistaa kaikkia osakkeita.");
          }
        setTimeout(() => setLoading(false), 1)
      }



    const resultColumns: TableColumnDefs<ReusableRow> = [
        {
            accessorKey: 'firstField',
            header: () => fieldTitles[0],
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'secondField',
            header: () => fieldTitles[1],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'thirdField',
            header: () => fieldTitles[2],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'calculatedAmount',
            header: () => 'Erotus',
            className: 'text-left width-middle nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount),
        },
    ]

    if(addCommentField){
        resultColumns.push({
            accessorKey: 'comment',
            header: () => 'Muuta',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        })
    }

    const data = []
    for(var i=0; i<formState.calculationFormData[id].length; i++){
        const dataObject = formState.calculationFormData[id][i]
        data.push({
            firstField: dataObject.firstField,
            secondField: dataObject.secondField,
            thirdField: dataObject.thirdField,
            calculatedAmount: dataObject.secondField - dataObject.thirdField,
            comment: dataObject.comment
        })
    }

    return !loading ? (
        <AuditingTable<ReusableRow>
            sectionKey={sectionKey}
            data={data}
            columns={resultColumns}
            showGlobalFilter={false}
            disableSort
            onRowDelete={handleDeleteRow}
            onAddNewRow={handleAddNewRow}
        />
    ): null
}


export const ReusableCalculationFormWithDoubleCalculators = ({ formState, patchFormState, id, sectionKey, fieldTitles, addCommentField }:ReusableProps) => {
    const [loading, setLoading] = useState(false)
    if(!formState || !formState?.calculationFormData) return null;

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: [
                    ...formState.calculationFormData[id],
                    {
                        firstField: "Uusi asiakas",
                        secondField: 0,
                        thirdField: 0,
                    }    
                ]
            }
        })
      }
      
    function handleEdit<T>({
        rowIndex,
        columnId,
        value,
      }: OnEditProps<T>) {
        const duplicateData = [...formState.calculationFormData[id]]
        duplicateData[rowIndex][columnId] = value
        
        patchFormState({
            ...formState,
            calculationFormData:{
                ...formState.calculationFormData,
                [id]: duplicateData
            }
        })
      }

      const handleDeleteRow = async (data: any) => {
        setLoading(true)
          const newDataList = [...formState.calculationFormData[id]].filter((item: { firstField: any; }) =>
                item.firstField !== data.firstField);
          if (newDataList.length > 0) {
            patchFormState({
                ...formState,
                calculationFormData:{
                    ...formState.calculationFormData,
                    [id]: newDataList
                }
            })
          } else {
            alert("Et voi poistaa kaikkia osakkeita.");
          }
        setTimeout(() => setLoading(false), 1)
      }



    const resultColumns: TableColumnDefs<ReusableRowWithDoubleCalculators> = [
        {
            accessorKey: 'firstField',
            header: () => fieldTitles[0],
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        },
        {
            accessorKey: 'secondField',
            header: () => fieldTitles[1],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'thirdField',
            header: () => fieldTitles[2],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'calculatedAmount',
            header: () => 'Erotus',
            className: 'text-left width-middle nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount),
        },

        {
            accessorKey: 'secondField2',
            header: () => fieldTitles[3],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'thirdField2',
            header: () => fieldTitles[4],
            onNumberEdit: handleEdit,
            className: 'text-left width-min nowrap',
        },
        {
            accessorKey: 'calculatedAmount2',
            header: () => 'Erotus',
            className: 'text-left width-middle nowrap',
            accessorFn: row => formatCurrency(row.calculatedAmount2),
        },
    ]

    if(addCommentField){
        resultColumns.push({
            accessorKey: 'comment',
            header: () => 'Muuta',
            onEdit: handleEdit,
            className: 'text-left width-middle nowrap',
        })
    }

    const data = []
    for(var i=0; i<formState.calculationFormData[id].length; i++){
        const dataObject = formState.calculationFormData[id][i]
        data.push({
            firstField: dataObject.firstField,
            secondField: dataObject.secondField,
            thirdField: dataObject.thirdField,
            calculatedAmount: dataObject.secondField - dataObject.thirdField,

            secondField2: dataObject.secondField2,
            thirdField2: dataObject.thirdField2,
            calculatedAmount2: dataObject.secondField2 - dataObject.thirdField2,

            comment: dataObject.comment
        })
    }

    return !loading ? (
        <AuditingTable<ReusableRowWithDoubleCalculators>
            sectionKey={sectionKey}
            data={data}
            columns={resultColumns}
            showGlobalFilter={false}
            disableSort
            onRowDelete={handleDeleteRow}
            onAddNewRow={handleAddNewRow}
        />
    ): null
}

