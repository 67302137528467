import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../components/layout/Wrapper';
import { useStore } from '../store/RootContext';
import Table, { TableColumnDefs } from '../components/table/Table';
import { confirmDelete } from '../utils';
import Modal from '../components/Modal';
import CompanyForm from '../components/forms/CompanyForm';
import { getAddress } from '../utils/company';
import { useFetchAuditFirms } from '../utils/hooks';
import Divider from '../components/Divider';

export const AuditFirms: React.FC = observer(() => {
  const { t } = useTranslation(['auditFirms', 'common']);

  const { auditFirmStore } = useStore();

  const { allEntities, deleteEntity } = auditFirmStore;

  const [stagedEntity, setStagedEntity] = useState<Partial<AuditFirm>>();

  useFetchAuditFirms();

  const clearStagedEntity = () => setStagedEntity(undefined);

  const handleAddNew = () => {
    setStagedEntity({});
  };

  const handleEdit = (entity: AuditFirm) => {
    setStagedEntity(entity);
  };

  const handleDelete = (entity: AuditFirm) => {
    if (confirmDelete(entity.name)) {
      deleteEntity({ id: entity.id });
    }
  };

  const handleSubmit = async (company: Omit<Company, 'id'>) => {
    let success: boolean | undefined;

    if (stagedEntity?.id) {
      const response = await auditFirmStore.editEntity({
        id: stagedEntity.id,
        data: company,
      });
      success = response.success;
    } else {
      const response = await auditFirmStore.addEntity({ data: company });
      success = response.success;
    }

    if (success) clearStagedEntity();
  };

  const columns: TableColumnDefs<AuditFirm> = [
    {
      accessorKey: 'businessId',
      header: () => t('common:label.businessId'),
      className: 'nowrap',
    },
    {
      accessorKey: 'name',
      header: () => t('auditFirms:label.name'),
      className: 'text-bold',
    },
    {
      id: 'address',
      accessorFn: row => getAddress(row),
      header: () => t('common:label.address'),
      cell: info => info.getValue(),
      sortingFn: (a, b) =>
        getAddress(a.original).localeCompare(getAddress(b.original)),
    },
    {
      accessorKey: 'contactName',
      header: () => t('common:label.contactName'),
    },
    {
      accessorKey: 'contactEmail',
      header: () => t('common:label.email'),
    },
    {
      accessorKey: 'contactPhone',
      header: () => t('common:label.phone'),
    },
  ];

  return (
    <Wrapper>
      <h1 data-testid="view-title">{t('title')}</h1>
      <Divider />

      <Table<AuditFirm>
        data={allEntities ?? []}
        columns={columns}
        onAddNew={handleAddNew}
        onRowEdit={handleEdit}
        onRowDelete={handleDelete}
        showIdColumn
      />

      {stagedEntity && (
        <Modal
          title={t(`auditFirms:${stagedEntity.id ? 'edit' : 'add'}`)}
          onClose={clearStagedEntity}
          isInitiallyOpen
        >
          <CompanyForm company={stagedEntity} onSubmit={handleSubmit} />
        </Modal>
      )}
    </Wrapper>
  );
});

export default AuditFirms;
