import { Api } from '../RootApi';

const URL = {
  login: 'auth/login',
  otpcheck: 'auth/otpcheck',
  sendOTP: 'auth/send-otp',
  logout: 'auth/logout',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'auth/update-password',
};

export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async login(params: Api.Payload.Login) {
    const response = await this.api.post<Login>(URL.login, params, {});
    if (response.kind === 'OK') this.api.setToken(response.data.accessToken);
    return response;
  }

  // Verify OTP
  async otpcheck(params: Api.Payload.OTP) {
    const response = await this.api.post<OTP>(URL.otpcheck, params, {});
    if (response.kind === 'OK') {
      this.api.setOTP(response.data.user.otpSecret);
      this.api.setVerified(response.data.user.isVerified);
    }
    return response;
  }

  // Send OTP
  async sendOTP(params: Api.Payload.SendOTP) {
    return await this.api.post(URL.sendOTP, params, {});
  }

  async logout() {
    this.api.get(URL.logout);
    this.api.setToken(undefined);
    this.api.setOTP(undefined);
  }

  async forgotPassword(params: Api.Payload.ForgotPassword) {
    return await this.api.post(URL.forgotPassword, params, {});
  }

  async resetPassword(params: Api.Payload.ResetPassword) {
    return await this.api.post(URL.resetPassword, params, {});
  }
}
