import { getWindowDimensions, hidePartBasedOnDetailedPlan } from "../../../../utils"
import auditingJson from '../../../../../src/i18n/locales/fi/auditing.json'
import { SpreadSheet } from "./SpreadSheet"

export const innerCircleElementDFS = {
    spreadSheetIC: [
      {
        column: 15,
        row: 15,
        cellData: [],
      }
    ],
    useSheetIC: null,
    relationsSelect: null,
    relationsSelect2: null,
    relationsSelect3: null,
    relationsSelect4: null,
    reference5: auditingJson.referenceValue3,
    ideasGoalsAndCompletedWork6: auditingJson.ideasGoalsAndCompletedWorkValueRelations,
    findingsAndComments6: auditingJson.defaultFindingsAndCommentsValue,
}
export function innerCircleElement(formState: any, formBuilder:any, auditing:any, batchString:string){
  return (      
    formBuilder.accordionGroup({title: "Lähipiiri",items:[
      formBuilder.checkboxGroup({accessor: 'relationsSelect', options: Object.keys(auditingJson.relationsSelect), plainLabel: auditingJson.innerCircleSelectTitles.one, 
        optionDisplayValue: (key: string) => auditingJson.relationsSelect[key as keyof typeof auditingJson.relationsSelect]
      }),
      formBuilder.checkboxGroup({accessor: 'relationsSelect2', options: Object.keys(auditingJson.relationsSelect2), plainLabel: auditingJson.innerCircleSelectTitles.two, 
        optionDisplayValue: (key: string) => auditingJson.relationsSelect2[key as keyof typeof auditingJson.relationsSelect2]
      }),
      formBuilder.checkboxGroup({accessor: 'relationsSelect3', options: Object.keys(auditingJson.relationsSelect3), plainLabel: auditingJson.innerCircleSelectTitles.three, 
        optionDisplayValue: (key: string) => auditingJson.relationsSelect3[key as keyof typeof auditingJson.relationsSelect3]
      }),
      formBuilder.checkboxGroup({accessor: 'relationsSelect4', options: Object.keys(auditingJson.relationsSelect4), plainLabel: auditingJson.innerCircleSelectTitles.four, 
        optionDisplayValue: (key: string) => auditingJson.relationsSelect4[key as keyof typeof auditingJson.relationsSelect4]
      }),
      formBuilder.textArea({plainLabel: auditingJson.reference ,accessor: 'reference5'}),
      formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheetIC'}),
      formBuilder.textArea({plainLabel: auditingJson.ideasGoalsAndCompletedWork ,accessor: 'ideasGoalsAndCompletedWork6'}),
      formBuilder.textArea({plainLabel: auditingJson.remarksAndComments ,accessor: 'findingsAndComments6'}),
      formBuilder.custom((props: {patchFormState: Function, formState: any}) => formState.useSheetIC ? <SpreadSheet customHeight={getWindowDimensions().height *0.5} id="spreadSheetIC" {...props} /> : null),

    ], hidden: hidePartBasedOnDetailedPlan(auditing, 'innerCircle', batchString)})
  )
}