import React, { PropsWithChildren, useCallback } from 'react';
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getAcceptedFileTypes } from '../../utils';
import { InputContainer } from './InputContainer';
import { Label } from './Label';

const getStatusColorKey = (p: ContainerProps) =>
  p.isDragAccept ? 'success' : p.isDragReject ? 'error' : 'grey500';

interface ContainerProps {
  isDragAccept?: boolean;
  isDragReject?: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: ${p => p.theme.spacing.lg};
  background-color: ${p => p.theme.color.grey100};
  border-width: 1px;
  border-style: dashed;
  border-color: ${p => p.theme.color[getStatusColorKey(p)]};
  border-radius: ${p => p.theme.borderRadius.sm};
  margin: ${p => p.theme.spacing.sm} 0;
  text-align: center;
  user-select: none;

  cursor: pointer;

  opacity: 0.75;
  :hover {
    opacity: 1;
  }

  span {
    font-style: italic;
  }

  .children-container {
    display: inline-block;
  }

  .drag-area-text {
    color: ${p => p.theme.color[getStatusColorKey(p)]};
  }
`;

interface Props {
  label?: string;
  accept?: AcceptFileType[];
  onFileUpload: (file: File) => void;
}

const FileUpload: React.FC<PropsWithChildren<Props>> = ({
  label,
  accept,
  onFileUpload,
  children,
}) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (accepted: File[], _rejections: FileRejection[], _event: DropEvent) => {
      if (accepted.length) {
        const file = accepted[0];
        onFileUpload(file);
      }
    },
    [onFileUpload]
  );

  const dropZoneOptions: DropzoneOptions = {
    onDrop,
    multiple: false,
    noKeyboard: true,
    accept: getAcceptedFileTypes(accept),
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropZoneOptions);

  return (
    <InputContainer>
      <Label>{label ?? t('action:uploadFile')}</Label>
      <Container
        {...getRootProps()}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        <input {...getInputProps()} />

        <div>
          {isDragActive ? (
            <span className="drag-area-text">
              {isDragAccept
                ? `${t('action:dropFileHere')}...`
                : t('common:fileNotSupported')}
            </span>
          ) : (
            <span className="drag-area-text">
              {t('action:dragFileHere', {
                acceptedTypes: accept ? ` (${accept?.join(', ')})` : '',
              })}
            </span>
          )}
        </div>

        <div className="children-container">{children}</div>
      </Container>
    </InputContainer>
  );
};

export default FileUpload;
