import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils/auditing';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ProcessDescriptionTable } from './reUsables/ProcessDescription';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = OtherOperatingExpensesForm;
const sectionKey: AuditingSectionKey = 'otherOperatingExpenses';
export const tBase = `auditing:form.${sectionKey}`;

const vocabulary = auditingJson?.form?.otherOperatingExpenses || {};
//const batch = FinancialStatementBatch['KB-otherOperatingExpenses'];
const batchString = 'KB-otherOperatingExpenses';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const processTextData = auditing?.materialsAndServices?.form.processText || '';
  const processDateData = auditing?.materialsAndServices?.form.processDate || '';
  const processValueData = auditing?.materialsAndServices?.form.processValue || '';
  return {
    procedureAccountsManualData: [{
      currentYear: 0,
      priorYear: 0,
      accountName: "0000...",
      comment: ""
    }],
    procedureAccountsData: {},
    firstTable:[ 
      {
        id: '1',
        account: '',
        CY: 0,
        PY: 0,
        other: '',
      }
    ],
    tableColumnData: [
      {
        left: 'Liiketoiminnan muita kuluja on kirjattu tilikaudelle ilman perusteita.',
        right: auditingJson.TAP,
      },
      {
        left: 'Kaikkia tarvittavia muita kuluja ei ole kirjattu kaudelle.',
        right: auditingJson.TAY,
      },
      {
        left: 'Kuluja ei ole kirjattu oikean määräisinä.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Kaudelle on kirjattu kaudelle kuulumattomia kuluja tai kuluja on jäänyt kirjaamatta kaudelle.',
        right: auditingJson.KAT,
      },
      {
        left: '',
        right: auditingJson.LUOK,
      },
      {
        left: '',
        right: auditingJson.ESI,
      },
    ],
    textArea: "Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liiketoiminnan muiden kulujen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella liiketoiminnan muut kulut ovat ollennaisin osin oikein.",
    // Muiden kulujen analyyttinen tarkastus
    inspectionConclusionReview: '1',

    processText: processTextData,
    processDate: processDateData,
    processValue: processValueData,
    useSheetTab: false,
    useSheet: null,
    sheetdata1: [{
        cellData: [],
    }],
    useSheet2: null,
    sheetdata2: [{
        cellData: [],
    }],
    useSheet3: null,
    sheetdata3: [{
        cellData: [],
    }],
    useSheet4: null,
    sheetdata4: [{
        cellData: [],
    }],

  };

}

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  formBuilder.group({items: [
    formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
    formBuilder.custom(props => <ProcedureAccountsElement auditing={auditing} {...props} batchString={batchString} addCommentField />),
    formBuilder.custom(props => <TableColumn title='Mahdollinen virheellisyyden riski muissa kuluissa' headerText='Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan   (ISA 315.11)' {...props} />),
    formBuilder.textArea({
      accessor: 'textArea',
    }),
  ]}),

  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => 
        <ProcessDescriptionTable
          processText={auditing?.materialsAndServices?.form.processText ?? ''} 
          processDate={auditing?.materialsAndServices?.form.processDate ?? ''} 
          processValue={auditing?.materialsAndServices?.form.processValue ?? ''} 
          {...props} 
        />
      ),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, "purchases"),
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Muiden kulujen analyyttinen tarkastus
      accordionId: 'analyticalReview',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'inspectionConclusionReview', 
          options: Object.keys(vocabulary?.inspectionConclusionReviewOptions || {})
        }),
      ],
      lowerElements: [
        formBuilder.boolean({accessor: "useSheet", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null),
      ]
    },
    {
      // Muiden kulujen katko
      accordionId: 'interruptionExpenses',
      lowerElements: [
        formBuilder.boolean({accessor: "useSheet3", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="sheetdata3" {...props} /> : null),
      ]
    },
    {
      // Muiden kulujen aineistotarkastus
      accordionId: 'materialInspection',
      lowerElements: [
        formBuilder.boolean({accessor: "useSheet4", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="sheetdata4" {...props} /> : null),
      ],
    },
    {
      // Lakikulut
      accordionId: 'legalFees',
      lowerElements: [
        formBuilder.boolean({accessor: "useSheet2", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="sheetdata2" {...props} /> : null),
      ],
    }
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;