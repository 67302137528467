import React, { HTMLInputTypeAttribute } from 'react';
import { InputContainer } from './InputContainer';
import { Label } from './Label';

interface Props extends CommonInputProps {
  plainLabel?: string;
  type?: HTMLInputTypeAttribute;
  autocomplete?: string;
  placeholder?: string;
  value: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setNumberValue?: React.Dispatch<React.SetStateAction<number>>;
  fullWidth?: boolean;
  minLength?: number;
  min?: number;
  max?: number;
}

export const TextInput: React.FC<Props> = ({
  plainLabel,
  id,
  type = 'text',
  autocomplete,
  name,
  label,
  contextInfo,
  placeholder,
  value,
  onChange,
  onClick,
  setValue,
  setNumberValue,
  autoFocus,
  required = false,
  fullWidth = false,
  disabled = false,
  minLength,
  min,
  max,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    if (setValue) setValue(event.currentTarget.value);
    if (setNumberValue) setNumberValue(Number(event.currentTarget.value));
  };

  return (
    <InputContainer
      id={id}
      data-testid={id}
      fullWidth={fullWidth}
      minWidth={type === 'number'}
      mediumWidth={type === 'date'}
    >
      {plainLabel ? (
        <Label
        disabled={disabled}
        contextInfo={contextInfo}
        className={required ? 'is-required' : ''}
      >
        {plainLabel}
      </Label>
      ) : label && (
        <Label
          disabled={disabled}
          contextInfo={contextInfo}
          className={required ? 'is-required' : ''}
        >
          {label}
        </Label>
      )}
      <input
        data-testid={`${id}-input`}
        type={type}
        autoComplete={autocomplete}
        name={name}
        value={type === 'date' ? `${value}`.slice(0, 10) : value}
        onChange={handleChange}
        onClick={onClick}
        placeholder={placeholder}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
        minLength={minLength}
        min={min}
        max={max}
      />
    </InputContainer>
  );
};

export default TextInput;
