import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { confirmDelete } from '../../utils';
import {
  emptyOtherSignificantRisk,
  getDefaultRiskValues,
  OtherSignificantRiskKind,
} from '../../views/auditingSections/planning/risksUtils';
import Accordion from '../Accordion';
import Divider from '../Divider';
import Icon from '../Icon';
import { Button, Label, Select, TextArea, TextInput } from '../inputs';
import { OnEditProps } from '../table/Table';
import { OtherSignificantRiskStatements } from './Risks.OtherSignificantRiskStatements';

const Container = styled.div`
  margin: ${p => p.theme.spacing.lg} 0;
  padding: ${p => p.theme.spacing.xsm} ${p => p.theme.spacing.lg};
  border: 1px solid ${p => p.theme.color.grey200};
  border-radius: ${p => p.theme.borderRadius.sm};

  .accordion-panel > * {
    margin: ${p => p.theme.spacing.lg} 0;
  }

  .other-risk-title {
    margin: 0;
    ${p => p.theme.font.h5};
  }
`;

const sectionKey: AuditingSectionKey = 'risks';

const tBase = `auditing:form.${sectionKey}.otherSignificantRisks`;

export const OtherSignificantRisks = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<RisksForm>) => {
  const { t } = useTranslation();

  const { auditing, auditingLocked, isAuditingSectionFinished } =
    store.auditingStore;

  if (!formState) return null;

  const data = formState.otherSignificantRisks ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.otherSignificantRisks.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, otherSignificantRisks: patch });
  }

  const handleAddNewRow = () => {
    const risks = getDefaultRiskValues(auditing);
    patchFormState({
      ...formState,
      otherSignificantRisks: [
        ...(formState.otherSignificantRisks ?? []),
        emptyOtherSignificantRisk({ risks }),
      ],
    });
  };

  const handleDeleteRow = ({ id, name }: OtherSignificantRisk) => {
    if (confirmDelete(name ?? '')) {
      const patch = (formState.otherSignificantRisks ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, otherSignificantRisks: patch });
    }
  };

  const riskNamePlaceholder = t(`${tBase}.riskNamePlaceholder`);
  const kindDetailsPlaceholder = t(`${tBase}.kindDetailsPlaceholder`);

  const disabled = auditingLocked || isAuditingSectionFinished(sectionKey);

  return (
    <>
      <p>{t(`${tBase}.info`)}</p>

      {data.map((risk, rowIndex) => {
        const key = `otherSignificantRisk-accordion-${risk.id}`;
        return (
          <Container key={key}>
            <Accordion
              noBorder
              title={
                <h3 className="other-risk-title">
                  {risk.name || riskNamePlaceholder}
                </h3>
              }
              sideContent={
                !disabled && (
                  <Button
                    onClick={() => handleDeleteRow(risk)}
                    icon={<Icon type="Delete" color="error" />}
                    variant="outlined"
                  />
                )
              }
              defaultOpen={
                store.appStore.getComponentState(key)?.isOpen ?? true
              }
              onClick={isOpen =>
                store.appStore.setComponentState({ key, isOpen })
              }
            >
              <TextInput
                value={risk.name}
                label={t(`${tBase}.riskName`)}
                placeholder={riskNamePlaceholder}
                disabled={disabled}
                fullWidth
                onChange={event =>
                  handleEdit<string>({
                    rowIndex,
                    columnId: 'name',
                    value: event.currentTarget.value,
                  })
                }
              />

              <TextArea
                value={risk.details}
                label={t(`${tBase}.riskDetails`)}
                disabled={disabled}
                fullWidth
                rows={3}
                onChange={event =>
                  handleEdit<string>({
                    rowIndex,
                    columnId: 'details',
                    value: event.currentTarget.value,
                  })
                }
              />

              <div className="flex-row flex-row-gap">
                <div className="flex-1">
                  <Select
                    fullWidth
                    label={t(`${tBase}.kind`)}
                    options={Object.values(OtherSignificantRiskKind)}
                    displayValue={option => t(`${tBase}.kindOptions.${option}`)}
                    disabled={disabled}
                    value={risk.kind ?? undefined}
                    setValue={value =>
                      handleEdit<string | null>({
                        rowIndex,
                        columnId: 'kind',
                        value: !value ? null : `${value}`,
                      })
                    }
                  />
                </div>
                <div className="flex-1">
                  {risk.kind === OtherSignificantRiskKind.other && (
                    <TextInput
                      value={risk.kindDetails ?? ''}
                      label={t(`${tBase}.kindDetails`)}
                      placeholder={kindDetailsPlaceholder}
                      disabled={disabled}
                      fullWidth
                      onChange={event =>
                        handleEdit<string>({
                          rowIndex,
                          columnId: 'kindDetails',
                          value: event.currentTarget.value,
                        })
                      }
                    />
                  )}
                </div>
              </div>

              <div>
                <Label>{t(`${tBase}.statements`)}</Label>
                <OtherSignificantRiskStatements
                  data={risk.statements}
                  onEdit={(statements: OtherSignificantRiskStatement[]) => {
                    handleEdit<OtherSignificantRiskStatement[]>({
                      rowIndex,
                      columnId: 'statements',
                      value: statements,
                    });
                  }}
                />
              </div>
            </Accordion>
          </Container>
        );
      })}

      {!disabled && (
        <>
          <Divider size="sm" noBorder />

          <Button
            text={t('action:addNew')}
            icon="Plus"
            onClick={handleAddNewRow}
            variant="outlined"
            fullWidth
          />
        </>
      )}
    </>
  );
};
