
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { LoanConfimationsTableColumn, ReconciliationTableColumn } from '../../../components/auditing/CapitalLoans';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = CapitalLoansForm;
const sectionKey: AuditingSectionKey = 'capitalLoans';
export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'R2-capitalLoans';
const jsonVocab = auditingJson.form.capitalLoans;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
    loanConfirmations: [{
        id: "1",
        generalLedger: 0,
        balance: 0,
        minus: 0,
        other: "",
    }],
    reconciliationTables: [{
        id: "1",
        generalLedger: 0,
        shortPerYear: 0,
        shortMade: 0,
        balance: 0,
        minus: 0,
        other: "",
    }],
    procedureAccountsManualData: [{
        currentYear: 0,
        priorYear: 0,
        accountName: "",
        comment: ""
    }],
    procedureAccountsData: {},
    tableColumnData: [
    {
        left: "Velat, jotka taseelle on merkitty ovat olemassa.",
        right: auditingJson.OLE
    },
    {
        left: "Taseella esitetyt velat ovat yhtiön velvotteita.",
        right: auditingJson.OVE
    },
    {
        left: "Kaikki velat, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jorka olisi pitänyt esittää tilinpäätöksellä on esitetty.",
        right: auditingJson.TAY
    },
    {
        left: "Velat on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti.",
        right: auditingJson.ARV
    },
    {
        left: "Velat on luokiteltu oikein.",
        right: auditingJson.LUOK
    },
    {
        left: "Velat on yhdistelty tai jaoteltu asianmukaisesti ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja.",
        right: auditingJson.ESI
    }
    ],
    conclusion: jsonVocab.conclusionFieldValue,
    describePeriod: 'Kuvaa tässä lyhyesti yleisellä tasolla yhtiön lainoja ja niissä tapahtuneita muutoksia, esimerkiksi: Yhtiöllä on  pääomalainaa omistajalta. Lainan määrässä ei ole tapahtunut muutoksia vertailukauteen nähden. Kyseessä on vieraan pääoman alla esitettävä pääomalaina.',
    inspectionConclusion: 'overPm',
    reference: 'Viite tiedostoon esim R2',
    statementsAndGoals: 'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ, esimerkiksi: käyty läpi yhtiön pääomalainasopimuksia ja niihin liittyviä ehtoja.  Tarkastuksen tavoitteena on muodostaa käsitys lainoihin liittyvistä oikeuksista ja velvollisuuksista (OVE) ja havaita ehdot, jotka voivat vaikuttaa lainojen luokitteluun / esittämiseen tilinpäätöksellä (LUO,ESI)',
    OYL: null,
    VPO: null,
    isLoanClassified: null,
    areTheTerms: null,
    notesAndComments: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja ja  avaa halutessasi keskeiset ehdot esim tarkastelun perusteella yhtiön pääomalainat ovat tasalyhenteisiä, laina-aika on 5 vuotta, korko 5% .Esittäminen VPO alla asianmukaista huomioiden, että lainalle on määritelty lyhennysaikataulut ja korko.',
    inspectionConclusion2: 'ampt',
    receivedRequestedInfo: null,
    reference2: 'Viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon',
    statementsAndGoals2: 'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ',
    notesAndComments2: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja',
    useReadyMade: null,
    inspectionConclusion3: "pm",
    reference3: "viittaus vahvistusten tallennuspaikkaan ja soveltuvin osin viittaus tarkastustiedostoon",
    statementsAndGoals3: "kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ",
    notesAndComments3: "kuvaa tässä mahdolliset havainnot / ei havaintoja",
    useReadyMade2: null,
    inspectionConclusion4: "overPm",
    statementsAndGoals4: 'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ esimerkiksi tarkastettu korkokuluja muodostamalla odotusarvo rahoitussopimuksen perusteella. Arvioitu koron kirjaamisen ja maksun edellytyksiä',
    notesAndComments4: 'Kuvaa tässä mahdolliset havainnot / ei havaintoja esim. Koron maksun edellytykset ovat täyttyneet ja korko on kirjattu tilikaudelle. Kirjattu korko on linjassa uudelleen lasketun koron kanssa.',
    anyConditions: null,
    interestExpense: null,
    unrecordedInterest: null,

    spreadsheet1:[{
        cellData: [],
    }],
    spreadsheet2:[{
        cellData: [],
    }],
    spreadsheet3:[{
        cellData: [],
    }],
    spreadsheet4:[{
        cellData: [],
    }],
    // Sheets
    useSheetTab: null,
    useSheet1: null,
    useSheet2: null,
    useSheet3: null,
    useSheet4: null,
})

const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({
        items: [
            formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
            formBuilder.custom(props => <ProcedureAccountsElement auditing={auditing} {...props} batchString={batchString} addCommentField />),
            formBuilder.custom(props => <TableColumn {...props} />),
            formBuilder.textArea({accessor: 'conclusion', plainLabel: auditingJson.conclusion}),
        ]
    }),
    ...displayAuditingProcedures(store, batchString, formBuilder, [
        {
            // Pääomalainasopimusten läpikäynti...
            accordionId: 'reviewingOfCapitalLoan',
            lowerElements: [
                formBuilder.group({
                    items: [
                        formBuilder.subtitle({title: jsonVocab.basedOnTheLoanTerms}),
                        formBuilder.checkboxGroup({
                            accessor: 'OYL',
                            options: Object.keys(jsonVocab.OYLOptions),
                        }),
                        formBuilder.checkboxGroup({
                            accessor: 'VPO',
                            options: Object.keys(jsonVocab.VPOOptions),
                        }),
                        formBuilder.checkboxGroup({
                            accessor: 'isLoanClassified',
                            options: Object.keys(jsonVocab.isLoanClassifiedOptions),
                        }),
                        formBuilder.checkboxGroup({
                            accessor: 'areTheTerms',
                            options: Object.keys(jsonVocab.areTheTermsOptions),
                        }),
                    ],
                }),
                formBuilder.boolean({accessor: 'useSheet1', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet id="spreadsheet1" {...props} /> : null),
            ]
        },
        {
            // Lainojen täsmäytys saldovahvistuksille
            accordionId: 'loanReconciliation',
            upperElements: [
                formBuilder.checkboxGroup({
                    accessor: "receivedRequestedInfo",
                    plainLabel: auditingJson.haveConfirmationsBeenReceived,
                    options: Object.keys(auditingJson.receivedConfirmationsOptions),
                    optionDisplayValue: (key: string) => auditingJson.receivedConfirmationsOptions[key as keyof typeof auditingJson.receivedConfirmationsOptions],
                }),
            ],
            lowerElements: [
                formBuilder.boolean({accessor: 'useReadyMade'}),
                formBuilder.custom(props => formState.useReadyMade ? <LoanConfimationsTableColumn {...props} /> : null),	

                formBuilder.boolean({accessor: 'useSheet2', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="spreadsheet2" {...props} /> : null),
            ]
        },
        {
            // Lainojen täsmäytys velkakirjoille
            accordionId: 'reconciliationOfLoans',
            lowerElements: [
                formBuilder.boolean({accessor: 'useReadyMade2'}),
                formBuilder.custom(props => formState.useReadyMade2 ? <ReconciliationTableColumn {...props} /> : null),

                formBuilder.boolean({accessor: 'useSheet3', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="spreadsheet3" {...props} /> : null),
            ]
        },
        {
            // Pääomalainojen korot
            accordionId: 'interestRatesOnCapitalLoans',
            lowerElements: [
                formBuilder.group({
                    items: [
                        formBuilder.checkboxGroup({
                            accessor: 'anyConditions',
                            options: Object.keys(jsonVocab.anyConditionsOptions),
                        }),
                        formBuilder.checkboxGroup({
                            accessor: 'interestExpense',
                            options: Object.keys(jsonVocab.interestExpenseOptions),
                        }),
                        formBuilder.checkboxGroup({
                            accessor: 'unrecordedInterest',
                            options: Object.keys(jsonVocab.unrecordedInterestOptions),
                            hidden: formState.interestExpense ? formState.interestExpense.no === false : true
                        }),
                    ],
                }),
                formBuilder.boolean({accessor: 'useSheet4', plainLabel: auditingJson.useSheet}),
                formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="spreadsheet4" {...props} /> : null),
            ]
        }
    ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;