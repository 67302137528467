import { Colors } from "../../theme";
import { uuid } from "../../utils";
import AuditingTable from "../table/AuditingTable";
import { OnEditProps, TableColumnDefs } from "../table/Table";

const sectionKey: AuditingSectionKey = 'errors';

export const AuditFindingsTable = ({
    formState,
    patchFormState,
    store,
}: FormFieldProps<AuditingProceduresForm>) => {

    if (!formState) return null;

    const defaultColor = Colors.transparent;
    const fixed = Colors.green;
    const notEssential = Colors.yellow;
    const essential = Colors.red;

    const bgColor = (row: AuditFindings) => {
        if (row.fixed) return fixed;
        if (row.essential) return essential;
        if (row.notEssential) return notEssential;
        return defaultColor;
    };
    const addRowColor = (row: AuditFindings) => ({
        ...row,
        bgColor: bgColor(row),
    })

    // TODO: Add colors to the table
    const data: AuditFindings[] = formState?.auditFindings?.map(addRowColor) ?? [
        {
            id: '1',
            conclusion: '',
            perception: '',
            fixed: false,
            notEssential: false,
            essential: false,
        }
    ];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });
        const newFormState = { 
            ...formState, 
            auditFindings: patch,
        };
        patchFormState(newFormState);
    }

    const tableColumns: TableColumnDefs<AuditFindings> = [
        {
            accessorKey: 'perception',
            header: () => 'Havainto',
            onEdit: (editProps: OnEditProps<string>) => {
                handleEdit<string>(editProps);
            },
            className: 'text-center nowrap width-half'
        },
        {
            accessorKey: 'conclusion',
            header: () => 'Johtopäätös',
            onEdit: (editProps: OnEditProps<string>) => {
                handleEdit<string>(editProps);
            },
            className: 'text-center nowrap width-half'
        },
        {
            accessorKey: 'fixed',
            header: () => 'Korjattu',
            onBooleanEdit: (editProps: OnEditProps<boolean>) => {
                handleEdit<boolean>(editProps);
            },
            className: 'text-center nowrap width-half'
        },
        {
            accessorKey: 'notEssential',
            header: () => 'Korjaamaton ei olennainen',
            onBooleanEdit: (editProps: OnEditProps<boolean>) => {
                handleEdit<boolean>(editProps);
            },
            className: 'text-center nowrap width-half'
        },
        {
            accessorKey: 'essential',
            header: () => 'Korjaamaton olennainen',
            onBooleanEdit: (editProps: OnEditProps<boolean>) => {
                handleEdit<boolean>(editProps);
            },
            className: 'text-center nowrap width-half'
        },
    ];

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            auditFindings: [
                ...(formState.auditFindings ?? []),
                {
                    id: uuid(),
                    conclusion: '',
                    perception: '',
                    fixed: false,
                    notEssential: false,
                    essential: false,
                }
            ]
        })
    };

    return (
        <>
        <AuditingTable<AuditFindings>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            onAddNewRow={handleAddNewRow}
        />
        </>
    )
};