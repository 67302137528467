import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  getExampleCSV,
  ParseMethod,
} from '../views/auditingSections/analyzes/generalLedgerUtils';

const Container = styled.div`
  position: relative;
  margin: ${p => p.theme.spacing.sm} 0;
  ${p => p.theme.font.size[12]};

  .title {
    position: absolute;
    bottom: 0;
    right: -2px;
    transform: rotate(90deg) translate(0, 0);
    transform-origin: right bottom;
    color: ${p => p.theme.color.grey300};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    ${p => p.theme.color.grey100},
    rgba(0, 0, 0, 0) 40%
  );
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  border: 2px solid ${p => p.theme.color.grey300};

  thead tr {
    border-bottom: 2px solid ${p => p.theme.color.grey300};
  }

  td {
    background-color: ${p => p.theme.color.background};
    border: 1px solid ${p => p.theme.color.grey200};
    color: ${p => p.theme.color.grey400};
    font-weight: normal;
  }

  th {
    background-color: ${p => p.theme.color.grey200};
    border: 1px solid ${p => p.theme.color.grey300};
    color: ${p => p.theme.color.background};
    font-weight: bold;
  }

  td,
  th {
    height: 16px;
    max-width: 125px;
    padding: 0 ${p => p.theme.spacing.sm};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

interface Props {
  id?: string;
  type?: ParseMethod;
  separator?: ';' | ',';
}

const CsvParseMethodExample: React.FC<Props> = ({
  id,
  type,
  separator = ';',
}) => {
  const { t } = useTranslation();

  const exampleData = getExampleCSV(type)?.split('\n');

  if (!exampleData?.length) return null;

  const [headerRow, ...dataRows] = exampleData;

  return (
    <Container id={id} data-testid={id}>
      <Overlay />

      <div className="title" data-testid={`${id}-title`}>
        {t('common:label.example') + ' CSV'}
      </div>

      <StyledTable>
        <thead>
          <tr>
            {headerRow.split(separator).map((value, i) => (
              <th key={`${value}-${i}`}>{value}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {dataRows.map((row, i) => {
            const [headerCell, ...cells] = row.split(separator);

            return (
              <tr key={`row-${i}`}>
                <th>{headerCell}</th>
                {cells.map((value, i) => (
                  <td key={`${value}-${i}`}>{value}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </Container>
  );
};

export default CsvParseMethodExample;
