import { t } from '../i18n';
import { PASSWORD_LENGTH } from './constants';

export const isDefined = (value: any) => value !== null && value !== undefined;

type ValidationMessages = string[] | undefined;

export const validatePassword = (
  password: string,
  passwordConfirm?: string
): ValidationMessages => {
  const validationMessages: string[] = [];

  if (password.length < PASSWORD_LENGTH)
    validationMessages.push(t('validate:error.passwordLength'));
  if (passwordConfirm && password !== passwordConfirm)
    validationMessages.push(t('validate:error.passwordMatch'));

  return validationMessages.length ? validationMessages : undefined;
};
