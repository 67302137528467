import React, { useEffect, useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';

interface Props {
    auditing?: Auditing;
    formState: any;
    patchFormState: Function;
    firstLabel: string;
    secondLabel: string;
    id: string;
    addCommentField?: boolean;
}

export const TwoFieldSubtractionForm: React.FC<Props> = ({ formState, patchFormState, firstLabel, secondLabel, id, addCommentField }) => {
    /*
      Using this function, add this to the defaultFormState:
            TwoFieldSubtractionFormData: []
    */

    if(formState.TwoFieldSubtractionFormData[id].length < 1){
        patchFormState({
            ...formState,
            TwoFieldSubtractionFormData: {
                ...formState.TwoFieldSubtractionFormData,
                [id]: [
                    {
                        label: firstLabel,
                        value: 0,
                        id: 1,
                        comment: ""
                    },
                    {
                        label: secondLabel,
                        value: 0,
                        id: 2,
                        comment: ""
                    },
                ]
            }
        })
    }
  
    const [data, setData] = useState([
        {
            label: firstLabel,
            value: 0,
            id: 1,
            comment: ""
        },
        {
            label: secondLabel,
            value: 0,
            id: 2,
            comment: ""
        },
    ])
    
    useEffect(() => {
        const formData = formState?.TwoFieldSubtractionFormData;
        formState && id && formData && setData(formData[id]);
    }, [formState, formState?.TwoFieldSubtractionFormData, id]); // Fix: Add formState to the dependency array
  
    function handleEdit(props:any) {
        var duplicateFormState = [...formState.TwoFieldSubtractionFormData[id]];
        duplicateFormState.find(item => item.id === props.itemId).value = props.value
        patchFormState({
            ...formState,
            TwoFieldSubtractionFormData: {
                ...formState.TwoFieldSubtractionFormData,
                [id]: duplicateFormState
            }
        })
    }
  
    var tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => null,
        },
        {
            accessorKey: 'value',
            header: () => null,
            className: 'width-min text-center nowrap',
            onNumberEdit: handleEdit
        }
    ];

    addCommentField && tableColumns.push({
        accessorKey: 'comment',
        header: () => "Kommentit",
        className: 'width-min text-center nowrap',
        onEdit: handleEdit
    })

    return (
      <>
        <AuditingTable
          data={data}
          columns={tableColumns}
          showGlobalFilter={false}
        />
        {data.length > 0 &&<p style={{margin: 5}}><b>Erotus: {data[0].value - data[1].value} </b></p>}
      </>
    );
  };
  
  