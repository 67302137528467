import { useTranslation } from 'react-i18next';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

type FormType = PermanentDataForm;
type TableItemType = {
  label: string;
  value: string;
  source: string;
};
type DataKey = keyof CompanyInformation;

const sectionKey: AuditingSectionKey = 'permanentData';
const formFieldKey: keyof FormType = 'companyInformation';

interface Props extends FormFieldProps<FormType> {
  visibleData: DataKey[];
}

export const CompanyInformation = ({
  visibleData,
  formState,
  patchFormState,
}: Props) => {
  const { t } = useTranslation();

  const companyData = formState[formFieldKey];

  if (!companyData) return null;

  const tBase = `auditing:form.${sectionKey}.${formFieldKey}`;

  const data: TableItemType[] = visibleData.map((key) => {
    const sourceKey = `${key}Source` as DataKey;
    return ({
      id: key,
      label: t(`${tBase}.label.${key}`),
      value: `${companyData[key] ?? ''}`,
      source: companyData[sourceKey] ? `${companyData[sourceKey]}` : '',
    })
  });

  function handleEdit<T>({ itemId, columnId, value }: OnEditProps<T>) {
    const isSourceField = columnId === 'source';
    const fieldKey = (isSourceField ? `${itemId}Source` : itemId) as DataKey;
    const patch = {
      ...formState.companyInformation,
      [fieldKey]: value,
    };
    patchFormState({ ...formState, [formFieldKey]: patch });
  }

  const tableColumns: TableColumnDefs<TableItemType> = [
    {
      accessorKey: 'label',
      header: () => '',
      className: 'width-min nowrap text-bold',
    },
    {
      accessorKey: 'value',
      header: () => '',
      className: 'width-max',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'source',
      header: () => t(`${tBase}.source`),
      className: 'width-min nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
  ];

  return (
    <AuditingTable<TableItemType>
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
