import { FormFieldBuilder } from '../FormFieldBuilder';
import auditingJson from '../../../i18n/locales/fi/auditing.json';

type SectionFormType = ManualExportsForm;

const sectionKey: AuditingSectionKey = 'manualExports';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.manualExports;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  unusualShortcuts: null,
  reference: jsonVocab.referenceValue,
  ideasGoalsAndCompletedWork: jsonVocab.conclusionsGoalsAndWorkValue,
  findingsAndComments: jsonVocab.notesAndCommensValue,

  part1SubtitleText: jsonVocab.part1subtitleText,
  part1checkBox: null,
  part1textArea: jsonVocab.part1textAreaValue,

  part2textArea: jsonVocab.part2textAreaValue,

  part3textArea: jsonVocab.part3textAreaValue,

  part4checkBox: jsonVocab.part4checkBoxOptions,
  part4textArea: jsonVocab.part4textAreaValue,

  
  part5checkBox: jsonVocab.part5checkBoxOptions,
  part5textArea: jsonVocab.part5textAreaValue,

  part6checkBox: jsonVocab.part6checkBoxOptions,
  part6textArea: jsonVocab.part6textAreaValue
})

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  // Upper part
  formBuilder.group({items: [
    formBuilder.group({items: [
      formBuilder.subtitle({title: jsonVocab.subtitleText}),
      formBuilder.text({text: jsonVocab.paragraphText}),
      formBuilder.checkboxGroup({accessor: 'unusualShortcuts', options: Object.keys(jsonVocab.unusualShortcutsOptions), plainLabel: auditingJson.inspectionConclusion,
      optionDisplayValue: (key: string) => jsonVocab.unusualShortcutsOptions[key as keyof typeof jsonVocab.unusualShortcutsOptions]}),
      formBuilder.textArea({accessor: 'reference', plainLabel: auditingJson.reference}),
      formBuilder.textArea({accessor: 'ideasGoalsAndCompletedWork', plainLabel: auditingJson.ideasGoalsAndCompletedWork}),
      formBuilder.textArea({accessor: 'findingsAndComments', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 1
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part1subtitleText}),
      formBuilder.checkboxGroup({accessor: 'part1checkBox', options: Object.keys(jsonVocab.part1checkBoxOptions), plainLabel: ' ',
      optionDisplayValue: (key: string) => jsonVocab.part1checkBoxOptions[key as keyof typeof jsonVocab.part1checkBoxOptions]}),
      formBuilder.textArea({accessor: 'part1textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 2
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part2subtitleText}),
      formBuilder.textArea({accessor: 'part2textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 3
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part3subtitleText}),
      formBuilder.textArea({accessor: 'part3textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 4
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part4subtitleText}),
      formBuilder.checkboxGroup({accessor: 'part4checkBox', options: Object.keys(jsonVocab.part4checkBoxOptions), plainLabel: ' ',
      optionDisplayValue: (key: string) => jsonVocab.part4checkBoxOptions[key as keyof typeof jsonVocab.part4checkBoxOptions]}),
      formBuilder.textArea({accessor: 'part4textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 5
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part5subtitleText}),
      formBuilder.checkboxGroup({accessor: 'part5checkBox', options: Object.keys(jsonVocab.part5checkBoxOptions), plainLabel: ' ',
      optionDisplayValue: (key: string) => jsonVocab.part5checkBoxOptions[key as keyof typeof jsonVocab.part5checkBoxOptions],
      showContextInfo: 'top-right'}),
      formBuilder.textArea({accessor: 'part5textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  
    // 6
    formBuilder.group({items: [
      formBuilder.text({text: jsonVocab.part6subtitleText}),
      formBuilder.checkboxGroup({accessor: 'part6checkBox', options: Object.keys(jsonVocab.part6checkBoxOptions), plainLabel: ' ',
      optionDisplayValue: (key: string) => jsonVocab.part6checkBoxOptions[key as keyof typeof jsonVocab.part6checkBoxOptions]}),
      formBuilder.textArea({accessor: 'part6textArea', plainLabel: auditingJson.remarksAndComments}),
    ]}),
  ]}),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
