import React from "react";
import { TextInput, InputGroup, Select, Button } from "../../../components/inputs";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../utils";

export const IncomeStatementMaterialityScoping = ({
    formState,
    patchFormState,
    store
}: FormFieldProps<IncomeStatementAndBalanceScopingForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    /*
    "selectMaterialityOptions": {
        "optionOne": "Valitse rajattu riski kaikkiin eriin, jotka ylittävät olennaisuuslaskennassa määritellyn PM tason",
        "optionTwo": "Valitse rajattu riski kaikkiin eriin, jotka ylittävät muun euromääräisen rajan"
    }
    */
    const tBase = `action:selectMaterialityOptions`;
    const tKey = 'auditing:form.incomeStatementAndBalance.updateIncomeMateriality';

    const {
        auditingStore: { materialityComputeds, getAuditingSection }
    } = store;

    const materialityForm = getAuditingSection('materiality')?.form;
    const materialities = materialityComputeds(materialityForm);

    const options = [
        { value: false, label: t(`${tBase}.optionOne`) },
        { value: true, label: t(`${tBase}.optionTwo`) }
    ];

    return (
        <>
        {/* Radio here to select between tBase's optionOne and optionTwo */}
        <InputGroup
            label=''
            children={
                <>
                <Select
                    value={options.find(option => option.value === formState.selectMateriality)}
                    setValue={value => {
                        if (typeof value === 'object' && value !== null && 'value' in value) {
                            patchFormState({ selectMateriality: value.value });
                        }
                    }}
                    options={options}
                    displayValue={(option) => option.label}
                    fullWidth
                />
                <TextInput
                    value={formState.materialityNumber}
                    setValue={value => patchFormState({ materialityNumber: value })}
                    type="number"
                    disabled={false}
                />
                </>
            }
        />
        {!formState.selectMateriality && (
            <Button
                text={t(tKey, { value: formatCurrency(materialities.workingMateriality) })}
                onClick={() => patchFormState({ materialityNumber: materialities.workingMateriality })}
                fullWidth
                variant="link"
            />
            )
        }
        </>
    )
}