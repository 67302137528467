import styled from 'styled-components';
import { DATE_INPUT_WIDTH, INPUT_WIDTH, NUMBER_INPUT_WIDTH } from '../../utils';

interface InputContainerProps {
  fullWidth?: boolean;
  minWidth?: boolean;
  minimal?: boolean;
  mediumWidth?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  margin: ${p => p.theme.spacing[p.minimal ? 'xsm' : 'md']} 0;

  textarea {
    resize: vertical;
  }

  input,
  textarea,
  .button-as-input {
    display: block;
  }

  .button-as-input {
    position: relative;
    text-align: left;
    height: ${p => (p.minimal ? 28 : 42)}px;
    svg {
      position: absolute;
      right: ${p => (p.minimal ? 0.3 : 0.8)}rem;
      bottom: ${p => (p.minimal ? 0.45 : 0.75)}rem;
      cursor: pointer;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input:not(input[type='radio']),
  textarea,
  .button-as-input {
    width: ${p =>
      p.fullWidth
        ? '100%'
        : p.minWidth || p.minimal
        ? NUMBER_INPUT_WIDTH
        : p.mediumWidth
        ? DATE_INPUT_WIDTH
        : INPUT_WIDTH};
    border-width: 1px;
    border-style: solid;
    border-color: ${p => p.theme.color[p.minimal ? 'grey300' : 'secondary']};
    border-radius: ${p => p.theme.borderRadius.sm};
    background-color: ${p => p.theme.color.background};
    margin-top: ${p => p.theme.spacing.xsm};
    padding: ${p => p.theme.spacing[p.minimal ? 'sm' : 'md']};
    padding-right: 14px;
    ${p => (p.minimal ? p.theme.font.size[12] : undefined)};
  }

  input[disabled],
  textarea[disabled],
  .button-as-input[disabled] {
    border: 1px solid ${p => p.theme.color.grey300} !important;
    color: ${p => p.theme.color.grey400};
    background-color: ${p => p.theme.color.background};
    resize: none;
    cursor: not-allowed;
    svg {
      display: none;
    }
  }

  button.input-action-button {
    position: absolute;
    padding: 9px 12px;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
`;

export const InputCheckboxContainer = styled.div`
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: ${p => p.theme.spacing.md} 0;
    user-select: none;

    > span {
      padding-left: ${p => p.theme.spacing.sm};
    }
  }
`;
