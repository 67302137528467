import { t } from '../../../i18n';

export enum IsSpecialistNeeded {
  no = 'no',
  yes = 'yes',
}

export enum Unpredictability {
  nothing = 'nothing',
  auditAccountsOutsideMaterialityOrRisks = 'auditAccountsOutsideMaterialityOrRisks',
  changeTiming = 'changeTiming',
  credentialsForAuditor = 'credentialsForAuditor',
  useAbnormalMethods = 'useAbnormalMethods',
  other = 'other',
}

export enum ControlsToBeTested {
  controlsDetected = 'controlsDetected',
  controlsNotTrusted = 'controlsNotTrusted',
  other = 'other',
}

export const showUnpredictabilityDetails = (form: AuditingPlanningForm) =>
  form.unpredictability === null ||
  ![
    Unpredictability.auditAccountsOutsideMaterialityOrRisks,
    Unpredictability.other,
  ].includes(form.unpredictability);

export const showUnpredictabilityContext = (form: AuditingPlanningForm) =>
  form.unpredictability ===
  Unpredictability.auditAccountsOutsideMaterialityOrRisks;

export const showAnalyticalRiskAssessmentMeasuresDetails = (
  form: AuditingPlanningForm
) =>
  form.analyticalRiskAssessmentMeasuresDone === null ||
  form.analyticalRiskAssessmentMeasuresDone === true;

export const unpredictabilityDetailsContextText = t(
  `auditing:form.auditingPlanning.unpredictabilityAuditAccountsOutsideMaterialityOrRisksInfo`
);
