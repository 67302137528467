import React from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';
import { formatNumber } from '../../../../components/auditing/PMATables';

interface Props {
    auditing?: Auditing;
    formState: any;
    patchFormState: Function;
    id: string;
    useCurrency?: boolean;
}

export const WareHousesForm: React.FC<Props> = ({ formState, patchFormState, id, useCurrency }) => {
    /*
      Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
      
        const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
            WareHousesFormData: []
        })
    */

  
    function handleEdit(props:any) {
        var duplicateFormState = [...formState.WareHousesFormData[id]];
        duplicateFormState.find((item:any) => item.id === props.itemId).right = props.value
        if(duplicateFormState !== formState.WareHousesFormData[id]){
            patchFormState({
                ...formState,
                WareHousesFormData: {
                    ...formState.WareHousesFormData,
                    [id]: duplicateFormState
                }
            })
        }
  
    }
  
    var tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'left',
            className: 'width-min',
            header: () => null,
        },
        {
            accessorKey: 'right',
            className: 'width-min',
            header: () => null,
            onNumberEdit: handleEdit
        },
    ];

      
    var unEditableTableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'left',
            className: 'width-min',
            header: () => null,
        },
        {
            accessorKey: 'right',
            className: 'width-min',
            header: () => null,
        },
    ];



    const thisData = formState.WareHousesFormData[id];
    return (
      <>
        <AuditingTable
          data={[thisData[0], thisData[1]]}
          columns={tableColumns}
          showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
          data={[{left: "Varaston muutos tuloksella", right: thisData[1].right - thisData[0].right}]}
          columns={unEditableTableColumns}
          showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
          data={[thisData[2]]}
          columns={tableColumns}
          showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
          data={[{left: "Erotus", right: (thisData[1].right - thisData[0].right) - thisData[2].right}]}
          columns={unEditableTableColumns}
          showGlobalFilter={false}
        />
      </>
    );
  };

  export const SecondWarehousesForm: React.FC<Props> = ({ formState, patchFormState, id, useCurrency }) => {
    /*
      Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
      
        const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
            WareHousesFormData: []
        })
    */

  
    function handleEdit(props:any) {
        var duplicateFormState = [...formState.SecondWareHousesFormData[id]];
        duplicateFormState[props.rowIndex][props.columnId] = props.value
        if(duplicateFormState !== formState.SecondWareHousesFormData[id]){
            patchFormState({
                ...formState,
                SecondWareHousesFormData: {
                    ...formState.SecondWareHousesFormData,
                    [id]: duplicateFormState
                }
            })
        }
    }

    const data = formState.SecondWareHousesFormData[id];
    for(var i=0; i<data.length; i++){
        const item = data[i]
        item.warehouseValue = item.warehouseId * item.pricePerItem;
        item.differenceToWarehouseValue = item.warehouseValue - item.salePrice;
        item.differenceAllTogether = item.differenceToWarehouseValue * item.warehouseId;
    }

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            SecondWareHousesFormData: {
                ...formState.SecondWareHousesFormData,
                [id]: [...formState.SecondWareHousesFormData[id], {
                    id: formState.SecondWareHousesFormData[id].length,
                    label: '',
                    warehouseId: 0,
                    pricePerItem: 0,
                    warehouseValue: 0,
                    billNumber: '',
                    billDate: '',
                    differenceToWarehouseValue: 0,
                    differenceAllTogether: 0,
                    comments: ''
                }]
            }
        })
    }
  
    var tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => "Nimike",
            onEdit: handleEdit
        },
        {
            accessorKey: 'warehouseId',
            className: 'width-min',
            header: () => "Varastosaldo",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'pricePerItem',
            className: 'width-min',
            header: () => "Kappalehinta",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'warehouseValue',
            className: 'width-min',
            header: () => "Varastoarvo",
            cell: ({ cell }) => <div>{formatNumber(cell.row.original.warehouseValue)} €</div>,


        },
        {
            accessorKey: 'billNumber',
            className: 'width-min',
            header: () => "Laskunro",
            onEdit: handleEdit
        },
        {
            accessorKey: 'billDate',
            className: 'width-min',
            header: () => "Laskupäivä",
            onEdit: handleEdit
        },
        {
            accessorKey: 'salePrice',
            className: 'width-min',
            header: () => "Myyntihinta",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'differenceToWarehouseValue',
            className: 'width-min',
            header: () => "Ero varastoarvoon",
            cell: ({ cell }) => <div>{formatNumber(cell.row.original.differenceToWarehouseValue)} €</div>,

        },
        {
            accessorKey: 'differenceAllTogether',
            className: 'width-min',
            header: () => "Ero kaikki varastossa olevat kappaleet ",

        },
        {
            accessorKey: 'comments',
            className: 'width-min',
            header: () => "Kommentit",
            onEdit: handleEdit
        },
    ];

    return (
    <AuditingTable
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        onAddNewRow={handleAddNewRow}
    />
    );
  };
  

  export const ThirdWarehousesForm: React.FC<Props> = ({ formState, patchFormState, id, useCurrency }) => {
    /*
      Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
      
        const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
            WareHousesFormData: []
        })
    */

  
    function handleEdit(props:any) {
        var duplicateFormState = [...formState.ThirdWarehousesFormData[id]];
        duplicateFormState[props.rowIndex][props.columnId] = props.value
        if(duplicateFormState !== formState.ThirdWarehousesFormData[id]){
            patchFormState({
                ...formState,
                ThirdWarehousesFormData: {
                    ...formState.ThirdWarehousesFormData,
                    [id]: duplicateFormState
                }
            })
        }
    }

    const data = formState.ThirdWarehousesFormData[id];
    for(var i=0; i<data.length; i++){
        const item = data[i]
        item.warehouseValue = item.warehouseId * item.pricePerItem;
        item.differenceToWarehouseValue = item.warehouseValue - item.salePrice;
        item.profit = item.differenceToWarehouseValue/item.pricePerItem;
        item.differenceAllTogether = item.differenceToWarehouseValue * item.warehouseId;
    }

    const handleAddNewRow = () => {
        patchFormState({
            ...formState,
            ThirdWarehousesFormData: {
                ...formState.ThirdWarehousesFormData,
                [id]: [...formState.ThirdWarehousesFormData[id], {
                    id: formState.ThirdWarehousesFormData[id].length,
                    label: '',
                    warehouseId: 0,
                    pricePerItem: 0,
                    warehouseValue: 0,
                    billNumber: '',
                    billDate: '',
                    differenceToWarehouseValue: 0,
                    differenceAllTogether: 0,
                    comments: ''
                }]
            }
        })
    }
  
    var tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => "Nimike",
            onEdit: handleEdit
        },
        {
            accessorKey: 'warehouseId',
            className: 'width-min',
            header: () => "Varastosaldo",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'pricePerItem',
            className: 'width-min',
            header: () => "Kappalehinta",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'warehouseValue',
            className: 'width-min',
            header: () => "Varastoarvo",
            cell: ({ cell }) => <div>{formatNumber(cell.row.original.warehouseValue)} €</div>,


        },
        {
            accessorKey: 'billNumber',
            className: 'width-min',
            header: () => "Laskunro",
            onEdit: handleEdit
        },
        {
            accessorKey: 'billDate',
            className: 'width-min',
            header: () => "Laskupäivä",
            onEdit: handleEdit
        },
        {
            accessorKey: 'salePrice',
            className: 'width-min',
            header: () => "Myyntihinta",
            onNumberEdit: handleEdit
        },
        {
            accessorKey: 'differenceToWarehouseValue',
            className: 'width-min',
            header: () => "Ero varastoarvoon",
            cell: ({ cell }) => <div>{formatNumber(cell.row.original.differenceToWarehouseValue)} €</div>,

        },
        {
            accessorKey: 'profit',
            className: 'width-min',
            header: () => "Kate",
            cell: ({ cell }) => <div>{formatNumber(cell.row.original.profit)} %</div>,

        },
        {
            accessorKey: 'differenceAllTogether',
            className: 'width-min',
            header: () => "Ero kaikki varastossa olevat kappaleet ",

        },
        {
            accessorKey: 'comments',
            className: 'width-min',
            header: () => "Kommentit",
            onEdit: handleEdit
        },
    ];



    return (
    <AuditingTable
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        onAddNewRow={handleAddNewRow}
    />
    );
  };
  
  
  