import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import { useStore } from '../../store/RootContext';
import { useTranslation } from 'react-i18next';
import Menu from '../Menu';
import { path } from '../../i18n/utils';

const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    authStore: { logout, userFullName },
  } = useStore();

  const menuItems = [
    {
      label: t('route:myProfile'),
      icon: <Icon type="User" />,
      onClick: () => navigate(`/${path('myProfile')}`),
    },
    {
      label: t('action:logout'),
      icon: <Icon type="Logout" />,
      onClick: () => {
        logout();
        navigate('/');
      },
    },
  ];

  return (
    <div className="flex-row flex-row-gap no-print">
      <div>
        <div>{t('common:loggedIn')}</div>
        <div>{userFullName}</div>
      </div>

      <Menu items={menuItems} />
    </div>
  );
};

export default UserMenu;
