import { FormFieldBuilder } from '../FormFieldBuilder';
import { getAddress } from '../../../utils';
import { MandateCompanyInformation } from '../../../components/essentials/MandateAuditor.CompanyInformation';
import { visibleData } from './MandateAuditorUtils';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
//import FileDropZone from '../../../components/FileDropZone';

type SectionFormType = MandateAuditorForm;
const sectionKey: AuditingSectionKey = 'MandateAuditor';
export const tBase = `auditing:form.${sectionKey}`;

const vocabulary = auditingJson.form.MandateAuditor;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({

  companyInformation: {
    // Tiedot
    id: auditing.id,
    name: auditing.customerCompany?.name ?? '',
    nameSource: '',
    businessId: auditing.customerCompany?.businessId ?? '',
    businessIdSource: '',
    address: auditing.customerCompany ? getAddress(auditing.customerCompany) : '',
    
    // Databasesta ottaa startDate ja endDate 'en-US' muodossa, joten muutetaan ne 'fi-FI' muotoon.
    // toLocaleDateString -metodia päivämäärien muotoiluun.
    // trim -metodia käytetään poistamaan johtava tai seuraava välilyönti.
    financialPeriod: `
    ${auditing.startDate ? 
      new Date(auditing.startDate).toLocaleDateString('fi-FI', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''} - 
      ${auditing.endDate ? new Date(auditing.endDate).toLocaleDateString('fi-FI', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}`.trim(),

    selectedAuditor: '',
    inspectionTeamMembers: '',
    contactEmail: `${auditing.customerCompany?.contactName ?? 'Nimi'}   -   ${auditing.customerCompany?.contactEmail ?? 'Sähköposti'}   -   ${auditing.customerCompany?.contactPhone ?? 'Puhelinnumero'}`,
    accountant: '',
  },
  // Boolean -kenttä
  properIdentificationOfRepresentatives: '',
  properIdentificationOfRepresentativesDetails: '',
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.group({
    items: [
      // PART I
      formBuilder.custom(props => (<MandateCompanyInformation visibleData={visibleData.MandateCompanyInformationVisibleData} {...props} />)),
      // PART II
      formBuilder.checkboxGroup({
        accessor: 'properIdentificationOfRepresentatives',
        options: Object.keys(vocabulary.properIdentificationOfRepresentativesOptions)
      }),
      formBuilder.textArea({
        accessor: 'properIdentificationOfRepresentativesDetails',
        hidden: !formState.properIdentificationOfRepresentatives.true,
      }),
    ]
  })

  //formBuilder.custom(props => (<FileDropZone sectionKey={sectionKey} {...props} />)),

];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;