import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../Logo';
import UserMenu from './UserMenu';

const StyledHeader = styled.header`
  position: relative;
  flex: 0 0 ${p => p.theme.ui.headerHeight}px;
  padding: 0 ${p => p.theme.spacing.xxl};

  /* Draw the line under header */
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 2%;
    right: 2%;
    width: 96%;
    height: 4px;
    background-color: ${p => p.theme.color.secondary};
    border-radius: ${p => p.theme.borderRadius.xxl};
  }
`;

const Header: React.FC = () => {
  return (
    <StyledHeader className="flex-row flex-row-gap">
      <Link to="/">
        <Logo />
      </Link>
      <UserMenu />
    </StyledHeader>
  );
};

export default Header;
