import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Popover, { PopoverPosition } from './Popover';

const Container = styled.div`
  white-space: pre-wrap;
  font-weight: normal;
  text-align: left;
`;

export interface ContextInfoProps {
  title?: string;
  text?: string;
  position?: PopoverPosition;
}

const ContextInfo: React.FC<ContextInfoProps> = ({ title, position, text }) => (
  <Popover icon={<Icon type="Question" size={16} />} position={position}>
    <Container>
      {title ? (
        <>
          <strong>{title}</strong>
          {text && <p>{text}</p>}
        </>
      ) : (
        text
      )}
    </Container>
  </Popover>
);

export default ContextInfo;
