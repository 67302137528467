import auditingTranslations from '../../../i18n/locales/fi/auditing.json';
import { AuditingTemplate, uuid } from '../../../utils';

export enum BusinessProcessKind {
  financialStatement = 'financialStatement',
  turnover = 'turnover',
  operatingIncomesAndTradeReceivables = 'operatingIncomesAndTradeReceivables',
  purchases = 'purchases',
  //purchasesKB = 'purchasesKB',
  wages = 'wages',
  governmentTransfersAndTaxRevenues = 'governmentTransfersAndTaxRevenues',
  budget = 'budget',
  stocks = 'stocks',
  paymentServices = 'paymentServices',
  nonCurrentAssets = 'nonCurrentAssets',
  groupSupervision = 'groupSupervision',
  itSystems = 'itSystems',
}

const OMITTED_BUSINESS_PROCESSES: Record<
  AuditingTemplate,
  BusinessProcessKind[]
> = {
  [AuditingTemplate.private]: [
    BusinessProcessKind.governmentTransfersAndTaxRevenues,
    BusinessProcessKind.budget,
    BusinessProcessKind.operatingIncomesAndTradeReceivables,
    BusinessProcessKind.groupSupervision,
  ],
  [AuditingTemplate.public]: [BusinessProcessKind.turnover],
};

export enum AutomationOfPartialAccounting {
  automatic = 'automatic',
  manual = 'manual',
}

export const emptyRecognisedControl = () => ({
  id: uuid(),
  name: '',
  isTrusted: null,
});

export const emptyBusinessProcess = (
  props: { kind?: BusinessProcessKind } = {}
) => {
  const id = uuid();
  const kind = props.kind ?? `custom-${id}`;
  const labelTranslations =
    auditingTranslations.form.itAndProcesses.businessProcesses.kinds;
  const label = props.kind ? labelTranslations[props.kind] : '';

  return {
    id,
    selected: kind === BusinessProcessKind.financialStatement,
    kind,
    label,
    details: '',
    automationOfPartialAccounting: null,
    processDescription: {
      communicatedDetails: '',
      communicatedDate: null,
      description: '',
      recognisedControls: [emptyRecognisedControl()],
    },
  };
};

export const getDefaultBusinessProcesses = (
  template: AuditingTemplate
): BusinessProcess[] =>
  Object.values(BusinessProcessKind)
    .filter(kind => !OMITTED_BUSINESS_PROCESSES[template].includes(kind))
    .map(kind => emptyBusinessProcess({ kind }));

export enum CompletedProcedures {
  acquiredReport = 'acquiredReport',
  contactedServiceOrganization = 'contactedServiceOrganization',
  other = 'other',
}

export enum ServiceHasRelevanControls {
  no = 'no',
  yes = 'yes',
}

export enum ServiceRelatedControls {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
}

export enum ServiceRelatedControlsNoProcedures {
  testedControls = 'testedControls',
  usedOtherAuditor = 'usedOtherAuditor',
  other = 'other',
}

export enum ServiceRelatedControlsNAProcedures {
  acquiredReport = 'acquiredReport',
  testedControls = 'testedControls',
  usedOtherAuditor = 'usedOtherAuditor',
  other = 'other',
}

export const showServiceRelatedControls = (formState: ItAndProcessesForm) =>
  formState.serviceHasRelevanControls === ServiceHasRelevanControls.yes;

export const showServiceRelatedControlsNoProcedures = (
  formState: ItAndProcessesForm
) =>
  showServiceRelatedControls(formState) &&
  formState.serviceRelatedControls === ServiceRelatedControls.no;

export const showServiceRelatedControlsNAProcedures = (
  formState: ItAndProcessesForm
) =>
  showServiceRelatedControls(formState) &&
  formState.serviceRelatedControls === ServiceRelatedControls.notApplicable;
