import { useTranslation } from "react-i18next";
import AuditingTable from "../table/AuditingTable";
import { OnEditProps, TableColumnDefs } from "../table/Table";
import { formatPercent } from "../../utils";
import styled from "styled-components";
import { Colors } from "../../theme";

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
    font-size: 1.2rem;
    margin-top: 0.6rem;
  }
`;

function formatZeroDigitNumber(num: any) {
    if (num === null || num === undefined) return '0';
    return num.toLocaleString('fi-FI', { minimumFractionDigits: 0 });
  };

const sectionKey: AuditingSectionKey = "personelCosts";

// Palkkojen analyyttinen tarkastelu //
export const AnalyticalReviewTable = ({
    formState,
    patchFormState,
}: FormFieldProps<PersonelCostsForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}.analyticalReviewTable`;

    const data = Object.values(formState?.analyticalReviewTable.tableData ?? {});
    const totalWagesData = Object.values(formState?.analyticalReviewTable.totalWagesData ?? {});

    const calculateChange = (row: any) => {
        if (row) {
            row.change = row.cy - row.py;
            row.changeProcent = row.change / row.py * 100;
        }
    };

    const calculateProcent = (row: any, totalWages: any) => {
        if (row && totalWages) {
            row.cyProcent = row.cy / totalWages.cy * 100;
            row.pyProcent = row.py / totalWages.py * 100;
        }
    };

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const totalWagesData = formState?.analyticalReviewTable.totalWagesData;

        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };

                switch(columnId) {
                    case 'cy':
                    case 'py':
                        calculateChange(updatedRow);
                        if (currentRow.id === '1' || currentRow.id === '2') {
                            calculateProcent(updatedRow, totalWagesData.totalWages);
                        }
                        break;
                }

                return updatedRow;
            }
            return currentRow;
        });

        // Calculate the cy and py values for 'totalWages' when table data is updated
        const wagesAndRemuneration = patch.find(row => row.id === '1');
        const capitalizedSalaries = patch.find(row => row.id === '2');

        if (wagesAndRemuneration && capitalizedSalaries && totalWagesData) {
            totalWagesData.totalWages.cy = wagesAndRemuneration.cy + capitalizedSalaries.cy;
            totalWagesData.totalWages.py = wagesAndRemuneration.py + capitalizedSalaries.py;
            calculateChange(totalWagesData.totalWages);
        }
            
        const newFormState = { 
            ...formState, 
            analyticalReviewTable: { 
                tableData: Object.fromEntries(patch.map(item => [item.id, item])),
                totalWagesData: totalWagesData,
            } 
        };
        patchFormState(newFormState);
    }

    const tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'cyProcent',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.cyProcent)}</div>,
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'pyProcent',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.pyProcent)}</div>,
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    const totalWagesTable: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-left nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'undefined0',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-left nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'undefined1',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'undefined2',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'undefined3',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
        },
    ];

    return (
        <>
        <AuditingTable
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={totalWagesData}
            columns={totalWagesTable}
            showGlobalFilter={false}
            disableSort
            hideHeader
            variant="default"
        />
        </>
    )
}
export const PensionExpensesTable = ({
    formState,
    patchFormState,
}: FormFieldProps<PersonelCostsForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}.analyticalReviewTable`;

    const data = Object.values(formState?.pensionExpensesTable.pensionExpensesData ?? {});
    const totalPersonelSideCostsData = Object.values(formState?.pensionExpensesTable.totalPersonelSideCostsData ?? {});
    const totalPersonelCostsData = Object.values(formState?.pensionExpensesTable.totalPersonelCostsData ?? {});

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };

                // Calculate the change and changeProcent values when cy or py values are updated
                if (columnId === 'cy' || columnId === 'py') {
                    updatedRow.change = updatedRow.cy - updatedRow.py;
                    updatedRow.changeProcent = updatedRow.change / updatedRow.py * 100;
                }

                // Calculate the cyProcent value when cy value is updated
                if (columnId === 'cy' && currentRow.id === '1') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    const totalPersonelSideCostsData = formState?.pensionExpensesTable.totalPersonelSideCostsData;
                    if (totalPersonelSideCostsData && totalWagesData) {
                        updatedRow.cyProcent = updatedRow.cy / totalWagesData.totalWages.cy * 100;
                    }
                }
                if (columnId === 'py' && currentRow.id === '1') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    const totalPersonelSideCostsData = formState?.pensionExpensesTable.totalPersonelSideCostsData;
                    if (totalPersonelSideCostsData && totalWagesData) {
                        updatedRow.pyProcent = updatedRow.py / totalWagesData.totalWages.py * 100;
                    }
                }

                // Calculate the cyProcent value when cy value is updated
                if (columnId === 'cy' && currentRow.id === '2') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    const totalPersonelSideCostsData = formState?.pensionExpensesTable.totalPersonelSideCostsData;
                    if (totalPersonelSideCostsData && totalWagesData) {
                        updatedRow.cyProcent = updatedRow.cy / totalWagesData.totalWages.cy * 100;
                    }
                }
                if (columnId === 'py' && currentRow.id === '2') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    const totalPersonelSideCostsData = formState?.pensionExpensesTable.totalPersonelSideCostsData;
                    if (totalPersonelSideCostsData && totalWagesData) {
                        updatedRow.pyProcent = updatedRow.py / totalWagesData.totalWages.py * 100;
                    }
                }

                return updatedRow;
            }
            return currentRow;
        });

        // Calculate the cy and py values for 'totalPersonelSideCosts' when table data is updated
        const pensionExpenses = patch.find(row => row.id === '1');
        const otherPersonelSideCosts = patch.find(row => row.id === '2');
        const totalPersonelSideCostsData = formState?.pensionExpensesTable.totalPersonelSideCostsData;
        const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
        const totalPersonelCostsData = formState?.pensionExpensesTable.totalPersonelCostsData;

        if (pensionExpenses && otherPersonelSideCosts && totalPersonelSideCostsData && totalWagesData && totalPersonelCostsData) {
            totalPersonelSideCostsData.totalPersonelSideCosts.cy = pensionExpenses.cy + otherPersonelSideCosts.cy;
            totalPersonelSideCostsData.totalPersonelSideCosts.cyProcent = totalPersonelSideCostsData.totalPersonelSideCosts.cy / totalWagesData.totalWages.cy * 100;
            totalPersonelSideCostsData.totalPersonelSideCosts.py = pensionExpenses.py + otherPersonelSideCosts.py;
            totalPersonelSideCostsData.totalPersonelSideCosts.pyProcent = totalPersonelSideCostsData.totalPersonelSideCosts.py / totalWagesData.totalWages.py * 100;
            totalPersonelSideCostsData.totalPersonelSideCosts.change = totalPersonelSideCostsData.totalPersonelSideCosts.cy - totalPersonelSideCostsData.totalPersonelSideCosts.py;
            totalPersonelSideCostsData.totalPersonelSideCosts.changeProcent = totalPersonelSideCostsData.totalPersonelSideCosts.change / totalPersonelSideCostsData.totalPersonelSideCosts.py * 100;
        }

        const tableData = formState?.analyticalReviewTable.tableData;
        // PersonelCosts Table
        if (totalPersonelCostsData.totalPersonelCosts && tableData['1'] && totalPersonelSideCostsData.totalPersonelSideCosts) {
            totalPersonelCostsData.totalPersonelCosts.cy = totalPersonelSideCostsData.totalPersonelSideCosts.cy + tableData['1'].cy;
            totalPersonelCostsData.totalPersonelCosts.py = totalPersonelSideCostsData.totalPersonelSideCosts.py + tableData['1'].py;
            totalPersonelCostsData.totalPersonelCosts.change = totalPersonelCostsData.totalPersonelCosts.cy - totalPersonelCostsData.totalPersonelCosts.py;
            totalPersonelCostsData.totalPersonelCosts.changeProcent = totalPersonelCostsData.totalPersonelCosts.change / totalPersonelCostsData.totalPersonelCosts.py * 100;
        }
            
        const newFormState = { 
            ...formState, 
            pensionExpensesTable: { 
                pensionExpensesData: Object.fromEntries(patch.map(item => [item.id, item])),
                totalPersonelSideCostsData: totalPersonelSideCostsData,
                totalPersonelCostsData: totalPersonelCostsData,
            } 
        };
        patchFormState(newFormState);
    }

    const tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'cyProcent',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.cyProcent)}</div>,
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'pyProcent',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.pyProcent)}</div>,
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    const totalPersonelSideTable: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'cyProcent',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.cyProcent)}</div>,
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'pyProcent',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.pyProcent)}</div>,
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    const totalPersonelCostsTable: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'undefined0',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'undefined1',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    return (
        <>
        <AuditingTable
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={totalPersonelSideCostsData}
            columns={totalPersonelSideTable}
            showGlobalFilter={false}
            disableSort
            hideHeader
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={totalPersonelCostsData}
            columns={totalPersonelCostsTable}
            showGlobalFilter={false}
            disableSort
            hideHeader
            variant="default"
        />
        </>
    )
}
export const BusinessOverTable = ({
    formState,
    patchFormState,
}: FormFieldProps<PersonelCostsForm>) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}.analyticalReviewTable`;

    const data = Object.values(formState?.businessOverTable.numberOfPersonsData ?? {});
    const averageData = Object.values(formState?.businessOverTable.averageData ?? {});

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const averageData = formState?.businessOverTable.averageData;
        const tableData = formState?.analyticalReviewTable.tableData;

        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };

                // Calculate the change and changeProcent values when cy or py values are updated
                if (columnId === 'cy' || columnId === 'py') {
                    updatedRow.change = updatedRow.cy - updatedRow.py;
                    updatedRow.changeProcent = updatedRow.change / updatedRow.py * 100;
                }

                if (columnId === 'cy' && currentRow.id === '3') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    if (totalWagesData) {
                        updatedRow.cyProcent = updatedRow.cy / totalWagesData.totalWages.cy * 100;
                    }
                }
                if (columnId === 'py' && currentRow.id === '3') {
                    const totalWagesData = formState?.analyticalReviewTable.totalWagesData;
                    if (totalWagesData) {
                        updatedRow.pyProcent = updatedRow.py / totalWagesData.totalWages.py * 100;
                    }
                }

                return updatedRow;
            }
            return currentRow;
        });

        const personsKpl = patch.find(row => row.id === '1');
        const lenghtOfFinancialYear = patch.find(row => row.id === '2');
        const turnOver = patch.find(row => row.id === '3');

        let salaryPerson, turnOverPerson;

        if (Array.isArray(averageData)) {
            salaryPerson = averageData.find((row: { id: string; }) => row.id === '1');
            turnOverPerson = averageData.find((row: { id: string; }) => row.id === '2');
        } else {
            salaryPerson = averageData['salaryPerson'] || averageData['0'];
            turnOverPerson = averageData['turnOverPerson'] || averageData['1'];
        }

        const wagesAndRemuneration = tableData['1'];

        // PersonelCosts Table
        if (salaryPerson && wagesAndRemuneration && personsKpl && lenghtOfFinancialYear) {
            salaryPerson.cy = (wagesAndRemuneration.cy / personsKpl.cy / lenghtOfFinancialYear.cy);
            salaryPerson.py = (wagesAndRemuneration.py / personsKpl.py / lenghtOfFinancialYear.py);

            salaryPerson.change = salaryPerson.cy - salaryPerson.py;
            salaryPerson.changeProcent = salaryPerson.change / salaryPerson.py * 100;
        }
        if (turnOverPerson && turnOver && personsKpl) {
            turnOverPerson.cy = turnOver.cy / personsKpl.cy;
            turnOverPerson.py = turnOver.py / personsKpl.py;

            turnOverPerson.change = turnOverPerson.cy - turnOverPerson.py;
            turnOverPerson.changeProcent = turnOverPerson.change / turnOverPerson.py * 100;
        }

        if (averageData['0'] && wagesAndRemuneration && personsKpl && lenghtOfFinancialYear) {
            averageData['0'].cy = wagesAndRemuneration.cy / personsKpl.cy / lenghtOfFinancialYear.cy;
            averageData['0'].py = wagesAndRemuneration.py / personsKpl.py / lenghtOfFinancialYear.py;

            averageData['0'].change = averageData['0'].cy - averageData['0'].py;
            averageData['0'].changeProcent = averageData['0'].change / averageData['0'].py * 100;
        }
            
        const newFormState = { 
            ...formState, 
            businessOverTable: { 
                numberOfPersonsData: Object.fromEntries(patch.map(item => [item.id, item])),
                averageData: averageData,
            } 
        };
        patchFormState(newFormState);
    }

    const tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'cyProcent',
            header: 'CY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => {
                if (cell.row.original.id === '1' || cell.row.original.id === '2' || cell.row.original.id === '4' || cell.row.original.id === '5') {
                    return <div></div>; // return empty cell for id 1 and 2
                }
                return <div>{formatPercent(cell.row.original.cyProcent)}</div>;
            },
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'pyProcent',
            header: 'PY palkoista %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => {
                if (cell.row.original.id === '1' || cell.row.original.id === '2' || cell.row.original.id === '4' || cell.row.original.id === '5') {
                    return <div></div>; // return empty cell for id 1 and 2
                }
                return <div>{formatPercent(cell.row.original.pyProcent)}</div>;
            },
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    const averageTableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => t(`${tBase}.labels`),
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'cy',
            header: 'CY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>,
        },
        {
            accessorKey: 'undefined0',
            header: '',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'py',
            header: 'PY',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>,
        },
        {
            accessorKey: 'undefined1',
            header: '',
            className: 'text-center nowrap width-min',
        },
        {
            accessorKey: 'change',
            header: 'Muutos',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>,
        },
        {
            accessorKey: 'changeProcent',
            header: 'Muutos %',
            className: 'text-center nowrap width-min',
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.changeProcent)}</div>,
        },
    ];

    return (
        <>
        <AuditingTable
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={averageData}
            columns={averageTableColumns}
            showGlobalFilter={false}
            disableSort
            hideHeader
            variant="default"
        />
        <Container>
            <div className="additional-info">Jos taulukossa lukee "Infinity %" päivitä numeroita!</div>
        </Container>
        </>
    )
}
// End //

// Palkkojen analyyttinen tarkastus part 2 //
export const AnalyticalSalariesTable = ({
    formState,
    patchFormState,
    store
}: FormFieldProps<PersonelCostsForm>) => {

    const {
        auditingStore: { materialityComputeds, getAuditingSection }
    } = store;

    if (!formState) return null;

    const materialiyForm = getAuditingSection('materiality')?.form;
    const materialities = materialityComputeds(materialiyForm);
    const incomeStatementAndBalance = getAuditingSection('incomeStatementAndBalance')?.form.incomeStatement;

    const data = Object.values(formState?.analyticalSalariesTable ?? {});
    const salariesData = Object.values(formState?.salariesChangeTable.salariesData ?? {});
    const financialData = Object.values(formState?.financialYearTable.financialYearData ?? {});
    const salaryExpenseData = Object.values(formState?.expectedSalaryExpense.salaryExpense ?? {});

    // Add default items to the table (data)
    const itemsToAdd = {
        firstData: {
            threshHold: {
                id: '1',
                label: 'Kynnysarvo',
                inputValue: materialities.workingMateriality ?? 0,
            },
            expenses: {
                id: '2',
                label: 'Palkkakulut PY',
                inputValue: incomeStatementAndBalance.find((item: { key: string; }) => item.key === 'wagesAndSalaries')?.priorYear ?? 0,
            },
            period: {
                id: '3',
                label: 'Vertailukauden pituus kk',
                inputValue: 0,
            },
            people: {
                id: '4',
                label: 'Henkilömäärä vertailukaudella',
                inputValue: 0,
            },
        }
    };

    // Checker before adding them to the table (data)
    Object.values(itemsToAdd.firstData).forEach((item: any) => {
        if (!data.some((dataItem: any) => dataItem.id === item.id)) {
            data.push(item);
        }
    });

    // Utility function to find inputValue by ID or return 0 if not found
    const findInputValueById = (tableData: Array<{ id: string; inputValue?: number }>, id: string): number => {
        return tableData.find(row => row.id === id)?.inputValue ?? 0;
    };

    const analytical = formState?.analyticalSalariesTable;
    const threshold = findInputValueById(analytical, '1');
    const expenses = findInputValueById(analytical, '2');
    const people = findInputValueById(analytical, '4');
    const period = findInputValueById(analytical, '3');

    const salaryData = Array.isArray(formState?.salariesChangeTable.salariesData) ? formState.salariesChangeTable.salariesData : [];
    const salaries = findInputValueById(salaryData, '1');

    const financialYearData = Array.isArray(formState?.financialYearTable.financialYearData) ? formState.financialYearTable.financialYearData: [];
    const financialPerson = findInputValueById(financialYearData, '1');
    const financialLength = findInputValueById(financialYearData, '2');

    // Function to update the expected salary expense values
    function updateExpectedSalaryExpense(formState: { 
        expectedSalaryExpense: { salaryExpense: { expense: { inputValue: number; }; 
        realization: { inputValue: number; }; 
        minus: { inputValue: number; }; }; }; 
        }, { threshold, expenses, people, period, salaries, financialPerson, financialLength }: any) {
        if (formState?.expectedSalaryExpense?.salaryExpense?.expense) {
            const newExpenseValue = (expenses / people) / period * (1 + (salaries / 100)) * financialPerson * financialLength;
            formState.expectedSalaryExpense.salaryExpense.expense.inputValue = newExpenseValue;
        }
        if (formState?.expectedSalaryExpense?.salaryExpense?.realization) {
            const newRealizationValue = threshold + expenses;
            formState.expectedSalaryExpense.salaryExpense.realization.inputValue = newRealizationValue;
        }
        if (formState?.expectedSalaryExpense?.salaryExpense?.minus) {
            const newMinusValue = formState.expectedSalaryExpense.salaryExpense.expense.inputValue - formState.expectedSalaryExpense.salaryExpense.realization.inputValue;
            formState.expectedSalaryExpense.salaryExpense.minus.inputValue = newMinusValue;
        }
    }

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };
                return updatedRow;
            }
            return currentRow;
        });

        const newFormState = { 
            ...formState, 
            analyticalSalariesTable: patch,
        };
        patchFormState(newFormState);
        updateExpectedSalaryExpense(newFormState, { threshold, expenses, people, period, salaries, financialPerson, financialLength });
    }

    const tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => '',
            className: 'text-left nowrap width-half text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => '',
            className: 'text-center nowrap width-half',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
        }
    ];

    // Handle edit for salariesData
    function handleSalariesEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = salariesData.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };
                return updatedRow;
            }
            return currentRow;
        });
        const newFormState = { 
            ...formState, 
            salariesChangeTable: { 
                salariesData: patch,
            }
        };
        patchFormState(newFormState);
        updateExpectedSalaryExpense(newFormState, { threshold, expenses, people, period, salaries, financialPerson, financialLength });
    }

    const salariesColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => '',
            className: 'text-left nowrap width-half text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => '',
            className: 'text-center nowrap width-half',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleSalariesEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatPercent(cell.row.original.inputValue)}</div>,
            contextInfoProps: {
                text: 'Esimerkiksi inflaatio, TES korotukset tms.',
                position: 'top-left',
            },
        }
    ];

    // Handle edit for financialData
    function handleFinancialEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = financialData.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };
                return updatedRow;
            }
            return currentRow;
        });
        const newFormState = { 
            ...formState, 
            financialYearTable: { 
                financialYearData: patch,
            }
        };
        patchFormState(newFormState);
        updateExpectedSalaryExpense(newFormState, { threshold, expenses, people, period, salaries, financialPerson, financialLength });
    }

    const financialColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => '',
            className: 'text-left nowrap width-half text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => '',
            className: 'text-center nowrap width-half',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleFinancialEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
        }
    ];

    const salaryExpenseColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            header: () => '',
            className: 'text-left nowrap width-half text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => '',
            className: 'text-center nowrap width-half',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
        }
    ];

    return (
        <>
        <AuditingTable
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={salariesData}
            columns={salariesColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={financialData}
            columns={financialColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />

        <AuditingTable
            sectionKey={sectionKey}
            data={salaryExpenseData}
            columns={salaryExpenseColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        </>
    );
};
// End //
export const ExaminationTable = ({
    formState,
    patchFormState,
    store
}: FormFieldProps<PersonelCostsForm>) => {

    const {
        auditingStore: { getAuditingSection, materialityComputeds }
    } = store;

    if (!formState) return null;

    const materialiyForm = getAuditingSection('materiality')?.form;
    const materialities = materialityComputeds(materialiyForm);

    const examinationData = formState?.examinationTable ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = examinationData.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                const updatedRow = { ...currentRow, [columnId]: value };
                return updatedRow;
            }
            return currentRow;
        });

        // change examinationData's inputValues based on booleanValue
        const updatedPatch = patch.map((row: any) => {
            let inputValue;
            switch (row.booleanValue) {
                case 'Työskentelyolennaisuus (PM)':
                    inputValue = materialities.workingMateriality ?? 0;
                    break;
                case 'Yksittäisen virheen olennaisuusraja (AMPT)':
                    inputValue = materialities.singleErrorMateriality ?? 0;
                    break;
                case 'Muu':
                    inputValue = row.inputValue ?? 0; // Use the current inputValue for 'Muu'
                    break;
                default:
                    inputValue = 0;
                    break;
            }
            return { ...row, inputValue };
        });

        const newFormState = { 
            ...formState, 
            examinationTable: updatedPatch,
        };
        patchFormState(newFormState);
    }

    const booleanOptions = [
        'Työskentelyolennaisuus (PM)',
        'Yksittäisen virheen olennaisuusraja (AMPT)',
        'Muu'
    ];
    const examinationColumns: TableColumnDefs<Examination> = [
        {
            accessorKey: 'booleanValue',
            header: () => 'Kynnysarvon pohjana käytetty luku',
            className: 'text-left nowrap width-min',
            showRemark: (item: Examination) => 
                typeof item.booleanValue === 'boolean' && !item.booleanValue,
            selectProps: {
                options: booleanOptions,
                placeholder: 'Valitse',
                clearable: true,
                displayValue: (value: any) => value,
                setValue: (editProps: OnEditProps<string>) => {
                    const value = editProps.value;
                    handleEdit<string | null>({
                        ...editProps,
                        value: value,
                    });
                }
            }
        },
        {
            accessorKey: 'inputValue',
            header: () => 'Kynnysarvo',
            className: 'text-center nowrap width-half',
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            disabled: ({ booleanValue }) => booleanValue !== 'Muu',
        },
    ];

    return (
        <>
        <AuditingTable<Examination>
            sectionKey={sectionKey}
            data={examinationData}
            columns={examinationColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        </>
    )
};

export const AnalyticalExaminationTable = ({
    formState,
    patchFormState,
}: FormFieldProps<PersonelCostsForm>) => {

    if (!formState) return null;

    const belowThreshold = 'ALLE KYNNYSARVON';
    const aboveThreshold = 'YLI KYNNYSARVON';
    const bgColor = (row: any) => {
        if (!row.label.includes('Johtopäätös') || !row.threshold) return;
        return row.threshold === aboveThreshold
        ? Colors.red
        : row.threshold === belowThreshold
        ? Colors.green
        : undefined;
    };
    const addRowColor = (row: AnalyticalExamination) => ({
        ...row,
        bgColor: bgColor(row),
    });

    const data: AnalyticalExamination[] = formState?.analyticalExaminationTable.map(addRowColor) ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });
    
        if (columnId === 'inputValue') {
            const row1 = patch.find(row => row.id === '1');
            const row2 = patch.find(row => row.id === '2');
            const row3 = patch.find(row => row.id === '3');
            const row4 = patch.find(row => row.id === '4');
            const row5 = patch.find(row => row.id === '5');
            const row6 = patch.find(row => row.id === '6');
            const row7 = patch.find(row => row.id === '7');
            const row8 = patch.find(row => row.id === '8');
            // row 4 = row 1 + row 2 +/- row 3
            if (row4) {
                row4.inputValue = Number(((row1?.inputValue ?? 0) + (row2?.inputValue ?? 0) + (row3?.inputValue ?? 0)).toFixed(0));
            }
            if (row6) {
                const percentage = row5?.inputValue ? row5.inputValue / 100 : 0;
                row6.inputValue = (percentage) * (row4?.inputValue ?? 0);
            }
            if (row8) {
                row8.inputValue = (row7?.inputValue) - (row6?.inputValue); 
            }

            const row9 = patch.find(row => row.id === '9');
            const examinationTable = formState?.examinationTable;
            const examinationRow = examinationTable.find(row => row.id === '1');
            if (row9 && examinationTable) {
                const comparisonValue = examinationRow?.value !== undefined ? examinationRow?.value : examinationRow?.inputValue;
                row9.threshold = row8?.inputValue < comparisonValue ? belowThreshold : aboveThreshold;
            }

            const row10 = patch.find(row => row.id === '10');
            const row11 = patch.find(row => row.id === '11');
            const row12 = patch.find(row => row.id === '12');
            if (row12) {
                row12.inputValue = (row10?.inputValue ?? 0) + (row11?.inputValue ?? 0);
            }
            const row13 = patch.find(row => row.id === '13');
            const row14 = patch.find(row => row.id === '14');
            if (row14) {
                const percentage = row13?.inputValue ? row13.inputValue / 100 : 0;
                row14.inputValue = (percentage) * (row12?.inputValue ?? 0);
            }
            const row15 = patch.find(row => row.id === '15');
            const row16 = patch.find(row => row.id === '16');
            if (row16) {
                row16.inputValue = (row15?.inputValue) - (row14?.inputValue);
            }
            const row17 = patch.find(row => row.id === '17');
            if (row17 && examinationTable) {
                const comparisonValue = examinationRow?.value !== undefined ? examinationRow?.value : examinationRow?.inputValue;
                row17.threshold = row16?.inputValue < comparisonValue ? belowThreshold : aboveThreshold;
            }

        };
    
        const newFormState = { 
            ...formState, 
            analyticalExaminationTable: patch,
        };
        patchFormState(newFormState);
    }

    let disabledIds = ['4', '6', '8', '12', '14', '16'];
    let conclusionIds = ['9', '17'];
    const tableColumns: TableColumnDefs<AnalyticalExamination> = [
        {
            accessorKey: 'label',
            header: () => null,
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => null,
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
            disabled: ({ id }) => disabledIds.includes(id),
            hidden: ({ id }) => conclusionIds.includes(id),
        },
        {
            accessorKey: 'commentField',
            header: () => null,
            className: 'text-center nowrap width-min',
            onEdit: (editProps: OnEditProps<string>) => {
                handleEdit<string>(editProps);
            },
            hidden: ({ disabled }) => disabled,
        },
        {
            accessorKey: 'threshold',
            header: () => null,
            className: 'text-right nowrap width-min',
            hidden: ({ id }) => !conclusionIds.includes(id),
        }
    ];

    return (
        <>
        <AuditingTable<AnalyticalExamination>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        </>
    )
};

export const ExtraExaminationTable = ({
    formState,
    patchFormState,
}: FormFieldProps<PersonelCostsForm>) => {

    if (!formState) return null;

    const belowThreshold = 'ALLE KYNNYSARVON';
    const aboveThreshold = 'YLI KYNNYSARVON';
    const bgColor = (row: any) => {
        if (!row.label.includes('Johtopäätös') || !row.threshold) return;
        return row.threshold === aboveThreshold
        ? Colors.red
        : row.threshold === belowThreshold
        ? Colors.green
        : undefined;
    };
    const addRowColor = (row: ExtraAnalyticalExamination) => ({
        ...row,
        bgColor: bgColor(row),
    });

    const data: ExtraAnalyticalExamination[] = formState?.extraExaminationTable.map(addRowColor) ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });

        if (columnId === 'inputValue') {
            const row1 = patch.find(row => row.id === '1');
            const row2 = patch.find(row => row.id === '2');
            const row3 = patch.find(row => row.id === '3');
            if (row3) {
                row3.inputValue = (row1?.inputValue ?? 0) + (row2?.inputValue ?? 0);
            }
            const row4 = patch.find(row => row.id === '4');
            const row5 = patch.find(row => row.id === '5');
            if (row5) {
                const percentage = row4?.inputValue ? row4.inputValue / 100 : 0;
                row5.inputValue = (percentage) * (row3?.inputValue ?? 0);
            }
            const row6 = patch.find(row => row.id === '6');
            const row7 = patch.find(row => row.id === '7');
            if (row7) {
                row7.inputValue = (row6?.inputValue) - (row5?.inputValue);
            }
            const row8 = patch.find(row => row.id === '8');
            const examinationTable = formState?.examinationTable;
            const examinationRow = examinationTable.find(row => row.id === '1');
            if (row8 && examinationTable) {
                const comparisonValue = examinationRow?.value !== undefined ? examinationRow?.value : examinationRow?.inputValue;
                row8.threshold = row7?.inputValue < comparisonValue ? belowThreshold : aboveThreshold;
            }
        };

        const newFormState = { 
            ...formState, 
            extraExaminationTable: patch,
        };
        patchFormState(newFormState);
    }

    let disabledIds = ['3', '5', '7'];
    let conclusionIds = ['8'];
    const tableColumns: TableColumnDefs<ExtraAnalyticalExamination> = [
        {
            accessorKey: 'label',
            header: () => null,
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => null,
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
            disabled: ({ id }) => disabledIds.includes(id),
            hidden: ({ id }) => conclusionIds.includes(id),
        },
        {
            accessorKey: 'threshold',
            header: () => null,
            className: 'text-right nowrap width-min',
            hidden: ({ id }) => !conclusionIds.includes(id),
        }
    ];

    return (
        <>
        <AuditingTable<ExtraAnalyticalExamination>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        </>
    )
};

export const ReconciliationSalariesTable = ({
    formState,
    patchFormState,
    store,
}: FormFieldProps<PersonelCostsForm>) => {

    const {
        auditingStore: { getAuditingSection, materialityComputeds }
    } = store;

    if (!formState) return null;

    const materialiyForm = getAuditingSection('materiality')?.form;
    const materialities = materialityComputeds(materialiyForm);

    const belowThreshold = 'ALLE KYNNYSARVON';
    const aboveThreshold = 'YLI KYNNYSARVON';
    const bgColor = (row: any) => {
        if (!row.label.includes('Johtopäätös') || !row.threshold) return;
        return row.threshold === aboveThreshold
        ? Colors.red
        : row.threshold === belowThreshold
        ? Colors.green
        : undefined;
    };
    const addRowColor = (row: ReconciliationSalaries) => ({
        ...row,
        bgColor: bgColor(row),
    });

    const data: ReconciliationSalaries[] = formState?.reconciliationSalariesTable.map(addRowColor) ?? [];
    const booleanData = formState?.reconciliationBooleanTable ?? [];
    const secondData: ReconciliationSalariesSecond[] = formState?.reconciliationSalariesSecondTable.map(addRowColor) ?? [];

    function handleEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = data.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });

        if (columnId === 'inputValue') {
            const row1 = patch.find(row => row.id === '1');
            const row2 = patch.find(row => row.id === '2');
            const row3 = patch.find(row => row.id === '3');
            if (row3) {
                row3.inputValue = (row1?.inputValue ?? 0) - (row2?.inputValue ?? 0);
            }
        };

        const newFormState = { 
            ...formState, 
            reconciliationSalariesTable: patch,
        };
        patchFormState(newFormState);
    }

    let disabledIds = ['3'];
    const tableColumns: TableColumnDefs<ReconciliationSalaries> = [
        {
            accessorKey: 'label',
            header: () => null,
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => null,
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
            disabled: ({ id }) => disabledIds.includes(id),
        },
    ];

    function handleBooleanEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = booleanData.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });

        const updatedPatch = patch.map((row: any) => {
            let inputValue;
            switch (row.booleanValue) {
                case 'Työskentelyolennaisuus (PM)':
                    inputValue = materialities.workingMateriality ?? 0;
                    break;
                case 'Yksittäisen virheen olennaisuusraja (AMPT)':
                    inputValue = materialities.singleErrorMateriality ?? 0;
                    break;
                case 'Muu':
                    inputValue = row.inputValue ?? 0; // Use the current inputValue for 'Muu'
                    break;
                default:
                    inputValue = 0;
                    break;
            }
            return { ...row, inputValue };
        });

        const newFormState = { 
            ...formState, 
            reconciliationBooleanTable: updatedPatch,
        };
        patchFormState(newFormState);
    }

    const booleanOptions = [
        'Työskentelyolennaisuus (PM)',
        'Yksittäisen virheen olennaisuusraja (AMPT)',
        'Muu'
    ];
    const booleanColumns: TableColumnDefs<ReconciliationBoolean> = [
        {
            accessorKey: 'label',
            header: () => null,
            className: 'text-left nowrap width-half text-bold',
        },
        {
            accessorKey: 'booleanValue',
            header: () => null,
            className: 'text-center nowrap width-min',
            showRemark: (item: ReconciliationBoolean) => 
                typeof item.booleanValue === 'boolean' && !item.booleanValue,
            selectProps: {
                options: booleanOptions,
                placeholder: 'Valitse',
                clearable: true,
                displayValue: (value: any) => value,
                setValue: (editProps: OnEditProps<string>) => {
                    const value = editProps.value;
                    handleBooleanEdit<string | null>({
                        ...editProps,
                        value: value,
                    });
                }
            },
        },
        {
            accessorKey: 'inputValue',
            header: () => null,
            className: 'text-center nowrap width-half',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleBooleanEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)} €</div>,
            // Disabled if booleanValue is set to 'Muu'
            disabled: ({ booleanValue }) => booleanValue !== 'Muu',
        }
    ];

    function handleSecondEdit<T>({
        itemId,
        rowIndex,
        columnId,
        value,
        }: OnEditProps<T>) {
        const patch = secondData.map((currentRow: any, index: number) => {
            const idMatch = itemId !== undefined && itemId === currentRow.id;
            const rowIndexMatch = itemId === undefined && rowIndex === index;
            if (idMatch || rowIndexMatch) {
                return { ...currentRow, [columnId]: value };
            }
            return currentRow;
        });

        if (columnId === 'inputValue') {
            const row1 = patch.find(row => row.id === '1');
            const row2 = patch.find(row => row.id === '2');
            const row3 = patch.find(row => row.id === '3'); // Sivukulu %
            if (row3) {
                row3.inputValue = ((row1?.inputValue ?? 0) - (row2?.inputValue ?? 0)) / (row1?.inputValue ?? 0) * 100;
            }
            const row5 = patch.find(row => row.id === '5');
            const row6 = patch.find(row => row.id === '6');
            const row7 = patch.find(row => row.id === '7'); // Keskim. palkat per kk
            if (row7) {
                row7.inputValue = (row5?.inputValue ?? 0) / ((row6?.inputValue ?? 0) + 0.5);
            }
            const row8 = patch.find(row => row.id === '8');
            const row9 = patch.find(row => row.id === '9');
            const row10 = patch.find(row => row.id === '10');
            const row11 = patch.find(row => row.id === '11'); // Laskennalliset lomapalkat ilman lomarahoja
            if (row11) {
                row11.inputValue = ((row7?.inputValue ?? 0) / 25) * ((row8?.inputValue ?? 0) * (row9?.inputValue ?? 0) + (row10?.inputValue ?? 0));
            }
            const row12 = patch.find(row => row.id === '12'); // Laskennallinen lomapalkkavaraus ml. lomarahat ilman sivukuluja
            if (row12) {
                row12.inputValue = (row11?.inputValue ?? 0) * 1.5;
            }
            const row13 = patch.find(row => row.id === '13'); // Laskennallisen ja tehdyn varauksen erotus
            if (row13) {
                row13.inputValue = (row2?.inputValue ?? 0) - (row12?.inputValue ?? 0);
            }
            const row14 = patch.find(row => row.id === '14'); // Johtopäätös
            const booleanData = formState?.reconciliationBooleanTable;
            const booleanRow = booleanData.find(row => row.id === '1');
            if (row14 && booleanData) {
                const comparisonValue = booleanRow?.value !== undefined ? booleanRow?.value : booleanRow?.inputValue;
                row14.threshold = row13?.inputValue < comparisonValue ? belowThreshold : aboveThreshold;
            }
        };

        const newFormState = { 
            ...formState, 
            reconciliationSalariesSecondTable: patch,
        };
        patchFormState(newFormState);
    }

    let secondConlusionIds = ['14', '4']
    const secondTableColumns: TableColumnDefs<ReconciliationSalariesSecond> = [
        {
            accessorKey: 'label',
            header: () => null,
            className: 'text-left nowrap width-min text-bold',
        },
        {
            accessorKey: 'inputValue',
            header: () => null,
            className: 'text-center nowrap width-min',
            onNumberEdit: (editProps: OnEditProps<number>) => {
                handleSecondEdit<number>(editProps);
            },
            cell: ({ cell }) => <div>{formatZeroDigitNumber(cell.row.original.inputValue)}</div>,
            disabled: ({ disabled }) => disabled,
            hidden: ({ id }) => secondConlusionIds.includes(id),
        },
        {
            accessorKey: 'threshold',
            header: () => null,
            className: 'text-right nowrap width-min',
            hidden: ({ id }) => !secondConlusionIds.includes(id),
        }
    ];

    return (
        <>
        <AuditingTable<ReconciliationSalaries>
            sectionKey={sectionKey}
            data={data}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        <AuditingTable<ReconciliationBoolean>
            sectionKey={sectionKey}
            data={booleanData}
            columns={booleanColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        <AuditingTable<ReconciliationSalariesSecond>
            sectionKey={sectionKey}
            data={secondData}
            columns={secondTableColumns}
            showGlobalFilter={false}
            disableSort
            variant="default"
        />
        </>
    );
};