import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button, ButtonProps } from './inputs';
import styled from 'styled-components';
import { Overlay } from './Overlay';
import Icon from './Icon';

const DialogContainer = styled.div`
  .dialog-panel {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    > .dialog-content {
      padding: ${p => p.theme.spacing.lg};
      background-color: ${p => p.theme.color.background};
      ${p => p.theme.shadow.md};
      border-radius: ${p => p.theme.borderRadius.md};

      width: 50vw;
      @media ${p => p.theme.breakpoint.mobile} {
        width: 90vw;
        height: 90vh;
      }

      > header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .dialog-title {
          margin-top: 0;
        }
      }
    }
  }
`;

interface ModalProps {
  id?: string;
  buttonProps?: ButtonProps;
  title?: string;
  description?: string;
  isInitiallyOpen?: boolean;
  onClose?: () => void;
  children:
    | React.ReactNode
    | ((props: { closeModal: () => void }) => React.ReactNode);
}

export const Modal: React.FC<ModalProps> = ({
  id,
  buttonProps,
  title,
  description,
  isInitiallyOpen = false,
  onClose,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    if (onClose) onClose();
    setIsOpen(false);
  };

  const closeButton = (
    <Button
      icon={<Icon type="Close" color="grey500" />}
      variant="link"
      color="grey200"
      onClick={closeModal}
    />
  );

  return (
    <>
      {buttonProps && (
        <Button
          {...buttonProps}
          onClick={openModal}
          data-testid={`${id}-button`}
        />
      )}

      {isOpen && <Overlay />}

      <Dialog as="div" open={isOpen} onClose={closeModal}>
        <DialogContainer id={id} data-testid={id}>
          <Dialog.Panel className="dialog-panel">
            <div className="dialog-content">
              <header>
                <div>
                  {title && (
                    <Dialog.Title className="dialog-title">
                      {title}
                    </Dialog.Title>
                  )}
                </div>
                {closeButton}
              </header>

              <main>
                {description && (
                  <Dialog.Description className="dialog-description">
                    {description}
                  </Dialog.Description>
                )}

                {typeof children === 'function'
                  ? children({ closeModal })
                  : children}
              </main>
            </div>
          </Dialog.Panel>
        </DialogContainer>
      </Dialog>
    </>
  );
};

export default Modal;
