export enum Market {
  domesticMarket = 'domesticMarket',
  salesOutsideCommunity = 'salesOutsideCommunity',
  communitySales = 'communitySales',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum CyclicalActivity {
  seasonal = 'seasonal',
  yearRound = 'yearRound',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum CompetitiveSituation {
  notSignificant = 'notSignificant',
  normal = 'normal',
  significant = 'significant',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum CustomerBase {
  dependentOnFewCustomers = 'dependentOnFewCustomers',
  notDependentOnFewCustomers = 'notDependentOnFewCustomers',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum ProductionTecknologyLevel {
  normal = 'normal',
  advanced = 'advanced',
  regressive = 'regressive',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum EconomicSituation {
  notDetected = 'notDetected',
  detected = 'detected',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum SignificantChanges {
  notDetected = 'notDetected',
  detected = 'detected',
  comment = 'kommentti',
}

export enum HonestAndEthicalCulture {
  no = 'no',
  yesAndHasWrittenProceduralInstructions = 'yesAndHasWrittenProceduralInstructions',
  yesButNoWrittenProceduralInstructions = 'yesButNoWrittenProceduralInstructions',
  comment = 'kommentti',
}

export enum CommitmentToCompetence {
  normalOrGood = 'normalOrGood',
  belowNormal = 'belowNormal',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum ManagementInvolvement {
  normal = 'normal',
  aboveNormal = 'aboveNormal',
  belowNormal = 'belowNormal',
  comment = 'kommentti',
}

export enum AdministrativeIndependency {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum AdministrativeParticipation {
  appropriate = 'appropriate',
  notAppropriate = 'notAppropriate',
  comment = 'kommentti',
}

export enum DifferentiationOfWorkTasks {
  normalOrGood = 'normalOrGood',
  belowNormal = 'belowNormal',
  smallCompany = 'smallCompany',
  comment = 'kommentti',
}

export enum ManagementAttitudeToFinancialReporting {
  normalOrGood = 'normalOrGood',
  belowNormal = 'belowNormal',
  comment = 'kommentti',
}

export enum CommunityHasInternalAuditing {
  no = 'no',
  yes = 'yes',
  yesOutsourced = 'yesOutsourced',
  comment = 'kommentti',
}

export enum InternalAuditingInquiries {
  no = 'no',
  yes = 'yes',
  comment = 'kommentti',
}

export enum InternalAuditingInquiriesFindings {
  no = 'no',
  yes = 'yes',
  comment = 'kommentti',
}

export enum InternalAuditingEvidence {
  no = 'no',
  yes = 'yes',
  comment = 'kommentti',
}

export enum InternalControlProvideAppropriateBasis {
  no = 'no',
  yes = 'yes',
  comment = 'kommentti',
}

export enum OrganizationalStructureIsAppropriate {
  no = 'no',
  yes = 'yes',
  comment = 'kommentti',
}

export enum RiskManagementFollowsLawsAndInternalInstructions {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum InternalControlFollowsLawsAndInternalInstructions {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum ManagementsStatementOnInternalControlMeetsOurUnderstanding {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum SupervisionOfGroupEntitiesImplementedAppropriately {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export enum ManagementsReportMeetsOurUnderstanding {
  yes = 'yes',
  no = 'no',
  notApplicable = 'notApplicable',
  comment = 'kommentti',
}

export const showAdministrativeIndependency = (
  form: InternalControlAndOperatingEnvironmentForm
) => form.administrativeIndependency?.no;// === AdministrativeIndependency.no;

export const showAdministrativeParticipation = (
  form: InternalControlAndOperatingEnvironmentForm
) => form.administrativeIndependency?.yes;// === AdministrativeIndependency.yes;

export const show = (form: InternalControlAndOperatingEnvironmentForm) => form;

export const showInternalAuditingInquiries7a = (
  form: InternalControlAndOperatingEnvironmentForm
) => form.communityHasInternalAuditing?.yes;// === CommunityHasInternalAuditing.yes;

export const showInternalAuditingInquiriesFindings7b = (
  form: InternalControlAndOperatingEnvironmentForm
) =>
  showInternalAuditingInquiries7a(form) &&
  form.internalAuditingInquiries?.yes;// === InternalAuditingInquiries.yes;

export const showInternalAuditingEvidence7c = (
  form: InternalControlAndOperatingEnvironmentForm
) => form.communityHasInternalAuditing?.yes;// === CommunityHasInternalAuditing.yes;
