import AuditingProcedureItem from '../../../components/auditing/AuditingProcedureItem';
import { AnalyticalExaminationTable, AnalyticalReviewTable, AnalyticalSalariesTable, BusinessOverTable, ExaminationTable, ExtraExaminationTable, PensionExpensesTable, ReconciliationSalariesTable } from '../../../components/auditing/personelCostsTables';
import ThresholdTestTable from '../../../components/auditing/procedures/ThresholdTestTable';
import i18n from '../../../i18n';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { getAccountMappingValues, hidePartBasedOnItAndProcesses } from '../../../utils';
import { getPartNameBasedOnActionKey } from '../../../utils/addedPartsAutomation';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { getAccountRangeFromAccountMap } from '../analyzes/accountMapUtils';
import { AccountClassKey, AccountKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import { PersonelProcessDescriptionTable } from './notReUsables/personelCostsProcess';
import {
  analyticalReviewOfWagesRows
} from './peronelCostsUtils.analyticalReviewOfWages';
import { thresholdComputationRules } from './personelCostsUtils';
import { analyticalAuditOfPersonnelOverheadCostsRows } from './personelCostsUtils.analyticalAuditOfPersonnelOverheadCosts';
import { analyticalAuditOfVacationPayAccrualsRows } from './personelCostsUtils.analyticalAuditOfVacationPayAccruals';
import { analyticalAuditOfWagesRows } from './personelCostsUtils.analyticalAuditOfWages';
import { makeDefaultPayAccrualsComparisonRows } from './personelCostsUtils.matchingOfPayAccruals';
import { matchingOfWagesToAnnualReportRows } from './personelCostsUtils.matchingOfWagesToAnnualReport';
import ProcedureAccounts from './reUsables/ProcedureAccounts';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = PersonelCostsForm;

const sectionKey: AuditingSectionKey = 'personelCosts';
const batch = FinancialStatementBatch['L-personelCosts'];

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const getAccountRange = getAccountRangeFromAccountMap(auditing);
  const incomeStatementAndBalance: IncomeStatementAndBalanceItem[] = auditing?.incomeStatementAndBalance?.form.incomeStatement ?? [];
  return {
    averageNumberOfPersonsCY: null,
    averageNumberOfPersonsPY: null,
    accountMappings: {
      wagesTotal: getAccountRange(AccountKey.personelCosts),
      pensionExpenses: getAccountRange(AccountKey.pensionExpences),
      tyelOrKuelAccounts: '',
      sicknessInsuranceAccounts: '',
      holidayPayWithAssociatedCostsAccount: '',
      holidayPayWithoutAssociatedCostsAccounts: '',
      capitalizedWagesOrOtherAdjustmentsAccounts: '',
      yelWagesAccounts: '',
    },

    overallAssessmentComments: '',

    processText: '',
    processDate: '',
    processValue: '',

    conclusionOnAuditPersonnelCosts: 'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi rahoituskulujen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella erät olennaisin osin oikein',
    tableColumnData: [
      {
        left: 'Kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.',
        right: auditingJson.OLE,
      },
      {
        left: 'Tapahtumat, jotka olisi pitänyt kirjata, on kirjattu, ja kaikki niihin liittyvät tiedot, jotka olisi pitänyt esittää tilinpäätöksessä, on esitetty siinä.',
        right: auditingJson.OVE,
      },
      {
        left: 'Tapahtumiin liittyvät rahamäärät ja muu tieto on kirjattu asianmukaisesti, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti.',
        right: auditingJson.TAY,
      },
      {
        left: 'Tapahtumat on kirjattu oikealle tilikaudelle.',
        right: auditingJson.ARV,
      },
      {
        left: 'Tapahtumat on kirjattu oikeille tileille  / oikeaan erään kirjanpidossa',
        right: auditingJson.LUOK,
      },
      {
        left: 'Tapahtumat on yhdistelty tai jaoteltu asianmukaisesti ja kuvattu selvästi, ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston vaatimukset huomioon ottaen.',
        right: auditingJson.ESI,
      },
    ],
    // Palkkojen analyyttinen tarkastelu
    analyticalReviewTable: {
      tableData: {
        wagesAndRemuneration: {
          label: 'Palkat ja palkkio',
          id: '1',
          cy: incomeStatementAndBalance.find(item => item.key === 'wagesAndSalaries')?.currentYear ?? 0,
          cyProcent: 0,
          py: incomeStatementAndBalance.find(item => item.key === 'wagesAndSalaries')?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        capitalizedSalaries: {
          label: 'Aktivoidut palkat',
          id: '2',
          cy: 10000,
          cyProcent: 0,
          py: 15000,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalWagesData: {
        totalWages: {
          label: 'Palkat yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    pensionExpensesTable: {
      pensionExpensesData: {
        pensionExpenses: {
          label: 'Eläkekulut',
          id: '1',
          cy: incomeStatementAndBalance.find(item => item.key === 'pensionExpences')?.currentYear ?? 0,
          cyProcent: 0,
          py: incomeStatementAndBalance.find(item => item.key === 'pensionExpences')?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        otherPersonelSideCosts: {
          label: 'Muut henkilöstösivukulut',
          id: '2',
          cy: incomeStatementAndBalance.find(item => item.key === 'otherSocialSecurityCosts')?.currentYear ?? 0,
          cyProcent: 0,
          py: incomeStatementAndBalance.find(item => item.key === 'otherSocialSecurityCosts')?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalPersonelSideCostsData: {
        totalPersonelSideCosts: {
          label: 'Henkilöstösivukulut yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalPersonelCostsData: {
        totalPersonelCosts: {
          label: 'Henkilöstökulut yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    businessOverTable: {
      numberOfPersonsData: {
        personsKpl: {
          label: 'Henkilömäärä kpl',
          id: '1',
          cy: 10,
          cyProcent: 0,
          py: 15,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        lenghtOfFinancialYear: {
          label: 'Tilikauden pituus',
          id: '2',
          cy: 12,
          cyProcent: 0,
          py: 12,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        turnOver: {
          label: 'Liikevaihto',
          id: '3',
          cy: incomeStatementAndBalance.find(item => item.key === 'turnover')?.currentYear ?? 0,
          cyProcent: 0,
          py: incomeStatementAndBalance.find(item => item.key === 'turnover')?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      averageData: {
        salaryPerson: {
          label: 'Palkka / henkilö / kk',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        turnOverPerson: {
          label: 'Liikevaihto / henkilö',
          id: '2',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    analyticalSalariesTable: [],
    salariesChangeTable: {
      salariesData: {
        salaries: {
          id: '1',
          label: 'Palkkojen muutos % vertailukauteen nähden',
          inputValue: 0,
        },
      }
    },
    financialYearTable: {
      financialYearData: {
        financialLength: {
          id: '1',
          label: 'Tarkastettavan tilikauden pituus',
          inputValue: 0,
        },
        financialPerson: {
          id: '2',
          label: 'Henkilömäärä tarkastettavalla tilikaudella',
          inputValue: 0,
        }
      },
    },
    expectedSalaryExpense: {
      salaryExpense: {
        expense: {
          id: '1',
          label: 'Odotusarvoinen palkkakulu',
          inputValue: 0,
        },
        realization: {
          id: '2',
          label: 'Palkkakulujen toteuma',
          inputValue: 0,
        },
        minus: {
          id: '3',
          label: 'Erotus',
          inputValue: 0,
        }
      }
    },

    analyticalExaminationBoolean: '',
    analyticalExaminationNumber: 0,
    examinationTable: [
      {
        id: '1',
        booleanValue: 'Muu',
        inputValue: 0,
      }
    ],

    analyticalExaminationTable: [
      {
        id: '1',
        label: 'Palkat yhteensä',
        inputValue: incomeStatementAndBalance.find(item => item.key === 'wagesAndSalaries')?.currentYear ?? 0,
        commentField: 'Kommentoi',
      },
      {
        id: '2',
        label: 'YEL palkat (-NEGATIIVINEN)',
        inputValue: -10000,
        commentField: 'Kommentoi',
      },
      {
        id: '3',
        label: 'Muut huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label: 'Eläkemaksujen perusteena olevat palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '5',
        label: 'Odotusarvoinen eläkekulu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '6',
        label: 'Odotusarvoinen eläkekulu €',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '7',
        label: 'Toteuma',
        inputValue: incomeStatementAndBalance.find(item => item.key === 'pensionExpences')?.currentYear ?? 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '9',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
      {
        id: '10',
        label: 'Palkat yhteensä',
        inputValue: incomeStatementAndBalance.find(item => item.key === 'wagesAndSalaries')?.currentYear ?? 0,
        commentField: 'Kommentoi',
      },
      {
        id: '11',
        label: 'Huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '12',
        label: 'Sairasvakuutusmaksun perusteena olevat palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '13',
        label: 'Odotusarvoinen sairasvakuutusmaksu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '14',
        label: 'Odotusarvoinen sairasvakuutusmaksut €',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '15',
        label: 'Toteuma',
        inputValue: incomeStatementAndBalance.find(item => item.key === 'pensionExpences')?.currentYear ?? 0,
        disabled: true,
      },
      {
        id: '16',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '17',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
    ],
    extraExaminationTable: [
      {
        id: '1',
        label: 'Palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '2',
        label: 'Huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '3',
        label: 'Laskennan perusteena olevat palkat',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label: 'Odotusarvoinen % ',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '5',
        label: 'Odotusarvoinen määrä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '6',
        label: 'Toteuma',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '7',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
    ],
    reconciliationSalariesTable: [
      {
        id: '1',
        label: 'Lomapalkkavelka pääkirjanpidossa',
        inputValue: 0,
      },
      {
        id: '2',
        label: 'Palkkajärjestelmän mukainen velka 31.12.',
        inputValue: 0,
      },
      {
        id: '3',
        label: 'Erotus',
        inputValue: 0,
      }
    ],
    reconciliationBooleanTable: [
      {
        id: '1',
        label: 'Kynnysarvo',
        booleanValue: null,
        inputValue: 0,
      },
    ],
    reconciliationSalariesSecondTable: [
      {
        id: '1',
        label: 'Tase-erittelyn mukaiset lomapalkat sivukuluineen',
        inputValue: 0,
      },
      {
        id: '2',
        label: 'Lomapalkat ilman sivukuluja',
        inputValue: 0,
      },
      {
        id: '3',
        label: 'Sivukulu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label: 'Pääkpidon maksettujen palkkojen mukainen laskennallinen lomapalkkavaraus ilman sivukuluja:',
        inputValue: 0,
        disabled: true,
        threshold: '',
      },
      {
        id: '5',
        label: 'Palkat yht.',
        inputValue: incomeStatementAndBalance.find(item => item.key === 'wagesAndSalaries')?.currentYear ?? 0,
      },
      {
        id: '6',
        label: 'Tilikauden pituus',
        inputValue: 12,
      },
      {
        id: '7',
        label: 'Keskim. palkat per kk',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Keskim. kertyneet lomapäivät per kk',
        inputValue: 2.50,
      },
      {
        id: '9',
        label: 'Kertyneiden lomakuukausien määrä (1.4 alkaen)',
        inputValue: 9,
      },
      {
        id: '10',
        label: 'Keskim. pitämättömät talvilomapäivät yht.',
        inputValue: 6,
      },
      {
        id: '11',
        label: 'Laskennalliset lomapalkat ilman lomarahoja',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '12',
        label: 'Laskennallinen lomapalkkavaraus ml. lomarahat ilman sivukuluja',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '13',
        label: 'Laskennallisen ja tehdyn varauksen erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '14',
        label: 'Johtopäätös',
        inputValue: 0,
        threshold: '---',
        disabled: true,
      },
    ],

    addNewTableButton: false,
    useTableButton: false,
    useReconciliationButton: false,
    describePeriodTextArea: 'Kuvaa tässä esimerkiksi erää yleisellä tasolla',

    analyticalReviewOfWages: analyticalReviewOfWagesRows(),
    analyticalAuditOfPersonnelOverheadCosts:
      analyticalAuditOfPersonnelOverheadCostsRows(),
    analyticalAuditOfWages: analyticalAuditOfWagesRows(),
    matchingOfWagesToAnnualReport: matchingOfWagesToAnnualReportRows(),
    personnelCostsPeriodization: {},
    auditOfVacationPayAccruals: {},
    matchingOfVacationPayAccruals: {
      useCalculationBase: true,
      noCalculationBaseReason: '',
      balanceComparisons: makeDefaultPayAccrualsComparisonRows(),
    },
    analyticalAuditOfVacationPayAccruals:
      analyticalAuditOfVacationPayAccrualsRows(),
    auditOfWages: {},
    auditOfIndividualTransactions: {},
    processMappingWithKeyAccountingPrinciples: {},
  };
};

const formFields: FormFields<SectionFormType> = ({
  store,
  auditing,
  formState,
}) => [

  formBuilder.custom(
    <ProcedureAccounts classKey={AccountClassKey[batch]} auditing={auditing} />
  ),
  formBuilder.custom(props => <TableColumn title='Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan (ISA 315.11)' {...props} />),
  formBuilder.textArea({
    accessor: 'conclusionOnAuditPersonnelCosts',
  }),

  /*
  formBuilder.custom(props => (
    <AccountMappings
      classKey={AccountClassKey[batch]}
      auditing={auditing}
      accountMappings={formState.accountMappings}
      {...props}
    />
  )),
  formBuilder.custom(props => <PersonelCostsCommonFormFields {...props} />),
  */

  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => <PersonelProcessDescriptionTable processText={''} processDate={null} processValue={''} {...props} />),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, "wages"),
  }),

  formBuilder.accordionGroup({
    title: auditingJson.form.advancePayments.describePeriod,
    items: [
      formBuilder.textArea({
        accessor: 'describePeriodTextArea',
      }),
    ],
  }),

  ...store.auditingStore.getAuditingProceduresBatch(batch).map(procedure =>{
    return formBuilder.accordionGroup({
      title: procedure.actionKey.includes("action-") || procedure.actionKey.includes("control-") ? getPartNameBasedOnActionKey(procedure.actionKey, auditing) : i18n.t(
        `auditing:auditingProcedureAction.${batch}.${procedure.actionKey}`
      ),
      items: [
        formBuilder.custom(
          <AuditingProcedureItem auditingProcedure={procedure} />
        ),
        formBuilder.custom(props => {
          const { formState, patchFormState } = props;
          const { actionKey } = procedure;

          const accountMappingValues = getAccountMappingValues(
            auditing?.generalLedger?.form.groupedGeneralLedger,
            formState.accountMappings
          );

          switch (actionKey) {
            case 'analyticalReviewOfWages':
              return (
                <>
      {/* 
      <AnalyticalReviewOfWages
        dataKey={actionKey}
        accountMappingValues={accountMappingValues}
        analyticalReviewOfWagesComputationRules={
          analyticalReviewOfWagesRowsComputationRules
        }
        {...props}
      />
       */}
                  <AnalyticalReviewTable {...props} />
                  <PensionExpensesTable {...props} />
                  <BusinessOverTable {...props} />
                </>
              );
            case 'analyticalAuditOfWages':
              return (
                <>
                  <AnalyticalSalariesTable {...props} />
                </>
              );
            case 'analyticalExaminationOfSideCosts':
              return (
                <>
                <ExaminationTable {...props} />
                <AnalyticalExaminationTable {...props} />
                <br/>
                <input type='checkbox' onChange={() => patchFormState({ addNewTableButton: !formState.addNewTableButton })} /> Lisää laskentataulukko
                <br/>
                {formState.addNewTableButton && <ExtraExaminationTable {...props} />}
                </>
              );
            case 'analyticalAuditOfVacationPayAccruals':
              return (
                <>
                <br/>
                <input type='checkbox' onChange={() => patchFormState({ useTableButton: !formState.useTableButton })} /> Käytä valmista laskentataulukkoa
                <br/>
                {formState.useTableButton &&
                    <ThresholdTestTable<SectionFormType>
                    dataKey={actionKey}
                    accountMappingValues={accountMappingValues}
                    thresholdComputationRules={
                      thresholdComputationRules[actionKey]
                    }
                    {...props}
                  />
                }
                </>
              );
            case 'reconciliationOfSalaries':
              return (
                <>
                <input type='checkbox' onChange={() => patchFormState({ useReconciliationButton: !formState.useReconciliationButton })} /> Käytä valmista laskentataulukkoa
                <br/>
                {formState.useReconciliationButton &&
                  <ReconciliationSalariesTable {...props} />
                }
                </>
              );
            case 'inspectionOfPersonnelExpenseAccurals':
            default:
              return null;
          }
        }),
      ],
    })
  }),
  /*
  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
        showContextInfo: 'top-right',
      }),
    ],
  }),
  */
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['materiality'/* , 'detailedPlan' */],
};

export default section;
