import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { getWindowDimensions } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = InvestmentsForm;

const sectionKey: AuditingSectionKey = 'investments';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = "O3-investments"

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [{
    currentYear: 0,
    priorYear: 0,
    accountName: "",
    comment: ""
  }],
  procedureAccountsData: {},
  tableColumnData: [
    {
      left: "Sijoitusten olemassaolo",
      right: "Sijoitusten arvostus"
    },
    {
      left: "Sijoitusten luokittelu",
      right: "Tytäryhtiöiden osakkeiden arvostus"
    },
  ],

  conclusionText: auditingJson.defaultConclusionValue,
  useSheet: null,
  sheetdata1: [{
    cellData: [
      {
        row: 0,
        column: 0,
        cellData: {
          contains: "Lisää tähän tarvittaessa olennaiset kaavat(esim WACC, DCF)"
        },
        
      }
    ],
    row: 15,
    column:15
  }],

  useSheetTab: null,

  useSheet1: null,
  sheetdata1_second: [{
      cellData: [],
  }],
  useSheet2: null,
  sheetdata2: [{
      cellData: [],
  }],
  useSheet3: null,
  sheetdata3: [{
      cellData: [],
  }],
  useSheet4: null,
  sheetdata4: [{
      cellData: [],
  }],


})


const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
    formBuilder.group({items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} showChange />),
      formBuilder.subtitle({titleKey: 'tableColumnTitle'}),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({ accessor: 'conclusionText', plainLabel: auditingJson.inspectionConclusion}),
      formBuilder.boolean({accessor: 'useSheet', plainLabel: auditingJson.useSheet}),
      formBuilder.custom(props => formState.useSheet ? <SpreadSheet customHeight={getWindowDimensions().height *0.5} id="sheetdata1" {...props} /> : null)
    ]}),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
      {
        accordionId: 'checkingAndVerifyingInvestments',
        lowerElements: [
          formBuilder.boolean({accessor: "useSheet1", plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet1 ? <SpreadSheet id="sheetdata1" {...props} /> : null),
        ]
      },
      {
        accordionId: 'appreciationOfInvestments',
        lowerElements: [
          formBuilder.boolean({accessor: "useSheet2", plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="sheetdata2" {...props} /> : null),
        ]
      },
      {
        accordionId: 'investmentsClassification',
        lowerElements: [
          formBuilder.boolean({accessor: "useSheet3", plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="sheetdata3" {...props} /> : null),
        ]
      },
      {
        accordionId: 'appreciationOfSubsidiariesInvestments',
        lowerElements: [
          formBuilder.boolean({accessor: "useSheet4", plainLabel: auditingJson.useSheet}),
          formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="sheetdata4" {...props} /> : null),
        ]
      },
    ]),

];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
