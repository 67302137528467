import { t } from '../../../i18n';
import { uuid } from '../../../utils';
import { AccountClassKey, AccountKey } from '../planning/accountMapKeys';
import { Risk } from '../analyzes/incomeStatementAndBalance';

export enum AuditingProcedureResult {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  treatedGood = 'treatedGood',
  treatedModerately = 'treatedModerately',
  shortcomings = 'shortcomings',
  noEvidenceReceived = 'noEvidenceReceived',
}


export const hasShortcomings = ({ auditingResult }: AuditingProcedure) =>
  auditingResult === AuditingProcedureResult.shortcomings;

export const auditingProcedureResultToNumber = (
  auditingProcedureResult: AuditingProcedureResult | null
) => {
  switch (auditingProcedureResult) {
    case AuditingProcedureResult.notStarted:
      return 0;
    case AuditingProcedureResult.inProgress:
      return 6;
    case AuditingProcedureResult.treatedGood:
      return 1;
    case AuditingProcedureResult.treatedModerately:
      return 2;
    case AuditingProcedureResult.shortcomings:
      return 3;
    case AuditingProcedureResult.noEvidenceReceived:
      return 4;
    case null:
      return 5;
  }
};

/**
 * Helper to look if a given income statement items have risks
 */
export const incomeStatementAndBalanceItemHasNoRisks =
  (auditing?: Auditing) =>
  (...incomeStatementAndBalanceKeys: string[]) => {
    const { incomeStatement, balanceAssets, balanceLiabilities } =
    // Takes from incomeStatementAndBalance form (Tuloslaskelma ja tase - Tarkastus) Risk
      auditing?.incomeStatementAndBalance?.form ?? {};
    // Add a new incomeStatementAndBalanceScoping form (Tuloslaskelma ja tase - Päätös) Risk
    const { incomeStatement: incomeStatementScoping, balanceAssets: balanceAssetsScoping, balanceLiabilities: balanceLiabilitiesScoping } =
      auditing?.incomeStatementAndBalanceScoping?.form ?? {};

    const keyValueHasSomeRisk = (item: IncomeStatementAndBalanceItem) =>
      incomeStatementAndBalanceKeys.includes(
        item.classKey ? AccountClassKey[item.classKey] : AccountKey[item.key]
      ) &&
      item.risk &&
      [Risk.significant, Risk.limitedRisk].includes(item.risk);

    const itemsHavingSomeRisk = [
      ...(incomeStatement ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssets ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilities ?? []).filter(keyValueHasSomeRisk),
      ...(incomeStatementScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssetsScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilitiesScoping ?? []).filter(keyValueHasSomeRisk),
    ];
    const noRisksFound = !itemsHavingSomeRisk.length;

    return noRisksFound;
  };

// If you add new batch, remember to add it to the 'batchToFileNameMap' in handleExcelUtils.tsx
export enum FinancialStatementBatch {
  'REMARK' = 'REMARK',
  'B-commonActions' = 'B-commonActions',
  'F-financialStatement' = 'F-financialStatement',
  'F1-assignmentsFirstTime' = 'F1-assignmentsFirstTime',
  'F3-presentationAndContent' = 'F3-presentationAndContent',
  'G-management' = 'G-management',
  'J-operatingIncome' = 'J-operatingIncome',
  'J1-turnover' = 'J1-turnover',
  'JB-otherOperatingIncome' = 'JB-otherOperatingIncome',
  'K-materialsAndServices' = 'K-materialsAndServices',
  'L-personelCosts' = 'L-personelCosts',
  'OA-deprecations' = 'OA-deprecations',
  'KB-otherOperatingExpenses' = 'KB-otherOperatingExpenses',
  'XJ-financialIncome' = 'XJ-financialIncome',
  'XK-financialExpenses' = 'XK-financialExpenses',
  'OB-financialStatementTransfers' = 'OB-financialStatementTransfers',
  'M-governmentTransfersAndTaxRevenues' = 'M-governmentTransfersAndTaxRevenues',
  'N-taxes' = 'N-taxes',
  'O1-fixedAssets' = 'O1-fixedAssets',
  'P-inventories' = 'P-inventories',
  'J2-accountsReceivable' = 'J2-accountsReceivable',
  'Q1-otherReceivables' = 'Q1-otherReceivables',
  'RA-subordinatedLoans' = 'RA-subordinatedLoans',
  'RB-provisions' = 'RB-provisions',
  'S1-financialInstitutions' = 'S1-financialInstitutions',
  'K2-tradeCreditors' = 'K2-tradeCreditors',
  'T-otherCreditors' = 'T-otherCreditors',
  'T1-otherLiabilities' = 'T1-otherLiabilities',
  'U-assignmentAssetsAndLiabilities' = 'U-assignmentAssetsAndLiabilities',
  'O4-funding' = 'O4-funding',
  'K1-materialsAndServices' = 'K1-materialsAndServices',
  'Q2-cashEquivalents' = 'Q2-cashEquivalents',
  'Q3-loanReceivables' = 'Q3-loanReceivables',
  'T2-advancePayments' = 'T2-advancePayments',
  'G4.4-innerCircle' = 'G4.4-innerCircle',
  'F8-manualExports' = 'F8-manualExports',
  'F9-continuityOfOperation' = 'F9-continuityOfOperation',
  'V-reserves' = 'V-reserves',
  'R2-capitalLoans' = 'R2-capitalLoans',
  'R1-ownCapital' = 'R1-ownCapital',
  'P1-warehouses' = 'P1-warehouses',
  'O3-investments' = 'O3-investments'
}

export const initialBatches: FinancialStatementBatch[] = [
  FinancialStatementBatch['B-commonActions'],
  FinancialStatementBatch['F-financialStatement'],
  FinancialStatementBatch['F3-presentationAndContent'],
  FinancialStatementBatch['G-management'],
];

export const isDefaultBatch = (batch: FinancialStatementBatch) =>
  initialBatches.includes(batch);

export const isRemarkBatch = ({ batch }: { batch: FinancialStatementBatch }) =>
  batch === FinancialStatementBatch.REMARK;

export const isNotRemarkBatch = ({
  batch,
}: {
  batch: FinancialStatementBatch;
}) => batch !== FinancialStatementBatch.REMARK;

/**
 * Check if auditing procedure has either "period" or "tp" (or both) value set to true.
 */
export const hasPeriodOrTP = (auditingProcedure: AuditingProcedure) => {
  return !!auditingProcedure.period || !!auditingProcedure.tp;
};

/**
 * Check if auditing procedure has inExcel value set to true.
 */
export const hasInExcel = (auditingProcedure: AuditingProcedure) => {
  if (auditingProcedure.inExcel) {
    auditingProcedure.period = null;
    auditingProcedure.tp = null;
  }
  return !!auditingProcedure.inExcel;
};

/**
 * Filter auditing proceduder that should be visible in "auditing" & "reporting" phases
 */
export const pruneAuditingProcedures =
  (auditing?: Auditing) => (auditingProcedure: AuditingProcedure) => {
    const { batch } = auditingProcedure;

    const batchHasSomeRisks =
      !incomeStatementAndBalanceItemHasNoRisks(auditing)(batch);

    const isValidBatch = isDefaultBatch(batch) || batchHasSomeRisks;

    return (isValidBatch && hasPeriodOrTP(auditingProcedure)) || (isValidBatch && hasInExcel(auditingProcedure));
  };

export const batchName = (batch: FinancialStatementBatch) =>
  t(`auditing:accountClass.${batch}`);

export enum FinancialStatementEntirety {
  financialStatementAndAccounting = 'financialStatementAndAccounting',
  internalControlAndManagement = 'internalControlAndManagement',
  businessIndicators = 'businessIndicators',
  lawAndTaxation = 'lawAndTaxation',
  inspectionGeneralAndOthers = 'inspectionGeneralAndOthers',
}

// Use shortened Batch & Entirety collections internally (just to keep lines more compact)
const Batch = { ...FinancialStatementBatch };
const Entirety = {
  ...FinancialStatementEntirety,
  // Below is just simple shorthands for entirety enum values:
  '1': FinancialStatementEntirety.financialStatementAndAccounting,
  '2': FinancialStatementEntirety.internalControlAndManagement,
  '3': FinancialStatementEntirety.businessIndicators,
  '4': FinancialStatementEntirety.lawAndTaxation,
  '5': FinancialStatementEntirety.inspectionGeneralAndOthers,
};
type EntiretyKey = '1' | '2' | '3' | '4' | '5';

export const makeAuditingProcedure = (
  props: Partial<AuditingProcedure> & { batch: FinancialStatementBatch }
): AuditingProcedure => {
  const id = uuid();

  let auditingReference = "";
  let auditingComments = "";
  let auditingProcedureGoals = "";
  let auditingProcedurePerceptions = "";

  if(props.actionKey === "accountingEstimates"){
    auditingReference = t('auditing:form.auditingProcedures.accountingEstimatesValues.auditingReferenceValue');
    auditingComments = t('auditing:form.auditingProcedures.accountingEstimatesValues.auditingCommentsValue');
    auditingProcedureGoals = t('auditing:form.auditingProcedures.accountingEstimatesValues.auditingProcedureGoalsValue');
    props.inspectionBackground = t('auditing:form.auditingProcedures.accountingEstimatesValues.inspectionBackgroundValue');
    props.inspectionDescription = t('auditing:form.auditingProcedures.accountingEstimatesValues.inspectionDescriptionValue');
    
  }else if(props.actionKey === "describePeriod"){
    auditingProcedurePerceptions = t('auditing:form.auditingProcedures.auditingProcedureGoalsValue');
  }else if(props.actionKey === "innerCircle"){
    auditingReference = t('auditing:form.auditingProcedures.innerCircleValues.auditingReferenceValue');
    auditingComments = t('auditing:form.auditingProcedures.innerCircleValues.auditingCommentsValue');
    auditingProcedureGoals = t('auditing:form.auditingProcedures.innerCircleValues.auditingProcedureGoalsValue')
    props.innerCircleAuditingResult = {
      handlingInAccounting:null,
      demonstratedData:null,
      effectOnPicture:null,
      abuseRisks:null
    };
  }else{
    auditingReference = t('auditing:form.auditingProcedures.auditingReferenceValue');
    auditingComments = t('auditing:form.auditingProcedures.auditingCommentsValue');
    auditingProcedureGoals = t('auditing:form.auditingProcedures.auditingProcedureGoalsValue');
  }



  return {
    id,
    actionKey: `action-${id}`,
    action: '',
    entirety: null,
    period: null,
    tp: null,
    inExcel: null,
    auditor: '',
    auditingResult: null,
    auditingReference: auditingReference,
    auditingComments: auditingComments,
    auditingProcedureGoals: auditingProcedureGoals,
    auditingProcedureNatureAndScopeAndExpectations: '',
    auditingProcedurePerceptions: auditingProcedurePerceptions,
    impactOnIncomeStatement: null,
    impactOnBalanceSheet: null,
    impactOnEquity: null,
    conclusion: '',
    fixed: null,
    ...props,
  };
};

export const makeNewControl = (
  props: Partial<AuditingProcedure> & { batch: FinancialStatementBatch }
): AuditingProcedure => {
  const id = uuid();
  return {
    id,
    actionKey: `control-${id}`,
    action: '',
    entirety: null,
    period: null,
    tp: null,
    inExcel: null,
    auditor: '',
    auditingResult: null,
    auditingReference: '',
    auditingComments: t('auditingCommentsValue'),
    auditingProcedureGoals: '',
    auditingProcedureNatureAndScopeAndExpectations: '',
    auditingProcedurePerceptions: '',
    impactOnIncomeStatement: null,
    impactOnBalanceSheet: null,
    impactOnEquity: null,
    conclusion: '',
    fixed: null,
    ...props,
  };
};

export const transformRemarkToAuditingProcedure = (
  remark: AuditingRemark
): AuditingProcedure =>
  makeAuditingProcedure({
    id: remark.key,
    batch: FinancialStatementBatch.REMARK,
    action: remark.label,
    period: false,
    tp: false,
    auditingResult: AuditingProcedureResult.shortcomings,
    auditingReference: t(`route:auditing.${remark.sectionKey}`),
  });

type Action = string;
type Period = boolean | null;
type TP = boolean | null;
type AuditingProcedureTuple = [Action, EntiretyKey, Period, TP];

const castToTupleArrays = (array: (string | boolean | null)[][]) =>
  array as AuditingProcedureTuple[];

const toAuditingProcedure =
  (batch: FinancialStatementBatch) =>
  ([actionKey, entiretyKey, period, tp]: AuditingProcedureTuple) =>
    makeAuditingProcedure({
      batch,
      actionKey,
      action: t(`auditing:auditingProcedureAction.${batch}.${actionKey}`),
      entirety: Entirety[entiretyKey],
      period,
      tp,
      auditingResult: AuditingProcedureResult.notStarted,
    });

export const defaultAuditingProcedures = (): AuditingProcedure[] => [
  /************************************************************************** /
   * B - Tarkastuksen yleiset toimet
   ***************************************************************************/

  ...castToTupleArrays([
    ['discussionsWithManagement', '5', true, null],
    ['auditPlan', '5', true, null],
    ['confirmationLetter', '5', null, true],
    ['dataRequests', '5', true, true],
    ['reportingInterimReportAndOtherPossibleReports', '5', null, true],
  ]).map(toAuditingProcedure(Batch['B-commonActions'])),

  /************************************************************************** /
   * F - Tilinpäätös ja kirjanpito
   ***************************************************************************/

  ...castToTupleArrays([
    ['assignmentsFirstTime', '1', null, null],
    ['gitcUserRights', '2', true, null],
    ['manualExports', '1', null, true],
    ['auditOfOpeningBalance', '1', true, null],
    ['reviewOfOpeningBalanceAndPreviousFinancialStatements', '1', true, null],
    ['analyticalReviewOfIncomeStatementAndBalanceSheet', '1', true, true],
    //['reviewOfFinancialStatementPresentationAndContent', '1', null, true],
    //['reviewOfManagementReportContent', '1', null, true],
    ['consolidationAudit', '1', null, true],
    ['auditOfEventsAfterTheBalanceSheetDate', '1', null, true],
    //['reviewOfFinancialStatementSignatures', '1', null, true],
    ['generalLedgerVsSubLedgerMatchings', '1', null, null],
    ['continuityOfOperation', '1', null, null],
  ]).map(toAuditingProcedure(Batch['F-financialStatement'])),

  /************************************************************************** /
   * F3 - Tilinpäätöksen prikkaus ja sisältö
   ***************************************************************************/
  ...castToTupleArrays([
    ['appropriatenessOfFinancial', '1', true, null],
    ['summaryOfTheFinancial', '1', true, null],
    ['inspectionOfTheConsolidated', '1', null, null],
    ['summaryAndContent', '1', null, null],
    ['inspectionOfResponsibilities', '1', null, null],
  ]).map(toAuditingProcedure(Batch['F3-presentationAndContent'])),

  /************************************************************************** /
   * P1 - Varasto
   * *************************************************************************/
  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['reconciliationOfInventory', '1', null, null],
    ['inventoryChangeReconciliation', '1', null, null],
    ['costTesting', '1', null, null],
    ['comparingTheInventory', '1', null, null],
    ['dataAnalysis', '1', null, null],
    ['participationInInventory', '1', null, null],
    ['checkingStock', '1', null, null],
    ['accountingEstimates', '1', null, null],
  ]).map(toAuditingProcedure(Batch['P1-warehouses'])),

  /************************************************************************** /
   * G - Hallinto
   ***************************************************************************/

  ...castToTupleArrays([
    //['publicNetworkDocuments', '2', true, null],
    //['municipalStrategy', '2', true, null],
    //['administrativeRule', '2', true, null],
    //['budget', '2', true, null],
    ['internalInstructions', '2', true, null],
    ['groupInstruction', '2', true, null],
    ['boardMeetingMinutes', '2', true, true],
    //['councilMeetingMinutes', '2', true, null],
    //['reviewOfPriorityBoards', '2', true, null],
    //['reviewOfPriorityOfficials', '2', true, null],
    ['insurancePolicies', '2', true, null],
    ['affiliationRegister', '2', true, null],
    ['innerCircle', '2', true, null],
  ]).map(toAuditingProcedure(Batch['G-management'])),

  /************************************************************************** /
   * J1 - Liikevaihto
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', true, true],
    ['processMappingWtAndMainAccountingPrinciples', '1', true, null],
    ['reviewOfIndividualTransactions', '1', true, null],
    ['matchingOfSalesToInvoicing', '1', null, true],
    ['reviewOfRevenueRecognition', '1', null, true],
    ['innerCircle', '1', true, null],
    //['dataAnalysis', '1', true, null],
    ['accountingEstimates', '1', null, null],
/*     ['reviewOfCreditInvoices', '1', true, null],
    ['reviewOfSignificantContracts', '1', true, null],
    ['controlTesting', '2', true, null],
    ['reviewOfCreditInvoicesInCurrentFinancialYear', '1', null, true],
    ['reviewOfUnbilledDeliveriesListing', '1', null, null], */
  ]).map(toAuditingProcedure(Batch['J1-turnover'])),

  /************************************************************************** /
   * JB - Liiketoiminnan muut tuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', true, null],
    ['individualTransactionInspection', '1', true, null],
    ['breakCheck', '1', true, null],
    ['innerCircle', '1', null, true],
  ]).map(toAuditingProcedure(Batch['JB-otherOperatingIncome'])),

  /************************************************************************** /
   * J2 - Myyntisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, true],
    ['accountsReceivableReconciliation', '1', null, true],
    ['mathematicalAccuracy', '1', null, true],
    ['recheckingAccountsReceivable', '1', null, true],
    ['payments', '1', null, true],
    ['balanceConfirmation', '1', null, true],
    ['paymentConfirmation', '1', null, true],
    ['classificationAndPresentation', '2', true, true],
    ['accountingEstimates', '1', null, true],
  ]).map(toAuditingProcedure(Batch['J2-accountsReceivable'])),

  /************************************************************************** /
   * K - Materiaalit ja palvelut
   ***************************************************************************/

  ...castToTupleArrays([
    ['reviewOfIndividualTransactions', '1', true, null],
    ['riskAnalysisReview', '1', true, null],
    ['analysisOfProfitMargins', '1', true, true],
    ['dataAnalysis', '1', true, null],
    ['matchingOfAccountsPayableListToGeneralLedger', '1', true, null],
    ['classificationOfPurchases', '1', true, null],
    ['verificationOfPurchaseCutoff', '1', null, true],
    ['controlTesting', '2', true, null],
    ['processMappingWTAndKeyAccountingPolicies', '2', true, null],
    ['pivotingOfSuppliersAndReviewOfTheList', '1', true, null],
  ]).map(toAuditingProcedure(Batch['K-materialsAndServices'])),

  /************************************************************************** /
   * K1 - Materiaalit ja palvelut
   ***************************************************************************/
  ...castToTupleArrays([
    ['analyticalInspectionOfPurchases', '1', true, null],
    ['marginAnalysis', '1', true, true],
    ['inspectionOfInterruption', '1', null, true],
    ['materialInspection', '1', null, null],
    ['innerCircle', '1', null, null],
  ]).map(toAuditingProcedure(Batch['K1-materialsAndServices'])),

  /************************************************************************** /
   * K2 - Ostovelat
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['reconciliationOfThePurchase', '1', null, true],
    ['mathematicalCorrectness', '1', null, null],
    ['reviewOfAccounts', '1', null, true],
    ['breakInExpenses', '1', null, null],
    ['breakInExpensesEvents', '1', null, null],
    ['existenceOfAccounts', '1', null, null],
    ['classification', '1', null, null],
    ['accountingEstimates', '1', null, null],
  ]).map(toAuditingProcedure(Batch['K2-tradeCreditors'])),

  /************************************************************************** /
   * L - Henkilöstökulut
   ***************************************************************************/

  ...castToTupleArrays([
    //['describePeriod', '1', null, true],
    ['analyticalReviewOfWages', '1', null, true],
    ['analyticalAuditOfWages', '1', null, true],
    ['analyticalExaminationOfSideCosts', '1', null, true],
    ['analyticalAuditOfVacationPayAccruals', '1', null, true],
    ['reconciliationOfSalaries', '1', null, true],
    ['inspectionOfPersonnelExpenseAccurals', '1', null, true],
  ]).map(toAuditingProcedure(Batch['L-personelCosts'])),

  /************************************************************************** /
   * KB - Liiketoiminnan muut kulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['analyticalReview', '1', null, true],
    ['interruptionExpenses', '1', null, true],
    ['materialInspection', '1', null, true],
    ['legalFees', '1', true, null],
    ['innerCircle', '1', true, null],
/*     ['classificationOfExpenses', '1', null, true],
    ['legalExpenses', '1', true, null], */
  ]).map(toAuditingProcedure(Batch['KB-otherOperatingExpenses'])),

  /************************************************************************** /
   * M - Valtionosuudet ja verotulot
   ***************************************************************************/

  ...castToTupleArrays([
    ['reliabilityOfGovernmentSharesStudentInformation', '2', true, null],
    ['reliabilityOfGovernmentSharesBasicServices', '2', true, true],
    ['settlingForGovernmentSharesStudentInformation', '2', true, null],
    ['settlingForGovernmentSharesBasicServices', '2', true, null],
    ['taxReconciliationAccountingVersusTaxAuthority', '1', null, true],
    ['govermentSharesReconciliationStudentInformation', '1', null, true],
    ['govermentSharesReconciliationBasicServices', '1', null, true],
  ]).map(toAuditingProcedure(Batch['M-governmentTransfersAndTaxRevenues'])),

  /************************************************************************** /
   * T1 - Muut velat ja siirtovelat 
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['inspectionOfLoans', '1', null, true],
    ['auditOfOtherDebts', '1', null, true],
    ['auditOfAccruedLiaibilities', '1', null, true],
    ['inspectionOfTheClassification', '1', null, true],
    ['completenessOfOther', '1', null, true],
    ['checkingOtherDebts', '1', null, true],
    ['innerCircle', '1', null, true],
  ]).map(toAuditingProcedure(Batch['T1-otherLiabilities'])),

  /************************************************************************** /
   * XJ - Rahoitustuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['checkingDividendIncome', '1', null, null],
    ['reconciliationOfInterestExpenses', '1', null, null],
    ['analyticalExamination', '1', null, null],
    ['analyticalExaminationOfFinancialExpenses', '1', null, null],
    ['reconciliationOfFinancialExpenses', '1', null, null],
    ['examinationOfPermanent', '1', null, null],
    ['examinationOfImpairments', '1', null, null],
  ]).map(toAuditingProcedure(Batch['XJ-financialIncome'])),

  /************************************************************************** /
   * XK - Rahoituskulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', true, true],
    ['reconciliationOfInterestExpenses', '1', null, true],
    ['analyticalExamination', '1', true, null],
    ['analyticalExaminationOfFinancialExpenses', '1', null, true],
    ['examinationOfPermanentPriceDecrease', '1', true, null],
    ['examinationOfChangingAssets', '1', true, null],
  ]).map(toAuditingProcedure(Batch['XK-financialExpenses'])),

  /************************************************************************** /
   * O1 - Käyttöomaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', true, null],
    ['accountingReconciliation', '1', true, null],
    ['verifyingRemovals', '1', true, null],
    ['removedDifferencesCheck', '1', true, null],
    ['additionsCheck', '1', true, null],
    ['removalsCheck', '1', true, null],
    ['fixedAssetsAppreciation', '1', true, null],
    ['decreasedValueOfFixedAssetsCheck', '1', true, null],
    ['checkingAdditionsAndSubtractions', '1', true, null],
    ['accountingEstimates', '1', true, null],
  ]).map(toAuditingProcedure(Batch['O1-fixedAssets'])),

    /************************************************************************** /
   * O3 - Sijoitukset
   ***************************************************************************/

    ...castToTupleArrays([
      ['checkingAndVerifyingInvestments', '1', true, null],
      ['appreciationOfInvestments', '1', true, null],
      ['investmentsClassification', '1', true, null],
      ['appreciationOfSubsidiariesInvestments', '1', true, null],
    ]).map(toAuditingProcedure(Batch['O3-investments'])),
    

  /************************************************************************** /
   * P - Vaihto-omaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['participationInInventory', '1', true, null],
    ['matchingOfInventoryChanges', '1', null, true],
    ['valuationAndPricingAuditOfInventory', '1', null, true],
    ['matchingOfInventoryListToLedger', '1', null, true],
    [
      'auditOfDifferencesBetweenFinalInventoryAndInventoryList',
      '1',
      null,
      true,
    ],
    ['dataAnalysis', '1', null, true],
    ['processMappingWTAndKeyAccountingPrinciples', '2', true, null],
    ['analysisOfInventoryResults', '2', null, true],
    ['analysisOfSlowMovingItems', '2', null, true],
  ]).map(toAuditingProcedure(Batch['P-inventories'])),

  /************************************************************************** /
   * Q1 - Saamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, true],
    ['verificationOfReceivables', '1', null, true],
    ['checkingMovedReceivables', '1', null, true],
    ['classificationAndPresentation', '1', null, true],
    ['perfectionOfOtherReceivables', '1', null, true],
    ['inspectionOtherReceivables', '1', null, true],
    ['accountingEstimates', '1', null, true],
    ['innerCircle', '1', null, true],

  ]).map(toAuditingProcedure(Batch['Q1-otherReceivables'])),

  /************************************************************************** /
   * Q2 - Rahat ja pankkisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod','1',null,true],
    ['balanceConfirmation','1',null,true],
    ['accountReconciliation','1',null,true],
    ['balanceConfirmationThroughBank','1',null,true],
    ['debetAndCreditConfirmation','1',null,true],
    ['cashCounterConfirmation','1',null,true],
    ['manualPaymentsConfirmation','1',null,true],
    ['cashFlowClassificationLUO','1',null,true],
  ]).map(toAuditingProcedure(Batch['Q2-cashEquivalents'])),

  /************************************************************************** /
   * R1 - Oma pääoma
   ***************************************************************************/

  ...castToTupleArrays([
    ['equityAudit', '1', true, null],
    ['assessmentOfTheAppropriateness', '1', true, null],
  ]).map(toAuditingProcedure(Batch['R1-ownCapital'])),

  /************************************************************************** /
   * S1 - Lainat rahoituslaitoksilta
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, true],
    ['loanReconciliationForBalance', '1', null, true],
    ['reconciliationOfLoans', '1', null, true],
    ['inspectionOfLoanBalance', '1', null, true],
    ['inspectionOfLoanClassification', '1', null, true],
    ['reviewingLoanAgreements', '1', null, true],
    ['inspectionOfCovenants', '1', null, true],
  ]).map(toAuditingProcedure(Batch['S1-financialInstitutions'])),

  /************************************************************************** /
   * T - Muut velat
   ***************************************************************************/

  ...castToTupleArrays([
    ['existenceClassificationAndMatchingOfOtherLiabilities', '1', null, true],
    ['existenceClassificationAndMatchingOfAccruedExpenses', '1', null, true],
    ['examinationOfIndividualTransactions', '1', null, true],
  ]).map(toAuditingProcedure(Batch['T-otherCreditors'])),

  /************************************************************************** /
   * N - Verot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '4', true, null],
    ['lastSeasonTaxesCheck', '1', null, true],
    ['checkingOfTaxes', '1', true, null],
  ]).map(toAuditingProcedure(Batch['N-taxes'])),

    /************************************************************************** /
   * O4 - Rahoitusomaisuus
   ***************************************************************************/

    ...castToTupleArrays([
      ['describePeriod', '1', null, true],
      ['fundingAppreciation', '1', true, null],
      ['fundingInspection', '4', true, null],
      ['fundingClassificationInspection', '4', true, null],
      ['accountingEstimates', '4', true, null],
    ]).map(toAuditingProcedure(Batch['O4-funding'])),

    /************************************************************************** /
   * Q3 - Lainasaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['loanAgreementsAndConditions', '1', null, true],
    ['reviewOfLoanReceivables', '1', null, true],
    ['loanReceivablesReconciliationToBalance', '1', null, true],
    ['classificationAndPresentationOfReceivables', '1', null, true],
    ['accountingEstimates', '1', null, true],
    ['innerCircle', '1', null, true],
  ]).map(toAuditingProcedure(Batch['Q3-loanReceivables'])),

  /************************************************************************** /
   * T2 - Saadut Ennakot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['receivedAdvancesCheck', '1', null, true],
    ['classificationAndPresentationOfAdvances', '1', null, true],
    ['perfectionOfAdvances', '1', null, true],
  ]).map(toAuditingProcedure(Batch['T2-advancePayments'])),

  /************************************************************************** /
   * V - Varaukset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['mandatoryReservationCheck', '1', null, null],
    ['auditOfVoluntaryReservations', '1', null, null],
    ['checkOfReservation', '1', null, null],
    ['accountingEstimates', '1', null, null],
  ]).map(toAuditingProcedure(Batch['V-reserves'])),

  /************************************************************************** /
   * R2 - Pääomalainat
   ***************************************************************************/
  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['reviewingOfCapitalLoan', '1', null, null],
    ['loanReconciliation', '1', null, null],
    ['reconciliationOfLoans', '1', null, null],
    ['interestRatesOnCapitalLoans', '1', null, null],
  ]).map(toAuditingProcedure(Batch['R2-capitalLoans'])),
];

export const makeEmptyThresholdTestRow = (
  thresholdTestRow?: Partial<ThresholdTestRow>
): ThresholdTestRow => {
  const id = uuid();
  return {
    id,
    key: `${id}-custom`,
    value: null,
    ...thresholdTestRow,
  };
};

/**
 * Returns the value of the threshold test row item if it is set to use custom value.
 * Otherwise returns the given default value or the default value of the row item itself.
 */
export const getValueOrDefaultValue = (
  rowItem?: ThresholdTestRow,
  defaultValue?: ThresholdTestRowValue
) => {
  const value = rowItem?.useCustomValue
    ? rowItem?.value
    : defaultValue ?? rowItem?.defaultValue;
  return value ?? 0;
};
