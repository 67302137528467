import { FormFieldBuilder } from '../FormFieldBuilder';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { TableColumnWithFiveRows } from './reUsables/TableColumn';


type SectionFormType = innerCircleTabForm;

const sectionKey: AuditingSectionKey = 'innerCircleTab';
const jsonVocab = auditingJson.form.innerCircleTab;

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  tableColumnWithFiveRowsData: {
    "1": (auditing.hasOwnProperty("risks")
    ? auditing.risks?.form.innerCircleParties.map((item) => ({
        one: item.name,
        two: item.basisForBelonging,
        three: item.basisForTransaction,
        four: 0,
        five: "",
      }))
    : []) || [
    {
      one: "",
      two: "",
      three: "",
      four: 0,
      five: "",
    },
  ],
    "2": [{
      one: "",
      two: "",
      three: "",
      four: 0,
      five: ""
    }]
  }
  ,
  // innerCircle
  relationsSelect: null,
  relationsSelect2: null,
  relationsSelect3: null,
  relationsSelect4: null,
  doesCircleHaveSufficientMontoring: null,
  additionalInfo: auditingJson.giveAdditionalInfoIfNeeded,
  useRisksTable: null,

  // Lähipiiritapahtumien tarkastus
  documentedOnFollowingTable: null,
  referenceFileWhereVerified: jsonVocab.referenceFileWhereVerifiedValue,
  ideasGoalsAndCompletedWork: jsonVocab.ideasGoalsAndCompletedWork,
  reference: jsonVocab.reference,
  remarksAndComments: jsonVocab.remarksAndComments,
  hasNewInformationBeenDiscovered: null,
  additionalInfo2: jsonVocab.additionalInfo2Value,
  additionalInfo3: jsonVocab.additionalInfo3Value,

  hasUnusualBehaviorBeenNoticed: null,
  additionalInfo4: jsonVocab.additionalInfo4Value,

  summaryOfRelated: null,

})

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.group({items: [
    formBuilder.checkboxGroup({accessor: 'relationsSelect', options: Object.keys(auditingJson.relationsSelect), plainLabel: auditingJson.innerCircleSelectTitles.one, 
      optionDisplayValue: (key: string) => auditingJson.relationsSelect[key as keyof typeof auditingJson.relationsSelect]
    }),
    formBuilder.checkboxGroup({accessor: 'relationsSelect2', options: Object.keys(auditingJson.relationsSelect2), plainLabel: auditingJson.innerCircleSelectTitles.two, 
      optionDisplayValue: (key: string) => auditingJson.relationsSelect2[key as keyof typeof auditingJson.relationsSelect2]
    }),
    formBuilder.checkboxGroup({accessor: 'relationsSelect3', options: Object.keys(auditingJson.relationsSelect3), plainLabel: auditingJson.innerCircleSelectTitles.three, 
      optionDisplayValue: (key: string) => auditingJson.relationsSelect3[key as keyof typeof auditingJson.relationsSelect3]
    }),
    formBuilder.checkboxGroup({accessor: 'doesCircleHaveSufficientMontoring', plainLabel: jsonVocab.doesCircleHaveSufficientMontoringTitle,
      optionDisplayValue: (key: string) => auditingJson.yesOrNoOptions[key as keyof typeof auditingJson.yesOrNoOptions],
      options: Object.keys(auditingJson.yesOrNoOptions)}),
    formBuilder.textArea({accessor: 'additionalInfo', plainLabel: auditingJson.giveAdditionalInfoIfNeeded, 
      hidden: formState.doesCircleHaveSufficientMontoring == null ? true: formState.doesCircleHaveSufficientMontoring.no === false}),
  ]}),
  formBuilder.group({items: [
    formBuilder.subtitle({title: 'Lähipiiritapahtumien tarkastus'}),

    formBuilder.textArea({accessor: 'ideasGoalsAndCompletedWork', plainLabel: auditingJson.ideasGoalsAndCompletedWork}),
    formBuilder.textArea({accessor: 'reference', plainLabel: auditingJson.reference}),
    formBuilder.textArea({accessor: 'remarksAndComments', plainLabel: auditingJson.remarksAndComments}),

    formBuilder.boolean({accessor: 'hasNewInformationBeenDiscovered', plainLabel: jsonVocab.hasNewInformationBeenDiscovered, options: Object.keys(auditingJson.yesOrNoOptions),
    }),
    formBuilder.group({items: [
      formBuilder.textArea({accessor: 'additionalInfo2', plainLabel: "Tiedusteltu johdolta, mistä syystä yhteisön lähipiirisuhteita ja -liiketoimia koskevat kontrollit eivät onnistuneet siinä, että lähipiirisuhteet tai -liiketoimet olisi tunnistettu tai niistä olisi annettu tietoja (ISA 550.22(b)."}),
      formBuilder.textArea({accessor: 'additionalInfo3', plainLabel: "Onko edellä tunnistetuilla lähipiirisuhteilla ja liiketoimilla vaikutusta aiemmin tehtyyn riskiarvioon? " })
    ], hidden: formState.hasNewInformationBeenDiscovered !== false}),

    formBuilder.boolean({accessor: 'hasUnusualBehaviorBeenNoticed', plainLabel: jsonVocab.hasUnusualBehaviorBeenNoticed, options: Object.keys(auditingJson.yesOrNoOptions),
    }),
    formBuilder.group({items: [
      formBuilder.textArea({accessor: 'additionalInfo4', plainLabel: "Tunnistettujen tapahtumien arviointi: "}),
    ], hidden: formState.hasUnusualBehaviorBeenNoticed !== false}),

    formBuilder.group({
      items: [
        formBuilder.text({text: 'Yhteenveto lähipiiritapahtumista tilikaudella '}),
        formBuilder.checkboxGroup({
          accessor: 'documentedOnFollowingTable', 
          options: Object.keys(jsonVocab.documentedInFollowingTableOptions), 
          plainLabel: jsonVocab.documentedInFollowingTable,
          optionDisplayValue: (key: string) => jsonVocab.documentedInFollowingTableOptions[key as keyof typeof jsonVocab.documentedInFollowingTableOptions],
        }),
        formBuilder.textArea({
          accessor: 'referenceFileWhereVerified', 
          plainLabel: auditingJson.reference, 
          hidden: formState.documentedOnFollowingTable == null ? true: formState.documentedOnFollowingTable.elsewhere !== true
        }),
        /*
        formBuilder.boolean({
          accessor: 'summaryOfRelated',
          plainLabel: 'Käytä valmista pohjaa lähipiiri tapahtumien yhteenvedon laatimiseen',
          hidden: formState.documentedOnFollowingTable === null ? true: formState.documentedOnFollowingTable.elsewhere !== true
        }),
        */

        formBuilder.boolean({
          accessor: 'useRisksTable', 
          hidden: formState.documentedOnFollowingTable === null ? true: formState.documentedOnFollowingTable.yes !== true,
          plainLabel: 'Käytä pohjana "riskit välilehden lähipiiritaulukkoa'
        }),
    
        formBuilder.custom(props => (formState.documentedOnFollowingTable === null ? false: formState.documentedOnFollowingTable.yes === true) && formState.useRisksTable === true ? 
          <TableColumnWithFiveRows id="1" {...props} headers={[
          'Osapuoli',
          'Peruste Lähipiiriin kuulumiselle',
          'Totetutettu liiketoimin',
          'Liiketoimi (EUR)',
          'Kommentit / viite tarkastukseen'
        ]}/> : null),
    
        formBuilder.custom(props => (formState.documentedOnFollowingTable === null ? false: formState.documentedOnFollowingTable.yes === true) && !formState.useRisksTable === true ? 
          <TableColumnWithFiveRows id="2" {...props} headers={[
          'Osapuoli',
          'Peruste Lähipiiriin kuulumiselle',
          'Totetutettu liiketoimin',
          'Liiketoimi (EUR)',
          'Kommentit / viite tarkastukseen'
        ]}/> : null),
      ]
    })
  ]})

  
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
