import { IndicatorsClassTable, IndicatorsTable } from '../../../components/analyzes/Indicators.Table';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = IndicatorsForm;

const sectionKey: AuditingSectionKey = 'indicators';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  indicators: [],
  indicatorsClassTable: [
    {
      id: '1',
      companyImplementation: '',
      lastBenchmark: '',
      industry: '',
      estimate: '',
      boolean: null,
    }
  ],
  tableDataPushed: false,
})

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.custom(props => <IndicatorsClassTable {...props}/>),

  // Kannattavuus/tulos taulukko
  formBuilder.custom(props => <IndicatorsTable {...props}/>),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  // Making sure that the generalLedger is loaded before this section
  depending: ['generalLedgerScoping'],
};

export default section;
