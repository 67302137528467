export enum AccountKey {
  'turnover' = 'turnover',
  'inventoryChange' = 'inventoryChange',
  'manufactureForOwnUse' = 'manufactureForOwnUse',
  'otherOperatingIncome' = 'otherOperatingIncome',
  'materialsAndServices' = 'materialsAndServices',
  'materialsSupliesAndGoods' = 'materialsSupliesAndGoods',
  'purchases' = 'purchases',
  'stocksChange' = 'stocksChange',
  'externalServices' = 'externalServices',
  'personelCosts' = 'personelCosts',
  'wagesAndSalaries' = 'wagesAndSalaries',
  'socialSecurityCosts' = 'socialSecurityCosts',
  'pensionExpences' = 'pensionExpences',
  'otherSocialSecurityCosts' = 'otherSocialSecurityCosts',
  'depreciationAndAmortization' = 'depreciationAndAmortization',
  'otherOperatingExpenses' = 'otherOperatingExpenses',
  'operatingProfitOrLoss' = 'operatingProfitOrLoss',
  'financialIncomeAndExpences' = 'financialIncomeAndExpences',
  'financialIncome' = 'financialIncome',
  'financialExpenses' = 'financialExpenses',
  'profitOrLossBeforeTransfersAndTaxes' = 'profitOrLossBeforeTransfersAndTaxes',
  'financialStatementTransfers' = 'financialStatementTransfers',
  'incomeTaxes' = 'incomeTaxes',
  'otherTaxes' = 'otherTaxes',
  'profitOrLoss' = 'profitOrLoss',
  'nonCurrentAssets' = 'nonCurrentAssets',
  'intangibleAssets' = 'intangibleAssets',
  'startUpCosts' = 'startUpCosts',
  'researchCosts' = 'researchCosts',
  'developmentCosts' = 'developmentCosts',
  'intangibleRights' = 'intangibleRights',
  'goodwill' = 'goodwill',
  'otherCapitalisedLongtermCosts' = 'otherCapitalisedLongtermCosts',
  'nonCurrentAssets_advancePayments' = 'nonCurrentAssets_advancePayments',
  'tangibleAssets' = 'tangibleAssets',
  'landAndWaters' = 'landAndWaters',
  'buildings' = 'buildings',
  'machineryAndEquipment' = 'machineryAndEquipment',
  'otherTangibleAssets' = 'otherTangibleAssets',
  'advancePaymentsAndConstructionInProgress' = 'advancePaymentsAndConstructionInProgress',
  'nonCurrentAssets_investments' = 'nonCurrentAssets_investments',
  'nonCurrentAssets_holdingsInGroupMemberCompanies' = 'nonCurrentAssets_holdingsInGroupMemberCompanies',
  'receivablesFromGroupMemberCompanies' = 'receivablesFromGroupMemberCompanies',
  'participatingInterests' = 'participatingInterests',
  'receivablesFromParticipatingInterestCompanies' = 'receivablesFromParticipatingInterestCompanies',
  'nonCurrentAssets_otherSharesAndSimilarRightsOfOwnership' = 'nonCurrentAssets_otherSharesAndSimilarRightsOfOwnership',
  'otherReceivables' = 'otherReceivables',
  'nonCurrentAssets_ownSharesAndSimilarRightsOfOwnership' = 'nonCurrentAssets_ownSharesAndSimilarRightsOfOwnership',
  'currentAssets' = 'currentAssets',
  'stocks' = 'stocks',
  'rawMaterialsAndConsumables' = 'rawMaterialsAndConsumables',
  'semiFinishedProducts' = 'semiFinishedProducts',
  'finishedProductsOrGoods' = 'finishedProductsOrGoods',
  'otherStocks' = 'otherStocks',
  'currentAssets_advancePayments' = 'currentAssets_advancePayments',
  'receivables' = 'receivables',
  'longTermReceivables' = 'longTermReceivables',
  'longTerm_tradeReceivables' = 'longTerm_tradeReceivables',
  'longTerm_receivablesFromGroupMemberCompanies' = 'longTerm_receivablesFromGroupMemberCompanies',
  'longTerm_receivablesFromParticipatingInterestCompanies' = 'longTerm_receivablesFromParticipatingInterestCompanies',
  'longTerm_loanReceivables' = 'longTerm_loanReceivables',
  'longTerm_otherReceivables' = 'longTerm_otherReceivables',
  'longTerm_subscribedCapitalUnpaid' = 'longTerm_subscribedCapitalUnpaid',
  'longTerm_prepaymentsAndAccruedIncome' = 'longTerm_prepaymentsAndAccruedIncome',
  'longTerm_taxReceivables' = 'longTerm_taxReceivables',
  'shortTermReceivables' = 'shortTermReceivables',
  'shortTerm_tradeReceivables' = 'shortTerm_tradeReceivables',
  'shortTerm_receivablesFromGroupMemberCompanies' = 'shortTerm_receivablesFromGroupMemberCompanies',
  'shortTerm_receivablesFromParticipatingInterestCompanies' = 'shortTerm_receivablesFromParticipatingInterestCompanies',
  'shortTerm_loanReceivables' = 'shortTerm_loanReceivables',
  'shortTerm_otherReceivables' = 'shortTerm_otherReceivables',
  'shortTerm_subscribedCapitalUnpaid' = 'shortTerm_subscribedCapitalUnpaid',
  'shortTerm_prepaymentsAndAccruedIncome' = 'shortTerm_prepaymentsAndAccruedIncome',
  'shortTerm_taxReceivables' = 'shortTerm_taxReceivables',
  'currentAssets_investments' = 'currentAssets_investments',
  'currentAssets_holdingsInGroupMemberCompanies' = 'currentAssets_holdingsInGroupMemberCompanies',
  'currentAssets_ownSharesAndSimilarRightsOfOwnership' = 'currentAssets_ownSharesAndSimilarRightsOfOwnership',
  'currentAssets_otherSharesAndSimilarRightsOfOwnership' = 'currentAssets_otherSharesAndSimilarRightsOfOwnership',
  'otherInvestments' = 'otherInvestments',
  'cashInHandAndAtBanks' = 'cashInHandAndAtBanks',
  'balanceAssets' = 'balanceAssets',
  'equity' = 'equity',
  'tiedEquity' = 'tiedEquity',
  'subscribedEquity' = 'subscribedEquity',
  'emissionOfShares' = 'emissionOfShares',
  'sharePremiumAccount' = 'sharePremiumAccount',
  'revaluationReserve' = 'revaluationReserve',
  'otherReserves' = 'otherReserves',
  'freeEquity' = 'freeEquity',
  'svop' = 'svop',
  'retainedProfitOrLoss' = 'retainedProfitOrLoss',
  'freeEquity_profitOrLoss' = 'freeEquity_profitOrLoss',
  'freeEquity_subordinatedLoans' = 'freeEquity_subordinatedLoans',
  'appropriations' = 'appropriations',
  'cumulativeAcceleratedDepreciation' = 'cumulativeAcceleratedDepreciation',
  'untaxedReserves' = 'untaxedReserves',
  'provisions' = 'provisions',
  'provisionsForPensions' = 'provisionsForPensions',
  'provisionsForTaxation' = 'provisionsForTaxation',
  'otherProvisions' = 'otherProvisions',
  'creditors' = 'creditors',
  'longTermCreditors' = 'longTermCreditors',
  'longTermCreditors_subordinatedLoans' = 'longTermCreditors_subordinatedLoans',
  'longTermCreditors_bonds' = 'longTermCreditors_bonds',
  'longTermCreditors_convertibleBonds' = 'longTermCreditors_convertibleBonds',
  'longTermCreditors_loansFromCreditInstitutions' = 'longTermCreditors_loansFromCreditInstitutions',
  'longTermCreditors_pensionLoans' = 'longTermCreditors_pensionLoans',
  'longTermCreditors_advancesReceived' = 'longTermCreditors_advancesReceived',
  'longTermCreditors_tradeCreditors' = 'longTermCreditors_tradeCreditors',
  'longTermCreditors_amountsOwedToGroupMemberCompanies' = 'longTermCreditors_amountsOwedToGroupMemberCompanies',
  'longTermCreditors_amountsOwedToParticipatingInterestCompanies' = 'longTermCreditors_amountsOwedToParticipatingInterestCompanies',
  'longTermCreditors_taxCreditors' = 'longTermCreditors_taxCreditors',
  'longTermCreditors_otherCreditors' = 'longTermCreditors_otherCreditors',
  'longTermCreditors_accrualsAndDeferredIncome' = 'longTermCreditors_accrualsAndDeferredIncome',
  'shortTermCreditors' = 'shortTermCreditors',
  'shortTermCreditors_subordinatedLoans' = 'shortTermCreditors_subordinatedLoans',
  'shortTermCreditors_bonds' = 'shortTermCreditors_bonds',
  'shortTermCreditors_convertibleBonds' = 'shortTermCreditors_convertibleBonds',
  'shortTermCreditors_loansFromCreditInstitutions' = 'shortTermCreditors_loansFromCreditInstitutions',
  'shortTermCreditors_pensionLoans' = 'shortTermCreditors_pensionLoans',
  'shortTermCreditors_advancesReceived' = 'shortTermCreditors_advancesReceived',
  'shortTermCreditors_tradeCreditors' = 'shortTermCreditors_tradeCreditors',
  'shortTermCreditors_amountsOwedToGroupMemberCompanies' = 'shortTermCreditors_amountsOwedToGroupMemberCompanies',
  'shortTermCreditors_amountsOwedToParticipatingInterestCompanies' = 'shortTermCreditors_amountsOwedToParticipatingInterestCompanies',
  'shortTermCreditors_taxCreditors' = 'shortTermCreditors_taxCreditors',
  'shortTermCreditors_otherCreditors' = 'shortTermCreditors_otherCreditors',
  'shortTermCreditors_accrualsAndDeferredIncome' = 'shortTermCreditors_accrualsAndDeferredIncome',
  'balanceLiabilities' = 'balanceLiabilities',

  // Used in public template
  'operatingIncome' = 'operatingIncome',
  'salesProceeds' = 'salesProceeds',
  'feeIncome' = 'feeIncome',
  'subsidiesAndGrants' = 'subsidiesAndGrants',
  'public_otherOperatingIncome' = 'public_otherOperatingIncome',
  'rentalIncome' = 'rentalIncome',
  'otherIncome' = 'otherIncome',
  'subsidiesAndGrantsFromMunicipality' = 'subsidiesAndGrantsFromMunicipality',
  'changeInInventory' = 'changeInInventory',
  'operatingExpenses' = 'operatingExpenses',
  'servicesPurchases' = 'servicesPurchases',
  'customerServicePurchases' = 'customerServicePurchases',
  'otherServicePurchases' = 'otherServicePurchases',
  'grants' = 'grants',
  'householdGrants' = 'householdGrants',
  'public_otherOperatingExpenses' = 'public_otherOperatingExpenses',
  'rentalExpenses' = 'rentalExpenses',
  'otherExpenses' = 'otherExpenses',
  'profitOrLossOfAssociatedEntities' = 'profitOrLossOfAssociatedEntities',
  'profitMargin' = 'profitMargin',
  'taxRevenues' = 'taxRevenues',
  'governmentTransfers' = 'governmentTransfers',
  'governmentTransfers_municipalitysBasicServices' = 'governmentTransfers_municipalitysBasicServices',
  'governmentTransfers_educationalAndCulturalActivity' = 'governmentTransfers_educationalAndCulturalActivity',
  'governmentTransfers_other' = 'governmentTransfers_other',
  'compensationForLossOfTaxRevenue' = 'compensationForLossOfTaxRevenue',
  'interestIncome' = 'interestIncome',
  'otherFinancialIncome' = 'otherFinancialIncome',
  'interestExpenses' = 'interestExpenses',
  'otherFinancingCosts' = 'otherFinancingCosts',
  'annualContributionMargin' = 'annualContributionMargin',
  'plannedDepreciations' = 'plannedDepreciations',
  'ownershipEliminationDifferences' = 'ownershipEliminationDifferences',
  'groupGoodwillDepreciationAndGroupReserveReduction' = 'groupGoodwillDepreciationAndGroupReserveReduction',
  'impairments' = 'impairments',
  'occasionalIncomeAndExpenses' = 'occasionalIncomeAndExpenses',
  'occasionalIncome' = 'occasionalIncome',
  'occasionalExpenses' = 'occasionalExpenses',
  'depreciationDifferenceChange' = 'depreciationDifferenceChange',
  'reservationsChange' = 'reservationsChange',
  'fundsChange' = 'fundsChange',
  'taxesForTheFinancialYear' = 'taxesForTheFinancialYear',
  'deferredTaxes' = 'deferredTaxes',
  'minorityInterests' = 'minorityInterests',
  'financialYearSurplusOrDeficit' = 'financialYearSurplusOrDeficit',
  'computerSoftwares' = 'computerSoftwares',
  'otherIntangibleRights' = 'otherIntangibleRights',
  'groupGoodwill' = 'groupGoodwill',
  'landAndWaters_includeValueIncreases' = 'landAndWaters_includeValueIncreases',
  'landAndWaters_withoutValueIncreases' = 'landAndWaters_withoutValueIncreases',
  'propertyConnectionFees' = 'propertyConnectionFees',
  'propertyRentalRightsAndConnectionFees' = 'propertyRentalRightsAndConnectionFees',
  'landAndWaters_valueIncreases' = 'landAndWaters_valueIncreases',
  'residentialBuildings' = 'residentialBuildings',
  'otherBuildings' = 'otherBuildings',
  'fixedStructuresAndEquipments' = 'fixedStructuresAndEquipments',
  'meansOfTransport' = 'meansOfTransport',
  'computerEquipments' = 'computerEquipments',
  'communicationDevices' = 'communicationDevices',
  'otherMachinesAndEquipments' = 'otherMachinesAndEquipments',
  'naturalResources' = 'naturalResources',
  'valuableAndArtObjects' = 'valuableAndArtObjects',
  'otherCommodities' = 'otherCommodities',
  'incompletePurchases' = 'incompletePurchases',
  'partnershipShares' = 'partnershipShares',
  'affiliatedCompanyShares' = 'affiliatedCompanyShares',
  'jointVentureShares' = 'jointVentureShares',
  'nonCurrentAssets_shares' = 'nonCurrentAssets_shares',
  'sharesInSubsidiaries' = 'sharesInSubsidiaries',
  'housingSharesInSubsidiaries' = 'housingSharesInSubsidiaries',
  'municipalAssociationShares' = 'municipalAssociationShares',
  'basicCapitalSharesOfBusinessEstablishments' = 'basicCapitalSharesOfBusinessEstablishments',
  'basicCapitalSharesOfOtherBalanceSheetUnits' = 'basicCapitalSharesOfOtherBalanceSheetUnits',
  'otherHousingShares' = 'otherHousingShares',
  'increasesInTheValueOfShares' = 'increasesInTheValueOfShares',
  'nonCurrentAssets_bondReceivables' = 'nonCurrentAssets_bondReceivables',
  'otherLoanReceivables' = 'otherLoanReceivables',
  'capitalLoanReceivables' = 'capitalLoanReceivables',
  'otherLoanReceivables2' = 'otherLoanReceivables2',
  'otherReceivables2' = 'otherReceivables2',
  'refundableJoiningFees' = 'refundableJoiningFees',
  'assignmentFunds' = 'assignmentFunds',
  'governmentMandates' = 'governmentMandates',
  'balanceAssets_brokeredGovernmentLoans' = 'balanceAssets_brokeredGovernmentLoans',
  'otherGovernmentMandates' = 'otherGovernmentMandates',
  'endowmentFundsAssets' = 'endowmentFundsAssets',
  'otherAssignmentAssets' = 'otherAssignmentAssets',
  'longTerm_municipalityReceivables' = 'longTerm_municipalityReceivables',
  'longTerm_deferredTaxAssets' = 'longTerm_deferredTaxAssets',
  'longTerm_transferredInterest' = 'longTerm_transferredInterest',
  'longTerm_otherTransferReceivables' = 'longTerm_otherTransferReceivables',
  'shortTerm_municipalityReceivables' = 'shortTerm_municipalityReceivables',
  'shortTerm_deferredTaxAssets' = 'shortTerm_deferredTaxAssets',
  'shortTerm_vatReceivables' = 'shortTerm_vatReceivables',
  'shortTerm_transferredInterest' = 'shortTerm_transferredInterest',
  'shortTerm_otherTransferReceivables' = 'shortTerm_otherTransferReceivables',
  'currentAssets_shares' = 'currentAssets_shares',
  'investmentsInMoneyMarketInstruments' = 'investmentsInMoneyMarketInstruments',
  'investmentsInGovernmentBonds' = 'investmentsInGovernmentBonds',
  'investmentsInMunicipalCertificates' = 'investmentsInMunicipalCertificates',
  'investmentsInOtherMoneyMarketInstruments' = 'investmentsInOtherMoneyMarketInstruments',
  'currentAssets_bondReceivables' = 'currentAssets_bondReceivables',
  'totalFundShares' = 'totalFundShares',
  'basicCapital' = 'basicCapital',
  'basicCapitalOfFoundationsAndAssociations' = 'basicCapitalOfFoundationsAndAssociations',
  'appreciationFund' = 'appreciationFund',
  'otherOwnFunds' = 'otherOwnFunds',
  'surplusOrDeficitOfPreviousFinancialYears' = 'surplusOrDeficitOfPreviousFinancialYears',
  'surplusOrDeficitForPeriod' = 'surplusOrDeficitForPeriod',
  'balanceLiabilities_minorityInterests' = 'balanceLiabilities_minorityInterests',
  'balanceLiabilities_minorityInterests2' = 'balanceLiabilities_minorityInterests2',
  'depreciationDifferenceAndVoluntaryReservations' = 'depreciationDifferenceAndVoluntaryReservations',
  'voluntaryReservations' = 'voluntaryReservations',
  'capitalsOfAssignments' = 'capitalsOfAssignments',
  'governmentAssignments' = 'governmentAssignments',
  'balanceLiabilities_toBeBrokeredGovernmentLoans' = 'balanceLiabilities_toBeBrokeredGovernmentLoans',
  'balanceLiabilities_brokeredGovernmentLoans' = 'balanceLiabilities_brokeredGovernmentLoans',
  'otherCapitalOfGovernmentMandates' = 'otherCapitalOfGovernmentMandates',
  'capitalsOfEndowmentFunds' = 'capitalsOfEndowmentFunds',
  'otherAssignmentCapitals' = 'otherAssignmentCapitals',
  'groupReserve' = 'groupReserve',
  'groupReserve2' = 'groupReserve2',
  'longTermInterestBearingDebt' = 'longTermInterestBearingDebt',
  'longTermCreditors_loansFromFinancialAndInsuranceInstitutions' = 'longTermCreditors_loansFromFinancialAndInsuranceInstitutions',
  'longTermCreditors_loansFromTheMunicipality' = 'longTermCreditors_loansFromTheMunicipality',
  'longTermCreditors_loansFromPublicEntities' = 'longTermCreditors_loansFromPublicEntities',
  'longTermCreditors_loansFromOtherLenders' = 'longTermCreditors_loansFromOtherLenders',
  'longTermInterestFreeDebtCapital' = 'longTermInterestFreeDebtCapital',
  'longTermCreditors_interestFreeDebtsFromTheMunicipality' = 'longTermCreditors_interestFreeDebtsFromTheMunicipality',
  'longTermCreditors_joiningFeesAndOtherDebts' = 'longTermCreditors_joiningFeesAndOtherDebts',
  'longTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts' = 'longTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts',
  'longTermCreditors_joiningFeesAndOtherDebts2' = 'longTermCreditors_joiningFeesAndOtherDebts2',
  'shortTerm' = 'shortTerm',
  'shortTermInterestBearingDebt' = 'shortTermInterestBearingDebt',
  'repaymentsOfLongTermLoans_bonds' = 'repaymentsOfLongTermLoans_bonds',
  'shortTermCreditors_loansFromFinancialAndInsuranceInstitutions' = 'shortTermCreditors_loansFromFinancialAndInsuranceInstitutions',
  'repaymentsOfLongTermLoans_loansFromFinancialAndInsuranceInstitutions' = 'repaymentsOfLongTermLoans_loansFromFinancialAndInsuranceInstitutions',
  'shortTermCreditors_loansFromTheMunicipality' = 'shortTermCreditors_loansFromTheMunicipality',
  'repaymentsOfLongTermLoans_loansFromTheMunicipality' = 'repaymentsOfLongTermLoans_loansFromTheMunicipality',
  'shortTermCreditors_loansFromPublicEntities' = 'shortTermCreditors_loansFromPublicEntities',
  'repaymentsOfLongTermLoans_loansFromPublicEntities' = 'repaymentsOfLongTermLoans_loansFromPublicEntities',
  'shortTermCreditors_loansFromOtherLenders' = 'shortTermCreditors_loansFromOtherLenders',
  'repaymentsOfLongTermLoans_loansFromOtherLenders' = 'repaymentsOfLongTermLoans_loansFromOtherLenders',
  'shortTermInterestFreeDebtCapital' = 'shortTermInterestFreeDebtCapital',
  'shortTermCreditors_interestFreeDebtsFromTheMunicipality' = 'shortTermCreditors_interestFreeDebtsFromTheMunicipality',
  'shortTermCreditors_joiningFeesAndOtherDebts' = 'shortTermCreditors_joiningFeesAndOtherDebts',
  'shortTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts' = 'shortTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts',
  'shortTermCreditors_vatDebt' = 'shortTermCreditors_vatDebt',
  'shortTermCreditors_connectionFeeDebts' = 'shortTermCreditors_connectionFeeDebts',
  'transferredInterest' = 'transferredInterest',
  'periodizationOfHolidayPay' = 'periodizationOfHolidayPay',
  'otherAccruedLiabilities' = 'otherAccruedLiabilities',
  'deferredTaxLiabilities' = 'deferredTaxLiabilities',
}

export enum AccountClassKey {
  'F8-manualExports' = 'F8-manualExports',
  'G-management' = 'G-management',
  'G4.4-innerCircle' = 'G4.4-innerCircle',
  'J1-turnover' = 'J1-turnover',
  'J2-accountsReceivable' = 'J2-accountsReceivable',
  'J-operatingIncome' = 'J-operatingIncome',
  'JB-otherOperatingIncome' = 'JB-otherOperatingIncome',
  'K-materialsAndServices' = 'K-materialsAndServices',
  'K1-materialsAndServices' = 'K1-materialsAndServices',
  'K2-tradeCreditors' = 'K2-tradeCreditors',
  'KB-otherOperatingExpenses' = 'KB-otherOperatingExpenses',
  'L-personelCosts' = 'L-personelCosts',
  'M-governmentTransfersAndTaxRevenues' = 'M-governmentTransfersAndTaxRevenues',
  'N-taxes' = 'N-taxes',
  'O1-fixedAssets' = 'O1-fixedAssets',
  'O4-funding' = 'O4-funding',
  'P-inventories' = 'P-inventories',
  'P1-warehouses' = 'P1-warehouses',
  'Q1-otherReceivables' = 'Q1-otherReceivables',
  'Q2-cashEquivalents' = 'Q2-cashEquivalents',
  'Q3-loanReceivables'= 'Q3-loanReceivables',
  'R1-ownCapital' = 'R1-ownCapital',
  'R2-capitalLoans' = 'R2-capitalLoans',
  'S1-financialInstitutions' = 'S1-financialInstitutions',
  'T-otherCreditors' = 'T-otherCreditors',
  'T1-otherLiabilities' = 'T1-otherLiabilities',
  'T2-advancePayments' = 'T2-advancePayments',
  'U-assignmentAssetsAndLiabilities' = 'U-assignmentAssetsAndLiabilities',
  'XJ-financialIncome' = 'XJ-financialIncome',
  'XK-financialExpenses' = 'XK-financialExpenses',
  'V-reserves' = 'V-reserves',
  'O3-investments' = 'O3-investments'
}
