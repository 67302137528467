import { Sheet } from '@fortune-sheet/core/dist/types';
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";
import { useCallback, useState } from 'react';
import { getWindowDimensions } from '../../../../utils';


type CustomSheet = {
    name?: string;
    color?: string,
    column?: number;
    row?: number;
    showGridLines?: boolean;
    cellHeight?: number,
    cellWidth?: number,
    cellData?: any[],
}

type Cell = {
    bold?: any, 
    italic?: boolean,
    contains: string|number,
    fontSize?: number,
    fontColor?: "blue"|"green"|"red"|"yellow"|"black",
    horizontalAlign?: "left"|"center"|"right",
    verticalAlign?: "center"|"bottom"|"top",
    initialValue?: number;
    bg?: string;

}

type LibraryCell = { // cell
    r: number;
    c: number;
    v: LibraryCellV;
}


type LibraryCellV = { // cell value
    ct: {fa?: string, t?:string};
    v: string;
    m: string;
}

/*
Here is an example of what the data should look like in the formstate

    sheetId1:[{ // this is the sheet id
        cellData: [ // this specifies what we want to be contained by default in the sheet, this can be left empty.
            {
                row: 2,
                column: 1,
                cellData:{
                    contains: "=B2",
                    initialValue: 1,
                    bg: "red",
                    bold: 1,
                    fontColor: "white",
                    italic: true
                } 
            },
        ],
    }]

And here is what the component would look for that data:
    <SpreadSheet id="sheetId1" {...props} />
*/


function generateSheet(customSheetSettings: CustomSheet, id: string): Sheet{
    /*
        Converts sheetdata from easy format to format of fortunesheet
    */

    const calcChainsArray: { r: any; c: any; id: string; }[] = [];

    customSheetSettings.cellData?.forEach(item => typeof item.cellData.contains === "string" && item.cellData.contains.includes("=") && // only proceed if contains a formula
    // for each cell that contains a formula, add a calcChain object to calcChainsArray.
    // the calcChain object ensures that when a cell that a formula uses updates, the cell with the formula will also recalculate the formula.
    // add regex to better check this
        calcChainsArray.push({
            r: item.row,
            c: item.column,
            id: id
        })
    )

    function generateCell(row: number, column: number, cellContent: Cell){
        // using cellContent format the data so that FS can understand it
        const isFormula = typeof cellContent.contains === "string" && cellContent.contains.includes("=");

        const cell = cellContent ? {
            ct:{
                fa: 'General',
                t: typeof cellContent.contains === "string" ? 'n' : 'g'
            },
            v: cellContent.hasOwnProperty("initialValue") ? cellContent.initialValue : cellContent.contains, // real value
            m: cellContent.hasOwnProperty("initialValue") ? cellContent.initialValue!.toString() : cellContent.contains.toString(), // display value (string)
            f: isFormula ? cellContent.contains.toString() : "", // formula
            bg: cellContent.bg ? cellContent.bg : "white", // background color
            bl: cellContent.bold ? cellContent.bold : 0, // boldness
            fc: cellContent.fontColor ? cellContent.fontColor : "black", // font-color
            it: cellContent.italic ? 1 : 0, // italic
        }: null;
    
        return {
            r: row,
            c: column,
            v: cell 
        }
    }

    const defaultSheetSettings = {
        id: id,
        name: "Taulukko 1", 
        color: "green", 
        status: 1, 
        order: 0, 
        hide: 0,
        row: 10, 
        column: 10, 
        defaultRowHeight: 19, 
        defaultColWidth: 73, 
        celldata: customSheetSettings.cellData?.map(item => generateCell(item.row, item.column, item.cellData)),
        calcChain: calcChainsArray,
        showGridLines: 1,
        zoomRatio: 1.1,
        wasConverted: true
    }



    const generatedObject = {
        ...defaultSheetSettings,
        defaultRowHeight: customSheetSettings.cellHeight,
        defaultColWidth: customSheetSettings.cellWidth,
        ...customSheetSettings // any setting specified here overwrites the default setting
    }

    
    delete generatedObject.cellData; // cellData was converted to celldata which is used, so we can delete cellData

    return generatedObject;
}

export function SpreadSheet({
        patchFormState,
        formState,
        disabled,
        id,
        title,
        customHeight,
        customWidth
    }: {
        patchFormState: Function;
        formState: {[key: string]: any};
        disabled?: boolean;
        id: string;
        title?: string;
        customHeight?: number
        customWidth?: number
    }){
        const [data] = useState(formState[id]);
        const indexOfSheet = 0


        const handleEdit = useCallback((operations: any[]) => {
            const data = formState[id];

            if(!data[indexOfSheet].hasOwnProperty("wasConverted")){
                /*
                    Here we ensure that the data the user has given will be converted to format that fortunesheet uses 
                */
                data[indexOfSheet] = generateSheet(data[indexOfSheet],id);
                
                patchFormState({
                    ...formState,
                    [id]: data,
                })
            }

            operations.forEach(operation => {
                // if we add multi sheet functionality this needs to be changed, multi sheet functionality is by default in FS


                let newData = data[indexOfSheet];

                if(operation.path[0] === "data"){// if operation specifies that data should be modified
                    const [column, row] = [operation.path[2], operation.path[1]]

                    // get which cell to edit
                    const previouslyOccupiedIndex = newData.celldata.findIndex((cell: {r:number, c:number}) =>  cell.r === row && cell.c === column);

                    let newCellObject = {} as LibraryCell;

                    
                    if(operation.path.length === 4){
                        // modify value of the cell(key is specified by operaration.path[3])

                        let newV = {...newData.celldata[previouslyOccupiedIndex].v};

                        newV[operation.path[3]] = operation.value;

                        newCellObject = {
                            r: row,
                            c: column,
                            v: newV
                        };
                    }else{
                        // if key to edit is not specified, update the whole value.
                        newCellObject = {
                            r: row,
                            c: column,
                            v: operation.value
                        };
                    }

                    if(previouslyOccupiedIndex !== -1){
                        // data existed on this cell before, update the cell
                        newData.celldata[previouslyOccupiedIndex] = newCellObject
                    }else{
                        // data did not exist on this cell before, append the cell
                        newData.celldata.push(newCellObject)
                    }
                }else if(operation.path[0] === "calcChain"){// if operation specifies that we want to modify the calcChain
                    if(newData.hasOwnProperty("calcChain")){
                        const index = newData.calcChain.findIndex((chain: any) => chain.r && chain.c && chain.c === operation.c && chain.r === operation.r)
                        if(index === -1){// if the calcChain did not previously exist, add it
                            if(operation.value.length > 0){// for some reason sometimes calcChain comes as a index of an array, sometimes it doesnt.
                                newData.calcChain = [...newData.calcChain, {
                                    ...operation.value[0],
                                    id: id// for some reason here the id doesnt automatically get set
                                }]
                            }else{
                                newData.calcChain = [...newData.calcChain, operation.value]
                            }
                        }else{// if the calcChain did previously exist, modify the calcChain object
                            newData.calcChain[index] = operation.value[0];
                        }
                    }else{
                        // if the calcChain key doesnt exist, add it
                        newData.calcChain = [operation.value[0]]
                    }
                }
                data[indexOfSheet] = newData;// update the newData to the wanted sheet
            
            })


            patchFormState({
                ...formState,
                [id]: data,
            })
            // eslint-disable-next-line
        }, [])

        return(
            <div style={{width: customWidth ? customWidth : getWindowDimensions().width*0.6, height: customHeight ? customHeight : 400}}>
                {title ? <h2>{title}</h2> : null}
                <Workbook 
                    lang="en"
                    data={data}
                    onOp={handleEdit}
                    allowEdit={disabled === true ? false : true} // dont allow editing if spreadsheet disabled
                    showSheetTabs={false}// If we add multi sheet functionality, this needs to then be enabled
                />
            </div>
        )
    }

