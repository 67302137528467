import React, { useEffect, useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';
import { generateAccountMap, getAllAccountMapRowsMatchingClassKey } from '../../analyzes/accountMapUtils';

interface Props {
  classKey: AccountClassKey;
  auditing?: Auditing;
  addCommentField?: boolean;
  formState: any;
  patchFormState: Function;
  showChange?: boolean
}
    
const O1Form2: React.FC<Props> = ({ auditing, classKey, addCommentField, formState, patchFormState, showChange }) => {
  /*
    Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        O1Form2Data: {},
      })
  */

  const [data, setData] = useState(
    [
      {
        label: 'Käyttöomaisuuskirjanpito alkusaldo ',
        value: 0,
      },
      {
        label: 'Kirjanpidon alkusaldo',
        value: 0,
      },
      {
        label: 'Käyttöomaisuuskirjanpito loppusaldo',
        value: 0,
      },
      {
        label: 'Kirjanpito loppusaldo ',
        value: 0,
      },
    ]
  )
  

  useEffect(() => {
    formState && setData(formState.O1Form2Data)
  },[formState])



  function handleEdit(props:any) {
    var duplicateFormState = [...formState.O1Form2Data];
    duplicateFormState.find(item => item.id === props.itemId).value = props.value
    patchFormState({
      ...formState,
      O1Form2Data: duplicateFormState
    })
  }

  var tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'width-min',
      header: () => null,
    },
    {
      accessorKey: 'value',
      header: () => null,
      className: 'width-min text-center nowrap',
      onNumberEdit: handleEdit
    }
  ];

  return (
    <>
      <AuditingTable
        data={data.slice(0,2)}
        columns={tableColumns}
        showGlobalFilter={false}
      />
      <p style={{margin: 5}}><b>Erotus: {data[0].value - data[1].value } </b></p>
      <AuditingTable
        data={data.slice(2,4)}
        columns={tableColumns}
        showGlobalFilter={false}
      />
      <p style={{margin: 5}}><b>Erotus: {data[2].value - data[3].value } </b></p>

    </>
  );
};


function getInitialValues(auditing:any, classKey:any){
  const auditingTemplate = auditing?.template;
  const auditingAccountMap = auditing?.accountMap?.form.accountMap;
  const auditingGeneralLedger =
    auditing?.generalLedger?.form.groupedGeneralLedger;

  const accountMap =
    !auditingAccountMap && auditingTemplate
      ? generateAccountMap(auditingTemplate)
      : auditingAccountMap;

  if (!accountMap) return null;

  // Get all rows from the account map that match the class key
  const accountsMapRows = getAllAccountMapRowsMatchingClassKey(
    accountMap,
    classKey
  );

  var data: any[] = [

  ]

      // Filter the general ledger to only include rows that match the account map rows
    const procedureAccountRowsPreData = auditingGeneralLedger?.filter(({account}:any ) => {
      const hasMatchingAccountClass = !!accountsMapRows.find(
        ({ start, end }) => account >= start && account <= end
      );
      return hasMatchingAccountClass;
    });


    if(procedureAccountRowsPreData){
      for(var i=0; i<procedureAccountRowsPreData?.length; i++){
        var object = procedureAccountRowsPreData[i]

        const undefinedHandler = (item:number|undefined) => {
          if(item === undefined){
            return 0
          }else return item
        }
        object.changeInNumbers = undefinedHandler(object.priorYear) - undefinedHandler(object.currentYear);
        object.changeInPercent = Math.round(((undefinedHandler(object.currentYear)-undefinedHandler(object.priorYear))/undefinedHandler(object.priorYear))*100)
        object.deletion = object.changeInNumbers;
        object.addition = 0;
        object.subtraction = 0;
        object.removalPercent = (object.priorYear + object.addition - object.subtraction)/object.priorYear
        if(object.currentYear === undefined || object.priorYear === undefined){
          object.changeInPercent = undefined
        }else{
          object.changeInPercent = Math.round(((object.currentYear-object.priorYear)/object.priorYear)*100)
        }
      
        data.push(object)
      }
    }
  return data
}


export { O1Form2, getInitialValues };

