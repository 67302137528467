import { useTranslation } from 'react-i18next';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

type FormType = ManagementForm;
type TableItemType = {
  label: string;
  value: string;
  upToDate: boolean | null;
  source: string;
};
type DataKey = keyof ManagementForm;

const sectionKey: AuditingSectionKey = 'management';

interface Props extends FormFieldProps<FormType> {
  titleAccessor?: string;
  hintAccessor?: string;
  visibleData: DataKey[];
}

export const PerceptionInformation = ({
  titleAccessor,
  hintAccessor,
  visibleData,
  formState,
  patchFormState,
}: Props) => {
  const { t } = useTranslation();

  const tBase = `auditing:form.${sectionKey}`;

  const data: TableItemType[] = visibleData.map(key => {
    const upToDateKey = `${key}UpToDate` as DataKey;
    const sourceKey = `${key}Source` as DataKey;
    return {
      id: key,
      label: t(`${tBase}.${key}`),
      value: `${formState[key] ?? ''}`,
      upToDate:
        typeof formState[upToDateKey] === 'boolean'
          ? !!formState[upToDateKey]
          : null,
      source: `${formState[sourceKey] ?? ''}`,
    };
  });

  const getFieldKey = (itemId: DataKey, columnId: string) => {
    const isSourceField = columnId === 'source';
    const isUpToDateField = columnId === 'upToDate';

    const makeKey = (key: DataKey) => {
      if (isSourceField) return `${key}Source`;
      if (isUpToDateField) return `${key}UpToDate`;
      return key;
    };

    return makeKey(itemId);
  };

  function handleEdit<T>({ itemId, columnId, value }: OnEditProps<T>) {
    const fieldKey = getFieldKey(itemId as DataKey, columnId);
    const patch = {
      ...formState,
      [fieldKey]: value,
    };
    patchFormState({ ...formState, ...patch });
  }

  const tableColumns: TableColumnDefs<TableItemType> = [
    {
      accessorKey: 'label',
      header: () => '',
      className: 'width-max text-bold',
    },
    {
      accessorKey: 'upToDate',
      className: 'width-min text-center',
      header: () => t(`${tBase}.upToDate`),
      contextInfoProps: {
        position: 'top-left',
        text: hintAccessor
          ? t(`${tBase}.${hintAccessor}`)
          : t(`${tBase}.upToDateInfo`),
      },
      showRemark: (item: TableItemType) =>
        typeof item.upToDate === 'boolean' ? !item.upToDate : false,
      selectProps: {
        options: ['false', 'true'],
        placeholder: 'N/A',
        clearable: true,
        displayValue: (option: string) =>
          t(`auditing:form.management.upToDateOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) => {
          const { value } = editProps;
          handleEdit<boolean | null>({
            ...editProps,
            value: value === 'true' ? true : value === 'false' ? false : null,
          });
        },
      },
    },
    {
      accessorKey: 'source',
      header: () => t(`${tBase}.source`),
      className: 'width-min nowrap',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    },
    {
      accessorKey: 'value',
      header: () => t(`${tBase}.value`),
      className: 'width-max',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable<TableItemType>
      title={titleAccessor && t(`auditing:form.management.${titleAccessor}`)}
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
