import { QualificationLevel, Role } from '../../utils';
import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'user',
  me: 'user/me',
  roles: 'roles',
  qualificationLevels: 'qualification_levels',
};

export class UserApi extends ModuleBaseApi<User> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }

  async getRoles() {
    return await this.api.get<Role[]>(URL.roles);
  }

  async getQualificationLevels() {
    return await this.api.get<QualificationLevel[]>(URL.qualificationLevels);
  }

  async getMe() {
    return await this.api.get<User>(URL.me);
  }

  async updateMe(data: Partial<User & { currentPassword?: string }>) {
    return await this.api.patch<User>(URL.me, data);
  }
}
