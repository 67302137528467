import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/RootContext';
import { Colors } from '../../../theme';
import { formatCurrency } from '../../../utils';
import { makeEmptyBalanceComparison } from '../../../views/auditingSections/auditing/balanceComparisonUtils';
import { Checkbox, TextArea } from '../../inputs';
import AuditingTable from '../../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../table/Table';

interface Props {
  formState: BalanceComparisonForm;
  patchForm: (patch: Partial<BalanceComparisonForm>) => void;
}

function BalanceMatching({ formState, patchForm }: Props) {
  const { t: _t } = useTranslation('auditing');

  const t = (key: string) =>
    _t(`form.auditingProcedures.balanceMatching.${key}`);

  const {
    auditingStore: { materialityComputeds },
  } = useStore();

  const singleErrorMateriality = materialityComputeds().singleErrorMateriality;

  const { useCalculationBase, balanceComparisons, noCalculationBaseReason } =
    formState;

  function handleEdit<T>({ columnId, itemId, value }: OnEditProps<T>) {
    patchForm({
      balanceComparisons: balanceComparisons.map(balanceComparison => {
        if (balanceComparison.id !== itemId) return balanceComparison;
        return { ...balanceComparison, [columnId]: value };
      }),
    });
  }

  const data = balanceComparisons.map(balanceComparison => {
    const hasBalanceValues =
      balanceComparison.balanceInLedgerOrSeparateAccounting !== null &&
      balanceComparison.balanceInGeneralLedger !== null &&
      singleErrorMateriality !== undefined;

    const balanceDifference =
      (balanceComparison.balanceInLedgerOrSeparateAccounting ?? 0) -
      (balanceComparison.balanceInGeneralLedger ?? 0);

    return {
      ...balanceComparison,
      balanceDifference,
      bgColor:
        hasBalanceValues && Math.abs(balanceDifference) < singleErrorMateriality
          ? Colors.green
          : undefined,
    };
  });

  const tableColumns: TableColumnDefs<
    BalanceComparison & { balanceDifference: number }
  > = [
    {
      id: 'label',
      accessorKey: 'label',
      className: 'width-full',
      header: () => null,
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      id: 'balanceInLedgerOrSeparateAccounting',
      accessorKey: 'balanceInLedgerOrSeparateAccounting',
      header: () => t('balanceInLedgerOrSeparateAccounting'),
      className: 'width-min text-center',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      id: 'balanceInGeneralLedger',
      accessorKey: 'balanceInGeneralLedger',
      header: () => t('balanceInGeneralLedger'),
      className: 'width-min text-center',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      id: 'balanceDifference',
      accessorKey: 'balanceDifference',
      accessorFn: ({ balanceDifference }) => formatCurrency(balanceDifference),
      header: () => t('balanceDifference'),
      className: 'width-min text-center nowrap',
    },
  ];

  return (
    <div>
      <Checkbox
        label={t('useCalculationBase')}
        checked={useCalculationBase}
        onChange={e => patchForm({ useCalculationBase: e.target.checked })}
      />

      {useCalculationBase && (
        <AuditingTable
          sectionKey="personelCosts"
          data={data}
          columns={tableColumns}
          showGlobalFilter={false}
          disableSort
          onAddNewRow={() =>
            patchForm({
              balanceComparisons: [
                ...balanceComparisons,
                makeEmptyBalanceComparison(),
              ],
            })
          }
          onRowDelete={({ id }) =>
            patchForm({
              balanceComparisons: balanceComparisons.filter(
                balanceComparison => balanceComparison.id !== id
              ),
            })
          }
        />
      )}

      {!useCalculationBase && (
        <TextArea
          label={t('matchingPerformedInAnotherWay')}
          placeholder={t('matchingPerformedInAnotherWayInfo')}
          value={noCalculationBaseReason}
          onChange={e => patchForm({ noCalculationBaseReason: e.target.value })}
          fullWidth
        />
      )}
    </div>
  );
}

export default BalanceMatching;
