import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { TableColumnDefs } from '../table/Table';

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalanceScoping';

interface TableItem {
  key: 'assetsLiabilitiesDifference' | 'turnover';
  label: string;
  value?: number;
}

export const IncomeStatementAndBalanceComputeds = ({
  store,
}: FormFieldProps<IncomeStatementAndBalanceScopingForm>) => {
  const { t } = useTranslation();

  const { getFinancialNumberScoping } = store.auditingStore;

  const tBase = `auditing:form.${sectionKey}`;

  const tableColumns: TableColumnDefs<TableItem> = [
    {
      accessorKey: 'label',
    },
    {
      accessorKey: 'value',
      cell: ({ row: { original: row } }) => formatCurrency(row.value),
      className: 'text-right nowrap',
      showRemark: ({ key }: TableItem) =>
        store.auditingStore.isMarkedAsRemark(sectionKey, key),
    },
  ];

  const rows: TableItem[] = [
    {
      key: 'assetsLiabilitiesDifference',
      label: t(`${tBase}.assetsLiabilitiesDifference`).toUpperCase(),
      value: getFinancialNumberScoping('assetsLiabilitiesDifference'),
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      data={rows}
      columns={tableColumns}
      showGlobalFilter={false}
      hideHeader
      disableSort
    />
  );
};
