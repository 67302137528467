import { PopoverPosition } from '../../components/Popover';
import { t } from '../../i18n';

/**
 * Helper class for creating different form fields
 *
 *
 */
export class FormFieldBuilder<FormType> {
  sectionKey: AuditingSectionKey;
  tBase: string;

  constructor(props: { sectionKey: AuditingSectionKey; tBase?: string }) {
    this.sectionKey = props.sectionKey;
    this.tBase = props.tBase ?? `auditing:form.${props.sectionKey}`;
  }

  private getInfoText(accessor?: keyof FormType) {
    return t(`${this.tBase}.${accessor as string}Info`);
  }

  private getContextInfo(
    position?: PopoverPosition,
    accessor?: keyof FormType
  ) {
    if (!position) return undefined;
    return {
      position,
      text: this.getInfoText(accessor),
    };
  }

  /**
   * Plain text
   *
   *
   */
  public text(props: TextBuilder): FormField<FormType> {
    const text =
      props.text ?? props.textKey
        ? t(`${this.tBase}.${props.textKey}`)
        : undefined;

    return { type: 'text', text, ...props };
  }

  /**
   * Plain subtitle text
   *
   *
   */
  public subtitle(props: TitleBuilder): FormField<FormType> {
    const title =
      props.title ?? props.titleKey
        ? t(`${this.tBase}.${props.titleKey}`)
        : undefined;

    return { type: 'subtitle', title, ...props };
  }

  /**
   * Either text input or text area
   * This is PRIVATE, use textInput or textArea instead.
   *
   */
  private textField(
    type: 'textInput' | 'textArea' | 'numberInput',
    props: TextFieldBuilder<FormType>
  ) {
    const { accessor, showContextInfo } = props;
    return {
      type,
      contextInfo: showContextInfo
        ? this.getContextInfo(showContextInfo, accessor)
        : undefined,
      placeholder: showContextInfo ? this.getInfoText(accessor) : undefined,
      ...props,
    };
  }

  /**
   * Text input
   *
   *
   */
  public textInput(props: TextFieldBuilder<FormType>): FormField<FormType> {
    // props: 'value' optional for text input and display this value in the input field
    return this.textField('textInput', props);
  }

  /**
   * Text area
   *
   *
   */
  public textArea(props: TextFieldBuilder<FormType>): FormField<FormType> {
    return this.textField('textArea', props);
  }

  /**
   * Number input
   *
   *
   */
  public numberInput(props: TextFieldBuilder<FormType>): FormField<FormType> {
    return this.textField('numberInput', props);
  }

  /**
   * Date input
   *
   *
   */
  public dateField(props: DateFieldBuilder<FormType>): FormField<FormType> {
    return {
      type: 'dateInput',
      ...props,
    };
  }

  /**
   * Boolean field,
   * with optional details field (text area)
   *
   */
  public boolean(props: BooleanBuilder<FormType>): FormField<FormType> {
    const {
      plainLabel,
      accessor,
      hidden,
      hiddenInTemplate,
      disabled,
      showContextInfo,
      options,
      detailsAccessor,
    } = props;

    const groupItems: FormField<FormType>[] = [
      {
        plainLabel,
        accessor,
        hidden,
        hiddenInTemplate,
        disabled,
        type: 'boolean',
        contextInfo: this.getContextInfo(showContextInfo, accessor),
        options,
      },
    ];

    if (detailsAccessor)
      groupItems.push(
        this.textArea({
          ...props,
          accessor: detailsAccessor,
          showContextInfo: props.showDetailsContextInfo,
          hidden: props.hidden || props.detailsHidden,
        })
      );

    return { type: 'group', groupItems };
  }

  private inputGroup(
    type: 'radioGroup' | 'checkboxGroup',
    props: InputGroupBuilder<FormType>
  ): FormField<FormType> {
    const {
      plainLabel,
      accessor,
      hidden,
      hiddenInTemplate,
      disabled,
      showContextInfo,
      options,
      detailsAccessor,
      optionDisplayValue,
      customOnChange,
    } = props;

    const defaultOptionDisplayValue = (option: string) =>
      t(`${this.tBase}.${accessor as string}Options.${option}`);

    const groupItems: FormField<FormType>[] = [
      {
        plainLabel,
        accessor,
        hidden,
        hiddenInTemplate,
        disabled,
        type,
        contextInfo: this.getContextInfo(showContextInfo, accessor),
        options,
        optionDisplayValue: optionDisplayValue ?? defaultOptionDisplayValue,
        customOnChange,
      },
    ];

    if (detailsAccessor)
      groupItems.push(
        this.textArea({
          ...props,
          accessor: detailsAccessor,
          showContextInfo: props.showDetailsContextInfo,
          hidden: props.hidden || props.detailsHidden,
        })
      );

    return { type: 'group', groupItems };
  }

  /**
   * Checkbox group,
   * with optional details field (text area)
   *
   */
  public checkboxGroup(
    props: InputGroupBuilder<FormType>
  ): FormField<FormType> {
    return this.inputGroup('checkboxGroup', props);
  }

  /**
   * Radio button group,
   * with optional details field (text area)
   *
   */
  public radioGroup(props: InputGroupBuilder<FormType>): FormField<FormType> {
    return this.inputGroup('radioGroup', props);
  }

  /**
   * Select input
   *
   *
   */
  public select(props: SelectBuilder<FormType>): FormField<FormType> {
    const { accessor, optionDisplayValue } = props;
  
    const defaultOptionDisplayValue = (option: string) =>
      option
        ? t(`${this.tBase}.${accessor as string}Options.${option}`)
        : undefined;
  
    return {
      type: 'select',
      optionDisplayValue: optionDisplayValue ?? defaultOptionDisplayValue,
      ...props,
    };
  }

  /**
   * Renders custom input
   *
   *
   */
  public custom(
    render: FormFieldRenderType,
    props: CustomBuilder = {}
  ): FormField<FormType> {
    return {
      type: 'custom',
      render,
      ...props,
    };
  }

  /**
   * Groups form fields
   *
   *
   */
  public group(props: GroupBuilder<FormType>): FormField<FormType> {
    const { items } = props;
    return {
      type: 'group',
      groupItems: items,
      ...props,
    };
  }

  /**
   * Groups form fields on one row
   *
   *
   */
  public groupRow(props: GroupBuilder<FormType>): FormField<FormType> {
    const { items } = props;
    return {
      type: 'groupRow',
      groupItems: items,
      ...props,
    };
  }

  /**
   * Groups form fields as expandable accordion
   *
   *
   */
  public accordionGroup(
    props: AccordionGroupBuilder<FormType>
  ): FormField<FormType> {
    const title =
      props.title ?? props.titleKey
        ? t(`${this.tBase}.${props.titleKey}`)
        : undefined;

    return {
      type: 'groupAccordion',
      title,
      groupItems: props.items,
      ...props,
    };
  }

}
