import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { validatePassword, QualificationLevel, Role } from '../../utils';
import {
  useFetchAuditFirms,
  useFetchCustomerCompanies,
  useFetchQualificationLevels,
  useFetchRoles,
} from '../../utils/hooks';
import Divider from '../Divider';
import { Button, Select, TextInput } from '../inputs';

interface UserFormProps {
  user: Partial<User>;
  onSubmit?: (params: Partial<User>) => void;
  onSuccess?: () => void;
  disabledFields?: (keyof User)[];
}

const UserForm: React.FC<UserFormProps> = observer(
  ({ user, onSubmit, onSuccess, disabledFields }) => {
    const { t } = useTranslation(['users', 'action']);

    const {
      authStore: { user: authUser, role: authRole },
      userStore: {
        addEntity,
        editEntity,
        getAuditFirmId,
        allRoles,
        allQualificationLevels,
      },
      auditFirmStore,
      customerCompanyStore,
      notificationStore: { notify },
    } = useStore();

    const editMode = !!user.id;
    const editingAuthUser = user.id === authUser?.id;

    const [email, setEmail] = useState(user.email ?? '');
    const [contactEmail, setContactEmail] = useState(user.contactEmail ?? '');
    const [role, setRole] = useState<Role | undefined>(user.role);
    const [firstName, setFirstName] = useState(user.firstName ?? '');
    const [lastName, setLastName] = useState(user.lastName ?? '');

    // Qualification level is filled only if user role is 'auditor'
    const [qualificationLevel, setQualificationLevel] = useState<
      QualificationLevel | undefined
    >(user.qualificationLevel);

    // Audit firm is filled only if user role is 'auditor' or 'auditor_admin'
    const [auditFirm, setAuditFirm] = useState<AuditFirm>();

    // Customer company is filled only if user role is 'customer'
    const [customerCompany, setCustomerCompany] = useState<CustomerCompany>();

    // Password is filled only for new users!
    const [password, setPassword] = useState('');

    const shouldSelectQualificationLevel = useMemo(
      () => !!role && [Role.Auditor].includes(role),
      [role]
    );

    const shouldSelectAuditFirm = useMemo(
      () => !!role && [Role.Auditor, Role.AuditorAdmin].includes(role),
      [role]
    );

    const shouldSelectCustomerCompany = useMemo(
      () => !!role && [Role.Customer].includes(role),
      [role]
    );

    // Fetch roles
    useFetchRoles();

    // Fetch qualification levels (when neccessary)
    useFetchQualificationLevels({ disabled: !shouldSelectQualificationLevel });

    // Fetch audit firms (when neccessary)
    useFetchAuditFirms({ disabled: !shouldSelectAuditFirm });

    // Fetch customer companies (when neccessary)
    useFetchCustomerCompanies({ disabled: !shouldSelectCustomerCompany });

    // Initialize role whenever user's role changes
    useEffect(() => {
      setRole(user.role);
    }, [user.role]);

    // Initialize qualification level whenever user's qualificationLevel changes
    useEffect(() => {
      setQualificationLevel(user.qualificationLevel);
    }, [user.qualificationLevel]);

    // Initialize user's audit firm whenever firms (or user.auditorFirmId) changes
    useEffect(() => {
      if (auditFirmStore.allEntities) {
        const auditFirmId = getAuditFirmId(user) ?? getAuditFirmId(authUser);
        setAuditFirm(auditFirmStore.findEntity(auditFirmId));
      }
    }, [
      auditFirmStore,
      auditFirmStore.allEntities,
      authUser,
      getAuditFirmId,
      user,
    ]);

    // Initialize user's company whenever companies (or user.customerCompanyId) changes
    useEffect(() => {
      if (customerCompanyStore.allEntities)
        setCustomerCompany(
          customerCompanyStore.findEntity(user.customerCompanyId)
        );
    }, [
      customerCompanyStore,
      customerCompanyStore.allEntities,
      user.customerCompanyId,
    ]);

    // Initialize (rest of) form data whenever user changes
    useEffect(() => {
      setEmail(user.email ?? '');
      setContactEmail(user.contactEmail ?? '');
      setFirstName(user.firstName ?? '');
      setLastName(user.lastName ?? '');
      setPassword('');
    }, [user]);

    const collectFormData = () => ({
      email,
      contactEmail,
      firstName,
      lastName,
      role,
      qualificationLevel: shouldSelectQualificationLevel
        ? qualificationLevel
        : undefined,
      auditFirmId: shouldSelectAuditFirm ? auditFirm?.id : undefined,
      customerCompanyId: shouldSelectCustomerCompany
        ? customerCompany?.id
        : undefined,
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const formData = collectFormData();

      let success: boolean | undefined;

      if (onSubmit) {
        onSubmit(formData);
      } else if (editMode && user.id) {
        const response = await editEntity({ id: user.id, data: formData });
        success = response.success;
      } else {
        const validationMessages = validatePassword(password);
        if (validationMessages) {
          validationMessages.forEach(message => notify(message, 'error'));
        } else {
          const response = await addEntity({
            data: { ...formData, password },
          });
          success = response.success;
        }
      }

      if (success && onSuccess) onSuccess();
    };

    const isRoleSelectDisabled = editingAuthUser || (role === Role.Admin && !authRole.isAdmin);

    return (
      <form onSubmit={handleSubmit}>
        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              label={t('users:label.firstName')}
              value={firstName}
              setValue={setFirstName}
              required
              disabled={disabledFields?.includes('firstName')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              label={t('users:label.lastName')}
              value={lastName}
              setValue={setLastName}
              required
              disabled={disabledFields?.includes('lastName')}
              fullWidth
            />
          </div>
        </div>

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            <TextInput
              type="text"
              label={t('users:label.username')}
              value={email}
              setValue={setEmail}
              autoFocus
              required
              disabled={disabledFields?.includes('email')}
              fullWidth
            />
          </div>

          <div className="flex-1">
            <TextInput
              type="email"
              label={t('users:label.contactEmail')}
              value={contactEmail}
              setValue={setContactEmail}
              autoFocus
              required
              disabled={disabledFields?.includes('contactEmail')}
              fullWidth
            />
          </div>
        </div>

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            {!editMode && (
              <TextInput
                type="password"
                label={t('users:label.password')}
                value={password}
                setValue={setPassword}
                required
              />
            )}
          </div>
        </div>

        <Divider noBorder />
              {/* Saadaan näkyviin "Asiakas" valinta. */}
              {/* "Asiakas" valinta näkyy Auditor Adminilla. */}
        <div className="flex-row flex-row-gap">
          <div className="flex-1">
          <Select<Role>
            label={t('users:label.role')}
            options={allRoles ?? []}
            value={role}
            setValue={setRole}
            displayValue={(role?: Role) =>
              role ? t(`users:role.${role}`) : ''
            }
            required
            disabled={isRoleSelectDisabled}
            fullWidth
          />
          </div>

          <div className="flex-1">
            {shouldSelectQualificationLevel && (
              <Select<QualificationLevel>
                label={t('users:label.qualificationLevel')}
                options={allQualificationLevels ?? []}
                value={qualificationLevel}
                setValue={setQualificationLevel}
                displayValue={(level?: QualificationLevel) =>
                  level ? t(`users:qualificationLevel.${level}`) : ''
                }
                required
                disabled={isRoleSelectDisabled}
                fullWidth
              />
            )}
          </div>
        </div>

        <div className="flex-row flex-row-gap">
          <div className="flex-1">
            {shouldSelectAuditFirm && (
              <Select<AuditFirm>
                autocomplete
                label={t('common:label.auditFirm')}
                options={auditFirmStore.allEntities ?? []}
                value={auditFirm}
                setValue={setAuditFirm}
                displayValue={(firm: AuditFirm) => firm?.name}
                required
                disabled={!authRole.isAdmin}
                fullWidth
              />
            )}

            {shouldSelectCustomerCompany && (
              <Select<CustomerCompany>
                autocomplete
                label={t('common:label.customerCompany')}
                options={customerCompanyStore.allEntities ?? []}
                value={customerCompany}
                setValue={setCustomerCompany}
                displayValue={(company: CustomerCompany) => company?.name}
                required
                disabled={!authRole.isAdmin}
                fullWidth
              />
            )}
          </div>
          <div className="flex-1"></div>
        </div>

        <Divider noBorder />

        <Button text={t('action:save')} type="submit" />
      </form>
    );
  }
);

export default UserForm;
