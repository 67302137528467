import { uuid } from '../../../utils';

type VisibleData = {
  [key: string]: (keyof ManagementForm)[];
};

export const emptyDetailedInformationItem = (
  props: {
    value?: string;
    page?: string;
  } = {}
): DetailedInformationItem => ({
  id: uuid(),
  value: props.value ?? '',
  page: props.page ?? '',
});

export const emptyEssentialNotesItem = (
  props: {
    minutes?: string;
    date?: string;
    section?: string;
    notes?: string;
    reference?: string;
    mustBeNoted?: boolean;
  } = {}
): EssentialNotesItem => ({
  id: uuid(),
  minutes: props.minutes ?? '',
  date: props.date ?? '',
  section: props.section ?? '',
  notes: props.notes ?? '',
  reference: props.reference ?? '',
  mustBeNoted: props.mustBeNoted ?? false,
});

export const visibleData: VisibleData = {
  publicNetworkDocuments: [
    'municipalStrategy',
    'administrativeRule',
    'budget',
    'financialStatement',
    'auditBoardEvaluationReport',
    'auditReport',
    'municipalCooperationAgreements',
    'groupInstruction',
    'boardCommitments',
    'boardRewardJustifications',
    'serviceFees',
  ],
  municipalStrategy: ['programmeOfMeasures'],
  administrativeRule: ['administrativeRuleMeetsRequirements'],
  budget: [
    'budgetAndMunicipalStrategyInLine',
    'isBudgetRealistic',
    'budgetAndChangesProperlyApproved',
  ],
  internalInstructions: [
    'councilGuidelinesForInternalInstructions',
    'internalInstructionsMeetCouncilGuidelines',
    'internalInstructionsUpToDate',
    'currentPracticesAreValid',
  ],
  groupInstruction: [
    'planningOfBudgetAndInvestments',
    'groupSupervisionAndReporting',
    'informingAndInformationAccess',
    'obligationToGetMunicipalitysOpinionBeforeDecisions',
    'groupsInternalServices',
    'subsidiaryBoardCompositionAndAppointment',
    'subsidiaryGoodManagementAndLeadership',
  ],
  boardMeetingMinutes: [],
  councilMeetingMinutes: [
    'previousYearFinancialStatementConfirmed',
    'nextYearBudgetApproved',
    'handlingOfBudgetChanges',
    'isBudgetReviewedEveryQuarter',
    'affiliationNotices',
    'evaluationReport',
  ],
  reviewOfPriorityBoards: [],
  reviewOfPriorityOfficials: [],
  insurancePolicies: [],
  affiliationRegister: [],
  auditOfRelatedPartyTransactions: [],
};
