import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { PaymentConfirmationCalculationForm } from '../../../components/forms/accountsReceivableCalculationForm';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ReusableCalculationForm } from './reUsables/ReusableTableColumn';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = AccountsReceivableForm;
const sectionKey: AuditingSectionKey = 'accountsReceivable';

export const tBase = `auditing:form.${sectionKey}`;
const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.accountsReceivable;
const batchString = "J2-accountsReceivable"


const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsData: {},
  procedureAccountsManualData: [{
    currentYear: 0,
    priorYear: 0,
    accountName: "",
    comment: ""
  }],
  tableColumnData: [
    {
      left: "Taseelle merkityt varat ovat olemassa",
      right: auditingJson.OLE
    },
    {
      left: "Yhtiö on oikeutettu saamaan suorituksen kolmannelta osapuolelta taseelle merkityssä määrässä ja määrä edustaa asiakkaiden velkaa raportointikauden lopussa	",
      right: auditingJson.OVE
    },
    {
      left: "Saamiset on kirjattu ja kirjaukset on tehty oikeassa summassa ja mahdolliset alaskirjaustarpeet on tunnistettu 	",
      right: auditingJson.TAY_ARV
    },
    {
    left: "Saamiset on luokiteltu taseella asianmukaisesti, kyseisiä eriä koskevat tilinpäätöksessä esitettävät tiedot on määritetty ja kuvattu asianmukaisesti - esittämistapa , luokittelu	",
    right: auditingJson.ESI_LUO
    },
  ],
  secondTableColumnData: [
    {
      left: "",
      right: ""
    },
  ],

  reasonableEvidenceFound: jsonVocab.reasonableEvidenceFoundValue,
  
  // Myyntireskontran täsmäytys taseelle
  useSheet: null,
  sheetdata1: [{
      cellData: [],
  }],
  useCalculationForm: false,
  useCalculationForm2: false,
  useCalculationForm3: false,
  additionalInfo: jsonVocab.additionalInfoValue,
  additionalInfo2: jsonVocab.additionalInfoValue2,

  calculationFormData: {// Tarvitaan CalculationFormia varten
    "1": 
    [
      {
        firstField: "Myyntisaamiset tili",
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: ""
      }
    ],
    "2": 
    [
      {
        firstField: "Myyntisaamiset tili",
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: ""
      }
    ],
    "3": 
      [
        {
          firstField: "Esimerkki asiakas",
          secondField: 0,
          thirdField: 0,
          calculatedAmount: 0,
          comment: ""
        }
      ],
    "4": 
      [
        {
          customer: "",
          billNumber: 0,
          balanceAtReskontra: 0,
          paymentDateOnStatement: "__:__:____",
          paymentConfirmationOnStatement: 0,
          comment: ""
        }
      ],
  },

  useSheet5: null,
  sheetdata5: [{
      cellData: [],
  }],
  useSheet6: null,
  sheetdata6: [{
      cellData: [],
  }],
  useSheet7: null,
  sheetdata7: [{
      cellData: [],
  }],

  // Myyntisaamisten läpikäynti
  describeAmountAndActions: auditingJson.describeAmountAndActions,
  describeAmountAndActions2: auditingJson.describeAmountAndActions,
  describeAmountAndActions3: auditingJson.describeAmountAndActions,

  saleEarningsNotExpired: null,
  saleEarningsIsExpired: null,
  salesRelatedNegativeBalancesNo: null,
  salesRelatedNegativeBalancesYes: null,
  abnormalBalancesNoticed: null,
  noAbnormalBalancesNoticed: null,

  useSheet2: null,
  sheetdata2: [{
      cellData: [],
  }],

  // Myyntisaamisten olemassaolo - saldovahvistukset
  useSheet3: null,
  sheetdata3: [{
      cellData: [],
  }],

  requestedConfirmationsReceivedTrue: null,
  requestedConfirmationsReceivedFalse: null,

  describeSituationAndPlan: auditingJson.defaultMoreDetailsAndPlannedActionsValue,
  
  //Myyntisaamisten olemassaolo - Maksuun tarkastus
  useSheet4: null,
  sheetdata4: [{
      cellData: [],
  }],
  accountsReceivableExistsPaymentConfirmationAMPT: null,
  useCalculationForm4: null,
  

  useSheetTab: null,
})



const formFields: FormFields<SectionFormType> = ({ formState, auditing, store }) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({accessor: "useSheetTab", plainLabel:"Käytä laskenta taulukko osiota"}),
      formBuilder.custom(props => <ProcedureAccountsElement {...props} auditing={auditing} batchString={batchString} addCommentField={true} />),
      formBuilder.custom(props => <TableColumn title={auditingJson.ideasGoalsAndCompletedWork} {...props} />),
      formBuilder.textArea({accessor: 'reasonableEvidenceFound'}),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Myyntireskontran täsmäytys taseelle
      accordionId: 'accountsReceivableReconciliation',
      lowerElements: [
        formBuilder.boolean({accessor: 'useSheet', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null),
        
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculationForm'}),
        formBuilder.custom(props => 
          formState.useCalculationForm ? <ReusableCalculationForm sectionKey={sectionKey} 
            fieldTitles={["Tili", "Saldo reskontralla tai erilliskirjanpidossa (EUR)", "Saldo pääkirjanpidossa (EUR)"]} {...props} id="1" /> : null
        ),
        formBuilder.textArea({
          accessor: 'additionalInfo',
          plainLabel: auditingJson.giveAdditionalInfo,
          hidden: formState.useCalculationForm === false
        }),

        formBuilder.boolean({plainLabel: auditingJson.openTableAgain, accessor: 'useCalculationForm2', hidden: formState.useCalculationForm === false}),
        formBuilder.custom(props => 
          formState.useCalculationForm && formState.useCalculationForm2 ? <ReusableCalculationForm sectionKey={sectionKey} 
          fieldTitles={["Tili", "Saldo reskontralla tai erilliskirjanpidossa (EUR)", "Saldo pääkirjanpidossa (EUR)"]} {...props} id="2" /> : null
        ),
        formBuilder.textArea({
          accessor: 'additionalInfo2',
          plainLabel: auditingJson.giveAdditionalInfo,
          hidden: formState.useCalculationForm2 === false
        }),
      ]
    },
    {
      // Myyntireskontran matemaattinen oikeellisuus
      accordionId: 'mathematicalAccuracy',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheet5'}),
        formBuilder.custom(props => formState.useSheet5 ? <SpreadSheet id="sheetdata5" {...props} /> : null),
      ]
    },
    {
      // Myyntisaamisten läpikäynti
      accordionId: 'recheckingAccountsReceivable',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheet2'}),
        formBuilder.custom(props => formState.useSheet2 ? <SpreadSheet id="sheetdata2" {...props} /> : null),
        // Myyntisaamisten erääntyminen
        formBuilder.group({items: [
          formBuilder.boolean({accessor: 'saleEarningsNotExpired'}),
          formBuilder.boolean({accessor: 'saleEarningsIsExpired'}),
          formBuilder.textArea({plainLabel: auditingJson.describeAmountAndActions, accessor: 'describeAmountAndActions', hidden: formState.saleEarningsIsExpired !== true}),
        ]}),

        // Myyntisaamisiin sisältyvät negatiiviset saldot
        formBuilder.group({items: [
          formBuilder.boolean({accessor: 'salesRelatedNegativeBalancesNo'}),
          formBuilder.boolean({accessor: 'salesRelatedNegativeBalancesYes'}),
          formBuilder.textArea({plainLabel: auditingJson.describeAmountAndActions, accessor: 'describeAmountAndActions2', hidden: formState.salesRelatedNegativeBalancesYes !== true}),
        ]}),
        
        // Myyntireskontran läpikänti poikkeavien erien tai saldojen varalta
        formBuilder.group({items: [
          formBuilder.boolean({accessor: 'abnormalBalancesNoticed'}),
          formBuilder.boolean({accessor: 'noAbnormalBalancesNoticed'}),
        ]}),
      ]
    },
    {
      // Myyntisaamisten olemassaolo - alkaneen kauden hyvityslaskut
      accordionId: 'payments',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheet6'}),
        formBuilder.custom(props => formState.useSheet6 ? <SpreadSheet id="sheetdata6" {...props} /> : null),
      ]
    },
    {
      // Myyntisaamisten olemassaolo - saldovahvistukset
      accordionId: 'balanceConfirmation',
      upperElements: [
        formBuilder.group({items: [
          formBuilder.boolean({accessor: 'requestedConfirmationsReceivedTrue'}),
          formBuilder.boolean({accessor: 'requestedConfirmationsReceivedFalse'}),
          formBuilder.textArea({accessor: 'describeSituationAndPlan', hidden: formState.requestedConfirmationsReceivedFalse !== true, plainLabel: auditingJson.defaultMoreDetailsAndPlannedActionsValue}),
        ]}),
      ],
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculationForm3'}),
        formBuilder.custom(props => 
          formState.useCalculationForm3 ? <ReusableCalculationForm 
            fieldTitles={["Asiakas", "Saldo reskontralla (EUR)", "Saldo vahvistuksella (EUR)"]} 
            {...props} id="3" addCommentField={true} sectionKey={sectionKey} /> : null
        ),
        formBuilder.boolean({accessor: "useSheet3", plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet3 ? <SpreadSheet id="sheetdata3" {...props} /> : null),
      ]
    },
    {
      // Myyntisaamisten olemassaolo - Maksuun tarkastus
      accordionId: 'paymentConfirmation',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useCalculation, accessor: 'useCalculationForm4'}),
        formBuilder.custom(props => 
          formState.useCalculationForm4 ? <PaymentConfirmationCalculationForm {...props} id="4" /> : null
        ),
        formBuilder.boolean({accessor: 'useSheet4', plainLabel: auditingJson.useSheet}),
        formBuilder.custom(props => formState.useSheet4 ? <SpreadSheet id="sheetdata4" {...props} /> : null),
      ]
    },
    {
      // Myynitysaamisten luokittelu ja esittäminen
      accordionId: 'classificationAndPresentation',
      lowerElements: [
        formBuilder.boolean({plainLabel: auditingJson.useSheet, accessor: 'useSheet7'}),
        formBuilder.custom(props => formState.useSheet7 ? <SpreadSheet id="sheetdata7" {...props} /> : null),
      ]
    }
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
