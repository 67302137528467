import { findByKey } from '../../../utils/array';
import {
  getValueOrDefaultValue,
  makeEmptyThresholdTestRow,
} from './auditingProceduresUtils';

/**
 * Define rows for auditing procedure unit rows.
 */
export const analyticalAuditOfWagesRows = () => [
  makeEmptyThresholdTestRow({
    key: 'pyPayrollExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'pyLength',
    defaultValue: 12,
    unit: 'kk',
  }),
  makeEmptyThresholdTestRow({
    key: 'pyNumberOfEmployees',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'pyPayPerEmployeePerMonth',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'inflationOrGeneralIncreases',
    defaultValue: 2.2,
    unit: '%',
  }),
  makeEmptyThresholdTestRow({
    key: 'cyNumberOfEmployees',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'cyLength',
    unit: 'kk',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'expectedPayrollExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceInActualAndExpectedPayrollExpenses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({ key: 'THRESHOLD', unit: '€' }),
  makeEmptyThresholdTestRow({ key: 'CONCLUSION', disabled: true }),
];

/**
 * Define computation rules/formulas for each row.
 */
const computationRules: ThresholdComputationRules = {
  // Tuloslaskelman palkat + aktivoidut palkat
  pyPayrollExpenses: ({ getAccountMappingValues }) => {
    const [wagesTotal, capitalizedWagesOrOtherAdjustmentsAccounts] =
      getAccountMappingValues.py(
        'wagesTotal',
        'capitalizedWagesOrOtherAdjustmentsAccounts'
      );
    return Math.abs(wagesTotal + capitalizedWagesOrOtherAdjustmentsAccounts);
  },

  // Tilikauden pituus PY
  pyLength: ({ data }) => {
    return getValueOrDefaultValue(data.find(findByKey('pyLength')));
  },

  // Henkilömäärä PY
  pyNumberOfEmployees: ({ auditingStore }) => {
    return (
      auditingStore.auditing?.personelCosts?.form.averageNumberOfPersonsPY ?? 0
    );
  },

  // Palkka per henkilö/kk PY
  pyPayPerEmployeePerMonth: props => {
    const { pyPayrollExpenses, pyLength, pyNumberOfEmployees } =
      computationRules;

    const employeeCount = pyNumberOfEmployees(props);
    const length = pyLength(props);

    if (employeeCount === 0 || length === 0) return 0;

    return pyPayrollExpenses(props) / employeeCount / length;
  },

  // Inflaatio/yleiskorotukset
  inflationOrGeneralIncreases: ({ data }) => {
    return getValueOrDefaultValue(
      data.find(findByKey('inflationOrGeneralIncreases'))
    );
  },

  // Henkilömäärä CY
  cyNumberOfEmployees: ({ auditingStore }) => {
    return (
      auditingStore.auditing?.personelCosts?.form.averageNumberOfPersonsCY ?? 0
    );
  },

  // Tilikauden pituus CY
  cyLength: props => {
    const months = props.auditingStore.getFinancialPeriodMonthCount();
    return months ?? 12;
  },

  // Odotusarvoinen palkkakulu
  //  =Palkka per henkilö/kk PY x (1 + inflaatio) x Henkilömäärä CY x Tilikauden pituus CY
  expectedPayrollExpenses: props => {
    const {
      pyPayPerEmployeePerMonth,
      inflationOrGeneralIncreases,
      cyNumberOfEmployees,
      cyLength,
    } = computationRules;

    const payPerEmployee = pyPayPerEmployeePerMonth(props);
    const inflation = inflationOrGeneralIncreases(props);
    const employeeCount = cyNumberOfEmployees(props);
    const length = cyLength(props);

    return payPerEmployee * (inflation / 100 + 1) * employeeCount * length;
  },

  // Toteuman ja odotusarvon erotus
  differenceInActualAndExpectedPayrollExpenses: props => {
    const { expectedPayrollExpenses } = computationRules;

    const [wagesTotal, capitalizedWagesOrOtherAdjustmentsAccounts] =
      props.getAccountMappingValues.cy(
        'wagesTotal',
        'capitalizedWagesOrOtherAdjustmentsAccounts'
      );
    const cyWages = Math.abs(
      wagesTotal + capitalizedWagesOrOtherAdjustmentsAccounts
    );
    return expectedPayrollExpenses(props) - cyWages;
  },

  // KYNNYSARVO
  THRESHOLD: ({ data, auditingStore }) => {
    return Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('THRESHOLD')),
        auditingStore.materialityComputeds().materiality
      )
    );
  },

  // JOHTOPÄÄTÖS (ERO KYNNYSARVOON)
  CONCLUSION: props => {
    const threshold = computationRules.THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceInActualAndExpectedPayrollExpenses(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },
};

export const analyticalAuditOfWagesComputationRules = computationRules;
