/**
 * Tools for 
 * - changing results to points
 * - calculating the health index
 * - generating the report
 * - rendering the pie chart
 */
import React, { useEffect, useState } from "react";
import { AuditingProcedureResult } from "../auditing/auditingProceduresUtils";
import { Risk } from "../analyzes/incomeStatementAndBalance";
import { Estimate } from "../../../components/analyzes/Indicators.Table";
import Chart, { ChartConfiguration } from 'chart.js/auto';
import { Button } from "../../../components/inputs";
import styled from "styled-components";

const Container = styled.div`
    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }
    .pie-chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        canvas {
            max-width: 400px;
            max-height: 400px;
        }
    }
`;

// Change auditingResults to points
const getAuditingResultPoints = ({ auditingResult }: ReportingProcedure) => {
    switch (auditingResult) {
        case AuditingProcedureResult.treatedGood:
            return 4;
        case AuditingProcedureResult.treatedModerately:
            return 2;
        case AuditingProcedureResult.shortcomings:
            return 0;
        default:
            return 0;
    }
}
const getRiskPoints = ({ risk }: IncomeStatementAndBalanceItem) => {
    switch (risk) {
        case Risk.significant:
            return 0;
        case Risk.limitedRisk:
            return 2;
        case Risk.notSignificant:
            return 4;
        default:
            return 0;
    }
}

// Change indicators 'estimate' to points
const getIndicatorsPoints = ({ estimate }: IndicatorsItem) => {
    switch (estimate) {
        case Estimate.Good:
            return 4;
        case Estimate.Moderate:
            return 2;
        case Estimate.Poor:
            return 0;
        default:
            return 0;
    }

}

let timesFour = 4;

const calculateHealthIndexProcedures = (
    procedures: ReportingProcedure[] | undefined,
) => {
    if (!procedures) return 0;

    const totalPoints = procedures?.reduce((acc, row) => acc + getAuditingResultPoints(row), 0) || 0;
    const maxPoints = (procedures?.length || 0) * timesFour; // Assuming the max points for each item is 4 (for risk)
    let percentage = (totalPoints / maxPoints) * 100;

    // Cap the percentage at 100
    percentage = Math.min(percentage, 100);

    return percentage;
}

const calculateHealthIndexStatements = (
    statements: Statement[] | undefined,
) => {
    if (!statements) return 0;

    let totalPoints = 0;
    let maxPoints = 0;

    statements?.forEach((statement) => {
        const statementItems = [...statement.incomeStatement, ...statement.balanceAssets, ...statement.balanceLiabilities];
        statementItems.forEach((item) => {
            totalPoints += getRiskPoints(item);
            maxPoints += timesFour; // Assuming the max points for each item is 4 (for risk)
        });
    });

    let percentage = (totalPoints / maxPoints) * 100;

    // Cap the percentage at 100
    percentage = Math.min(percentage, 100);

    return percentage;
}

const calculateHealthIndexIndicators = (
    indicators: IndicatorsItem[] | undefined,
) => {
    if (!indicators) return 0;

    // Filter out the indicators that doesnt have an estimate
    const filteredIndicators = indicators?.filter((indicator) => indicator.estimate !== undefined) || [];
    const totalPoints = filteredIndicators?.reduce((acc, row) => acc + getIndicatorsPoints(row), 0) || 0;
    const maxPoints = (filteredIndicators?.length || 0) * timesFour; // Assuming the max points for each item is 4 (for risk)
    let percentage = (totalPoints / maxPoints) * 100;

    // Cap the percentage at 100
    percentage = Math.min(percentage, 100);

    return percentage;
}

export const calculateHealthIndex = (
    form: ReportingForm,
) => {
    const proceduresIndex = calculateHealthIndexProcedures(form.procedures);
    const statementsIndex = calculateHealthIndexStatements(form.statements);
    const indicatorsIndex = calculateHealthIndexIndicators(form.indicators);

    // Combine the two indexes in some way, for example by averaging them
    const combinedIndex = (proceduresIndex + statementsIndex + indicatorsIndex);

    console.log(`Health index for both: ${combinedIndex}%`);
}

// Component + after pressing the input button, the health index is calculated and printed without the console.log
export const HealthIndexButton = ({ formState }: { formState: ReportingForm }) => {
    const [pressed, setPressed] = useState(false);

    if (!pressed) {
        return <input type='button' value='Test calculateHealthIndex' onClick={() => { calculateHealthIndex(formState); setPressed(true); }} />;
    }

    const proceduresIndex = calculateHealthIndexProcedures(formState.procedures).toFixed(2);
    const statementsIndex = calculateHealthIndexStatements(formState.statements).toFixed(2);
    const indicatorsIndex = calculateHealthIndexIndicators(formState.indicators).toFixed(2);
    
    // Convert the strings back to numbers before adding them together
    const combinedIndex = (parseFloat(proceduresIndex) + parseFloat(statementsIndex) + parseFloat(indicatorsIndex)).toFixed(2);
    
    return (
        <>
            <input type='button' value='Test calculateHealthIndex' onClick={() => calculateHealthIndex(formState)} />
            {pressed &&
                <>
                <p>Health index for procedures: {proceduresIndex}%</p>
                <p>Health index for statements: {statementsIndex}%</p>
                <p>Health index for indicators: {indicatorsIndex}%</p>
                <p>Combined index: {combinedIndex}%</p>
                </>
            }
        </>
    );
}

const HealthIndexPieChart = ({ formState }: { formState: ReportingForm }) => {

    const [shouldRenderChart, setShouldRenderChart] = useState(false);

    const proceduresIndex = parseFloat(calculateHealthIndexProcedures(formState.procedures).toFixed(2));
    const statementsIndex = parseFloat(calculateHealthIndexStatements(formState.statements).toFixed(2));
    const indicatorsIndex = parseFloat(calculateHealthIndexIndicators(formState.indicators).toFixed(2));

    // Combine procedures and statements indexes
    const combinedProceduresAndStatements = (proceduresIndex + statementsIndex).toFixed(2);

    // Placeholder for pieText[1] and pieText[3]
    const internalControl = 0;
    const lawAndTaxation = 0;

    // Only render the chart once the indexes have been calculated
    useEffect(() => {
        if (proceduresIndex !== undefined && statementsIndex !== undefined && indicatorsIndex !== undefined) {
            setShouldRenderChart(true);
        } else {
            setShouldRenderChart(false);
        }
    }, [proceduresIndex, statementsIndex, indicatorsIndex]);

    const pieText = [
        `Tilinpäätös ja kirjanpito`, // Yellow
        'Sisäinen valvonta ja hallinto', // Orange
        `Liiketoiminnan tunnusluvut`, // Blue
        'Laki ja verotus', // Red
    ]

    const renderChart = () => {
        // Destroy the previous chart if it exists
        const existingChart = Chart.getChart('myChart');
        if (existingChart) {
            existingChart.destroy();
        }

        // Configuration for the pie chart
        const config: ChartConfiguration<'pie', number[], string> = {
            type: 'pie',
            data: {
                labels: [pieText[0], pieText[1], pieText[2], pieText[3]],
                datasets: [{
                    label: 'Health Index',
                    data: [parseFloat(combinedProceduresAndStatements), internalControl, indicatorsIndex, lawAndTaxation], // Ensure these are calculated correctly
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)', // Background color for combinedProceduresAndStatements
                        'rgba(54, 162, 235, 0.5)', // Background color for internalControl
                        'rgba(255, 206, 86, 0.5)', // Background color for indicatorsIndex
                        'rgba(75, 192, 192, 0.5)'  // Background color for lawAndTaxation
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                responsive: true,
                aspectRatio: 1, // Default is 2
                plugins: {
                    legend: { position: 'top' },
                    title: { display: true, text: 'Talvea Audit' },
                    subtitle: { display: true, text: 'Health Index' },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                return `${context.label}: ${context.parsed}%`;
                            }
                        }
                    }
                }
            },
        };
        // Render the chart
        const canvas = document.getElementById('myChart') as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                new Chart(ctx, config);
            } else {
                console.error('Failed to get canvas context');
            }
        }
    };

    return (
        <>
        <Container>
            <div className="button-container">
                <Button
                    icon='AuditFirm'
                    text="Render chart"
                    onClick={() => renderChart()}
                    variant="outlined"
                />
            </div>
        {shouldRenderChart &&
            <div className="pie-chart-container">
                <canvas id="myChart"></canvas>
            </div>
        }
        </Container>
        </>
    )
}

// Function to push the data to formState
// Gather all the data that is in formState and use patchFormState to push the data to formState
export const pushDataToFormState = (
    formState: ReportingForm,
    data: ReportingForm,
    patchFormState?: (data: ReportingForm) => void,
) => {
    const newData = { ...formState, ...data };
    if (patchFormState) {
        patchFormState(newData);
    }
}

export default HealthIndexPieChart;