import React, { useEffect, useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';
import { uuid } from '../../../../utils';

interface Props {
    auditing?: Auditing;
    formState: any;
    patchFormState: Function;
    id: string
}

export const TaxCalculationForm: React.FC<Props> = ({ formState, patchFormState, id }) => {
    /*
      Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
      
        const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
            TaxCalculationFormData: []
        })
    */

    if(!formState.TaxCalculationFormData[id].hasOwnProperty("taxPercent")){
        patchFormState({
            ...formState,
            TaxCalculationFormData: {
                ...formState.TaxCalculationFormData,
                [id]: {
                    earningsBeforeTaxes: 100000,
                    taxPercent: 20,
                    additions: [
                        {
                            label: 'Edustuskulut',
                            value: 0,
                            id: "1a"
                        },
                        {
                            label: 'Verojen viivästysseuraukset',
                            value: 0,
                            id: "2a"
                        },
                        {
                            label: 'Sakot',
                            value: 0,
                            id: "3a"
                        },
                        {
                            label: 'Poistot, jotka ylittävät EVL poistot',
                            value: 0,
                            id: "4a"
                        },
                    ],
                    subtractions: [
                        {
                            label: 'Vahvistetut tappiot',
                            value: 0,
                            id: "1s"
                        },
                        {
                            label: 'Verovapaat osingot',
                            value: 0,
                            id: "2s"
                        },
                        {
                            label: 'Aikaisempina vuosina tehdyt poistot, (hyllypoistot)',
                            value: 0,
                            id: "3s"
                        },
                    ],
                    preTaxesOfSeason: 10000,
                    accountingTaxPeriod: 10000
                }
            }
        })
    }
  
    const [data, setData] = useState({
        earningsBeforeTaxes: 100000,
        taxPercent: 20,
        additions: [
            {
                label: 'Edustuskulut',
                value: 0,
                id: "1a"
            },
            {
                label: 'Verojen viivästysseuraukset',
                value: 0,
                id: "2a"
            },
            {
                label: 'Sakot',
                value: 0,
                id: "3a"
            },
            {
                label: 'Poistot, jotka ylittävät EVL poistot',
                value: 0,
                id: "4a"
            },
        ],
        subtractions: [
            {
                label: 'Vahvistetut tappiot',
                value: 0,
                id: "1s"
            },
            {
                label: 'Verovapaat osingot',
                value: 0,
                id: "2s"
            },
            {
                label: 'Aikaisempina vuosina tehdyt poistot, (hyllypoistot)',
                value: 0,
                id: "3s"
            },
        ],
        preTaxesOfSeason: 10000,
        accountingTaxPeriod: 10000
    })
    
    useEffect(() => {
        formState && setData((prevData) => ({
            ...prevData,
            ...formState.TaxCalculationFormData[id]
        }));
    }, [formState, id]); // Fix: Add formState and id to the dependency array
  
    function handleEdit(props:any) {
        var patch = {}
        if(props.itemId === 'taxPercent'){
            patch = {
                taxPercent: props.value
            }
        }else if(props.itemId === 'earningsBeforeTaxes'){
            patch = {
                earningsBeforeTaxes: props.value
            }
        }else if(props.itemId === 'preTaxesOfSeason'){
            patch = {
                preTaxesOfSeason: props.value
            }
        }else if(props.itemId === 'accountingTaxPeriod'){
            patch = {
                accountingTaxPeriod: props.value
            }
        }else if(props.itemId !== undefined){
            if(props.itemId.includes("s")){
                const index = formState.TaxCalculationFormData[id].subtractions.findIndex((item:any) => item.id === props.itemId)
                const duplicateSubtractions = [...formState.TaxCalculationFormData[id].subtractions]
                duplicateSubtractions[index].value = props.value
                patch = {
                    subtractions: duplicateSubtractions
                }
            }
            if(props.itemId.includes("a")){
                const index = formState.TaxCalculationFormData[id].additions.findIndex((item:any) => item.id === props.itemId)
                const duplicateSubtractions = [...formState.TaxCalculationFormData[id].additions]
                duplicateSubtractions[index].value = props.value
                patch = {
                    additions: duplicateSubtractions
                }
            }
        }
        props.itemId !== undefined && patchFormState({
            ...formState,
            TaxCalculationFormData: {
                ...formState.TaxCalculationFormData,
                [id]: {
                    ...formState.TaxCalculationFormData[id],
                    ...patch
                }
            }
        })
    }

    function calculateTaxedIncomes(){
        var additionsAllTogether = 0;
        var subtractionsAllTogether = 0;

        for(var i=0; i<data.additions.length; i++){
            additionsAllTogether += data.additions[i].value;
        }
        for(var l=0; l<data.subtractions.length; l++){
            subtractionsAllTogether += data.subtractions[l].value;
        }
        return (additionsAllTogether + data.earningsBeforeTaxes) - subtractionsAllTogether
    }

    function calculateAmountOfTaxesToBePaid(calculatedTaxedIncomes: number){
        return calculatedTaxedIncomes * data.taxPercent
    }
  
    var tableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => null,
        },
        {
            accessorKey: 'value',
            header: () => null,
            className: 'width-min text-center nowrap',
            onNumberEdit: handleEdit
        }
    ];

    var unEditableTableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => null,
        },
        {
            accessorKey: 'value',
            header: () => null,
            className: 'width-min text-center nowrap',
            accessorFn: row => row.value + " €"
        }
    ];

    var fullyEditableTableColumns: TableColumnDefs<any> = [
        {
            accessorKey: 'label',
            className: 'width-min',
            header: () => null,
            onEdit: handleEdit
        },
        {
            accessorKey: 'value',
            header: () => null,
            className: 'width-min text-center nowrap',
            onNumberEdit: handleEdit
        }
    ];
    
    function addNewRow(additionsOrSubtractions: any){
        if(additionsOrSubtractions === "subtractions"){
            patchFormState({
                ...formState,
                TaxCalculationFormData: {
                    ...formState.TaxCalculationFormData,
                    [id]: {
                        ...formState.TaxCalculationFormData[id],
                        subtractions: [
                            ...formState.TaxCalculationFormData[id].subtractions,
                            {
                                label: 'Lisää otsikko',
                                value: 0,
                                id: uuid()
                            },
                        ]
                    }
                }
            })
        }else if(additionsOrSubtractions === "additions"){
            patchFormState({
                ...formState,
                TaxCalculationFormData: {
                    ...formState.TaxCalculationFormData,
                    [id]: {
                        ...formState.TaxCalculationFormData[id],
                        additions: [
                            ...formState.TaxCalculationFormData[id].additions,
                            {
                                label: 'Lisää otsikko',
                                value: 0,
                                id: uuid()
                            },
                        ]
                    }
                }
            })
        }
    }

    function removeRow(data: any, additionsOrSubtractions: any){
        if(additionsOrSubtractions === "subtractions"){
            const newSubtractions = formState.TaxCalculationFormData[id].subtractions.filter((item:any) => !(item.label === data.label && item.value === data.value))
            patchFormState({
                ...formState,
                TaxCalculationFormData: {
                    ...formState.TaxCalculationFormData,
                    [id]: {
                        ...formState.TaxCalculationFormData[id],
                        subtractions: newSubtractions
                    }
                }
            })
        }else if(additionsOrSubtractions === "additions"){
            const newAdditions = formState.TaxCalculationFormData[id].additions.filter((item:any) => !(item.label === data.label && item.value === data.value))
            patchFormState({
                ...formState,
                TaxCalculationFormData: {
                    ...formState.TaxCalculationFormData,
                    [id]: {
                        ...formState.TaxCalculationFormData[id],
                        additions: newAdditions
                    }
                }
            })
        }
    }
    
    return (
      <>

        <AuditingTable
            data={[
                {label: "Veroprosentti %", value: data.taxPercent, id: "taxPercent"},
                {label: "Voitto ennen veroja €", value: data.earningsBeforeTaxes, id: "earningsBeforeTaxes"}
            ]}
            columns={tableColumns}
            showGlobalFilter={false}
        />

        <AuditingTable
            title='Lisäykset'
            hideHeader
            data={data.additions}
            columns={fullyEditableTableColumns}
            showGlobalFilter={false}
            onAddNewRow={() => addNewRow('additions')}
            onRowDelete={(data: any) => removeRow(data, 'additions')}
        />
        <h3>Vähennykset</h3>
        <p>(Lisätään positiivisen merkkisinä laskelmaan)</p>
        <AuditingTable
            hideHeader
            data={data.subtractions}
            columns={fullyEditableTableColumns}
            showGlobalFilter={false}
            onAddNewRow={() => addNewRow('subtractions')}
            onRowDelete={(data: any) => removeRow(data, 'subtractions')}
        />
        <hr></hr>
        <h4 style={{margin: 0, marginTop:40}}>Verotettava tulo yhteensä: <i>{calculateTaxedIncomes()}</i> €</h4>
        <h4 style={{margin: 0, marginBottom:40}}>Josta verot: <i>{calculateAmountOfTaxesToBePaid(calculateTaxedIncomes()/100)}</i> €</h4>
        <hr></hr>
        <AuditingTable
            hideHeader
            data={[
                {
                    label: "Tilikauden ennakkoverot",
                    value: data.preTaxesOfSeason,
                    id: 'preTaxesOfSeason'
                },
            ]}
            columns={tableColumns}
            showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
            data={[
                {
                    label: "Verovelka / -Verosaaminen",
                    value: calculateAmountOfTaxesToBePaid(calculateTaxedIncomes()/100) - data.preTaxesOfSeason,
                }
            ]}
            columns={unEditableTableColumns}
            showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
            data={[
                {
                    label: "Kirjanpidon verojaksotus",
                    value: data.accountingTaxPeriod,
                    id: "accountingTaxPeriod"
                },
            ]}
            columns={tableColumns}
            showGlobalFilter={false}
        />
        <AuditingTable
            hideHeader
            data={[
                {
                    label: "Ero",
                    value: (calculateAmountOfTaxesToBePaid(calculateTaxedIncomes()/100) - data.preTaxesOfSeason) - data.accountingTaxPeriod
                }
            ]}
            columns={unEditableTableColumns}
            showGlobalFilter={false}
        />
      </>
    );
  };
  
  